{
  "_comment": "[CI-LOCKED] 이 파일의 내용이 잠겼습니다. 기여를 환영하지만 변경 유효성 검사가 지연될 수 있습니다.",
  "ACCESS_KEY_ALT": "Alt Shift $1",
  "ACCESS_KEY_FOR_MAC_ALT": "Control Option $1",
  "ACTIVITY_BOT_SAID_ALT": "봇 $1의 말:",
  "ACTIVITY_YOU_SAID_ALT": "나의 말:",
  "ACTIVITY_BOT_ATTACHED_ALT": "봇이 첨부함:",
  "ACTIVITY_ERROR_BOX_TITLE": "오류 메시지",
  "ACTIVITY_INTERACTIVE_FOOTNOTE_ALT": "상호 작용하려면 클릭하세요.",
  "ACTIVITY_INTERACTIVE_REASON_INTERACTIVE_CONTENT_ALT": "대화형 메시지입니다.",
  "ACTIVITY_INTERACTIVE_REASON_LINK_ALT": "메시지에 링크가 하나 이상 있습니다.",
  "ACTIVITY_INTERACTIVE_REASON_SEND_FAILED_ALT": "전송 실패.",
  "ACTIVITY_YOU_ATTACHED_ALT": "내가 첨부함:",
  "ACTIVITY_NUM_ATTACHMENTS_ONE_ALT": "첨부 파일 1개.",
  "ACTIVITY_NUM_ATTACHMENTS_FEW_ALT": "첨부 파일 $1개.",
  "ACTIVITY_NUM_ATTACHMENTS_MANY_ALT": "첨부 파일 $1개.",
  "ACTIVITY_NUM_ATTACHMENTS_OTHER_ALT": "첨부 파일 $1개.",
  "ACTIVITY_NUM_ATTACHMENTS_TWO_ALT": "첨부 파일 $1개.",
  "ACTIVITY_STATUS_SEND_FAILED_RETRY": "보내기 실패. [다시 시도][RETRY].",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENDING": "보내는 중",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENT_AT": "$1에 보냄",
  "ACTIVITY_STATUS_TIMESTAMP_JUST_NOW": "방금",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_HOUR_AGO": "1시간 전",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_MINUTE_AGO": "1분 전",
  "ACTIVITY_STATUS_TIMESTAMP_TODAY": "오늘",
  "ACTIVITY_STATUS_TIMESTAMP_YESTERDAY": "어제",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_PARSE": "적응형 카드 구문 분석 오류",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_RENDER": "적응형 카드 렌더링 오류",
  "ATTACHMENT_AUDIO": "오디오 클립입니다.",
  "ATTACHMENT_CARD": "카드: $1 $2 $3",
  "ATTACHMENT_FILE": "파일: $1",
  "ATTACHMENT_IMAGE": "이미지",
  "ATTACHMENT_TEXT": "텍스트: $1",
  "ATTACHMENT_VIDEO": "비디오 클립",
  "CAROUSEL_FLIPPER_LEFT_ALT": "왼쪽",
  "CAROUSEL_FLIPPER_RIGHT_ALT": "오른쪽",
  "CONNECTIVITY_STATUS_ALT_CONNECTED": "연결됨",
  "CONNECTIVITY_STATUS_ALT_CONNECTING": "연결 중…",
  "CONNECTIVITY_STATUS_ALT_FATAL": "연결할 수 없습니다.",
  "CONNECTIVITY_STATUS_ALT_RECONNECTING": "네트워크 중단이 발생했습니다. 다시 연결하는 중입니다...",
  "CONNECTIVITY_STATUS_ALT_RENDER_ERROR": "렌더링 오류입니다. 콘솔을 확인하거나 봇 개발자에게 문의하십시오.",
  "CONNECTIVITY_STATUS_ALT_SLOW_CONNECTION": "연결하는 데 평소보다 오래 걸립니다.",
  "CONNECTIVITY_STATUS_ALT": "연결 상태: $1",
  "FILE_CONTENT_ALT": "'$1'",
  "FILE_CONTENT_DOWNLOADABLE_ALT": "'$1' 파일 다운로드",
  "FILE_CONTENT_DOWNLOADABLE_WITH_SIZE_ALT": "$2 크기의 다운로드 파일 '$1'",
  "FILE_CONTENT_WITH_SIZE_ALT": "$2 크기의 '$1'",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_BODY": "Enter 키",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_HEADER": "메시지 내 항목 액세스",
  "KEYBOARD_HELP_CHAT_HISTORY_HEADER": "채팅 기록",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_BODY": "ESC 키",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_HEADER": "메시지 나가기",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_BODY": "Tab 키\nShift + Tab 키",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_HEADER": "메시지 내 항목 사이 이동",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_BODY": "위쪽 화살표 키\n아래쪽 화살표 키",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_HEADER": "메시지 사이 이동",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_BODY": "Enter 키",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_HEADER": "작업 수행",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_BODY": "Tab 키\nShift + Tab 키",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_HEADER": "채팅 창에서 항목 사이 이동",
  "KEYBOARD_HELP_CHAT_WINDOW_HEADER": "채팅 창",
  "KEYBOARD_HELP_CLOSE_BUTTON_ALT": "닫기",
  "KEYBOARD_HELP_HEADER": "키보드 컨트롤",
  "MARKDOWN_EXTERNAL_LINK_ALT": "새 창으로 엽니다. 외부.",
  "RECEIPT_CARD_TAX": "세금",
  "RECEIPT_CARD_TOTAL": "합계",
  "RECEIPT_CARD_VAT": "VAT",
  "SEND_BOX_IS_EMPTY_TOOLTIP_ALT": "빈 메시지는 보낼 수 없습니다.",
  "SPEECH_INPUT_LISTENING": "듣는 중...",
  "SPEECH_INPUT_MICROPHONE_BUTTON_CLOSE_ALT": "마이크 끄기",
  "SPEECH_INPUT_MICROPHONE_BUTTON_OPEN_ALT": "마이크 켜기",
  "SPEECH_INPUT_STARTING": "시작하는 중…",
  "SUGGESTED_ACTIONS_LABEL_ALT": "제안된 작업",
  "TEXT_INPUT_ALT": "메시지 입력 상자",
  "TEXT_INPUT_PLACEHOLDER": "메시지 입력",
  "TEXT_INPUT_SEND_BUTTON_ALT": "보내기",
  "TEXT_INPUT_SPEAK_BUTTON_ALT": "말하기",
  "TEXT_INPUT_UPLOAD_BUTTON_ALT": "파일 업로드",
  "TOAST_ACCORDION_FEW": "$1개 알림: 자세한 내용을 보려면 여기를 클릭하십시오.",
  "TOAST_ACCORDION_MANY": "$1개 알림: 자세한 내용을 보려면 여기를 클릭하십시오.",
  "TOAST_ACCORDION_OTHER": "$1개 알림: 자세한 내용을 보려면 여기를 클릭하십시오.",
  "TOAST_ACCORDION_TWO": "$1개 알림: 자세한 내용을 보려면 여기를 클릭하십시오.",
  "TOAST_ALT_ERROR": "오류",
  "TOAST_ALT_INFO": "정보",
  "TOAST_ALT_SUCCESS": "성공",
  "TOAST_ALT_WARN": "경고",
  "TOAST_DISMISS_BUTTON": "해제",
  "TOAST_TITLE_ALT": "알림",
  "TRANSCRIPT_ARIA_LABEL_ALT": "채팅 기록, 아래쪽 화살표 키를 사용하여 탐색합니다.",
  "TRANSCRIPT_ARIA_ROLE_ALT": "채팅 기록",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_LABEL_ALT": "메시지가 대화형입니다. 채팅 기록으로 전환하기 위해 Shift Tab 키를 2~3회 누르세요. 그런 다음 상호 작용할 메시지를 클릭하세요.",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_WITH_LINKS_LABEL_ALT": "메시지에 링크가 하나 이상 있습니다. 채팅 기록으로 전환하기 위해 Shift Tab 키를 2~3회 누르세요. 그런 다음 상호 작용할 메시지를 클릭하세요.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_LABEL_ALT": "메시지가 작업을 제안했습니다. 선택하려면 Shift 탭을 2~3번 누르세요.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_WITH_ACCESS_KEY_LABEL_ALT": "메시지가 작업을 제안했습니다. 선택하려면 $1을 누르세요.",
  "TRANSCRIPT_LIVE_REGION_SEND_FAILED_ALT": "메시지를 보내지 못했습니다.",
  "TRANSCRIPT_MORE_MESSAGES": "추가 메시지",
  "TRANSCRIPT_NEW_MESSAGES": "새 메시지",
  "TRANSCRIPT_TERMINATOR_TEXT": "채팅 기록의 끝 부분",
  "TYPING_INDICATOR_ALT": "입력 표시기 표시",
  "TYPING_INDICATOR_SINGLE_TEXT": "$1님이 입력 중입니다.",
  "TYPING_INDICATOR_MULTIPLE_TEXT": "$1님과 다른 사용자가 입력 중입니다."
}