import React from 'react'
import type { IconDefaultProps } from '../../models/weatherTypes'

const LowSnowIcon = ({ className, color }: IconDefaultProps): JSX.Element => {
  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 30 30'
      className={className || 'rf-h-16 rf-w-16'}
      xmlSpace='preserve'
      fill={color || '#1999B1'}
    >
      <path
        d='M7.46,14.41c0-0.19,0.07-0.36,0.2-0.5c0.13-0.14,0.29-0.21,0.48-0.21c0.19,0,0.36,0.07,0.49,0.21c0.13,0.14,0.2,0.3,0.2,0.5
	c0,0.19-0.07,0.35-0.2,0.48c-0.13,0.13-0.3,0.2-0.48,0.2s-0.35-0.07-0.48-0.2C7.52,14.75,7.46,14.59,7.46,14.41z M9.44,14.41
	c0-0.19,0.07-0.36,0.2-0.5c0.13-0.14,0.29-0.21,0.48-0.21h3.23l-2.28-2.28c-0.14-0.12-0.21-0.28-0.21-0.47
	c0-0.19,0.07-0.35,0.21-0.49c0.14-0.14,0.3-0.21,0.49-0.21s0.35,0.07,0.49,0.21l2.27,2.27V9.52c0-0.19,0.07-0.36,0.21-0.5
	s0.3-0.21,0.5-0.21c0.19,0,0.35,0.07,0.48,0.21c0.13,0.14,0.2,0.3,0.2,0.5v3.23l2.29-2.3c0.14-0.14,0.3-0.21,0.48-0.21
	c0.18,0,0.35,0.07,0.49,0.21c0.12,0.14,0.18,0.3,0.18,0.49c0,0.19-0.06,0.35-0.18,0.47l-2.28,2.28h3.23c0.18,0,0.34,0.07,0.47,0.21
	c0.13,0.14,0.2,0.3,0.2,0.5c0,0.18-0.07,0.34-0.2,0.47c-0.13,0.13-0.29,0.2-0.47,0.2h-3.23l2.29,2.29c0.12,0.12,0.18,0.28,0.18,0.47
	s-0.06,0.35-0.18,0.49c-0.14,0.14-0.31,0.21-0.49,0.21c-0.18,0-0.35-0.07-0.48-0.21l-2.29-2.3v3.24c0,0.19-0.07,0.36-0.2,0.49
	c-0.13,0.13-0.29,0.2-0.48,0.2c-0.19,0-0.36-0.07-0.5-0.2c-0.14-0.13-0.21-0.3-0.21-0.49v-3.22l-2.27,2.27
	c-0.14,0.14-0.3,0.21-0.49,0.21s-0.35-0.07-0.49-0.21s-0.21-0.3-0.21-0.49s0.07-0.34,0.21-0.47l2.3-2.29h-3.24
	c-0.19,0-0.35-0.07-0.48-0.2C9.5,14.75,9.44,14.59,9.44,14.41z M9.45,19.25c0-0.18,0.07-0.35,0.21-0.48
	c0.12-0.14,0.28-0.21,0.47-0.21c0.19,0,0.35,0.07,0.49,0.21c0.14,0.14,0.21,0.3,0.21,0.48c0,0.18-0.07,0.35-0.21,0.48
	c-0.14,0.14-0.3,0.21-0.49,0.21c-0.19,0-0.35-0.07-0.47-0.21C9.52,19.6,9.45,19.44,9.45,19.25z M9.45,9.54
	c0-0.18,0.07-0.35,0.21-0.48c0.12-0.14,0.28-0.21,0.47-0.21c0.19,0,0.35,0.07,0.49,0.21c0.14,0.14,0.21,0.3,0.21,0.48
	s-0.07,0.35-0.21,0.49c-0.14,0.14-0.3,0.21-0.49,0.21c-0.19,0-0.35-0.07-0.47-0.21C9.52,9.89,9.45,9.72,9.45,9.54z M14.3,21.25
	c0-0.18,0.07-0.34,0.21-0.47c0.14-0.13,0.3-0.2,0.5-0.2c0.19,0,0.35,0.07,0.48,0.2c0.13,0.13,0.2,0.29,0.2,0.47
	c0,0.19-0.07,0.36-0.2,0.49c-0.13,0.13-0.29,0.2-0.48,0.2c-0.19,0-0.36-0.07-0.5-0.2C14.37,21.61,14.3,21.45,14.3,21.25z M14.3,7.54
	c0-0.19,0.07-0.36,0.21-0.49s0.3-0.2,0.5-0.2c0.19,0,0.35,0.07,0.48,0.2c0.13,0.13,0.2,0.3,0.2,0.49c0,0.18-0.07,0.34-0.2,0.47
	c-0.13,0.13-0.29,0.2-0.48,0.2c-0.19,0-0.36-0.07-0.5-0.2C14.37,7.88,14.3,7.72,14.3,7.54z M19.17,19.25c0-0.18,0.07-0.35,0.21-0.48
	c0.12-0.14,0.28-0.21,0.47-0.21c0.19,0,0.35,0.07,0.49,0.21c0.14,0.14,0.21,0.3,0.21,0.48c0,0.18-0.07,0.35-0.21,0.48
	c-0.14,0.14-0.3,0.21-0.49,0.21c-0.19,0-0.35-0.07-0.47-0.21C19.24,19.6,19.17,19.44,19.17,19.25z M19.17,9.54
	c0-0.18,0.07-0.35,0.21-0.48c0.12-0.14,0.28-0.21,0.47-0.21c0.19,0,0.35,0.07,0.49,0.21c0.14,0.14,0.21,0.3,0.21,0.48
	s-0.07,0.35-0.21,0.49c-0.14,0.14-0.3,0.21-0.49,0.21c-0.19,0-0.35-0.07-0.47-0.21C19.24,9.89,19.17,9.72,19.17,9.54z M21.17,14.41
	c0-0.19,0.07-0.36,0.2-0.5c0.13-0.14,0.3-0.21,0.49-0.21c0.18,0,0.34,0.07,0.47,0.21c0.13,0.14,0.2,0.3,0.2,0.5
	c0,0.18-0.07,0.34-0.2,0.47c-0.13,0.13-0.29,0.2-0.47,0.2c-0.19,0-0.36-0.07-0.49-0.2C21.24,14.75,21.17,14.59,21.17,14.41z'
      />
    </svg>
  )
}

export default LowSnowIcon
