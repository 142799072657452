import React, { useState } from 'react'

interface ToggleProps {
  texts: {
    left: string
    leftId: number
    right: string
    rightId: number
  }
  colors: {
    text: string
    background: string
  }
  handleChange: (chosen: number) => void
  defaultChecked?: number
}

const ToggleWithTexts = ({
  texts,
  colors,
  handleChange,
  defaultChecked
}: ToggleProps): JSX.Element => {
  const [left, setLeft] = useState<boolean>(
    !defaultChecked || defaultChecked === texts.leftId
  )

  /**
   * On call :
   *  - call parent method with either leftId or rightId depending on the leftSide parameter
   *  - set left state
   * @param leftSide : boolean
   */
  const handleToggle = (leftSide: boolean): void => {
    handleChange(leftSide ? texts.leftId : texts.rightId)
    setLeft(leftSide)
  }

  return (
    <div
      className='rf-w-full rf-flex rf-flex-row rf-justify-between rf-text-size-auto rf-rounded-8 rf-cursor-pointer rf-select-none rf-shadow-[inset_0_12px_12px_-12px_#00000029,inset_0_-12px_12px_-12px_#00000029]'
      style={{
        background:
          'linear-gradient(to left, #CBCBCB 0%, #9A9797 50%, #4E4C4C 100%)',
        color: colors.text
      }}
    >
      {/* Left part of the toggle */}
      <div
        className={`rf-px-4 rf-py-2 rf-uppercase rf-font-bold rf-border rf-rounded-8 ${
          left && 'rf-shadow-[0_0_3px_6px_#00000029]'
        } `}
        style={{
          background: left ? colors.background : 'transparent',
          borderColor: left ? colors.background : 'transparent'
        }}
        onClick={(): void => handleToggle(true)}
      >
        {texts.left}
      </div>

      {/* Right part of the toggle */}
      <div
        className={`rf-px-4 rf-py-2 rf-uppercase rf-font-bold rf-border rf-rounded-8 ${
          !left && 'rf-shadow-[0_0_3px_6px_#00000029]'
        }`}
        style={{
          background: left ? 'transparent' : colors.background,
          borderColor: left ? 'transparent' : colors.background
        }}
        onClick={(): void => handleToggle(false)}
      >
        {texts.right}
      </div>
    </div>
  )
}

export default ToggleWithTexts
