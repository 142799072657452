import React, { useState, useEffect, useMemo } from 'react'
import { useRetorik } from '../Contexts/RetorikContext'
import { useView } from '../Contexts/ViewContext'
import { useSpeech } from '../Contexts/SpeechContext'
import { useUtilsStore } from '../Contexts/utilsStore'

import type { ChatbotData, Queue } from '../../models/types'

import ChatbotProvider from '@davi/spirit-engine-sprite'
import Background from '../Common/Background'
import UserRequest from '../AnswerPanel/UserRequest'
import Subtitle from '../Utils/Subtitle'
import Logo from '../Common/Logo'
import BotResponse from '../AnswerPanel/BotResponse'
import useAnimationQueue from '../../hooks/useAnimationQueue'
import RetorikLogo from '../Common/RetorikLogo'
import SubViews from './SubViews'
import { Mode } from '../../models/enums'
import { ConversationPanel } from '../AnswerPanel/ConversationPanel'
import MenuDisplayer from '../Menu/MenuDisplayer'

interface HomeViewProps {
  chatbotData: ChatbotData
  externalComponents?: Array<{ name: string; component: JSX.Element }>
}

const HomeView = ({
  chatbotData,
  externalComponents
}: HomeViewProps): JSX.Element => {
  const { configuration, agentData } = useRetorik()
  const { mode } = useRetorik()
  const { configurations, isMobile, setDisplayControls } = useView()
  const displayAgent = useUtilsStore((state) => state.displayAgent)
  const displayData = useMemo(() => {
    return configurations.views.home
  }, [configurations])
  const lastAnimationQueue = useAnimationQueue()
  const { speaking, setSpeechAllowed } = useSpeech()
  const [animationQueue, setAnimationQueue] = useState<Queue>([])

  useEffect(() => {
    lastAnimationQueue && setAnimationQueue(lastAnimationQueue)
  }, [lastAnimationQueue])

  useEffect(() => {
    setSpeechAllowed(mode === Mode.vocal)
    setDisplayControls(!configuration.hideControls)
  }, [])

  return (
    <React.Fragment>
      {/* Background */}
      <Background config={displayData.background} />

      {/* Logo */}
      {configuration?.logo &&
        (!isMobile || (isMobile && mode !== Mode.text)) && <Logo />}

      {/* Chatbot + Subtitles */}
      {(!isMobile || (isMobile && mode !== Mode.text)) &&
        chatbotData &&
        agentData &&
        displayAgent && (
          <React.Fragment>
            <div className='rf-relative rf-col-start-1 rf-col-end-9 large:rf-col-end-6 large-vertical:rf-col-end-9 rf-row-span-full rf-self-end rf-h-full'>
              <ChatbotProvider
                licence='licence'
                agentData={agentData}
                height={chatbotData?.height}
                size={chatbotData?.size}
                speak={speaking}
                animationQueue={animationQueue}
                setAnimationQueue={setAnimationQueue}
              />
            </div>
            <Subtitle />
          </React.Fragment>
        )}

      {/* Activity input / output */}
      {configuration?.answerpanel !== false && (
        <React.Fragment>
          <UserRequest />
          {mode === Mode.vocal ? <BotResponse /> : <ConversationPanel />}
        </React.Fragment>
      )}

      {/* Menus */}
      <MenuDisplayer hideMenu={configuration?.hideMenu} />

      {/* Miscellaneous */}
      <SubViews externalComponents={externalComponents} />

      {/* Retorik Logo on right bottom */}
      {!configuration?.hideRetorikLogo && <RetorikLogo />}
    </React.Fragment>
  )
}

export default HomeView
