{
  "_comment": "[CI-LOCKED] Zawartość tego pliku jest zablokowana. Wkład twórczy jest mile widziany, ale weryfikacja zmian może być opóźniona.",
  "ACCESS_KEY_ALT": "Alt + Shift + $1",
  "ACCESS_KEY_FOR_MAC_ALT": "Control + Option + $1",
  "ACTIVITY_BOT_SAID_ALT": "Bot $1 powiedział:",
  "ACTIVITY_YOU_SAID_ALT": "Ty mówisz:",
  "ACTIVITY_BOT_ATTACHED_ALT": "Bot dołączył:",
  "ACTIVITY_ERROR_BOX_TITLE": "Komunikat o błędzie",
  "ACTIVITY_INTERACTIVE_FOOTNOTE_ALT": "Kliknij, aby wejść w interakcję.",
  "ACTIVITY_INTERACTIVE_REASON_INTERACTIVE_CONTENT_ALT": "Wiadomość jest interakcyjna.",
  "ACTIVITY_INTERACTIVE_REASON_LINK_ALT": "Wiadomość zawiera co najmniej jeden link.",
  "ACTIVITY_INTERACTIVE_REASON_SEND_FAILED_ALT": "Wysyłanie nie powiodło się.",
  "ACTIVITY_YOU_ATTACHED_ALT": "Ty dołączasz:",
  "ACTIVITY_NUM_ATTACHMENTS_ONE_ALT": "1 załącznik.",
  "ACTIVITY_NUM_ATTACHMENTS_FEW_ALT": "$1 załączniki.",
  "ACTIVITY_NUM_ATTACHMENTS_MANY_ALT": "$1 załączników.",
  "ACTIVITY_NUM_ATTACHMENTS_OTHER_ALT": "$1 załącznika.",
  "ACTIVITY_NUM_ATTACHMENTS_TWO_ALT": "$1 załączniki.",
  "ACTIVITY_STATUS_SEND_FAILED_RETRY": "Wysyłanie nie powiodło się. [Ponów próbę][RETRY].",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENDING": "Wysyłanie",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENT_AT": "Wysłano o $1",
  "ACTIVITY_STATUS_TIMESTAMP_JUST_NOW": "Teraz",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_HOUR_AGO": "Godzinę temu",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_MINUTE_AGO": "Minutę temu",
  "ACTIVITY_STATUS_TIMESTAMP_TODAY": "Dzisiaj",
  "ACTIVITY_STATUS_TIMESTAMP_YESTERDAY": "Wczoraj",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_PARSE": "Błąd analizowania karty adaptacyjnej",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_RENDER": "Błąd renderowania karty adaptacyjnej",
  "ATTACHMENT_AUDIO": "Klip audio.",
  "ATTACHMENT_CARD": "Karta: $1 $2 $3",
  "ATTACHMENT_FILE": "Plik: $1",
  "ATTACHMENT_IMAGE": "Obraz",
  "ATTACHMENT_TEXT": "Tekst: $1",
  "ATTACHMENT_VIDEO": "Klip wideo",
  "CAROUSEL_FLIPPER_LEFT_ALT": "W lewo",
  "CAROUSEL_FLIPPER_RIGHT_ALT": "W prawo",
  "CONNECTIVITY_STATUS_ALT_CONNECTED": "Połączono",
  "CONNECTIVITY_STATUS_ALT_CONNECTING": "Trwa nawiązywanie połączenia...",
  "CONNECTIVITY_STATUS_ALT_FATAL": "Nie można nawiązać połączenia.",
  "CONNECTIVITY_STATUS_ALT_RECONNECTING": "Wystąpiła przerwa w działaniu sieci. Trwa ponowne nawiązywanie połączenia...",
  "CONNECTIVITY_STATUS_ALT_RENDER_ERROR": "Błąd renderowania. Sprawdź konsolę lub skontaktuj się z deweloperem bota.",
  "CONNECTIVITY_STATUS_ALT_SLOW_CONNECTION": "Nawiązywanie połączenia trwa dłużej niż zazwyczaj.",
  "CONNECTIVITY_STATUS_ALT": "Stan łączności: $1",
  "FILE_CONTENT_ALT": "„$1”",
  "FILE_CONTENT_DOWNLOADABLE_ALT": "Pobierz plik „$1”",
  "FILE_CONTENT_DOWNLOADABLE_WITH_SIZE_ALT": "Pobierz plik „$1” o rozmiarze $2",
  "FILE_CONTENT_WITH_SIZE_ALT": "Plik „$1” o rozmiarze $2",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_BODY": "Klawisz Enter",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_HEADER": "Uzyskaj dostęp do elementów w wiadomości",
  "KEYBOARD_HELP_CHAT_HISTORY_HEADER": "Historia czatu",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_BODY": "Klawisz Escape",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_HEADER": "Zamknij komunikat",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_BODY": "Klawisz Tab\nKlawisze Shift + Tab",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_HEADER": "Przechodź między elementami w wiadomości",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_BODY": "Klawisz Strzałka w górę\nKlawisz Strzałka w dół",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_HEADER": "Przechodź między wiadomościami",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_BODY": "Klawisz Enter",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_HEADER": "Wykonaj akcję",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_BODY": "Klawisz Tab\nKlawisze Shift + Tab",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_HEADER": "Przechodź między elementami w oknie czatu",
  "KEYBOARD_HELP_CHAT_WINDOW_HEADER": "Okno czatu",
  "KEYBOARD_HELP_CLOSE_BUTTON_ALT": "Zamknij",
  "KEYBOARD_HELP_HEADER": "Sterowanie za pomocą klawiatury",
  "MARKDOWN_EXTERNAL_LINK_ALT": "Powoduje otwarcie w nowym oknie; link zewnętrzny.",
  "RECEIPT_CARD_TAX": "Podatek",
  "RECEIPT_CARD_TOTAL": "Suma",
  "RECEIPT_CARD_VAT": "Podatek VAT",
  "SEND_BOX_IS_EMPTY_TOOLTIP_ALT": "Nie można wysłać pustej wiadomości.",
  "SPEECH_INPUT_LISTENING": "Trwa słuchanie...",
  "SPEECH_INPUT_MICROPHONE_BUTTON_CLOSE_ALT": "Mikrofon wyłączony",
  "SPEECH_INPUT_MICROPHONE_BUTTON_OPEN_ALT": "Mikrofon włączony",
  "SPEECH_INPUT_STARTING": "Trwa rozpoczynanie...",
  "SUGGESTED_ACTIONS_LABEL_ALT": "Sugerowane akcje",
  "TEXT_INPUT_ALT": "Pole wprowadzania wiadomości",
  "TEXT_INPUT_PLACEHOLDER": "Wpisz wiadomość",
  "TEXT_INPUT_SEND_BUTTON_ALT": "Wyślij",
  "TEXT_INPUT_SPEAK_BUTTON_ALT": "Mów",
  "TEXT_INPUT_UPLOAD_BUTTON_ALT": "Przekaż plik",
  "TOAST_ACCORDION_FEW": "$1 powiadomienia: kliknij tutaj, aby wyświetlić szczegóły",
  "TOAST_ACCORDION_MANY": "$1 powiadomienia: kliknij tutaj, aby wyświetlić szczegóły",
  "TOAST_ACCORDION_OTHER": "$1 powiadomienia: kliknij tutaj, aby wyświetlić szczegóły",
  "TOAST_ACCORDION_TWO": "$1 powiadomienia: kliknij tutaj, aby wyświetlić szczegóły",
  "TOAST_ALT_ERROR": "Błąd",
  "TOAST_ALT_INFO": "Informacje",
  "TOAST_ALT_SUCCESS": "Sukces",
  "TOAST_ALT_WARN": "Ostrzeżenie",
  "TOAST_DISMISS_BUTTON": "Odrzuć",
  "TOAST_TITLE_ALT": "Powiadomienie",
  "TRANSCRIPT_ARIA_LABEL_ALT": "Historia czatu, naciskaj klawisze strzałek, aby nawigować.",
  "TRANSCRIPT_ARIA_ROLE_ALT": "historia czatu",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_LABEL_ALT": "Wiadomość jest interakcyjna. Naciśnij 2-3 razy klawisze Shift+Tab, aby przełączyć się do historii czatu. Następnie kliknij w wiadomości, aby wejść w interakcję.",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_WITH_LINKS_LABEL_ALT": "Wiadomość zawiera co najmniej jeden link. Naciśnij 2-3 razy klawisze Shift+Tab, aby przełączyć się do historii czatu. Następnie kliknij w wiadomości, aby wejść w interakcję.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_LABEL_ALT": "Wiadomość zawiera sugerowane akcje. Naciśnij klawisze Shift + Tab 2 lub 3 razy, aby je wybrać.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_WITH_ACCESS_KEY_LABEL_ALT": "Wiadomość zawiera sugerowane akcje. Naciśnij klawisz $1, aby je wybrać.",
  "TRANSCRIPT_LIVE_REGION_SEND_FAILED_ALT": "Nie można wysłać wiadomości.",
  "TRANSCRIPT_MORE_MESSAGES": "Więcej wiadomości",
  "TRANSCRIPT_NEW_MESSAGES": "Nowe wiadomości",
  "TRANSCRIPT_TERMINATOR_TEXT": "Koniec historii czatu",
  "TYPING_INDICATOR_ALT": "Wyświetlanie wskaźnika wpisywania",
  "TYPING_INDICATOR_SINGLE_TEXT": "$1 pisze.",
  "TYPING_INDICATOR_MULTIPLE_TEXT": "$1 i inni piszą."
}