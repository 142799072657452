import React, { useState } from 'react'
import { useUtilsStore } from '../Contexts/utilsStore'
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter'
import { checkForData } from '../../utils/localStorageInputsUtils'
import { CurrentSubView } from '../../models/enums'

interface InputToggleProps {
  id: string
  title?: string
  handleChange: (on: boolean) => void
  valueOn: string
  defaultValue: string
}

const InputToggle = ({
  id,
  title,
  handleChange,
  valueOn,
  defaultValue
}: InputToggleProps): JSX.Element => {
  const { currentSubView } = useUtilsStore()
  const [on, setOn] = useState<boolean>(
    checkForData(id, defaultValue) === valueOn
  )

  /**
   * On call :
   *  - check if the component will be rendered in history, if so bypass every interaction
   *  - call parent's handleChange method
   *  - set on state to its opposite
   */
  const handleToggle = (): void => {
    if (currentSubView !== CurrentSubView.history) {
      handleChange(!on)
      setOn(!on)
    }
  }

  return (
    <div className='rf-w-full rf-flex rf-flex-row rf-items-start rf-text-size-auto rf-text-white'>
      <label className='rf-flex rf-flex-row rf-items-start'>
        <input
          type='checkbox'
          onChange={handleToggle}
          className='rf-toggle-custom'
          checked={on}
        />
        <div className='rf-input-label'>
          {capitalizeFirstLetter(title || id)}
        </div>
      </label>
    </div>
  )
}

export default InputToggle
