import React from 'react'
import type { IconDefaultProps } from '../../../models/types'

const ArrowRightIcon = ({
  className,
  color
}: IconDefaultProps): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className || 'rf-h-6 rf-w-6'}
      fill='none'
      viewBox='0 0 24 24'
      stroke={color || 'currentColor'}
      strokeWidth={2}
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M13 7l5 5m0 0l-5 5m5-5H6'
      />
    </svg>
  )
}

export default ArrowRightIcon
