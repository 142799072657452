{
  "fr-FR": {
    "close": "Fermer"
  },
  "en-US": {
    "close": "Close"
  },
  "es-ES": {
    "close": "Close"
  },
  "de-DE": {
    "close": "Close"
  }
}
