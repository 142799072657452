{
  "fr-FR": {
    "loader": {
      "optimal": "Pour une expérience optimale,",
      "classic": "Pour une expérience plus classique",
      "vocal": "j'échange de vive voix avec l'agent.",
      "text": "j'échange à l'écrit avec l'agent."
    },
    "loaderValidation": "Démarrer la conversation",
    "modes": {
      "vocal": "mode vocal",
      "text": "mode texte"
    },
    "locale": "vérification de la langue",
    "supported": "vérification des langues disponibles",
    "store": "création du store",
    "directline": "connection au directline",
    "ponyfill": "création du ponyfill",
    "addressdata": "récupération des données"
  },
  "en-US": {
    "loader": {
      "optimal": "For an optimal experience,",
      "classic": "For a more classical experience,",
      "vocal": "I communicate by speaking with the agent.",
      "text": "I communicate by text with the agent."
    },
    "loaderValidation": "Start conversation",
    "modes": {
      "vocal": "vocal mode",
      "text": "text mode"
    },
    "locale": "checking locale",
    "supported": "checking supported locales",
    "store": "creating store",
    "directline": "connecting directline",
    "ponyfill": "ponyfilling",
    "addressdata": "retrieving data"
  },
  "es-ES": {
    "loader": {
      "optimal": "For an optimal experience,",
      "classic": "For a more classical experience,",
      "vocal": "I communicate by speaking with the agent.",
      "text": "I communicate by text with the agent."
    },
    "loaderValidation": "Start conversation",
    "modes": {
      "vocal": "vocal mode",
      "text": "text mode"
    },
    "locale": "checking locale",
    "supported": "checking supported locales",
    "store": "creating store",
    "directline": "connecting directline",
    "ponyfill": "ponyfilling",
    "addressdata": "retrieving data"
  },
  "de-DE": {
    "loader": {
      "optimal": "For an optimal experience,",
      "classic": "For a more classical experience,",
      "vocal": "I communicate by speaking with the agent.",
      "text": "I communicate by text with the agent."
    },
    "loaderValidation": "Start conversation",
    "modes": {
      "vocal": "vocal mode",
      "text": "text mode"
    },
    "locale": "checking locale",
    "supported": "checking supported locales",
    "store": "creating store",
    "directline": "connecting directline",
    "ponyfill": "ponyfilling",
    "addressdata": "retrieving data"
  }
}
