const color = '#00D7FF'
const white = '#F7F3F6'

export const adaptiveCardHostConfig = {
  hostCapabilities: {
    capabilities: null
  },
  choiceSetInputValueSeparator: ',',
  supportsInteractivity: true,
  fontTypes: {
    default: {
      fontFamily: '"Helvetica Neue", sans-serif',
      fontSizes: {
        small: 12,
        default: 16,
        medium: 20,
        large: 26,
        extraLarge: 33
      },
      fontWeights: {
        lighter: 200,
        default: 300,
        bolder: 400
      }
    },
    monospace: {
      fontFamily: '"Helvetica Neue", sans-serif',
      fontSizes: {
        small: 12,
        default: 16,
        medium: 20,
        large: 26,
        extraLarge: 33
      },
      fontWeights: {
        lighter: 200,
        default: 300,
        bolder: 400
      }
    }
  },
  spacing: {
    small: 8,
    default: 16,
    medium: 20,
    large: 30,
    extraLarge: 40,
    padding: 25
  },
  separator: {
    lineThickness: 1,
    lineColor: 'transparent'
  },
  imageSizes: {
    small: 40,
    medium: 100,
    large: 160
  },
  containerStyles: {
    default: {
      foregroundColors: {
        default: {
          default: `${white}`,
          subtle: `${color}`,
          highlightColors: {
            default: `${color}`,
            subtle: '#11000000'
          }
        },
        dark: {
          default: '#000000',
          subtle: '#66000000',
          highlightColors: {
            default: '#22000000',
            subtle: '#11000000'
          }
        },
        light: {
          default: '#FFFFFF',
          subtle: '#33000000',
          highlightColors: {
            default: '#22000000',
            subtle: '#11000000'
          }
        },
        accent: {
          default: '#0063B1',
          subtle: '#0063B1',
          highlightColors: {
            default: '#22000000',
            subtle: '#11000000'
          }
        },
        good: {
          default: '#54a254',
          subtle: '#DD54a254',
          highlightColors: {
            default: '#22000000',
            subtle: '#11000000'
          }
        },
        warning: {
          default: '#c3ab23',
          subtle: '#DDc3ab23',
          highlightColors: {
            default: '#22000000',
            subtle: '#11000000'
          }
        },
        attention: {
          default: '#FF0000',
          subtle: '#DDFF0000',
          highlightColors: {
            default: '#22000000',
            subtle: '#11000000'
          }
        }
      },
      backgroundColor: 'transparent'
    },
    emphasis: {
      foregroundColors: {
        default: {
          default: '#000000',
          subtle: '#767676',
          highlightColors: {
            default: '#22000000',
            subtle: '#11000000'
          }
        },
        dark: {
          default: '#000000',
          subtle: '#66000000',
          highlightColors: {
            default: '#22000000',
            subtle: '#11000000'
          }
        },
        light: {
          default: '#FFFFFF',
          subtle: '#33000000',
          highlightColors: {
            default: '#22000000',
            subtle: '#11000000'
          }
        },
        accent: {
          default: '#2E89FC',
          subtle: '#882E89FC',
          highlightColors: {
            default: '#22000000',
            subtle: '#11000000'
          }
        },
        good: {
          default: '#54a254',
          subtle: '#DD54a254',
          highlightColors: {
            default: '#22000000',
            subtle: '#11000000'
          }
        },
        warning: {
          default: '#c3ab23',
          subtle: '#DDc3ab23',
          highlightColors: {
            default: '#22000000',
            subtle: '#11000000'
          }
        },
        attention: {
          default: '#FF0000',
          subtle: '#DDFF0000',
          highlightColors: {
            default: '#22000000',
            subtle: '#11000000'
          }
        }
      },
      backgroundColor: '#F0F0F0'
    }
  },
  actions: {
    maxActions: 100,
    spacing: 'Default',
    buttonSpacing: 0,
    showCard: {
      actionMode: 'Inline',
      inlineTopMargin: 8,
      style: 'emphasis'
    },
    preExpandSingleShowCardAction: false,
    actionsOrientation: 'vertical',
    actionAlignment: 'left',
    wrap: true
  },
  adaptiveCard: {
    allowCustomStyle: true
  },
  imageSet: {
    maxImageHeight: 100
  },
  media: {
    allowInlinePlayback: true
  },
  factSet: {
    title: {
      size: 'Default',
      color: `${color}`,
      isSubtle: false,
      weight: 'Bolder',
      wrap: true
    },
    value: {
      size: 'Default',
      color: `${color}`,
      isSubtle: false,
      weight: 'Default',
      wrap: true
    },
    spacing: 1000
  },
  cssClassNamePrefix: null,
  _legacyFontType: {
    fontFamily: 'Helvetica Neue,sans-serif',
    fontSizes: {
      small: 12,
      default: 16,
      medium: 20,
      large: 26,
      extraLarge: 33
    },
    fontWeights: {
      lighter: 200,
      default: 400,
      bolder: 600
    }
  }
}
