import React from 'react'
import type { IconDefaultProps } from '../../../models/types'

const MicrophoneIconOff = ({
  className,
  color
}: IconDefaultProps): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className || 'rf-h-6 rf-w-6'}
      viewBox='0 0 19 19'
    >
      <circle cx='9.5' cy='9.5' r='9.5' fill={color || '#ff6462'} />
    </svg>
  )
}

export default MicrophoneIconOff
