import React, { useEffect, useState, useMemo } from 'react'
import { useLocaleStore } from '../Contexts/localeStore'
import { useUtilsStore } from '../Contexts/utilsStore'
import { getDateAsArray, getMonthAsString } from '../../utils/getDateAsArray'
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter'
import { CurrentSubView } from '../../models/enums'
import { Wheeler } from '../Utils'

interface InputDateProps {
  id: string
  defaultValue?: string
  maxValue?: string
  minValue?: string
  handleChange: (value: string) => void
}

/**
 * Oddmonths used to calculate the max amount of days in a month
 */
const oddMonths = [4, 6, 9, 11]

const InputDate = ({
  id,
  defaultValue,
  maxValue,
  minValue,
  handleChange
}: InputDateProps): JSX.Element => {
  const { locale } = useLocaleStore()
  const { currentSubView } = useUtilsStore()
  const [year, setYear] = useState<number>(2023)
  const [month, setMonth] = useState<number>(1)
  const [day, setDay] = useState<number>(1)

  const [maxYear, setMaxYear] = useState<number>(0)
  const [minYear, setMinYear] = useState<number>(100000)
  const [maxMonth, setMaxMonth] = useState<number>(12)
  const [minMonth, setMinMonth] = useState<number>(1)
  const [maxDay, setMaxDay] = useState<number>(31)
  const [minDay, setMinDay] = useState<number>(1)
  const [maxValueAsDate, setMaxValueAsDate] = useState<Date | undefined>(
    undefined
  )
  const [minValueAsDate, setMinValueAsDate] = useState<Date | undefined>(
    undefined
  )
  const [updatingDate, setUpdatingDate] = useState<boolean>(false)

  const [dayWheel, setDayWheel] = useState<number>(0)
  const [monthWheel, setMonthWheel] = useState<number>(0)
  const [yearWheel, setYearWheel] = useState<number>(0)

  const days = useMemo<number | undefined>(() => {
    if (month && year) {
      // If we check the february month, either 28 or 29 days depending on the year
      if (month === 2) {
        return year % 4 === 0 ? 29 : 28
      }
      // 30 days for oddMonths, 31 otherwise
      return oddMonths.includes(month) ? 30 : 31
    }
    return undefined
  }, [month, year])

  /**
   * On days state change :
   *  - set day state if current day is higher than days
   */
  useEffect(() => {
    day && days && day > days && setDay(days)
  }, [days])

  /**
   * On component mount :
   *  - set day / month / year from localStorage value if there is one, current Date otherwise
   *  - set max values from props or current Date + 150 years otherwise
   */
  useEffect(() => {
    const dateAsArray = getDateAsArray(id, defaultValue)
    setYear(dateAsArray[0])
    setMonth(dateAsArray[1])
    setDay(dateAsArray[2])

    // Set min and max years
    const now = new Date()
    if (maxValue) {
      const maxAsDate = new Date(maxValue)
      setMaxValueAsDate(maxAsDate)
      setMaxYear(maxAsDate.getFullYear())
      setMaxMonth(maxAsDate.getMonth() + 1)
      setMaxDay(maxAsDate.getDate())
    } else {
      setMaxYear(now.getFullYear() + 150)
    }

    if (minValue) {
      const minAsDate = new Date(minValue)
      setMinValueAsDate(minAsDate)
      setMinYear(minAsDate.getFullYear())
      setMinMonth(minAsDate.getMonth() + 1)
      setMinDay(minAsDate.getDate())
    } else {
      setMinYear(now.getFullYear() - 150)
    }
  }, [])

  /**
   * On day / month / year state change :
   *  - check if a day / month / year change is needed to keep the date a proper one
   *  - for example on february, there are either 28 or 29 days depending on the year
   *  - set the value in localStorage
   *  - if an update is needed, set the updatingDate state to true to prevent concurrent modification, update, and set updatingdate back to false to release lock
   */
  useEffect(() => {
    // Check if we are in the bounds of minValue and maxValue if there are some
    if (!updatingDate && day && month && year) {
      let updating = false
      let dateAsString = `${year}-${month}-${day}`
      const date = new Date(dateAsString)
      if (minValue && minValueAsDate && date < minValueAsDate) {
        dateAsString = minValue
        updating = true
      } else if (maxValue && maxValueAsDate && date > maxValueAsDate) {
        dateAsString = maxValue
        updating = true
      }

      // Save date
      handleChange(dateAsString)

      // Update states if necessary
      if (updating) {
        setUpdatingDate(true)
        const dateAsArray = dateAsString.split('-')
        setYear(parseInt(dateAsArray[0]))
        setMonth(parseInt(dateAsArray[1]))
        setDay(parseInt(dateAsArray[2]))
        setUpdatingDate(false)
      }
    }
  }, [day, month, year])

  /**
   * On dayWheel change, if dayWheel !== 0 :
   *  - try to update the day state
   *  - reset dayWheel state to 0
   */
  useEffect(() => {
    if (dayWheel !== 0 && day && days) {
      if (dayWheel > 0) {
        day + 1 > days ? setDay(1) : setDay((day): number => day + 1)
      } else {
        day - 1 > 0 ? setDay((day): number => day - 1) : setDay(days)
      }
      // Reset dayWheel
      setDayWheel(0)
    }
  }, [dayWheel])

  /**
   * On monthWheel change, if monthWheel !== 0 :
   *  - try to update the month state
   *  - reset monthWheel state to 0
   */
  useEffect(() => {
    if (monthWheel !== 0 && month) {
      if (monthWheel > 0) {
        month + 1 > 12 ? setMonth(1) : setMonth((month): number => month + 1)
      } else {
        month - 1 > 0 ? setMonth((month): number => month - 1) : setMonth(12)
      }
      // Reset monthWheel
      setMonthWheel(0)
    }
  }, [monthWheel])

  /**
   * On yearWheel change, if yearWheel !== 0 :
   *  - try to update the year state
   *  - reset yearWheel state to 0
   */
  useEffect(() => {
    if (yearWheel !== 0 && year && minYear && maxYear) {
      if (yearWheel > 0) {
        year !== maxYear && setYear((year): number => year + 1)
      } else {
        year !== minYear && setYear((year): number => year - 1)
      }
      // Reset yearWheel
      setYearWheel(0)
    }
  }, [yearWheel])

  /**
   * On wheel event on day / month / year part :
   *  - check if a hour / minute change is needed to respect minValue or maxValue
   *  - call wheel state depending on the type, that will trigger the related day / month / year state update if possible
   * @param delta : number
   * @param type : string
   */
  const handleWheel = (delta, type): void => {
    if (currentSubView !== CurrentSubView.history) {
      switch (type) {
        case 'day':
          setDayWheel(delta)
          break
        case 'month':
          setMonthWheel(delta)
          break
        case 'year':
          setYearWheel(delta)
          break
        default:
          break
      }
    }
  }

  /**
   * On click on a day / month / year :
   *  - check if a hour / minute change is needed to respect minValue or maxValue
   *  - set chosen day / month / year if it is within the accepted bounds
   * @param value : number
   * @param type : string
   */
  const handleClick = (value: number, type: string): void => {
    if (currentSubView !== CurrentSubView.history) {
      switch (type) {
        case 'day':
          day && setDay(value)
          break
        case 'month':
          month && value !== -1 && setMonth(value)
          break
        case 'year':
          year && value !== -1 && setYear(value)
          break
        default:
          break
      }
    }
  }

  return (
    <div
      className='rf-w-full rf-mt-4 rf-py-2 rf-flex rf-flex-row rf-justify-center rf-items-center rf-rounded-lg rf-subtitle-size-auto rf-text-formInputRadioCheckboxUncheckedBackground rf-unselectable'
      style={{
        background:
          'linear-gradient(rgb(0, 0, 0, 0.5) 0%, rgb(0, 0, 0, 0.5) 34%, black 34%, black 65%, rgb(0, 0, 0, 0.5) 65%, rgb(0, 0, 0, 0.5) 100%)'
      }}
    >
      <input type='date' className='rf-hidden' />
      {year && month && day && (
        <React.Fragment>
          {/* Days */}
          <Wheeler
            className='rf-flex rf-flex-col rf-items-center rf-px-4 rf-py-1'
            handleWheel={handleWheel}
            paramInHandle='day'
          >
            <div
              className='rf-py-1 hover:rf-cursor-pointer'
              onClick={(): void =>
                handleClick(
                  year === minYear && month === minMonth && day === minDay
                    ? -1
                    : day === 1
                    ? days || 1
                    : day - 1,
                  'day'
                )
              }
            >
              {year === minYear && month === minMonth && day === minDay
                ? '\u2800'
                : day === 1
                ? `${days}`
                : `${day - 1}`}
            </div>
            <div className='rf-py-1 rf-text-primary'>{day}</div>
            <div
              className='rf-py-1 hover:rf-cursor-pointer'
              onClick={(): void =>
                handleClick(
                  year === maxYear && month === maxMonth && day === maxDay
                    ? -1
                    : day === days
                    ? 1
                    : day + 1,
                  'day'
                )
              }
            >
              {year === maxYear && month === maxMonth && day === maxDay
                ? '\u2800'
                : day === days
                ? '1'
                : `${day + 1}`}
            </div>
          </Wheeler>
          {/* Months */}
          <Wheeler
            className='rf-flex rf-flex-col rf-items-center rf-px-4 rf-py-1'
            handleWheel={handleWheel}
            paramInHandle='month'
          >
            <div
              className='rf-py-1 hover:rf-cursor-pointer'
              onClick={(): void =>
                handleClick(
                  year === minYear && month === minMonth
                    ? -1
                    : month === 1
                    ? 12
                    : month - 1,
                  'month'
                )
              }
            >
              {year === minYear && month === minMonth
                ? '\u2800'
                : month === 1
                ? `${capitalizeFirstLetter(getMonthAsString(12, locale))}`
                : `${capitalizeFirstLetter(
                    getMonthAsString(month - 1, locale)
                  )}`}
            </div>
            <div className='rf-py-1 rf-text-primary'>{`${capitalizeFirstLetter(
              getMonthAsString(month, locale)
            )}`}</div>
            <div
              className='rf-py-1 hover:rf-cursor-pointer'
              onClick={(): void =>
                handleClick(
                  year === maxYear && month === maxMonth
                    ? -1
                    : month === 12
                    ? 1
                    : month + 1,
                  'month'
                )
              }
            >
              {year === maxYear && month === maxMonth
                ? '\u2800'
                : month === 12
                ? `${capitalizeFirstLetter(getMonthAsString(1, locale))}`
                : `${capitalizeFirstLetter(
                    getMonthAsString(month + 1, locale)
                  )}`}
            </div>
          </Wheeler>
          {/* Years */}
          <Wheeler
            className='rf-flex rf-flex-col rf-items-center rf-px-4 rf-py-1'
            handleWheel={handleWheel}
            paramInHandle='year'
          >
            <div
              className='rf-py-1 hover:rf-cursor-pointer'
              onClick={(): void =>
                handleClick(year === minYear ? -1 : year - 1, 'year')
              }
            >
              {year === minYear ? '\u2800' : year - 1}
            </div>
            <div className='rf-py-1 rf-text-primary'>{year}</div>
            <div
              className='rf-py-1 hover:rf-cursor-pointer'
              onClick={(): void =>
                handleClick(year === maxYear ? -1 : year + 1, 'year')
              }
            >
              {year === maxYear ? '\u2800' : year + 1}
            </div>
          </Wheeler>
        </React.Fragment>
      )}
    </div>
  )
}

export default InputDate
