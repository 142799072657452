import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useMemo,
  useEffect
} from 'react'
import type { RetorikActivity } from '../../models/activityTypes'
import { Mode, RetorikEvent } from '../../models/enums'
import type { PonyfillFactoryCredentials } from '../../models/types'
import { useRetorik } from './RetorikContext'
import { setRetorikEvent } from './utilsStore'
import { Boundary } from '../../models/speechTypes'

export type SpeechProviderProps = {
  ponyfillFactoryCredentials: PonyfillFactoryCredentials
  children?: ReactNode
}

type SpeechContextType = {
  speaking: boolean
  currentReplyToId: string | undefined
  currentPlaying: RetorikActivity | undefined
  queuedActivities: Array<RetorikActivity>
  endedActivities: Array<string>
  ponyfillCredentials: PonyfillFactoryCredentials
  muted: boolean
  speechAllowed: boolean
  boundaryData: Array<Boundary>
  setSpeaking: (x: boolean) => void
  setCurrentReplyToId: (x: string | undefined) => void
  setQueuedActivities: (x: Array<RetorikActivity>) => void
  setCurrentPlaying: (x: RetorikActivity | undefined) => void
  setEndedActivities: (x: Array<string>) => void
  setPonyfillCredentials: (x: PonyfillFactoryCredentials) => void
  setMuted: (x: boolean) => void
  setSpeechAllowed: (x: boolean) => void
  setBoundaryData: (x: Array<Boundary>) => void
}

const SpeechContextDefaultValues: SpeechContextType = {
  speaking: false,
  currentReplyToId: undefined,
  currentPlaying: undefined,
  queuedActivities: [],
  endedActivities: [],
  ponyfillCredentials: {
    region: 'francecentral',
    subscriptionKey: ''
  },
  muted: false,
  speechAllowed: true,
  boundaryData: [],
  setSpeaking: () => {},
  setCurrentReplyToId: () => {},
  setCurrentPlaying: () => {},
  setQueuedActivities: () => {},
  setEndedActivities: () => {},
  setPonyfillCredentials: () => {},
  setMuted: () => {},
  setSpeechAllowed: () => {},
  setBoundaryData: () => {}
}

export const SpeechContext = createContext<SpeechContextType>(
  SpeechContextDefaultValues
)

export function useSpeech(): SpeechContextType {
  return useContext(SpeechContext)
}

export function SpeechProvider({
  ponyfillFactoryCredentials,
  children
}: SpeechProviderProps): JSX.Element {
  const { mode } = useRetorik()
  const [speaking, setSpeaking] = useState<boolean>(false)
  const [currentReplyToId, setCurrentReplyToId] = useState<string | undefined>(
    undefined
  )
  const [currentPlaying, setCurrentPlaying] = useState<
    RetorikActivity | undefined
  >(undefined)
  const [queuedActivities, setQueuedActivities] = useState<
    Array<RetorikActivity>
  >([])
  const [endedActivities, setEndedActivities] = useState<Array<string>>([])
  const [ponyfillCredentials, setPonyfillCredentials] =
    useState<PonyfillFactoryCredentials>(ponyfillFactoryCredentials)
  const [muted, setMuted] = useState<boolean>(false)
  const [speechAllowed, setSpeechAllowed] = useState<boolean>(
    mode === Mode.vocal
  )
  const [boundaryData, setBoundaryData] = useState<Array<Boundary>>([])

  const value = useMemo(
    () => ({
      speaking: speaking,
      currentReplyToId: currentReplyToId,
      currentPlaying: currentPlaying,
      queuedActivities: queuedActivities,
      endedActivities: endedActivities,
      ponyfillCredentials: ponyfillCredentials,
      muted: muted,
      speechAllowed,
      boundaryData: boundaryData,
      setSpeaking,
      setCurrentReplyToId,
      setCurrentPlaying,
      setQueuedActivities,
      setEndedActivities,
      setPonyfillCredentials,
      setMuted,
      setSpeechAllowed,
      setBoundaryData
    }),
    [
      speaking,
      currentReplyToId,
      currentPlaying,
      queuedActivities,
      endedActivities,
      ponyfillCredentials,
      muted,
      speechAllowed,
      boundaryData,
      setSpeaking,
      setCurrentReplyToId,
      setCurrentPlaying,
      setQueuedActivities,
      setEndedActivities,
      setPonyfillCredentials,
      setMuted,
      setSpeechAllowed,
      setBoundaryData
    ]
  )

  /**
   * On mode change :
   *  - update speechAllowed state
   */
  useEffect(() => {
    setSpeechAllowed(mode === Mode.vocal)
  }, [mode])

  useEffect(() => {
    setRetorikEvent(
      speaking ? RetorikEvent.SpeechStarted : RetorikEvent.SpeechEnded
    )
  }, [speaking])

  return (
    <React.Fragment>
      <SpeechContext.Provider value={value}>{children}</SpeechContext.Provider>
    </React.Fragment>
  )
}
