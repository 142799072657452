/* eslint-disable react/jsx-boolean-value */
import React from 'react'
import { useView } from '../../Contexts/ViewContext'
import { FullScreen } from '../../Templates'
import AbsoluteLargeClosingButton from '../../Utils/AbsoluteLargeClosingButton'
import Logo from '../../Common/Logo'
import DisplayViews from '../ViewsDisplay/DisplayViews'
import DisplaySubViews from '../ViewsDisplay/DisplaySubViews'

interface DashboardProps {
  toggleDashboard?: () => void
}

const Dashboard = ({ toggleDashboard }: DashboardProps): JSX.Element => {
  const { isMobile } = useView()

  /**
   * On call :
   *  - if the parent method toggleDashboard is defined, call it
   */
  const handleClick = (): void => {
    toggleDashboard && toggleDashboard()
  }

  return isMobile ? (
    <div className='rf-w-full rf-px-5 rf-pt-4 rf-flex rf-flex-col rf-justify-start'>
      <DisplayViews conditionalHandleClick={handleClick} />
    </div>
  ) : (
    <FullScreen>
      <div className='rf-h-full rf-w-full rf-flex rf-justify-center rf-items-center'>
        {/* Logo */}
        <Logo />

        <div className='rf-relative rf-h-full rf-max-w-7/10 rf-flex rf-flex-col rf-items-start rf-gap-8'>
          {/* Available views */}
          <div className='rf-min-h-2/5 rf-py-4 rf-mt-auto rf-flex rf-flex-col rf-gap-8 rf-overflow-y-scroll rf-scrollbar-hidden'>
            <DisplayViews handleClick={toggleDashboard} />
          </div>

          {/* Available subviews */}
          <div className='rf-h-3/10 rf-flex rf-flex-row rf-items-center rf-flex-wrap rf-gap-8'>
            <DisplaySubViews handleClick={toggleDashboard} />
          </div>
        </div>
      </div>

      {/* Closing button */}
      <AbsoluteLargeClosingButton
        dashboardVisible={true}
        onClick={handleClick}
      />
    </FullScreen>
  )
}

export default Dashboard
