import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useRetorik } from '../Contexts/RetorikContext'
import { useRenderAttachment } from 'botframework-webchat-api/lib/hooks'
import { Mode } from '../../models/enums'
import Card from '../AnswerPanel/Card'
import Carousel from '../AnswerPanel/Carousel'

interface AttachmentsProps {
  activity: any
  history?: boolean
}

const classNameText = 'rf-max-h-full'
const classNameVocal =
  'rf-w-11/12 large:rf-w-3/5 large-vertical:rf-w-4/9 rf-min-w-11/12 large:rf-min-w-3/5 large-vertical:rf-min-w-4/9 rf-max-h-full'

const Attachments = ({ activity, history }: AttachmentsProps): JSX.Element => {
  const renderAttachment = useRenderAttachment()
  const { mode } = useRetorik()
  const attachments = useMemo<Array<any>>(() => {
    return activity?.attachments || []
  }, [activity])
  const className = useMemo<string>(() => {
    return mode === Mode.text ? classNameText : classNameVocal
  }, [mode])
  const [height, setHeight] = useState<number>(0)
  const carouselRef: React.MutableRefObject<HTMLDivElement | null> =
    useRef(null)
  const timerRef: React.MutableRefObject<any> = useRef(null)

  /**
   * On activity props change :
   *  - reset height by setting height state to 0
   *  - after a short timeout, get the max height from the rendered carousel, and put it into height state
   */
  useEffect(() => {
    setHeight(0)
    timerRef &&
      (timerRef.current = setTimeout(() => {
        if (carouselRef.current) {
          setHeight(carouselRef.current.clientHeight)
        }
      }, 300))

    return (): void => timerRef && clearTimeout(timerRef.current)
  }, [activity])

  return (
    <React.Fragment>
      {attachments?.length > 1 ? (
        height !== 0 ? (
          // Displayed carousel with cards having all the same min-height
          <Carousel history={history}>
            {attachments.map((attachment, key) => {
              return (
                <Card key={key} className='rf-w-full'>
                  {renderAttachment({
                    activity: activity,
                    attachment: attachment,
                    history: history,
                    height: height
                  })}
                </Card>
              )
            })}
          </Carousel>
        ) : (
          // Test carousel used to retrieve the highest height from all cards to be used to display the 'right' carousel
          <Carousel ref={carouselRef} history={history}>
            {attachments.map((attachment, key) => {
              return (
                <Card key={(key + 1) * 100} className='rf-w-full'>
                  {renderAttachment({
                    activity: activity,
                    attachment: attachment
                  })}
                </Card>
              )
            })}
          </Carousel>
        )
      ) : (
        <Card className={history ? 'rf-w-full' : className}>
          {renderAttachment({
            activity: activity,
            attachment: attachments[0],
            history: history
          })}
        </Card>
      )}
    </React.Fragment>
  )
}

export default Attachments
