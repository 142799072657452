import React from 'react'
import type { IconDefaultProps } from '../../../models/types'

const FootWalkingIcon = ({
  className,
  color
}: IconDefaultProps): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='-6 0 34.168 29.135'
      className={className || 'rf-w-8 rf-h-8'}
    >
      <path
        d='M11.4,5.249A2.625,2.625,0,1,0,8.772,2.625,2.625,2.625,0,0,0,11.4,5.249ZM16.564,13.4l-1.274-.645-.53-1.608A5.962,5.962,0,0,0,9.171,7c-1.968-.005-3.057.552-5.1,1.378A5.216,5.216,0,0,0,1.352,10.9l-.366.744a1.755,1.755,0,0,0,.776,2.346,1.726,1.726,0,0,0,2.324-.782l.366-.744a1.738,1.738,0,0,1,.9-.842l1.465-.591-.831,3.319A3.512,3.512,0,0,0,6.8,17.568l3.275,3.576a3.529,3.529,0,0,1,.815,1.515l1,4.008a1.75,1.75,0,0,0,3.4-.848l-1.214-4.866a3.5,3.5,0,0,0-.815-1.515l-2.488-2.718.94-3.756.3.9a3.487,3.487,0,0,0,1.733,2.023l1.274.645a1.726,1.726,0,0,0,2.324-.782,1.77,1.77,0,0,0-.782-2.351ZM4.048,21.1a3.393,3.393,0,0,1-.776,1.176L.538,25.01a1.75,1.75,0,1,0,2.472,2.477l3.248-3.248a3.554,3.554,0,0,0,.776-1.176l.738-1.848c-3.024-3.3-2.116-2.286-2.592-2.936L4.048,21.1Z'
        transform='translate(0.612 0.5)'
        fill={color || '#fff'}
        stroke='#000'
        strokeWidth='1'
      />
    </svg>
  )
}

export default FootWalkingIcon
