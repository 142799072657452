const checkForData = (id: string, defaultData: any): any => {
  let value = defaultData
  const currentData = JSON.parse(
    localStorage.getItem('Retorik.Framework.Inputs') || 'null'
  )

  if (currentData && Array.isArray(currentData)) {
    currentData.forEach((data) => {
      if (data.id === id) {
        value = data.value
      }
    })
  }

  return value
}

const updateData = (id: string, value: any): void => {
  const currentData = JSON.parse(
    localStorage.getItem('Retorik.Framework.Inputs') || 'null'
  )

  if (currentData && Array.isArray(currentData)) {
    let changed = false
    currentData.forEach((data) => {
      if (data.id === id) {
        data.value = value
        changed = true
      }
    })

    // If some data were changed
    if (changed) {
      localStorage.setItem(
        'Retorik.Framework.Inputs',
        JSON.stringify(currentData)
      )
    } // Otherwise, add the data and save all
    else {
      const newData = [...currentData, { id: id, value: value }]
      localStorage.setItem('Retorik.Framework.Inputs', JSON.stringify(newData))
    }
  } else {
    localStorage.setItem(
      'Retorik.Framework.Inputs',
      JSON.stringify([{ id: id, value: value }])
    )
  }
}

export { checkForData, updateData }
