import React from 'react'
import type { IconDefaultProps } from '../../../models/types'

const ZoomOutIcon = ({ className, color }: IconDefaultProps): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 37.91 37.91'
      className={className || 'rf-h-6 rf-w-6'}
    >
      <path
        d='M29.773,4.5l4.844,4.844-6.087,6.044,2.991,2.991,6.044-6.087,4.844,4.844V4.5ZM4.5,17.137l4.844-4.844,6.044,6.087,2.991-2.991L12.293,9.344,17.137,4.5H4.5ZM17.137,42.41l-4.844-4.844,6.087-6.044-2.991-2.991L9.344,34.617,4.5,29.773V42.41ZM42.41,29.773l-4.844,4.844-6.044-6.087-2.991,2.991,6.087,6.044L29.773,42.41H42.41Z'
        transform='translate(-4.5 -4.5)'
        fill={color || '#00d7ff'}
      />
    </svg>
  )
}

export default ZoomOutIcon
