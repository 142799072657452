import React, { useEffect, useRef } from 'react'
import type { WithChildren } from '../../models/utils'
import CloseButton from './CloseButton'

type ModalProps = WithChildren<{
  isOpened: boolean
  onClose: () => void
  colorClasses?: string
}>

const Modal = ({
  isOpened,
  onClose,
  colorClasses,
  children
}: ModalProps): JSX.Element => {
  const modalRef = useRef<HTMLDialogElement>(null)

  useEffect(() => {
    if (isOpened) {
      modalRef.current && modalRef.current.showModal()
    } else {
      modalRef.current && modalRef.current.close()
    }
  }, [isOpened])

  return (
    <dialog
      onCancel={onClose}
      className={`rf-absolute backdrop:rf-bg-black/50 rf-left-0 rf-w-[45ch] rf-max-w-[70vw] rf-px-4 rf-rounded ${
        colorClasses ||
        'rf-bg-truewhite rf-border rf-border-red-700 rf-text-red-700'
      } rf-font-semibold rf-text-center`}
      ref={modalRef}
      onClick={onClose}
    >
      <div
        className='rf-flex rf-flex-col rf-p-6'
        onClick={(event): void => event?.stopPropagation()}
      >
        {children}
        <CloseButton onClick={onClose} />
      </div>
    </dialog>
  )
}

export default Modal
