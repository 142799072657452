import React from 'react'
import Mode from './Mode'
import { Parameters } from '../Common'

const Remote = (): JSX.Element => {
  return (
    <div
      id='retorik-framework-remote'
      className='rf-w-full rf-pt-3 rf-pb-4 rf-flex rf-flex-col rf-items-center rf-rounded-l-lg rf-shadow-[0_3px_6px_#606267]'
      style={{
        background: 'linear-gradient(#F8F8FC, #EDEEF3)'
      }}
    >
      {/* Modes + vocal input button */}
      <Mode />

      {/* Separation line */}
      <div className='rf-w-[90%] rf-mb-4 rf-border-b-2 rf-border-b-[#C4C5C8]' />

      {/* Parameters */}
      <div className='rf-w-full rf-flex rf-flex-col rf-justify-center rf-gap-5 large-vertical:rf-gap-6'>
        <Parameters />
      </div>
    </div>
  )
}

export default Remote
