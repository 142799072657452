import { hooks } from 'botframework-webchat'

const { useActivities } = hooks

const useShowMap = (): boolean => {
  const [activities] = useActivities()
  if (activities && activities.length > 0) {
    const lastActivity = activities.slice(-1)[0] as any
    if (
      lastActivity.type === 'event' &&
      lastActivity.name === 'Hospitality.ShowMap'
    ) {
      return true
    }
  }

  return false
}

export default useShowMap
