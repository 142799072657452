import { adaptiveCardHostConfig } from '../models/adaptiveCardHostConfig'

const createAdaptiveCardHostConfig = (
  primary: string,
  cardBackground: string
): typeof adaptiveCardHostConfig => {
  const template = adaptiveCardHostConfig
  template.containerStyles.default.foregroundColors.default.default = primary
  template.containerStyles.default.backgroundColor = cardBackground

  return template
}

export default createAdaptiveCardHostConfig
