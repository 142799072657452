/* eslint-disable react/jsx-boolean-value */
import React from "react";
import type { WeatherData } from "../../models/weatherTypes";
import { dateOptions } from "../../models/constants";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { getIconFromWeatherCode } from "../../utils/getIconFromWeatherCode";

interface LargeWeatherProps {
  locale: string;
  currentWeather: WeatherData;
  previsions: Array<WeatherData>;
}

const LargeWeather = ({
  locale,
  currentWeather,
  previsions,
}: LargeWeatherProps): JSX.Element => {
  return (
    <div className="rf-w-full rf-height-fill-available rf-flex rf-flex-col rf-justify-between rf-items-center">
      {/* Date */}
      <div className="rf-pb-4 rf-title-extralarge-bold-size-auto">
        {capitalizeFirstLetter(
          new Date().toLocaleDateString(locale, dateOptions)
        )}
      </div>

      {/* Current day icon + temperature */}
      <div className="rf-flex rf-flex-col rf-items-center rf-gap-8">
        <div className="rf-h-64 rf-w-64">
          {getIconFromWeatherCode(
            currentWeather.weather,
            "rf-h-full rf-w-full"
          )}
        </div>
        <div className="rf-text-7xl">{`${Math.round(currentWeather.temperature.average || 0)}${currentWeather.temperature.unit}`}</div>
      </div>

      {/* Next 6 days */}
      <div
        className="rf-w-full rf-py-4"
        style={{
          background: "linear-gradient(to left bottom, #F2F3F6, #E5E6EC)",
        }}
      >
        <div className="rf-w-full rf-grid rf-grid-cols-6 rf-text-size-auto">
          {previsions.map((prevision, key) => {
            return (
              <div
                className={`rf-p-4 rf-flex rf-flex-col rf-gap-4 rf-items-center ${
                  key > 0 && "rf-border-l rf-border-l-menuBorder"
                }`}
                key={key}
              >
                {/* Weather fields : day - icon - min/max temperatures */}
                <div className="rf-capitalize rf-subtitle-size-auto rf-font-bold">
                  {capitalizeFirstLetter(
                    prevision.day
                      ? new Date(prevision.day).toLocaleDateString(
                          locale,
                          dateOptions
                        )
                      : "NC"
                  )}
                </div>
                {getIconFromWeatherCode(prevision.weather, "rf-h-24")}
                <div className="rf-flex rf-flex-col rf-gap-1 rf-items-center">
                  <div className="rf-flex rf-flex-row rf-items-center">
                    <p>Min.</p>
                    <p className="rf-font-bold rf-ml-1">
                      {prevision.temperature.min}
                      {prevision.temperature.unit}
                    </p>
                  </div>
                  <div className="rf-flex rf-flex-row rf-items-center">
                    <p>Max.</p>
                    <p className="rf-font-bold rf-ml-1">
                      {prevision.temperature.max}
                      {prevision.temperature.unit}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LargeWeather;
