import React from 'react'
import { useList } from '../../Contexts/ListContext'
import translation from '../../../translations/template.json'
import { useLocaleStore } from '../../Contexts/localeStore'
import VocalReminder from './Suggestions/VocalReminder'
import SuggestionsItem from './Suggestions/SuggestionsItem'
import { hooks } from 'botframework-webchat-component'
import type { Filter } from '../../../models/filterTypes'

const { useSendPostBack } = hooks

interface Suggestion {
  title: string
  value: {
    filter: Filter
    results?: number | string
  }
}

interface SuggestionsProps {
  className?: string
  suggestions: Suggestion[]
  handleClose: () => void
}

const Suggestions = ({
  className,
  suggestions,
  handleClose
}: SuggestionsProps): JSX.Element => {
  const sendPostBack = useSendPostBack()
  const { setSuggestedFilter } = useList()
  const { locale } = useLocaleStore()

  const handleClick = (value: Filter): void => {
    setSuggestedFilter(value)
    sendPostBack({ filters: [value] })
  }

  return (
    <div className={`rf-flex rf-flex-col rf-items-start ${className}`}>
      {/* Title */}
      <div className='rf-text-3xl large:rf-text-5xl rf-font-bold rf-mb-5'>
        {translation[locale]?.suggestiontitle ||
          translation['fr-FR'].suggestiontitle}
      </div>
      {/* Research suggestions + Image of Lea to redirect to vocal conversation */}
      <div className='rf-flex rf-flex-row rf-flex-wrap'>
        <div className='rf-flex rf-flex-row rf-flex-wrap'>
          {suggestions.map((suggestion, key) => {
            return (
              <SuggestionsItem
                key={key}
                title={suggestion.title}
                value={suggestion.value.filter}
                results={
                  suggestion.value.results !== undefined
                    ? suggestion.value.results
                    : 'NC'
                }
                handleClick={handleClick}
              />
            )
          })}
        </div>
        <VocalReminder handleClose={handleClose} className='large:rf-ml-12' />
      </div>
    </div>
  )
}

export default Suggestions
