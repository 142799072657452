export const CONTENT_TYPE_ADAPTIVE_CARD =
  'application/vnd.microsoft.card.adaptive'
export const CONTENT_TYPE_HERO_CARD = 'application/vnd.microsoft.card.hero'

export const CONTENT_TYPE_NEWS = 'application/vnd.davi.retorik.card.news'
export const CONTENT_TYPE_POI = 'application/vnd.davi.retorik.card.poi'
export const CONTENT_TYPE_PAGINATION =
  'application/vnd.davi.retorik.card.pagination'
export const CONTENT_TYPE_EMPTY = 'application/vnd.davi.retorik.card.empty'
export const CONTENT_TYPE_IMAGE = 'application/vnd.davi.retorik.card.image'
export const CONTENT_TYPE_LINK = 'application/vnd.davi.retorik.card.link'
export const CONTENT_TYPE_DISCOVER =
  'application/vnd.davi.retorik.card.discover'
export const CONTENT_TYPE_SUGGESTION =
  'application/vnd.davi.retorik.card.suggestion'
export const CONTENT_TYPE_SUGGESTEDACTION =
  'application/vnd.davi.retorik.card.suggestedaction'

interface Media {
  url: string
  type?: number
  alt?: string
}

interface Image {
  url: string
  alt?: string
}

interface NewsContent {
  title: string
  subtitle: string
  text: string
  category?: string
  image?: Image
  media?: Array<Media>
  buttons?: Array<{
    type: string
    title: string
    value: string
  }>
}

type POIContentType = {
  identifier?: string
  title?: string
  subtitle?: string
  text?: string
  address?: string
  contact?: string
  phoneNumber?: string
  category?: {
    id: string
    label: string
  }
  media?: [
    {
      url?: string
      title?: string
      credits?: string
    }
  ]
  color?: string
  customerReviews?: number
  coordinates?: Coordinates
  downloadUri?: string
  favorite?: boolean
  review?: Review
  infoBanner?: string
  otherInformations?: Array<{
    title: string
    content: string | Array<string>
  }>
  tap?: {
    [option: string]: string
  }
}

type DiscoverContentType = {
  title: string
  recommandationButton: { text: string; eventName: string }
  mainButtons: ImageButton[]
  history?: boolean
  height?: number | undefined
}
type ImageButton = {
  Text: string
  MediaUrl: string
  EventName: string
}
type Review = {
  author?: {
    name?: string
    capacity?: string
  }
  comment?: string
  picture?: {
    url?: string
    description?: string
  }
}

type Coordinates = {
  latitude: number
  longitude: number
}

export type {
  Media,
  Image,
  NewsContent,
  POIContentType,
  Coordinates,
  Review,
  DiscoverContentType,
  ImageButton
}
