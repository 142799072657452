import React, { useState } from 'react'
import { useView } from '../../Contexts/ViewContext'
import { ArrowRightIcon } from '../../Icons/ArrowIcons'
import { hooks } from 'botframework-webchat-component'

const { useSendPostBack } = hooks

interface BorderlessButtonMessageProps {
  text: string
  differentTextTosend?: string
}

const BorderlessButtonMessage = ({
  text,
  differentTextTosend
}: BorderlessButtonMessageProps): JSX.Element => {
  const { themeColors } = useView()
  const [hover, setHover] = useState<boolean>(false)
  const sendPostBack = useSendPostBack()

  const onClick = (text: string): void => {
    sendPostBack(text)
  }
  return (
    <div className='rf-w-full rf-flex rf-justify-start rf-cursor-pointer'>
      <div
        className='rf-w-full rf-flex rf-flex-row rf-items-center rf-justify-start rf-text-cardButtonTextDefault'
        onPointerEnter={(): void => setHover(true)}
        onPointerLeave={(): void => setHover(false)}
        onClick={(): void => {
          setHover(true)
          onClick(differentTextTosend || text)
        }}
      >
        <div
          className={`rf-h-6 rf-w-6 rf-rounded ${
            hover
              ? 'rf-bg-cardButtonBackgroundHover'
              : 'rf-bg-cardButtonBackgroundDefault'
          }`}
        >
          <ArrowRightIcon
            className='rf-h-6 rf-p-1 rf-border rf-rounded rf-border-cardButtonBorderDefault'
            color={
              hover
                ? themeColors.card.button.text.hover
                : themeColors.card.button.text.default
            }
          />
        </div>
        <div className='rf-ml-4'>{text || ''}</div>
      </div>
    </div>
  )
}

export default BorderlessButtonMessage
