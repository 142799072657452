import React from 'react'
import type { IconDefaultProps } from '../../../models/types'

const CyclingRegularIcon = ({
  className,
  color
}: IconDefaultProps): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 34.168 29.135'
      className={className || 'rf-w-8 rf-h-8'}
    >
      <g transform='translate(0.5 -1.484)'>
        <path
          d='M20.5,6.789a2.645,2.645,0,1,0-2.645-2.645A2.653,2.653,0,0,0,20.5,6.789Zm-13.885,8.6a6.612,6.612,0,1,0,0,13.224,6.612,6.612,0,1,0,0-13.224Zm0,11.24a4.628,4.628,0,1,1,0-9.257,4.628,4.628,0,1,1,0,9.257ZM14.282,13.4l3.174-3.174,1.058,1.058a9.264,9.264,0,0,0,6.744,2.777V11.418A6.57,6.57,0,0,1,20.5,9.434L17.984,6.922a3.242,3.242,0,0,0-2.116-.793,2.219,2.219,0,0,0-1.851.793l-3.7,3.7a2.611,2.611,0,0,0-.793,1.851,2.219,2.219,0,0,0,.793,1.851l4.232,3.7v6.612h2.645v-8.2Zm10.843,1.984A6.612,6.612,0,1,0,31.737,22,6.548,6.548,0,0,0,25.125,15.385Zm0,11.24A4.628,4.628,0,1,1,29.753,22,4.685,4.685,0,0,1,25.125,26.625Z'
          transform='translate(0 0.484)'
          fill={color || '#fff'}
          stroke='#000'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1'
        />
      </g>
    </svg>
  )
}

export default CyclingRegularIcon
