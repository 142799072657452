import type { ThemeColors } from './colorTypes'
import { commonColors } from './constants'

const widgetDefault = {
  rgb: 'rgb(112, 112, 112)'
}

const defaultThemeDark: ThemeColors = {
  ...commonColors,
  companyName: '#000',
  loader: {
    animation: commonColors.primary,
    background: 'rgba(18,18,18,0.7)',
    text: '#FFF',
    toggle: {
      text: '#000',
      background: commonColors.primary
    },
    button: {
      background: {
        default: 'transparent',
        hover: 'transparent'
      },
      border: {
        default: commonColors.primary,
        hover: '#FFF'
      },
      text: {
        default: commonColors.primary,
        hover: '#FFF'
      }
    }
  },
  card: {
    frame: {
      background: '#2B2B2B',
      border: 'transparent',
      text: '#F7F3F6'
    },
    button: {
      background: {
        default: 'transparent',
        hover: 'transparent'
      },
      border: {
        default: commonColors.primary,
        hover: '#FFF'
      },
      text: {
        default: commonColors.primary,
        hover: '#FFF'
      }
    },
    discoverButton: {
      background: {
        default: '#eaebf0',
        hover: 'transparent'
      },
      border: {
        default: 'transparent',
        hover: '#FFF'
      },
      text: {
        default: '#575F6B',
        hover: '#FFF'
      }
    }
  },
  textMode: {
    panel: {
      background: 'rgba(255, 255, 255, 0.9)',
      border: 'transparent',
      conversationUser: '#101219',
      conversationBot: '#1999B1'
    }
  },
  vocalMode: {
    subtitles: {
      text: '#FFF',
      background: '#000'
    }
  },
  formInput: {
    text: {
      default: commonColors.secondary,
      hover: '#FFF'
    },
    inputRadioCheckbox: {
      unchecked: {
        background: '#434141',
        border: '#818181'
      },
      checked: {
        background: commonColors.primary,
        border: commonColors.primary,
        item: '#000'
      }
    },
    inputButton: {
      background: {
        default: commonColors.black,
        hover: commonColors.primary
      },
      border: {
        default: commonColors.primary,
        hover: '#FFF'
      },
      text: {
        default: '#FFF',
        hover: '#FFF'
      }
    },
    likert: {
      default: 'transparent',
      hover: '#E8B712'
    }
  }
}

const defaultThemeLight: ThemeColors = {
  ...commonColors,
  companyName: '#000',
  loader: {
    animation: commonColors.primary,
    background: 'rgba(18,18,18,0.7)',
    text: '#FFF',
    toggle: {
      text: '#000',
      background: commonColors.primary
    },
    button: {
      background: {
        default: 'transparent',
        hover: 'transparent'
      },
      border: {
        default: commonColors.primary,
        hover: '#FFF'
      },
      text: {
        default: commonColors.primary,
        hover: '#FFF'
      }
    }
  },
  card: {
    frame: {
      background: '#2B2B2B',
      border: 'transparent',
      text: '#F7F3F6'
    },
    button: {
      background: {
        default: 'transparent',
        hover: 'transparent'
      },
      border: {
        default: commonColors.primary,
        hover: '#FFF'
      },
      text: {
        default: commonColors.primary,
        hover: '#FFF'
      }
    },
    discoverButton: {
      background: {
        default: '#eaebf0',
        hover: 'transparent'
      },
      border: {
        default: 'transparent',
        hover: '#FFF'
      },
      text: {
        default: '#575F6B',
        hover: '#FFF'
      }
    }
  },
  textMode: {
    panel: {
      background: 'rgba(255, 255, 255, 0.9)',
      border: 'transparent',
      conversationUser: '#101219',
      conversationBot: '#1999B1'
    }
  },
  vocalMode: {
    subtitles: {
      text: '#FFF',
      background: '#000'
    }
  },
  formInput: {
    text: {
      default: commonColors.secondary,
      hover: '#FFF'
    },
    inputRadioCheckbox: {
      unchecked: {
        background: '#434141',
        border: '#818181'
      },
      checked: {
        background: commonColors.primary,
        border: commonColors.primary,
        item: '#000'
      }
    },
    inputButton: {
      background: {
        default: commonColors.black,
        hover: commonColors.primary
      },
      border: {
        default: commonColors.primary,
        hover: '#FFF'
      },
      text: {
        default: '#FFF',
        hover: '#FFF'
      }
    },
    likert: {
      default: 'transparent',
      hover: '#E8B712'
    }
  }
}

export { defaultThemeDark, defaultThemeLight, widgetDefault }
