/* eslint-disable react/jsx-boolean-value */
import React, { useState, useEffect, useRef } from 'react'
import { useLocaleStore } from '../Contexts/localeStore'
import { useRetorik } from '../Contexts/RetorikContext'
import { useView } from '../Contexts/ViewContext'
import { useSpeech } from '../Contexts/SpeechContext'
import ChatbotProvider from '@davi/spirit-engine-sprite'
import useAnimationQueue from '../../hooks/useAnimationQueue'
import type { NewsConfig, ChatbotData, Queue } from '../../models/types'
import translation from '../../translations/menu.json'

import Background from '../Common/Background'
import Time from '../News/Time'
import News from '../News/News'
import Weather from '../Weather/Weather'
import AbsoluteLargeClosingButton from '../Utils/AbsoluteLargeClosingButton'
import { BackButton } from '../Menu/Common'
import { DeviceType } from '../../models/enums'

interface NewsViewProps {
  chatbotData: ChatbotData
  hideMenu?: boolean
  isRetorikNews?: boolean
  handleEndedWithoutloop?: () => void
}

// Steps enum
enum Steps {
  opening = 1,
  news,
  events,
  weather,
  ending
}

const NewsView = ({
  chatbotData,
  hideMenu,
  isRetorikNews,
  handleEndedWithoutloop
}: NewsViewProps): JSX.Element => {
  const locale = useLocaleStore((state) => state.locale)
  const { configuration, agentData } = useRetorik()
  const {
    configurations,
    setRoute,
    isMobile,
    currentDeviceType,
    setDisplayControls
  } = useView()
  const [displayData, setDisplayData] = useState<NewsConfig>(
    configurations.views.news || configurations.views.home
  )
  const { speaking, setSpeechAllowed } = useSpeech()
  const lastAnimationQueue = useAnimationQueue()
  const [animationQueue, setAnimationQueue] = useState<Queue>([])
  const [step, setStep] = useState<number>(
    configurations.views.news?.openingVideo ? Steps.opening : Steps.news
  )
  const timerRef: React.MutableRefObject<any> = useRef(null)
  const newsEndedTimerRef: React.MutableRefObject<any> = useRef(null)

  /**
   * On call :
   *  - if the component is news-only, return
   *  - else set ViewContext's route state to 'home'
   */
  const handleBack = (): void => {
    if (isRetorikNews) {
      return
    }
    setRoute('home')
  }

  useEffect(() => {
    setSpeechAllowed(true)
    setDisplayControls(false)
  }, [])

  /**
   * On step change :
   *  - switch on the current step to launch corresponding processing
   * On component unmount :
   *  - clear timerRef timeout
   */
  useEffect(() => {
    switch (step) {
      case Steps.opening: {
        const videoUrl = configurations.views.news?.openingVideo
        videoUrl
          ? setViewContextCurrentConfiguration(videoUrl as string)
          : setStep(Steps.news)
        break
      }
      case Steps.news:
        setDisplayData(configurations.views.news || configurations.views.home)
        break
      case Steps.events:
        break
      case Steps.weather:
        // If the position is known, display the weather page during 10 seconds, otherwise switch to the next step directly
        configuration.position?.latitude && configuration.position?.longitude
          ? (timerRef.current = setTimeout(() => {
              setStep(Steps.ending)
            }, 10000))
          : setStep(Steps.ending)
        break
      case Steps.ending: {
        const videoUrl = configurations.views.news?.openingVideo
        videoUrl
          ? setViewContextCurrentConfiguration(videoUrl as string)
          : exitNewsOrLoop()
        break
      }
      default:
        break
    }

    return (): void => {
      timerRef && clearTimeout(timerRef.current)
      newsEndedTimerRef && clearTimeout(newsEndedTimerRef.current)
    }
  }, [step])

  useEffect(() => {
    lastAnimationQueue && setAnimationQueue(lastAnimationQueue)
  }, [lastAnimationQueue])

  /**
   * On call :
   *  - set the current display configuration to have the given video as background
   * @param videoUrl
   */
  const setViewContextCurrentConfiguration = (videoUrl: string): void => {
    setDisplayData({
      ...configurations.views.news,
      background: {
        video: videoUrl,
        style: 'video',
        blur: 0
      }
    })
  }

  /**
   * On call :
   *  - send the event 'newsEnded' used in retorik Kiosk
   *  - if the component is news-only :
   *    + if 'loop' is explicitly set to false, call props' handleEndedWithoutloop method if defined. Call handleBack method otherwise
   *    + if 'loop' is not false, loop by setting step state to Steps.news
   *  - or else if 'loop' parameter in configuration is true, get back to Steps.news step. Call handleBack method otherwise
   */
  const exitNewsOrLoop = (): void => {
    // Send event 'newsEnded'
    const endEvent = new Event('newsEnded')
    document.dispatchEvent(endEvent)
    // Relaunch from the start if this is a news-only component and loop hasn't explicitly been set to false
    isRetorikNews
      ? displayData.loop !== false
        ? setStep(Steps.news)
        : handleEndedWithoutloop
        ? handleEndedWithoutloop()
        : handleBack()
      : // Relaunch from the start if the loop is activated, otherwise get back to homepage
      displayData.loop
      ? setStep(Steps.news)
      : handleBack()
  }

  /**
   * On video end :
   *  - if we are at the opening step, let's set the step state to Steps.news to launch the news
   *  - if we are at the ending step, call exitNewsOrLoop function
   */
  const handleVideoEnd = (): void => {
    switch (step) {
      case Steps.opening:
        setStep(Steps.news)
        break
      case Steps.ending:
        exitNewsOrLoop()
        break
    }
  }

  /**
   * On news end :
   *  - wait 3 seconds
   *  - get to the next step (Steps.events)
   */
  const handleNewsEnd = (): void => {
    newsEndedTimerRef &&
      (newsEndedTimerRef.current = setTimeout(() => {
        // setStep(Steps.events)
        setStep(Steps.weather)
      }, 3000))
  }

  return (
    <React.Fragment>
      <Background
        config={displayData.background}
        videoLoop={false}
        videoMuted={false}
        onVideoEnded={handleVideoEnd}
      />

      {/* Timer + back button in mobile / widget mode */}
      {isMobile && !isRetorikNews && !hideMenu ? (
        <div className='rf-relative rf-z-util rf-col-start-1 rf-col-span-full rf-row-start-1 rf-flex rf-flex-col rf-gap-2 rf-items-start'>
          <BackButton
            title={translation[locale]?.back || translation['fr-FR'].back}
            handleBack={handleBack}
            className='rf-pr-2 rf-mt-4 rf-ml-4 rf-text-size-auto rf-bg-truewhite rf-rounded-lg'
          />
          <Time belowReturnButton={true} />
        </div>
      ) : (
        <Time />
      )}

      {/* News step */}
      {step === Steps.news && agentData && (
        <React.Fragment>
          {chatbotData && (
            <div
              className={`rf-relative rf-col-start-1 vertical:rf-col-span-full rf-col-span-5 rf-row-start-1 rf-row-span-full rf-self-end ${
                currentDeviceType === DeviceType.widget
                  ? 'rf-h-full'
                  : 'rf-h-screen'
              }`}
            >
              <ChatbotProvider
                licence='licence'
                agentData={agentData}
                height={chatbotData?.height}
                size={chatbotData?.size}
                speak={speaking}
                animationQueue={animationQueue}
                setAnimationQueue={setAnimationQueue}
              />
            </div>
          )}
          <News
            intervalInSeconds={
              displayData.intervalInSeconds
                ? (displayData.intervalInSeconds as number)
                : undefined
            }
            onEnd={handleNewsEnd}
            isRetorikNews={isRetorikNews}
          />
        </React.Fragment>
      )}

      {step === Steps.weather && <Weather handleClose={handleBack} />}

      {/* Closing button if it needs to be displayed */}
      {!hideMenu && (
        <AbsoluteLargeClosingButton
          dashboardVisible={true}
          onClick={handleBack}
        />
      )}
    </React.Fragment>
  )
}

export default NewsView
