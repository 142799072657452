/* eslint-disable react/jsx-boolean-value */
import React from 'react'
import { useRetorik } from '../Contexts/RetorikContext'
import { useView } from '../Contexts/ViewContext'
import { CurrentSubView, Depth } from '../../models/enums'
import { FullScreen, SubMenuClosing } from '../Templates'
import AbsoluteLargeClosingButton from '../Utils/AbsoluteLargeClosingButton'
import { EmergencyContent } from './EmergencyContent'

interface EmergencyProps {
  handleClose?: () => void
}

const Emergency = ({ handleClose }: EmergencyProps): JSX.Element => {
  const { setCurrentSubView } = useRetorik()
  const { isMobile } = useView()

  const handleBack = (): void => {
    handleClose ? handleClose() : setCurrentSubView(CurrentSubView.none)
  }

  return isMobile ? (
    <SubMenuClosing
      handleClose={handleBack}
      backIsConversation={false}
      className='rf-height-fill-available -rf-mb-4'
    >
      {/* Emergency data */}
      <EmergencyContent />
    </SubMenuClosing>
  ) : (
    <FullScreen
      depth={Depth.dropdown}
      background='linear-gradient(to right bottom, #F2F8F8FC , #F2E1E2E8)'
    >
      <div className='rf-h-full rf-w-full rf-flex rf-flex-col rf-items-center rf-justify-center rf-gap-12'>
        {/* Emergency data */}
        <EmergencyContent />
      </div>

      {/* Closing button */}
      <AbsoluteLargeClosingButton
        dashboardVisible={true}
        onClick={handleBack}
      />
    </FullScreen>
  )
}

export default Emergency
