{
  "fr-FR": {
    "firstline": "Pour une expérience optimale,",
    "secondline": "basculez votre écran"
  },
  "en-US": {
    "firstline": "For an optimal experience,",
    "secondline": "flip your screen"
  },
  "es-ES": {
    "firstline": "For an optimal experience,",
    "secondline": "flip your screen"
  },
  "de-DE": {
    "firstline": "For an optimal experience,",
    "secondline": "flip your screen"
  }
}
