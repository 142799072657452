import React, { useEffect, useState } from 'react'
import type { NewsContent } from '../../models/attachmentTypes'
import RetorikSpeech from '../Speech/RetorikSpeech'
import { useSpeech } from '../Contexts/SpeechContext'
import { useVoiceSelector } from 'botframework-webchat-api/lib/hooks'
import createUtterance from '../../utils/createUtterance'
import type { Ponyfill, CreateUtteranceParams } from '../../models/speechTypes'
import type { RetorikActivity } from '../../models/activityTypes'
import { useLocaleStore } from '../Contexts/localeStore'

type SpeakNewsProps = {
  news: NewsContent
  onEnd: () => void
  ponyfill: Ponyfill
  activity: RetorikActivity | undefined
}

const SpeakNews = ({
  news,
  onEnd,
  ponyfill,
  activity
}: SpeakNewsProps): JSX.Element | null => {
  const { setSpeaking, muted } = useSpeech()
  const defaultLocale = useLocaleStore((state) => state.locale)
  if (activity && activity.type === 'message' && !activity.locale)
    activity.locale = defaultLocale
  const selectVoice = useVoiceSelector(activity)
  const [utterance, setUtterance] = useState<SpeechSynthesisUtterance | null>(
    null
  )

  useEffect(() => {
    if (ponyfill) {
      const tempActivity = {
        ...activity,
        speak: `${news.title}, <break/> ${news.subtitle}: <break/> ${news.text}`
      }
      const utteranceParams: CreateUtteranceParams = {
        ponyfill: ponyfill,
        selectVoice: selectVoice,
        activity: tempActivity,
        muted: muted
      }
      setUtterance(createUtterance({ ...utteranceParams }))
    }

    return (): void => setUtterance(null)
  }, [news])

  const handleStart = (): void => {
    setSpeaking(true)
    const startEvent = new Event('retorikSpeakStart')
    document.dispatchEvent(startEvent)
  }

  const handleEnd = (): void => {
    setSpeaking(false)
    const stopEvent = new Event('retorikSpeakStop')
    document.dispatchEvent(stopEvent)
    onEnd()
  }

  return utterance ? (
    <RetorikSpeech
      ponyfill={ponyfill}
      onEnd={handleEnd}
      onError={handleEnd}
      onStart={handleStart}
      utterance={utterance}
    />
  ) : null
}

export default SpeakNews
