import React from 'react'
import type { IconDefaultProps } from '../../../models/types'

const RetorikLogoIcon = ({
  className,
  color
}: IconDefaultProps): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 325 135'
      className={className || 'rf-h-6 rf-w-6'}
    >
      <rect x='0' y='-6' width='325' height='143' fill='transparent' />
      <g>
        <path
          fill={color || '#FFF'}
          d='M93.6,70.9V50.8c0-14.2-11.5-25.7-25.7-25.7h0c-14.2,0-25.7,11.5-25.7,25.7v20.1c0,14.2,11.5,25.7,25.7,25.7v0   h25.7l-13.3-3.2C88.2,89,93.6,80.6,93.6,70.9z M67.9,89.8C57.5,89.8,49,81.3,49,70.9V50.8c0-10.4,8.5-18.9,18.9-18.9   c10.4,0,18.9,8.5,18.9,18.9v20.1C86.8,81.3,78.3,89.8,67.9,89.8z'
        />
        <g>
          <path
            fill={color || '#FFF'}
            d='M67.9,82.6c-2.3,0-4.5-0.9-6.1-2.5c-0.7-0.7-0.7-1.9,0-2.6c0.7-0.7,1.9-0.7,2.6,0c0.9,0.9,2.2,1.4,3.5,1.4    c1.3,0,2.6-0.5,3.5-1.4c0.7-0.7,1.9-0.7,2.6,0c0.7,0.7,0.7,1.9,0,2.6C72.4,81.7,70.2,82.6,67.9,82.6z'
          />
        </g>
      </g>
      <g>
        <path
          fill={color || '#FFF'}
          d='M117.1,61.6V34.9h11.1c1.1,0,2.2,0.2,3.1,0.7c1,0.5,1.8,1.1,2.5,1.9c0.7,0.8,1.2,1.7,1.6,2.7   c0.4,1,0.6,2,0.6,3c0,1-0.2,1.9-0.5,2.8c-0.3,0.9-0.7,1.7-1.3,2.5c-0.6,0.7-1.2,1.3-2,1.8c-0.8,0.5-1.6,0.8-2.6,0.9l6.7,10.4h-2.2   l-6.5-10.1H119v10.1H117.1z M119,49.7h9.4c0.9,0,1.6-0.2,2.3-0.6c0.7-0.4,1.3-0.9,1.8-1.5c0.5-0.6,0.9-1.3,1.1-2.1   c0.3-0.8,0.4-1.6,0.4-2.4c0-0.9-0.2-1.7-0.5-2.5c-0.3-0.8-0.7-1.5-1.3-2.1c-0.5-0.6-1.2-1.1-1.9-1.4c-0.7-0.4-1.5-0.5-2.3-0.5H119   V49.7z'
        />
        <path
          fill={color || '#FFF'}
          d='M161.6,59.9v1.7h-17.8V34.9h17.5v1.7h-15.5v10.5h13.6v1.7h-13.6v11.1H161.6z'
        />
        <path
          fill={color || '#FFF'}
          d='M187.8,36.6H178v25h-1.9v-25h-9.8v-1.7h21.5V36.6z'
        />
        <path
          fill={color || '#FFF'}
          d='M203.7,61.8c-1.9,0-3.5-0.4-5.1-1.2c-1.5-0.8-2.8-1.8-4-3c-1.1-1.3-2-2.7-2.6-4.3c-0.6-1.6-0.9-3.3-0.9-5   c0-1.8,0.3-3.5,1-5.1c0.6-1.6,1.5-3.1,2.6-4.3c1.1-1.3,2.4-2.3,4-3c1.5-0.7,3.2-1.1,5-1.1c1.9,0,3.6,0.4,5.1,1.2   c1.5,0.8,2.9,1.8,4,3.1c1.1,1.3,1.9,2.7,2.5,4.3s0.9,3.3,0.9,4.9c0,1.8-0.3,3.5-1,5.2c-0.6,1.6-1.5,3.1-2.6,4.3   c-1.1,1.2-2.4,2.2-4,3C207.1,61.4,205.5,61.8,203.7,61.8z M193.1,48.2c0,1.5,0.3,3,0.8,4.4c0.5,1.4,1.2,2.7,2.2,3.8   c0.9,1.1,2.1,2,3.4,2.6c1.3,0.7,2.7,1,4.3,1c1.6,0,3.1-0.3,4.3-1c1.3-0.7,2.4-1.6,3.3-2.7c0.9-1.1,1.6-2.4,2.1-3.8   c0.5-1.4,0.8-2.8,0.8-4.3c0-1.5-0.3-3-0.8-4.4c-0.5-1.4-1.3-2.7-2.2-3.8c-0.9-1.1-2-2-3.3-2.6c-1.3-0.7-2.7-1-4.2-1   c-1.6,0-3.1,0.3-4.4,1S197,39,196,40.2c-0.9,1.1-1.6,2.4-2.1,3.8C193.4,45.4,193.1,46.8,193.1,48.2z'
          id='path19'
        />
        <path
          fill={color || '#FFF'}
          d='M223.7,61.6V34.9h11.1c1.1,0,2.2,0.2,3.1,0.7c1,0.5,1.8,1.1,2.5,1.9c0.7,0.8,1.2,1.7,1.6,2.7   c0.4,1,0.6,2,0.6,3c0,1-0.2,1.9-0.5,2.8c-0.3,0.9-0.7,1.7-1.3,2.5c-0.6,0.7-1.2,1.3-2,1.8c-0.8,0.5-1.6,0.8-2.6,0.9l6.7,10.4h-2.2   l-6.5-10.1h-8.7v10.1H223.7z M225.6,49.7h9.4c0.9,0,1.6-0.2,2.3-0.6c0.7-0.4,1.3-0.9,1.8-1.5c0.5-0.6,0.9-1.3,1.1-2.1   c0.3-0.8,0.4-1.6,0.4-2.4c0-0.9-0.2-1.7-0.5-2.5c-0.3-0.8-0.7-1.5-1.3-2.1c-0.5-0.6-1.2-1.1-1.9-1.4c-0.7-0.4-1.5-0.5-2.3-0.5h-9.1   V49.7z'
        />
        <path fill={color || '#FFF'} d='M250.5,61.6V34.9h1.9v26.7H250.5z' />
        <path
          fill={color || '#FFF'}
          d='M261.7,61.6V34.9h1.9v16.2l15.6-16.2h2.2l-11.1,11.7l11.7,15h-2.2l-10.7-13.8l-5.5,5.6v8.2H261.7z'
        />
      </g>
      <g>
        <g>
          <g>
            <g>
              <path
                fill={color || '#FFF'}
                d='M122.9,96.1c-1,0-1.9-0.3-2.7-0.8c-0.8-0.5-1.5-1.2-2-2v2.5h-1.1V78.4h1.2v7.7c0.6-0.9,1.3-1.6,2.1-2.1      c0.8-0.5,1.7-0.8,2.8-0.8c0.9,0,1.7,0.2,2.4,0.6c0.7,0.4,1.3,0.9,1.8,1.5c0.5,0.6,0.9,1.3,1.1,2.1c0.3,0.8,0.4,1.6,0.4,2.4      c0,0.9-0.2,1.7-0.5,2.4s-0.7,1.4-1.3,2c-0.5,0.6-1.2,1-1.9,1.4C124.5,95.9,123.7,96.1,122.9,96.1z M122.6,95      c0.7,0,1.4-0.2,2-0.5c0.6-0.3,1.1-0.7,1.6-1.2c0.4-0.5,0.8-1.1,1-1.7c0.2-0.6,0.4-1.3,0.4-2c0-0.7-0.1-1.4-0.3-2      c-0.2-0.6-0.6-1.2-1-1.7c-0.4-0.5-0.9-0.9-1.5-1.2c-0.6-0.3-1.2-0.5-1.9-0.5c-0.5,0-1,0.1-1.5,0.3c-0.5,0.2-0.9,0.4-1.3,0.7      c-0.4,0.3-0.7,0.7-1.1,1.1c-0.3,0.4-0.6,0.8-0.8,1.2v4.1c0,0.5,0.2,0.9,0.5,1.3c0.3,0.4,0.7,0.8,1.1,1.1      c0.4,0.3,0.9,0.5,1.4,0.7C121.7,95,122.2,95,122.6,95z'
              />
            </g>
            <g>
              <path
                fill={color || '#FFF'}
                d='M133.3,100.1c0.1,0,0.2,0,0.4,0c0.2,0,0.3,0,0.4,0c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.2-0.1,0.3-0.2      c0.1-0.1,0.2-0.3,0.3-0.5c0.1-0.3,0.3-0.7,0.5-1.2c0.2-0.5,0.5-1.2,0.9-2.1L131,83.5h1.2l4.9,11.3l4.6-11.3h1.2l-6.8,16.3      c-0.2,0.4-0.5,0.8-0.8,1c-0.4,0.2-0.9,0.4-1.6,0.4c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0V100.1z'
              />
            </g>
          </g>
        </g>
        <g>
          <path
            fill={color || '#FFF'}
            d='M213.9,95.9l0.4-0.3c1.4-1.2,2.1-2.7,2.1-4.6l0-8h-2.5V95.9z'
          />
        </g>
        <g>
          <polygon
            fill={color || '#FFF'}
            points='202.9,93.3 196.9,83 193.9,83 201.4,95.9 201.4,95.9 204.4,95.9 204.4,95.9 212,83 208.9,83   '
          />
        </g>
        <g>
          <path
            fill={color || '#FFF'}
            d='M174.1,87.5v3.9c0,2.5,2,4.5,4.5,4.5h8.9c0.7,0,1.4-0.2,2-0.5v0.4h2.5V83l-13.4,0    C176.1,83,174.1,85,174.1,87.5z M189.4,85.5v5.7c-0.1,1.2-1,2.1-2.2,2.1h-8.6c-1.2,0-2.2-1-2.2-2.3v-3.4c0-1.2,1-2.2,2.2-2.2    H189.4z'
          />
        </g>
        <g>
          <path
            fill={color || '#FFF'}
            d='M171.3,75.2l-2.5-0.8v9.1c-0.6-0.3-1.3-0.5-2-0.5H158c-2.5,0-4.5,2-4.5,4.5v3.9c0,2.5,2,4.5,4.5,4.5l13.2,0    h1.4h0.3c-1-1.2-1.6-2.8-1.6-4.5L171.3,75.2z M168.8,93.4h-10.7c-1.2,0-2.2-1-2.2-2.2v-3.4c0-1.2,1-2.2,2.2-2.2h8.5    c1.1,0,2.1,0.9,2.1,2.1V93.4z'
          />
        </g>
        <g>
          <path
            fill={color || '#FFF'}
            d='M215.1,80.6c0.3,0,0.6-0.1,0.9-0.4c0.3-0.2,0.4-0.5,0.4-0.9c0-0.3-0.1-0.6-0.4-0.9c-0.3-0.3-0.6-0.4-0.9-0.4    c-0.4,0-0.7,0.1-0.9,0.4c-0.2,0.2-0.4,0.5-0.4,0.9c0,0.4,0.1,0.7,0.4,0.9C214.5,80.5,214.8,80.6,215.1,80.6z'
          />
        </g>
      </g>
    </svg>
  )
}

export default RetorikLogoIcon
