import React, { useEffect } from 'react'
import { useLocaleStore, setLocale } from '../Contexts/localeStore'

const LocaleChangeEventListener = (): JSX.Element => {
  const supportedLanguages = useLocaleStore((state) => state.supported)

  useEffect(() => {
    document.addEventListener('changeRetorikLocale', handleChangeRetorikLocale)

    return (): void => {
      document.removeEventListener(
        'changeRetorikLocale',
        handleChangeRetorikLocale
      )
    }
  }, [])

  const handleChangeRetorikLocale = (event): void => {
    console.warn(
      'changeRetorikLocale event is depreciated and will be removed soon. Please use setLocale function instead.'
    )
    const newLocale = event.detail?.locale
    if (newLocale && supportedLanguages?.includes(newLocale)) {
      setLocale(newLocale)
    }
  }

  return <React.Fragment />
}

export default LocaleChangeEventListener
