// Check if the locale in the current browser is in the array of available locales
const checkLocaleFromBrowser = (allLanguages: string[]): string | undefined => {
  const navLang = navigator?.language
  if (navLang && allLanguages && allLanguages.length > 0) {
    let res: string | undefined
    // Navigator language can be 2-chars
    if (navLang.length === 2) {
      res = allLanguages.find((language) => {
        return (
          language.substring(3, 5).toLowerCase() ===
          navigator.language.toLowerCase()
        )
      })
    } else {
      res = allLanguages.find((language) => {
        return language.toLowerCase() === navigator.language.toLowerCase()
      })
    }

    if (res) return res
  }

  return undefined
}

export const checkLocale = (languageData, localeConfig): string => {
  if (localeConfig) {
    // Use localeConfig.default if available and supported
    if (
      localeConfig.default &&
      languageData.all.includes(localeConfig.default)
    ) {
      return localeConfig.default
    }

    // else, use browser locale if config say so
    if (localeConfig.getDefaultFromBrowser) {
      const browserLocale: string | undefined = checkLocaleFromBrowser(
        languageData.all
      )
      if (browserLocale) return browserLocale
    }
  }

  // else, use default from languageData
  return languageData.default
}

export const getMainLocale = (locale: string): string => {
  const split = locale.split('-')
  return split.length > 0 ? split[1] : split[0]
}
