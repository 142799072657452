import React, { useEffect } from 'react'
import Chatbot from './Chatbot'
import Loader from './Loader'
// eslint-disable-next-line no-unused-vars
import { CommonProps } from '../models/types'
import { checkLicence } from './redux/slices/licenceSlice'
import {
  useAppDispatch,
  useErrorCode,
  useIsLicenceAllowed
} from './redux/hooks'

const DataRetriever = ({
  licence,
  agentData,
  height,
  size,
  speak,
  animationQueue,
  setAnimationQueue
}: CommonProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const allowedByLicence = useIsLicenceAllowed()
  const errorCode = useErrorCode()

  useEffect(() => {
    dispatch(checkLicence())
  }, [licence])

  return (
    <React.Fragment>
      {allowedByLicence ? (
        <Chatbot
          agentData={agentData}
          height={height}
          size={size}
          speak={speak}
          animationQueue={animationQueue}
          setAnimationQueue={setAnimationQueue}
        />
      ) : (
        <Loader errorCode={errorCode} />
      )}
    </React.Fragment>
  )
}

export default DataRetriever
