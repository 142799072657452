import React, { useState } from 'react'
import { PhoneIcon } from '../../Icons/DetailedPOIIcons'

type ShowPhoneNumberPorps = {
  phoneNumber: string
}

const ShowPhoneNumber = ({
  phoneNumber
}: ShowPhoneNumberPorps): JSX.Element => {
  const [show, setShow] = useState<boolean>(false)

  return (
    <button
      onClick={(): void => setShow(!show)}
      className='rf-px-5 rf-py-3 rf-mb-8 rf-flex rf-flex-row rf-items-center rf-border-2 rf-border-black rf-rounded-lg hover:rf-bg-truewhite rf-font-bold'
    >
      <PhoneIcon className='rf-h-6 rf-w-6 rf-mr-2' />
      {show ? phoneNumber : 'Afficher le numéro'}
    </button>
  )
}

export default ShowPhoneNumber
