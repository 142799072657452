import React from 'react'
import type { IconDefaultProps } from '../../../models/types'

const ArrowIcon = ({ className, color }: IconDefaultProps): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 43.776 39.197'
      className={className || 'rf-h-6 rf-w-8'}
    >
      <g transform='translate(9.939 -0.321) rotate(17)'>
        <path
          d='M28.156,0S31.392,28.156,0,28.156'
          transform='translate(5.721 0)'
          fill='none'
          stroke={color || '#1999b1'}
          strokeLinecap='round'
          strokeWidth='2'
        />
        <path
          d='M0,0,8.8,8.8l8.8-8.8'
          transform='translate(8.688 18.779) rotate(81)'
          fill='none'
          stroke={color || '#1999b1'}
          strokeLinecap='round'
          strokeWidth='2'
        />
      </g>
    </svg>
  )
}

export default ArrowIcon
