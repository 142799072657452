{
  "_comment": "[CI-LOCKED] O conteúdo deste arquivo está bloqueado. Contribuições são bem-vindas, mas pode haver atrasos na validação das alterações.",
  "ACCESS_KEY_ALT": "Alt Shift $1",
  "ACCESS_KEY_FOR_MAC_ALT": "Control Option $1",
  "ACTIVITY_BOT_SAID_ALT": "O bot $1 disse:",
  "ACTIVITY_YOU_SAID_ALT": "Você disse:",
  "ACTIVITY_BOT_ATTACHED_ALT": "Bot anexado:",
  "ACTIVITY_ERROR_BOX_TITLE": "Mensagem de erro",
  "ACTIVITY_INTERACTIVE_FOOTNOTE_ALT": "Clique para interagir.",
  "ACTIVITY_INTERACTIVE_REASON_INTERACTIVE_CONTENT_ALT": "A mensagem é interativa.",
  "ACTIVITY_INTERACTIVE_REASON_LINK_ALT": "Um ou mais links na mensagem.",
  "ACTIVITY_INTERACTIVE_REASON_SEND_FAILED_ALT": "Falha no envio.",
  "ACTIVITY_YOU_ATTACHED_ALT": "Você anexou:",
  "ACTIVITY_NUM_ATTACHMENTS_ONE_ALT": "1 anexo.",
  "ACTIVITY_NUM_ATTACHMENTS_FEW_ALT": "$1 anexos.",
  "ACTIVITY_NUM_ATTACHMENTS_MANY_ALT": "$1 anexos.",
  "ACTIVITY_NUM_ATTACHMENTS_OTHER_ALT": "$1 anexos.",
  "ACTIVITY_NUM_ATTACHMENTS_TWO_ALT": "$1 anexos.",
  "ACTIVITY_STATUS_SEND_FAILED_RETRY": "Falha no envio. [Repetir][RETRY].",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENDING": "Enviando",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENT_AT": "Enviado às $1",
  "ACTIVITY_STATUS_TIMESTAMP_JUST_NOW": "Agora",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_HOUR_AGO": "Há uma hora",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_MINUTE_AGO": "Há um minuto",
  "ACTIVITY_STATUS_TIMESTAMP_TODAY": "Hoje",
  "ACTIVITY_STATUS_TIMESTAMP_YESTERDAY": "Ontem",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_PARSE": "Erro de análise do Cartão Adaptável",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_RENDER": "Erro de renderização do Cartão Adaptável",
  "ATTACHMENT_AUDIO": "Um clipe de áudio.",
  "ATTACHMENT_CARD": "Um cartão: $1 $2 $3",
  "ATTACHMENT_FILE": "Um arquivo: $1",
  "ATTACHMENT_IMAGE": "Uma imagem",
  "ATTACHMENT_TEXT": "Um texto: $1",
  "ATTACHMENT_VIDEO": "Um videoclipe",
  "CAROUSEL_FLIPPER_LEFT_ALT": "Esquerdo",
  "CAROUSEL_FLIPPER_RIGHT_ALT": "Direito",
  "CONNECTIVITY_STATUS_ALT_CONNECTED": "Conectado",
  "CONNECTIVITY_STATUS_ALT_CONNECTING": "Conectando…",
  "CONNECTIVITY_STATUS_ALT_FATAL": "Não é possível conectar.",
  "CONNECTIVITY_STATUS_ALT_RECONNECTING": "Ocorreu uma interrupção da rede. Reconectando…",
  "CONNECTIVITY_STATUS_ALT_RENDER_ERROR": "Erro de renderização. Verifique o console ou contate o desenvolvedor de bot.",
  "CONNECTIVITY_STATUS_ALT_SLOW_CONNECTION": "A conexão está demorando mais do que o habitual.",
  "CONNECTIVITY_STATUS_ALT": "Status da Conectividade: $1",
  "FILE_CONTENT_ALT": "'$1'",
  "FILE_CONTENT_DOWNLOADABLE_ALT": "Baixar o arquivo '$1'",
  "FILE_CONTENT_DOWNLOADABLE_WITH_SIZE_ALT": "Baixar o arquivo '$1' de tamanho $2",
  "FILE_CONTENT_WITH_SIZE_ALT": "'$1' de tamanho $2",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_BODY": "Tecla Enter",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_HEADER": "Acessar itens na mensagem",
  "KEYBOARD_HELP_CHAT_HISTORY_HEADER": "Histórico de chat",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_BODY": "Tecla Escape",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_HEADER": "Sair da mensagem",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_BODY": "Tecla Tab\nTecla Shift + tab",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_HEADER": "Mover entre itens em uma mensagem",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_BODY": "Tecla de seta para cima\nTecla de seta para baixo",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_HEADER": "Mover entre mensagens",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_BODY": "Tecla Enter",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_HEADER": "Realizar ação",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_BODY": "Tecla Tab\nTecla Shift + tab",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_HEADER": "Mover entre itens na janela de chat",
  "KEYBOARD_HELP_CHAT_WINDOW_HEADER": "Janela de chat",
  "KEYBOARD_HELP_CLOSE_BUTTON_ALT": "Fechar",
  "KEYBOARD_HELP_HEADER": "Controles do teclado",
  "MARKDOWN_EXTERNAL_LINK_ALT": "Abre em uma nova janela externa.",
  "RECEIPT_CARD_TAX": "Imposto",
  "RECEIPT_CARD_TOTAL": "Total",
  "RECEIPT_CARD_VAT": "IVA",
  "SEND_BOX_IS_EMPTY_TOOLTIP_ALT": "Não é possível enviar uma mensagem vazia.",
  "SPEECH_INPUT_LISTENING": "Escutando…",
  "SPEECH_INPUT_MICROPHONE_BUTTON_CLOSE_ALT": "Microfone desligado",
  "SPEECH_INPUT_MICROPHONE_BUTTON_OPEN_ALT": "Microfone ligado",
  "SPEECH_INPUT_STARTING": "Iniciando…",
  "SUGGESTED_ACTIONS_LABEL_ALT": "Ações sugeridas",
  "TEXT_INPUT_ALT": "Caixa de entrada de mensagens",
  "TEXT_INPUT_PLACEHOLDER": "Digite sua mensagem",
  "TEXT_INPUT_SEND_BUTTON_ALT": "Enviar",
  "TEXT_INPUT_SPEAK_BUTTON_ALT": "Falar",
  "TEXT_INPUT_UPLOAD_BUTTON_ALT": "Carregar arquivo",
  "TOAST_ACCORDION_FEW": "$1 Notificações: clique aqui para ver os detalhes",
  "TOAST_ACCORDION_MANY": "$1 Notificações: clique aqui para ver os detalhes",
  "TOAST_ACCORDION_OTHER": "$1 Notificações: clique aqui para ver os detalhes",
  "TOAST_ACCORDION_TWO": "$1 Notificações: clique aqui para ver os detalhes",
  "TOAST_ALT_ERROR": "Erro",
  "TOAST_ALT_INFO": "Informação",
  "TOAST_ALT_SUCCESS": "Sucesso",
  "TOAST_ALT_WARN": "Aviso",
  "TOAST_DISMISS_BUTTON": "Ignorar",
  "TOAST_TITLE_ALT": "Notificação",
  "TRANSCRIPT_ARIA_LABEL_ALT": "Histórico de chat, use as teclas de seta para navegar.",
  "TRANSCRIPT_ARIA_ROLE_ALT": "histórico de chat",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_LABEL_ALT": "A mensagem é interativa. Pressione a tecla Shift Tab de 2 para 3 vezes para alternar para o histórico do chat. Depois, clique na mensagem para interagir.",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_WITH_LINKS_LABEL_ALT": "Um ou mais links na mensagem. A mensagem é interativa. Pressione a tecla Shift Tab de 2 para 3 vezes para alternar para o histórico do chat. Depois, clique na mensagem para interagir.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_LABEL_ALT": "A mensagem tem ações sugeridas. Pressione shift tab de 2 a 3 vezes para selecioná-las.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_WITH_ACCESS_KEY_LABEL_ALT": "A mensagem tem ações sugeridas. Pressione $1 para selecioná-las.",
  "TRANSCRIPT_LIVE_REGION_SEND_FAILED_ALT": "Falha ao enviar a mensagem.",
  "TRANSCRIPT_MORE_MESSAGES": "Mais mensagens",
  "TRANSCRIPT_NEW_MESSAGES": "Novas mensagens",
  "TRANSCRIPT_TERMINATOR_TEXT": "Término do histórico de chat",
  "TYPING_INDICATOR_ALT": "Mostrando o indicador de digitação",
  "TYPING_INDICATOR_SINGLE_TEXT": "$1 está digitando.",
  "TYPING_INDICATOR_MULTIPLE_TEXT": "$1 e outros estão digitando."
}