import React from 'react'
import type { IconDefaultProps } from '../../../models/types'

const ScrollButtonIcon = ({
  className,
  color
}: IconDefaultProps): JSX.Element => {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 100 100'
      className={className}
    >
      <path
        id='Icon_ionic-ios-arrow-down'
        fill={color}
        className='st0'
        d='M 49.948 29.033 L 13.413 65.414 C 10.792 68.035 6.323 68.188 3.702 65.414 C 3.702 65.414 3.702 65.414 3.702 65.414 C 1.081 62.639 1.081 58.322 3.702 55.702 L 45.015 14.388 C 47.636 11.767 51.798 11.767 54.573 14.233 L 96.195 55.856 C 98.97 58.477 98.97 62.947 96.195 65.568 C 93.574 68.343 89.103 68.343 86.484 65.568 L 49.948 29.033 Z'
      />
    </svg>
  )
}

export default ScrollButtonIcon
