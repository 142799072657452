{
  "_comment": "[CI-LOCKED] Selle faili sisu on lukus. Panused on teretulnud, kuid muudatuste valideerimine võib aega võtta.",
  "ACCESS_KEY_ALT": "Alt Shift $1",
  "ACCESS_KEY_FOR_MAC_ALT": "Control Option $1",
  "ACTIVITY_BOT_SAID_ALT": "Robot $1 ütles:",
  "ACTIVITY_YOU_SAID_ALT": "Te ütlesite:",
  "ACTIVITY_BOT_ATTACHED_ALT": "Manustatud robot:",
  "ACTIVITY_ERROR_BOX_TITLE": "Tõrketeade",
  "ACTIVITY_INTERACTIVE_FOOTNOTE_ALT": "Klõpsake kasutamiseks.",
  "ACTIVITY_INTERACTIVE_REASON_INTERACTIVE_CONTENT_ALT": "Sõnum on interaktiivne.",
  "ACTIVITY_INTERACTIVE_REASON_LINK_ALT": "Sõnumis on vähemalt üks link.",
  "ACTIVITY_INTERACTIVE_REASON_SEND_FAILED_ALT": "Saatmine nurjus.",
  "ACTIVITY_YOU_ATTACHED_ALT": "Manustasite:",
  "ACTIVITY_NUM_ATTACHMENTS_ONE_ALT": "1 manus.",
  "ACTIVITY_NUM_ATTACHMENTS_FEW_ALT": "$1 manust.",
  "ACTIVITY_NUM_ATTACHMENTS_MANY_ALT": "$1 manust.",
  "ACTIVITY_NUM_ATTACHMENTS_OTHER_ALT": "$1 manust.",
  "ACTIVITY_NUM_ATTACHMENTS_TWO_ALT": "$1 manust.",
  "ACTIVITY_STATUS_SEND_FAILED_RETRY": "Saatmine nurjus. [Proovi uuesti][RETRY].",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENDING": "Saatmine",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENT_AT": "Saadetud kell $1",
  "ACTIVITY_STATUS_TIMESTAMP_JUST_NOW": "Just praegu",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_HOUR_AGO": "Tund tagasi",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_MINUTE_AGO": "Minut tagasi",
  "ACTIVITY_STATUS_TIMESTAMP_TODAY": "Täna",
  "ACTIVITY_STATUS_TIMESTAMP_YESTERDAY": "Eile",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_PARSE": "Kohandatava kaardi sõelumise tõrge",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_RENDER": "Kohandatava kaardi renderdamise tõrge",
  "ATTACHMENT_AUDIO": "Heliklipp.",
  "ATTACHMENT_CARD": "Kaart: $1 $2 $3",
  "ATTACHMENT_FILE": "Fail: $1",
  "ATTACHMENT_IMAGE": "Pilt",
  "ATTACHMENT_TEXT": "Tekst: $1",
  "ATTACHMENT_VIDEO": "Videoklipp",
  "CAROUSEL_FLIPPER_LEFT_ALT": "Vasak",
  "CAROUSEL_FLIPPER_RIGHT_ALT": "Parem",
  "CONNECTIVITY_STATUS_ALT_CONNECTED": "Ühendatud",
  "CONNECTIVITY_STATUS_ALT_CONNECTING": "Ühenduse loomine…",
  "CONNECTIVITY_STATUS_ALT_FATAL": "Ühendamine nurjus.",
  "CONNECTIVITY_STATUS_ALT_RECONNECTING": "Ilmnes võrgukatkestus. Uuesti ühendamine…",
  "CONNECTIVITY_STATUS_ALT_RENDER_ERROR": "Renderdamise tõrge. Kontrollige konsooli või pöörduge roboti arendaja poole.",
  "CONNECTIVITY_STATUS_ALT_SLOW_CONNECTION": "Ühenduse loomiseks läheb tavapärasest kauem aega.",
  "CONNECTIVITY_STATUS_ALT": "Ühenduse olek: $1",
  "FILE_CONTENT_ALT": "„$1“",
  "FILE_CONTENT_DOWNLOADABLE_ALT": "Laadi alla fail „$1“",
  "FILE_CONTENT_DOWNLOADABLE_WITH_SIZE_ALT": "Laadi alla fail „$1” mahuga $2",
  "FILE_CONTENT_WITH_SIZE_ALT": "„$1” mahuga $2",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_BODY": "Sisestusklahv",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_HEADER": "Juurdepääs sõnumi üksustele",
  "KEYBOARD_HELP_CHAT_HISTORY_HEADER": "Vestluse ajalugu",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_BODY": "Paoklahv",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_HEADER": "Välju sõnumist",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_BODY": "Tabeldusklahv\nTõstuklahv + tabeldusklahv",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_HEADER": "Sõnumis ühest üksusest teise liikumine",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_BODY": "Ülesnooleklahv\nAllanooleklahv",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_HEADER": "Ühest sõnumist teise liikumine",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_BODY": "Sisestusklahv",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_HEADER": "Tee toiming",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_BODY": "Tabeldusklahv\nTõstuklahv + tabeldusklahv",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_HEADER": "Vestlusaknas ühest üksusest teise liikumine",
  "KEYBOARD_HELP_CHAT_WINDOW_HEADER": "Vestlusaken",
  "KEYBOARD_HELP_CLOSE_BUTTON_ALT": "Sule",
  "KEYBOARD_HELP_HEADER": "Klaviatuuri juhtelemendid",
  "MARKDOWN_EXTERNAL_LINK_ALT": "Avab uues aknas; väline.",
  "RECEIPT_CARD_TAX": "Maks",
  "RECEIPT_CARD_TOTAL": "Kokku",
  "RECEIPT_CARD_VAT": "KM",
  "SEND_BOX_IS_EMPTY_TOOLTIP_ALT": "Tühja sõnumit ei saa saata.",
  "SPEECH_INPUT_LISTENING": "Kuulamine…",
  "SPEECH_INPUT_MICROPHONE_BUTTON_CLOSE_ALT": "Mikrofon väljas",
  "SPEECH_INPUT_MICROPHONE_BUTTON_OPEN_ALT": "Mikrofon sees",
  "SPEECH_INPUT_STARTING": "Algus…",
  "SUGGESTED_ACTIONS_LABEL_ALT": "Soovitatavad tegevused",
  "TEXT_INPUT_ALT": "Sõnumi sisestuse väli",
  "TEXT_INPUT_PLACEHOLDER": "Tippige oma sõnum",
  "TEXT_INPUT_SEND_BUTTON_ALT": "Saada",
  "TEXT_INPUT_SPEAK_BUTTON_ALT": "Räägi",
  "TEXT_INPUT_UPLOAD_BUTTON_ALT": "Laadi fail üles",
  "TOAST_ACCORDION_FEW": "$1 teatist: üksikasjade kuvamiseks klõpsake siin",
  "TOAST_ACCORDION_MANY": "$1 teatist: üksikasjade kuvamiseks klõpsake siin",
  "TOAST_ACCORDION_OTHER": "$1 teatist: üksikasjade kuvamiseks klõpsake siin",
  "TOAST_ACCORDION_TWO": "$1 teatist: üksikasjade kuvamiseks klõpsake siin",
  "TOAST_ALT_ERROR": "Tõrge",
  "TOAST_ALT_INFO": "Teave",
  "TOAST_ALT_SUCCESS": "Tehtud",
  "TOAST_ALT_WARN": "Hoiatus",
  "TOAST_DISMISS_BUTTON": "Sule",
  "TOAST_TITLE_ALT": "Teatis",
  "TRANSCRIPT_ARIA_LABEL_ALT": "Vestluse ajalugu, navigeerimiseks vajutage nooleklahvi.",
  "TRANSCRIPT_ARIA_ROLE_ALT": "vestluse ajalugu",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_LABEL_ALT": "Sõnum on interaktiivne. Vestluse ajaloo avamiseks vajutage 2–3 korda tõstu- ja tabeldusklahvi. Seejärel klõpsake suhtlemiseks sõnumit.",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_WITH_LINKS_LABEL_ALT": "Sõnumis on vähemalt üks link. Vestluse ajaloo avamiseks vajutage 2–3 korda tõstu- ja tabeldusklahvi. Seejärel klõpsake suhtlemiseks sõnumit.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_LABEL_ALT": "Sõnumil on soovitatavad tegevused. Nende valimiseks vajutage 2–3 korda klahvi Shift.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_WITH_ACCESS_KEY_LABEL_ALT": "Sõnumil on soovitatavad tegevused. Nende valimiseks vajutage $1.",
  "TRANSCRIPT_LIVE_REGION_SEND_FAILED_ALT": "Sõnumi saatmine nurjus.",
  "TRANSCRIPT_MORE_MESSAGES": "Rohkem sõnumeid",
  "TRANSCRIPT_NEW_MESSAGES": "Uued sõnumid",
  "TRANSCRIPT_TERMINATOR_TEXT": "Vestluse ajaloo lõpp",
  "TYPING_INDICATOR_ALT": "Tippimise näidiku kuvamine",
  "TYPING_INDICATOR_SINGLE_TEXT": "$1 kirjutab.",
  "TYPING_INDICATOR_MULTIPLE_TEXT": "$1 ja teised kirjutavad."
}