import React, { ComponentType } from 'react'
import { createRoot } from 'react-dom/client'

export default function renderAgent(
  RetorikWidget: ComponentType<any>,
  props: any,
  element: HTMLElement
): void {
  const root = createRoot(element)
  root.render(<RetorikWidget {...props} />)
}
