import React, { useEffect, useState, useRef } from 'react'
import { useRetorik } from '../../Contexts/RetorikContext'
import { MapContainer, TileLayer, Marker, GeoJSON } from 'react-leaflet'
import Routes from './Routes'
import MapUser from './MapUser'
import type { FeatureCollection } from 'geojson'
import type { GeoJSON as GeoJSONType } from 'leaflet'
import L from 'leaflet'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'
import { imageFiles } from '../../../utils/imageFiles'

type MapProps = {
  latitude: number
  longitude: number
  color?: string
  colorWithTransparency?: string
}

const userIcon = L.icon({
  iconUrl: `${imageFiles.mapDeparture}`,
  shadowUrl: iconShadow,
  iconSize: [32, 41],
  iconAnchor: [16, 41]
})

const Map = ({
  latitude,
  longitude,
  color,
  colorWithTransparency
}: MapProps): JSX.Element => {
  const { configuration } = useRetorik()
  const [mapLayerData, setMapLayerData] = useState<
    FeatureCollection | undefined
  >(undefined)
  const geoJsonLayer = useRef<GeoJSONType | null>(null)
  const coordinates =
    configuration.position?.latitude && configuration.position?.longitude
      ? [
          [configuration.position.longitude, configuration.position.latitude],
          [longitude, latitude]
        ]
      : undefined

  useEffect(() => {
    if (geoJsonLayer.current) {
      mapLayerData && geoJsonLayer.current.clearLayers().addData(mapLayerData)
    }
  }, [mapLayerData])

  return (
    <React.Fragment>
      <MapContainer
        center={[latitude, longitude]}
        zoom={12}
        scrollWheelZoom={false}
        style={{ height: '100%', width: '100%', zIndex: 0 }}
      >
        <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
        <Marker position={[latitude, longitude]} />
        {configuration.position?.latitude &&
          configuration.position?.longitude && (
            <Marker
              position={[
                configuration.position.latitude,
                configuration.position.longitude
              ]}
              icon={userIcon}
            />
          )}
        {mapLayerData && <GeoJSON ref={geoJsonLayer} data={mapLayerData} />}
        <MapUser
          configurationPosition={[
            configuration.position?.latitude || latitude,
            configuration.position?.longitude || longitude
          ]}
          coordinates={[latitude, longitude]}
          data={mapLayerData}
        />
      </MapContainer>
      {coordinates && (
        <Routes
          coordinates={coordinates}
          setMapLayerData={setMapLayerData}
          color={color}
          colorWithTransparency={colorWithTransparency}
        />
      )}
    </React.Fragment>
  )
}

export default Map
