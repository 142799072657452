{
  "_comment": "[CI-LOCKED] Fitxategi honen edukia blokeatuta dago. Ekarpenak onartzen dira, baina baliteke aldaketen balidazioa atzeratzea.",
  "ACCESS_KEY_ALT": "Alt + Maius + $1",
  "ACCESS_KEY_FOR_MAC_ALT": "Ktrl + Aukera + $1",
  "ACTIVITY_BOT_SAID_ALT": "$1 bot-ak hau esan du:",
  "ACTIVITY_YOU_SAID_ALT": "Hau esan duzu:",
  "ACTIVITY_BOT_ATTACHED_ALT": "Bot-ak hau erantsi du:",
  "ACTIVITY_ERROR_BOX_TITLE": "Errore-mezua",
  "ACTIVITY_INTERACTIVE_FOOTNOTE_ALT": "Sakatu hau interakzioa hasteko.",
  "ACTIVITY_INTERACTIVE_REASON_INTERACTIVE_CONTENT_ALT": "Mezua interaktiboa da.",
  "ACTIVITY_INTERACTIVE_REASON_LINK_ALT": "Esteka bat edo gehiago dago mezuan.",
  "ACTIVITY_INTERACTIVE_REASON_SEND_FAILED_ALT": "Ezin izan da bidali.",
  "ACTIVITY_YOU_ATTACHED_ALT": "Hau erantsi duzu:",
  "ACTIVITY_NUM_ATTACHMENTS_ONE_ALT": "1 eranskin.",
  "ACTIVITY_NUM_ATTACHMENTS_FEW_ALT": "$1 eranskin.",
  "ACTIVITY_NUM_ATTACHMENTS_MANY_ALT": "$1 eranskin.",
  "ACTIVITY_NUM_ATTACHMENTS_OTHER_ALT": "$1 eranskin.",
  "ACTIVITY_NUM_ATTACHMENTS_TWO_ALT": "$1 eranskin.",
  "ACTIVITY_STATUS_SEND_FAILED_RETRY": "Ezin izan da bidali. [Saiatu berriro][RETRY].",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENDING": "Bidaltzen",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENT_AT": "Bidaltze-ordua: $1",
  "ACTIVITY_STATUS_TIMESTAMP_JUST_NOW": "Oraintxe bertan",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_HOUR_AGO": "Duela ordubete",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_MINUTE_AGO": "Duela minutu bat",
  "ACTIVITY_STATUS_TIMESTAMP_TODAY": "Gaur",
  "ACTIVITY_STATUS_TIMESTAMP_YESTERDAY": "Atzo",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_PARSE": "Txartel moldagarriaren analisi-errorea",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_RENDER": "Txartel moldagarriaren errendatze-errorea",
  "ATTACHMENT_AUDIO": "Audio zati bat.",
  "ATTACHMENT_CARD": "Txartel bat: $1 $2 $3",
  "ATTACHMENT_FILE": "Fitxategi bat: $1",
  "ATTACHMENT_IMAGE": "Irudi bat",
  "ATTACHMENT_TEXT": "Testu bat: $1",
  "ATTACHMENT_VIDEO": "Bideo zati bat",
  "CAROUSEL_FLIPPER_LEFT_ALT": "Ezkerra",
  "CAROUSEL_FLIPPER_RIGHT_ALT": "Eskuina",
  "CONNECTIVITY_STATUS_ALT_CONNECTED": "Konektatu da",
  "CONNECTIVITY_STATUS_ALT_CONNECTING": "Konektatzen…",
  "CONNECTIVITY_STATUS_ALT_FATAL": "Ezin da konektatu.",
  "CONNECTIVITY_STATUS_ALT_RECONNECTING": "Sareko konexioa eten egin da. Berriro konektatzen…",
  "CONNECTIVITY_STATUS_ALT_RENDER_ERROR": "Errendatze-errorea. Egiaztatu kontsola edo jarri harremanetan botaren garatzailearekin.",
  "CONNECTIVITY_STATUS_ALT_SLOW_CONNECTION": "Ohi baino denbora gehiago behar izaten ari gara konektatzeko.",
  "CONNECTIVITY_STATUS_ALT": "Konexioaren egoera: $1",
  "FILE_CONTENT_ALT": "\"$1\"",
  "FILE_CONTENT_DOWNLOADABLE_ALT": "Deskargatu \"$1\" fitxategia",
  "FILE_CONTENT_DOWNLOADABLE_WITH_SIZE_ALT": "Deskargatu \"$1\" fitxategia (tamaina: $2)",
  "FILE_CONTENT_WITH_SIZE_ALT": "\"$1\" (tamaina: $2)",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_BODY": "\"Sartu\" tekla",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_HEADER": "Atzitu mezuko elementuak",
  "KEYBOARD_HELP_CHAT_HISTORY_HEADER": "Berriketa-historia",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_BODY": "Ihes tekla",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_HEADER": "Irten mezutik",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_BODY": "Tabulazio-tekla\nMaius + tabulazio-tekla",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_HEADER": "Mugitu mezu bateko elementuen artean",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_BODY": "Gora gezia\nBehera gezia",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_HEADER": "Mugitu mezuen artean",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_BODY": "\"Sartu\" tekla",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_HEADER": "Egin ekintza",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_BODY": "Tabulazio-tekla\nMaius + tabulazio-tekla",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_HEADER": "Mugitu berriketa-leihoko elementuen artean",
  "KEYBOARD_HELP_CHAT_WINDOW_HEADER": "Berriketaren leihoa",
  "KEYBOARD_HELP_CLOSE_BUTTON_ALT": "Itxi",
  "KEYBOARD_HELP_HEADER": "Teklatuaren kontrolak",
  "MARKDOWN_EXTERNAL_LINK_ALT": "Beste leiho batean irekitzen da; kanpokoa.",
  "RECEIPT_CARD_TAX": "Zerga",
  "RECEIPT_CARD_TOTAL": "Guztira",
  "RECEIPT_CARD_VAT": "BEZ",
  "SEND_BOX_IS_EMPTY_TOOLTIP_ALT": "Ezin da mezu hutsik bidali.",
  "SPEECH_INPUT_LISTENING": "Entzuten…",
  "SPEECH_INPUT_MICROPHONE_BUTTON_CLOSE_ALT": "Mikrofonoa desaktibatuta dago",
  "SPEECH_INPUT_MICROPHONE_BUTTON_OPEN_ALT": "Mikrofonoa aktibatuta dago",
  "SPEECH_INPUT_STARTING": "Hasten…",
  "SUGGESTED_ACTIONS_LABEL_ALT": "Iradokitako ekintzak",
  "TEXT_INPUT_ALT": "Mezua idazteko koadroa",
  "TEXT_INPUT_PLACEHOLDER": "Idatzi mezua",
  "TEXT_INPUT_SEND_BUTTON_ALT": "Bidali",
  "TEXT_INPUT_SPEAK_BUTTON_ALT": "Hitz egin",
  "TEXT_INPUT_UPLOAD_BUTTON_ALT": "Kargatu fitxategia",
  "TOAST_ACCORDION_FEW": "$1 jakinarazpen. Egin klik hemen xehetasunak ikusteko",
  "TOAST_ACCORDION_MANY": "$1 jakinarazpen. Egin klik hemen xehetasunak ikusteko",
  "TOAST_ACCORDION_OTHER": "$1 jakinarazpen. Egin klik hemen xehetasunak ikusteko",
  "TOAST_ACCORDION_TWO": "$1 jakinarazpen. Egin klik hemen xehetasunak ikusteko",
  "TOAST_ALT_ERROR": "Errorea",
  "TOAST_ALT_INFO": "Informazioa",
  "TOAST_ALT_SUCCESS": "Gauzatu da",
  "TOAST_ALT_WARN": "Abisua",
  "TOAST_DISMISS_BUTTON": "Baztertu",
  "TOAST_TITLE_ALT": "Jakinarazpena",
  "TRANSCRIPT_ARIA_LABEL_ALT": "Berriketa-historia. Nabigatzeko, erabili Gora eta Behera geziak.",
  "TRANSCRIPT_ARIA_ROLE_ALT": "berriketa-historia",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_LABEL_ALT": "Mezua interaktiboa da. Sakatu Maius + tabulazio-tekla 2 edo 3 aldiz berriketa-historiara aldatzeko. Ondoren, sakatu mezua harekin elkarrekintzan jarduteko.",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_WITH_LINKS_LABEL_ALT": "Esteka bat edo gehiago daude mezuan. Sakatu Maius + tabulazio-tekla 2 edo 3 aldiz berriketa-historiara aldatzeko. Ondoren, sakatu mezua harekin elkarrekintzan jarduteko.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_LABEL_ALT": "Mezuarekin lotutako ekintza iradokiak daude. Haiek hautatzeko, sakatu Maius fitxa 2 edo 3 aldiz.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_WITH_ACCESS_KEY_LABEL_ALT": "Mezuarekin lotutako ekintza iradokiak daude. Haiek hautatzeko, sakatu $1.",
  "TRANSCRIPT_LIVE_REGION_SEND_FAILED_ALT": "Ezin izan da bidali mezua.",
  "TRANSCRIPT_MORE_MESSAGES": "Mezu gehiago",
  "TRANSCRIPT_NEW_MESSAGES": "Mezu berriak",
  "TRANSCRIPT_TERMINATOR_TEXT": "Berriketa-historiaren amaiera",
  "TYPING_INDICATOR_ALT": "Idazketa-adierazlea ikusgai dago",
  "TYPING_INDICATOR_SINGLE_TEXT": "$1 idazten ari da.",
  "TYPING_INDICATOR_MULTIPLE_TEXT": "$1 eta beste batzuk idazten ari dira."
}