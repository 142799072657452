import React from 'react'
import type { IconDefaultProps } from '../../../models/types'

const CommentsPennantIcon = ({
  className,
  color
}: IconDefaultProps): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 72.258 45'
      className={className || 'w-6'}
    >
      <g transform='translate(-2203.071 -428.34)'>
        <path
          d='M11.129,3l2.512,5.089,5.617.821-4.064,3.959.959,5.593-5.024-2.642L6.105,18.461l.959-5.593L3,8.91l5.617-.821Z'
          transform='translate(2201.071 426.34)'
          fill='none'
          stroke={color || '#ffffff'}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        />
        <path
          d='M11.129,3l2.512,5.089,5.617.821-4.064,3.959.959,5.593-5.024-2.642L6.105,18.461l.959-5.593L3,8.91l5.617-.821Z'
          transform='translate(2255.071 426.34)'
          fill='none'
          stroke={color || '#ffffff'}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        />
        <path
          d='M28,11.225A5.775,5.775,0,1,1,22.225,17,5.774,5.774,0,0,1,28,11.225m0,24.75c8.167,0,16.775,4.015,16.775,5.775v3.025H11.225V41.75c0-1.76,8.608-5.775,16.775-5.775M28,6A11,11,0,1,0,39,17,11,11,0,0,0,28,6Zm0,24.75c-7.342,0-22,3.685-22,11V50H50V41.75C50,34.435,35.343,30.75,28,30.75Z'
          transform='translate(2211 423.34)'
          fill={color || '#ffffff'}
        />
      </g>
    </svg>
  )
}

export default CommentsPennantIcon
