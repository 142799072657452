import React, { useEffect, useState } from 'react'
import { useUtilsStore } from '../../Contexts/utilsStore'
import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter'
import { checkForData } from '../../../utils/localStorageInputsUtils'
import { CurrentSubView } from '../../../models/enums'

interface RadioProps {
  id: string
  choices: any
  split: boolean
  handleSelect: (value: string) => void
}

const Radio = ({
  id,
  choices,
  split,
  handleSelect
}: RadioProps): JSX.Element => {
  const { currentSubView } = useUtilsStore()
  const [display, setDisplay] = useState<Array<any>>([])
  const [chosen, setChosen] = useState<string>(checkForData(id, ''))

  /**
   * On choices prop change :
   *  - if choices is defined, fill display state with choices value
   */
  useEffect(() => {
    if (choices) {
      if (split) {
        const nbRows = Math.ceil(choices.length / 2)
        setDisplay([choices.slice(0, nbRows), choices.slice(nbRows)])
      } else {
        setDisplay(choices)
      }
    }
  }, [choices])

  /**
   * On call:
   *  - check if the component will be rendered in history, if so bypass every interaction
   *  - fill chosen state with value
   *  - call parent's handleSelect method
   * @param value : string
   */
  const handleChange = (value: string): void => {
    if (currentSubView !== CurrentSubView.history) {
      setChosen(value)
      handleSelect(value)
    }
  }

  return display && display.length > 0 ? (
    split ? (
      <div className='rf-w-full rf-mt-4 rf-flex rf-flex-row rf-text-size-auto rf-text-white'>
        {/* First column */}
        <div className='rf-w-1/2 rf-flex rf-flex-col rf-items-start'>
          {display[0].map((choice, key) => {
            return (
              <div
                key={key}
                className='rf-w-full rf-flex rf-flex-row rf-items-start rf-mb-3'
              >
                <input
                  type='radio'
                  value={choice.value}
                  checked={choice.value === chosen}
                  className='rf-radio-custom'
                  onChange={(): void => handleChange(choice.value)}
                />
                <label
                  htmlFor={`radio-${key}`}
                  className='rf-input-label rf-mr-2 rf-max-w-[calc(100%-2rem)]'
                >
                  {capitalizeFirstLetter(choice.title)}
                </label>
              </div>
            )
          })}
        </div>

        {/* Second column */}
        <div className='rf-w-1/2 rf-flex rf-flex-col rf-items-start'>
          {display[1].map((choice, key) => {
            return (
              <div
                key={key}
                className='rf-w-full rf-flex rf-flex-row rf-items-start rf-mb-3'
              >
                <input
                  type='radio'
                  value={choice.value}
                  checked={choice.value === chosen}
                  className='rf-radio-custom'
                  onChange={(): void => handleChange(choice.value)}
                />
                <label
                  htmlFor={`radio-${key}`}
                  className='rf-input-label rf-mr-2 rf-max-w-[calc(100%-2rem)]'
                >
                  {capitalizeFirstLetter(choice.title)}
                </label>
              </div>
            )
          })}
        </div>
      </div>
    ) : (
      <div className='rf-w-full rf-mt-4 rf-text-size-auto rf-text-white'>
        {display.map((choice, key) => {
          return (
            <div
              key={key}
              className='rf-w-full rf-flex rf-flex-row rf-items-start rf-mb-3'
            >
              <input
                type='radio'
                value={choice.value}
                checked={choice.value === chosen}
                className='rf-radio-custom'
                onChange={(): void => handleChange(choice.value)}
              />
              <label
                htmlFor={`radio-${key}`}
                className='rf-input-label rf-mr-2 rf-max-w-[calc(100%-2rem)]'
              >
                {capitalizeFirstLetter(choice.title)}
              </label>
            </div>
          )
        })}
      </div>
    )
  ) : (
    <React.Fragment />
  )
}

export default Radio
