import DashboardIcon from './DashboardIcon'
import KeyboardIcon from './KeyboardIcon'
import MicrophoneIcon from './MicrophoneIcon'
import MicrophoneIconOn from './MicrophoneIconOn'
import MicrophoneIconOff from './MicrophoneIconOff'

export {
  DashboardIcon,
  KeyboardIcon,
  MicrophoneIcon,
  MicrophoneIconOn,
  MicrophoneIconOff
}
