/* eslint-disable react/self-closing-comp */
import React from 'react'
import type { IconDefaultProps } from '../../../models/types'

const EmergencyIcon = ({ className, color }: IconDefaultProps): JSX.Element => {
  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      className={className || 'rf-h-6 rf-w-6'}
      viewBox='0 0 512 512'
      xmlSpace='preserve'
    >
      <g fill={color || '#1999B1'}>
        <path
          d='M294.4,182.4h34.1v34.1c0,3.9,3.1,7,7,7h47c3.9,0,7-3.1,7-7v-34.1h34.1c3.9,0,7-3.1,7-7v-47c0-3.9-3.1-7-7-7h-34.1V87.3
		c0-3.9-3.1-7-7-7h-47c-3.9,0-7,3.1-7,7v34.1h-34.1c-3.9,0-7,3.1-7,7v47C287.4,179.3,290.6,182.4,294.4,182.4z M301.4,135.4h34.1
		c3.9,0,7-3.1,7-7V94.3h33v34.1c0,3.9,3.1,7,7,7h34.1v33h-34.1c-3.9,0-7,3.1-7,7v34.1h-33v-34.1c0-3.9-3.1-7-7-7h-34.1V135.4z'
        />
        <path
          d='M218.1,285.1c0.1,3.8,3.2,6.8,6.9,6.8c0.9,0,95.2,1.1,133.1,1.1c78.5,0,142.5-63.9,142.5-142.5S436.7,8.2,358.1,8.2
		c-78.5,0-142.5,63.9-142.5,142.5C215.7,186.8,218.1,284.2,218.1,285.1z M358.1,22.2c70.8,0,128.5,57.6,128.5,128.5
		S429,279.1,358.1,279.1c-31.9,0-103.8-0.8-126.2-1c-0.5-22.8-2.3-97-2.3-127.4C229.7,79.8,287.3,22.2,358.1,22.2z'
        />
        <path
          d='M191.5,94c1,0.5,2,0.7,3,0.7c2.6,0,5.1-1.5,6.3-4c0.2-0.4,21.5-45.1,51-70.9c2.9-2.5,3.2-7,0.7-9.9c-2.5-2.9-7-3.2-9.9-0.7
		c-31.8,27.7-53.6,73.5-54.5,75.5C186.5,88.2,188,92.3,191.5,94z'
        />
        <path
          d='M176.6,60.1c1.1,0.6,2.2,0.8,3.3,0.8c2.5,0,4.9-1.3,6.2-3.7c0.1-0.2,13.2-24.6,27.9-36.4c3-2.4,3.5-6.8,1.1-9.8
		c-2.4-3-6.8-3.5-9.8-1.1c-16.9,13.6-30.9,39.6-31.5,40.7C171.9,54.1,173.2,58.3,176.6,60.1z'
        />
        <path
          d='M490.2,255.8c-0.3,0.4-34.6,36.1-70.7,51.3c-3.6,1.5-5.2,5.6-3.7,9.2c1.1,2.7,3.7,4.3,6.5,4.3c0.9,0,1.8-0.2,2.7-0.5
		c38.9-16.3,73.9-53,75.4-54.6c2.7-2.8,2.5-7.2-0.3-9.9S492.8,253,490.2,255.8z'
        />
        <path
          d='M503.6,292.4c-2.7-2.8-7.1-2.9-9.9-0.2c-0.2,0.2-20.3,19.2-37.9,25.8c-3.6,1.4-5.5,5.4-4.1,9c1.1,2.8,3.7,4.5,6.6,4.5
		c0.8,0,1.6-0.1,2.5-0.4c20.3-7.6,41.8-27.9,42.7-28.8C506.2,299.6,506.3,295.2,503.6,292.4z'
        />
        <path
          d='M490.2,372.5c-1.6-0.5-40.7-13.2-71.4,4c-16,9-26.7,24.2-31.6,45.4c-12.9,55-48.2,58-85.6,52l17.2-10
		c18.4-10.7,24.7-34.4,14-52.8L302.5,359c-10.7-18.4-34.4-24.7-52.8-14l-36.7,21.3c-0.2,0.1-0.3,0.2-0.5,0.3c-0.1,0-6.4,4-17.1,1.6
		c-12.6-2.7-32.6-14.3-56.7-53.7c-22-39.9-22.4-62.9-18.8-75.2c3.1-10.5,9.4-14.3,10.1-14.7c0.2-0.1,0.2-0.1,0.4-0.2l36.7-21.3
		c18.4-10.7,24.7-34.4,14-52.8L151,98.2c-10.7-18.4-34.4-24.7-52.8-14L60.8,106c-0.1,0-0.2,0.1-0.2,0.1c-1.5,1-38.1,24.6-48.6,67
		C-4,237.2,63,350,65.9,354.7c0.3,0.6,0.8,1.1,1.2,1.5c0.1,0.6,0.4,1.2,0.7,1.8c2.7,4.8,67.5,118.9,131,136.8
		c42.1,11.8,80.7-8.2,82.3-9c0.1,0,0.2-0.1,0.2-0.1l2.4-1.4c0.7,0.5,1.6,0.9,2.5,1c12.3,2.5,25.9,4.8,39.4,4.8
		c8.5,0,17-0.9,25.2-3.4c25.4-7.6,42.2-28.3,50-61.7c4-17.1,12.3-29.3,24.8-36.3c25.3-14.2,59.9-3.1,60.2-3c3.7,1.2,7.6-0.8,8.8-4.4
		C495.9,377.7,493.9,373.8,490.2,372.5z M256.8,357.2c11.7-6.8,26.8-2.8,33.7,8.9l30.3,52.1c6.8,11.7,2.8,26.8-8.9,33.7l-9.5,5.5
		c-39.1-34.5-52.5-80.7-55.8-94.2L256.8,357.2z M202.6,481.4C144.7,465.1,80.7,352.3,80,351.2c-0.4-0.6-0.8-1.2-1.3-1.7
		c-0.2-0.7-0.4-1.4-0.8-2c-0.7-1.1-66.9-112.5-52.4-171c8.9-35.9,40.5-57.3,42.4-58.5l14.6-8.5c1.8,7,5.2,18,10.9,30.9
		c1.6,3.5,5.7,5.1,9.2,3.6c0.2-0.1,0.5-0.2,0.7-0.4c3-1.8,4.3-5.6,2.9-8.9c-6.6-14.8-9.8-27-11.1-32.6l10.1-5.9
		c11.7-6.8,26.8-2.8,33.7,8.9l30.3,52.1c6.8,11.7,2.8,26.8-8.9,33.7l-9.6,5.6c-11.1-9.9-21-21.4-29.5-34.5c-2.1-3.2-6.4-4.1-9.7-2
		c-3.2,2.1-4.1,6.5-2,9.7c8.3,12.7,17.9,24.2,28.6,34.2l-14.2,8.2c-2.2,1.1-12.1,7-16.9,22.3c-6.6,21-0.5,49.1,18,83.7
		c0.2,0.5,0.4,1,0.6,1.4c0.4,0.7,0.8,1.3,1.2,2c0.4,0.7,0.7,1.3,1.1,2c0.3,0.5,0.6,0.9,0.9,1.3c20.9,33.2,42.3,52.4,63.8,57.1
		c15.7,3.4,25.7-2.3,27.7-3.6l13.7-7.9c4.7,18.1,19,60.2,55.7,94.3l-15.1,8.8C272.4,474.6,238.4,491.5,202.6,481.4z'
        />
      </g>
    </svg>
  )
}

export default EmergencyIcon
