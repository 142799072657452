import React, { useState } from 'react'
import { useView } from '../../Contexts/ViewContext'
import { ArrowRightIcon } from '../../Icons/ArrowIcons'
import { DownloadIcon } from '../../Icons/Miscellaneous'

interface BorderlessButtonProps {
  url: string
  text?: string
  download?: boolean
}

const BorderlessButton = ({
  url,
  text,
  download
}: BorderlessButtonProps): JSX.Element => {
  const { themeColors } = useView()
  const [hover, setHover] = useState<boolean>(false)

  return (
    <div className='rf-w-full rf-flex rf-justify-start'>
      <a
        href={url}
        target='_blank'
        rel='noopener noreferrer'
        className='rf-w-full rf-flex rf-flex-row rf-items-center rf-justify-start rf-text-cardButtonTextDefault'
        onPointerEnter={(): void => setHover(true)}
        onPointerLeave={(): void => setHover(false)}
        onClick={(): void => setHover(true)}
      >
        <div
          className={`rf-h-6 rf-w-6 rf-rounded ${
            hover
              ? 'rf-bg-cardButtonBackgroundHover'
              : 'rf-bg-cardButtonBackgroundDefault'
          }`}
        >
          {download ? (
            <DownloadIcon
              className='rf-h-6 rf-p-1 rf-border rf-rounded rf-border-cardButtonBorderDefault'
              color={
                hover
                  ? themeColors.card.button.text.hover
                  : themeColors.card.button.text.default
              }
            />
          ) : (
            <ArrowRightIcon
              className='rf-h-6 rf-p-1 rf-border rf-rounded rf-border-cardButtonBorderDefault'
              color={
                hover
                  ? themeColors.card.button.text.hover
                  : themeColors.card.button.text.default
              }
            />
          )}
        </div>
        <div className='rf-ml-4'>{text || ''}</div>
      </a>
    </div>
  )
}

export default BorderlessButton
