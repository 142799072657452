import React from 'react'
import { useLocaleStore } from '../Contexts/localeStore'
import { hooks } from 'botframework-webchat-component'
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter'
import type { Action } from '../../models/suggestedActionAttachment'

const { useSendMessage } = hooks

interface SuggestedActionAttachmentProps {
  title: string
  actions: Array<Action>
  history: boolean
  height?: number
}

const SuggestedActionAttachment = ({
  title,
  actions,
  history,
  height
}: SuggestedActionAttachmentProps): JSX.Element => {
  const { locale } = useLocaleStore()
  const sendMessage = useSendMessage()

  /**
   * On call :
   *  - use botframework's useSendMessage hook to send a message containing the value
   * @param value : string
   */
  const handleClick = (value): void => {
    sendMessage(value)
  }

  return (
    <div
      className='rf-w-full rf-p-4 rf-flex rf-flex-col rf-items-center rf-justify-center rf-rounded rf-bg-cardFrameBackground'
      style={{
        height: height
      }}
    >
      {/* Title */}
      <div className='rf-w-full rf-pb-4 rf-text-center rf-text-cardFrameText rf-subtitle-size-auto'>
        {capitalizeFirstLetter(title, locale)}
      </div>
      <div className='rf-w-full rf-pt-4 rf-flex rf-flex-row rf-justify-center rf-items-center rf-gap-4 rf-flex-wrap'>
        {actions.map((action, key) => {
          return (
            <button
              key={key}
              className='rf-card-button'
              onClick={
                history
                  ? (): void => {}
                  : (): void => handleClick(action.action)
              }
            >
              {action.title || '?'}
            </button>
          )
        })}
      </div>
    </div>
  )
}

export default SuggestedActionAttachment
