import React from 'react'
import { Mode } from '../../../models/enums'
import {
  DashboardIcon,
  KeyboardIcon,
  MicrophoneIcon
} from '../../Icons/MenuIcons'
import { useView } from '../../Contexts/ViewContext'

interface ModeButtonProps {
  label: string
  selected: boolean
  onClick: (mode: number) => void
}

const ModeButton = ({
  label,
  selected,
  onClick
}: ModeButtonProps): JSX.Element => {
  const { themeColors } = useView()

  return (
    <button
      className='rf-grid rf-w-full rf-h-12 rf-pb-4 rf-justify-center rf-items-end'
      onClick={(): void => onClick(Mode[label])}
      aria-label={label}
    >
      {label === 'vocal' ? (
        <MicrophoneIcon className='rf-h-5' color='#A4A7AF' />
      ) : label === 'text' ? (
        <KeyboardIcon
          className='rf-h-4'
          color={selected ? themeColors.secondary : '#A4A7AF'}
        />
      ) : (
        <DashboardIcon
          className='rf-h-[0.875rem]'
          color={selected ? themeColors.secondary : '#A4A7AF'}
        />
      )}
    </button>
  )
}

export default ModeButton
