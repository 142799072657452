import React from 'react'
import type { IconDefaultProps } from '../../../models/types'

const FavoriteIcon = ({ className }: IconDefaultProps): JSX.Element => {
  return (
    <div className={className || ''}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 72 72'
        className='rf-h-full'
      >
        <path
          d='M413.444,281.927a24.4,24.4,0,1,0,24.4,24.4A24.4,24.4,0,0,0,413.444,281.927Zm12.233,30.989a51.118,51.118,0,0,1-9.936,9.6l-2.3,1.654-2.3-1.654a51.118,51.118,0,0,1-9.936-9.6,17.7,17.7,0,0,1-3.462-9.826,10.357,10.357,0,0,1,10.227-10.321h.095a10.267,10.267,0,0,1,5.373,1.514,10.271,10.271,0,0,1,5.373-1.514h.095a10.357,10.357,0,0,1,10.226,10.321A17.7,17.7,0,0,1,425.677,312.916Z'
          transform='translate(-380.234 -271.942)'
          fill='#0c89a2'
        />
        <path
          d='M428.741,308.235h-.057a6.436,6.436,0,0,0-5.373,2.941,6.435,6.435,0,0,0-5.373-2.941h-.057a6.394,6.394,0,0,0-6.335,6.391,13.766,13.766,0,0,0,2.7,7.505,47.374,47.374,0,0,0,9.06,8.727,47.369,47.369,0,0,0,9.061-8.727,13.765,13.765,0,0,0,2.7-7.505A6.394,6.394,0,0,0,428.741,308.235Z'
          transform='translate(-390.101 -283.478)'
          fill='#cb0038'
        />
        <g>
          <path
            d='M379.123,306.092l.984-.568a3.667,3.667,0,1,1-4.851-1.228l.554,1a2.435,2.435,0,0,0-1.228,1.529,2.533,2.533,0,0,0,4.873,1.339A2.519,2.519,0,0,0,379.123,306.092Z'
            transform='translate(-373.354 -281.75)'
            fill='#161615'
          />
          <path
            d='M383.877,292.134a3.579,3.579,0,1,1-4.955,1.283A3.524,3.524,0,0,1,383.877,292.134Zm-.614,1.07a2.35,2.35,0,1,0,1.007,3.281A2.36,2.36,0,0,0,383.263,293.2Z'
            transform='translate(-375.577 -276.186)'
            fill='#161615'
          />
          <path
            d='M388.494,279.91l.851-.864,3.212,3.162a2.729,2.729,0,0,1-3.83,3.889l-3.211-3.163.85-.864,3.1,3.056a1.525,1.525,0,1,0,2.127-2.161Z'
            transform='translate(-378.687 -270.679)'
            fill='#161615'
          />
          <path
            d='M396.125,274.77l2.02-1.306a2.265,2.265,0,1,1,2.457,3.8l-1,.647,1.382,2.138-1.018.658Zm4.181-.008a1.092,1.092,0,0,0-1.664-.248l-.874.564,1.206,1.867.874-.565A1.092,1.092,0,0,0,400.306,274.762Z'
            transform='translate(-383.338 -268.046)'
            fill='#161615'
          />
          <path
            d='M418.982,268.841a3.387,3.387,0,0,1-2.494,4.305l-2.211.575-1.782-6.847,2.211-.576A3.387,3.387,0,0,1,418.982,268.841Zm-1.194.311a2.247,2.247,0,0,0-2.772-1.743l-1.056.275,1.2,4.617,1.057-.275A2.254,2.254,0,0,0,417.788,269.151Z'
            transform='translate(-390.516 -265.032)'
            fill='#161615'
          />
          <path
            d='M427.818,267.132l2.828-.109.044,1.151-2.827.11.073,1.9,3.132-.122.044,1.151-4.343.17-.275-7.07,4.282-.167.045,1.151-3.07.12Z'
            transform='translate(-396.655 -264.145)'
            fill='#161615'
          />
          <path
            d='M446.9,272.151l.56.988a3.666,3.666,0,1,1,1.268-4.839l-1.006.544a2.431,2.431,0,0,0-1.518-1.239,2.532,2.532,0,0,0-1.38,4.86A2.519,2.519,0,0,0,446.9,272.151Z'
            transform='translate(-403.416 -265.121)'
            fill='#161615'
          />
          <path
            d='M460.445,277.009a3.579,3.579,0,1,1-1.242-4.964A3.523,3.523,0,0,1,460.445,277.009Zm-1.064-.622a2.35,2.35,0,1,0-3.289.98A2.361,2.361,0,0,0,459.381,276.386Z'
            transform='translate(-408.594 -267.384)'
            fill='#161615'
          />
          <path
            d='M467.155,281.565l2.048,1.953-.8.834L466.36,282.4l-1.311,1.375,2.268,2.162-.8.834-3.145-3,4.882-5.12,3.1,2.957-.8.834-2.224-2.12Z'
            transform='translate(-412.827 -270.506)'
            fill='#161615'
          />
          <path
            d='M478.916,290.648l.678,1.006-3.737,2.52a2.729,2.729,0,0,1-3.052-4.525l3.738-2.521.678,1.006-3.612,2.435a1.525,1.525,0,1,0,1.7,2.514Z'
            transform='translate(-416.422 -274.223)'
            fill='#161615'
          />
          <path
            d='M482.854,299.49l.848,2.165a2.168,2.168,0,0,1-1.2,2.987,2.045,2.045,0,0,1-2.552-.694l-1.915,2.649-.516-1.317,1.873-2.535-.2-.518-2.485.973-.442-1.129Zm-.8,4c.649-.254.8-.835.518-1.56l-.351-.894-1.957.766.35.894C480.9,303.425,481.406,303.748,482.055,303.494Z'
            transform='translate(-418.479 -279.643)'
            fill='#161615'
          />
        </g>
        <g transform='translate(4.89 51.981)'>
          <path
            d='M384.888,365.352l-.9-1.038.58-.5a2.025,2.025,0,0,1-1.846-.74,3.084,3.084,0,0,1,4.657-4.019,2.09,2.09,0,0,1,.5,1.753l3.1-2.68.969,1.122Zm1.911-3.608a1.623,1.623,0,0,0-.294-1.589,1.695,1.695,0,0,0-2.562,2.211,1.621,1.621,0,0,0,1.529.524Z'
            transform='translate(-382.062 -357.324)'
            fill='#161615'
          />
          <path
            d='M397.773,372.316c-.066.09-.2.269-.337.442l-3.535-2.577a1.672,1.672,0,0,0,.629,1.927,2.359,2.359,0,0,0,1.648.467l-.057,1.335a3.75,3.75,0,0,1-2.392-.7,3.078,3.078,0,0,1-.733-4.342,2.949,2.949,0,1,1,4.777,3.452Zm-.893-1.293a1.481,1.481,0,0,0-2.346-1.71Z'
            transform='translate(-386.596 -361.465)'
            fill='#161615'
          />
          <path
            d='M412.418,369.575l1.391.51-3.215,8.768-1.391-.511Z'
            transform='translate(-393.963 -362.355)'
            fill='#161615'
          />
          <path
            d='M418.264,372.236l1.265.337-.784,2.936-1.265-.337Z'
            transform='translate(-397.592 -363.522)'
            fill='#161615'
          />
          <path
            d='M426.423,379.222c-.013.111-.039.331-.076.551l-4.344-.5a1.671,1.671,0,0,0,1.5,1.366,2.353,2.353,0,0,0,1.662-.407l.61,1.188a3.749,3.749,0,0,1-2.427.568,3.077,3.077,0,0,1-2.779-3.414,2.948,2.948,0,1,1,5.857.646Zm-1.414-.684a1.481,1.481,0,0,0-2.884-.33Zm.961-5.025-2.384,1.816-.842-1,2.343-1.895Z'
            transform='translate(-398.936 -363.608)'
            fill='#161615'
          />
          <path
            d='M438.911,378.258l.963,8.642-1.473.164-.382-3.425a2.092,2.092,0,0,1-1.561.933,3.084,3.084,0,0,1-.682-6.114,2.123,2.123,0,0,1,1.857.713l-.085-.761Zm-1.247,2.2a1.62,1.62,0,0,0-1.477-.655,1.694,1.694,0,0,0,.375,3.363,1.623,1.623,0,0,0,1.3-.965Z'
            transform='translate(-404.51 -366.163)'
            fill='#161615'
          />
          <path
            d='M447.021,379.887l-1.251-3.581,1.4-.489,1.117,3.2a1.053,1.053,0,0,0,1.5.783,1.442,1.442,0,0,0,.86-1.06l-1.288-3.686,1.4-.489,1.933,5.529-1.307.457-.281-.805a2.111,2.111,0,0,1-4.086.145Z'
            transform='translate(-409.996 -364.542)'
            fill='#161615'
          />
          <path
            d='M455.552,367.9a.976.976,0,1,1-.385,1.325A.97.97,0,0,1,455.552,367.9Zm.73,2.869,1.3-.714,2.819,5.132-1.3.714Z'
            transform='translate(-414.063 -361.567)'
            fill='#161615'
          />
          <path
            d='M463.272,367.506l.478.6a2.125,2.125,0,0,1,.809-1.818,3.086,3.086,0,0,1,3.842,4.807,2.089,2.089,0,0,1-1.764.445l2.152,2.694-1.159.926-5.43-6.8Zm2.462,2.9a1.625,1.625,0,0,0,1.6-.234,1.7,1.7,0,0,0-2.114-2.645,1.623,1.623,0,0,0-.582,1.509Z'
            transform='translate(-417.201 -360.651)'
            fill='#161615'
          />
          <path
            d='M478.073,357.422c.086.069.261.207.427.355l-2.719,3.423a1.67,1.67,0,0,0,1.95-.549,2.354,2.354,0,0,0,.535-1.626l1.332.111a3.751,3.751,0,0,1-.8,2.36,3.077,3.077,0,0,1-4.367.551,2.947,2.947,0,1,1,3.646-4.626Zm-1.327.837a1.481,1.481,0,0,0-1.806,2.273Z'
            transform='translate(-422.028 -356.717)'
            fill='#161615'
          />
        </g>
      </svg>
    </div>
  )
}

export default FavoriteIcon
