/* eslint-disable react/self-closing-comp */
import React from 'react'
import type { IconDefaultProps } from '../../../models/types'

const HomeIcon = ({ className, color }: IconDefaultProps): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className || 'rf-h-6 rf-w-6'}
      viewBox='0 0 25 25'
    >
      <path
        d='M39.908,31.05l-4.784-4.784V21.032a.312.312,0,0,0-.312-.312H31.887a.312.312,0,0,0-.312.312v1.685L27.72,18.861a.312.312,0,0,0-.441,0L15.091,31.05a.312.312,0,1,0,.441.441L17.925,29.1V42.483a.312.312,0,0,0,.312.312h6.825a.312.312,0,0,0,.312-.312V35.97h4.252v6.514a.312.312,0,0,0,.312.312h6.825a.312.312,0,0,0,.312-.312V29.1l2.393,2.393a.312.312,0,1,0,.441-.441ZM32.2,21.344h2.3v4.3l-2.3-2.3Zm4.252,20.827h-6.2V35.658a.312.312,0,0,0-.312-.312H25.062a.312.312,0,0,0-.312.312v6.514h-6.2v-13.7L27.5,19.523l4.167,4.167h0l2.925,2.925h0l1.859,1.859Z'
        transform='translate(-15 -18.77)'
        fill={color || '#1999b1'}
      />
    </svg>
  )
}

export default HomeIcon
