import React, { useEffect, useState } from 'react'
import { useRetorik } from '../Contexts/RetorikContext'
import { fetchEmergencyData } from '../../utils/fetchEmergencyData'
import { EmergencyTypes } from '../../models/emergencyTypes'
import * as Icons from '../Icons/ViewIcons'
import translation from '../../translations/views.json'
import emergencyTranslation from '../../translations/emergency.json'
import { AvailableViews } from '../../models/enums'
import Animation2 from '../Loader/Animation2'
import { useView } from '../Contexts/ViewContext'
import { useLocaleStore } from '../Contexts/localeStore'

const EmergencyContent = (): JSX.Element => {
  const locale = useLocaleStore((state) => state.locale)
  const { addressData } = useRetorik()
  const { isMobile } = useView()
  const [municipalPoliceNumber, setMunicipalPoliceNumber] = useState<string>('')
  const [localHospitalName, setLocalHospitalName] = useState<string>('')
  const [localHospitalNumber, setLocalHospitalNumber] = useState<string>('')
  const [poisonControlNumber, setPoisonControlNumber] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(true)
  const subView = AvailableViews.emergency

  useEffect(() => {
    const getData = async (): Promise<void> => {
      const data: EmergencyTypes = await fetchEmergencyData(addressData)
      if (data) {
        setMunicipalPoliceNumber(data.municipalPoliceNumber)
        setLocalHospitalName(data.localHospitalName)
        setLocalHospitalNumber(data.localHospitalNumber)
        setPoisonControlNumber(data.poisonControlNumber)
      }
      setLoading(false)
    }
    getData()
  }, [addressData])

  return (
    <>
      <div
        className={`rf-flex rf-flex-col rf-items-center ${
          isMobile
            ? 'rf-justify-center rf-gap-2'
            : 'rf-justify-end rf-flex-[2_1_0%] rf-gap-9'
        }`}
      >
        <Icons.EmergencyIcon className='rf-h-9 rf-w-9 large:rf-h-16 large:rf-w-16' />
        <h3 className='rf-text-verylarge-size-auto rf-font-bold rf-capitalize-first rf-text-center'>
          {translation[locale]?.[subView] || translation['fr-FR'][subView]}
        </h3>
      </div>
      <div
        className='rf-w-full rf-flex rf-flex-col rf-items-center rf-flex-[4_1_0%] rf-justify-evenly'
        style={{
          background: isMobile
            ? 'transparent'
            : 'transparent linear-gradient(291deg, #F2F3F6 0%, #E5E6EC 100%) 0% 0% no-repeat padding-box',
          boxShadow: isMobile
            ? ''
            : '#ffff -3px -3px 16px, rgb(0 0 0 / 25%) 0px 2px 10px 0px'
        }}
      >
        {loading ? (
          <>
            <div className='rf-w-6 rf-h-6 rf-mb-16'>
              <Animation2
                color='gray'
                loaderColor='#1999b1'
                backgroundColor='transparent'
              />
            </div>
          </>
        ) : (
          <>
            <div
              className={`rf-w-full rf-grid ${
                isMobile
                  ? 'rf-grid-cols-2 rf-gap-8 rf-mt-10 rf-mb-8'
                  : 'rf-grid-cols-4 rf-divide-x'
              } `}
              style={{ padding: isMobile ? '' : '0 10%' }}
            >
              <div className='rf-flex rf-flex-col rf-items-center rf-gap-2'>
                <p className='rf-text-xl rf-font-bold rf-uppercase'>
                  {emergencyTranslation[locale]?.samu ||
                    emergencyTranslation['fr-FR'].samu}
                </p>
                <p className='rf-text-5xl large:rf-text-6xl rf-font-bold'>15</p>
              </div>
              <div
                className={`rf-flex rf-flex-col rf-items-center ${
                  isMobile ? '' : 'rf-border-l rf-border-[#C7C6C6]'
                } rf-gap-2`}
              >
                <p className='rf-text-xl rf-font-bold rf-uppercase'>
                  {emergencyTranslation[locale]?.police ||
                    emergencyTranslation['fr-FR'].police}
                </p>
                <p className='rf-text-5xl large:rf-text-6xl rf-font-bold'>17</p>
              </div>
              <div
                className={`rf-flex rf-flex-col rf-items-center ${
                  isMobile ? '' : 'rf-border-l rf-border-[#C7C6C6]'
                } rf-gap-2`}
              >
                <p className='rf-text-xl rf-font-bold rf-uppercase'>
                  {emergencyTranslation[locale]?.firefighter ||
                    emergencyTranslation['fr-FR'].firefighter}
                </p>
                <p className='rf-text-5xl large:rf-text-6xl rf-font-bold'>18</p>
              </div>
              <div
                className={`rf-flex rf-flex-col rf-items-center ${
                  isMobile ? '' : 'rf-border-l rf-border-[#C7C6C6]'
                } rf-gap-2`}
              >
                <p className='rf-text-xl rf-font-bold rf-uppercase'>
                  {emergencyTranslation[locale]?.general ||
                    emergencyTranslation['fr-FR'].general}
                </p>
                <p className='rf-text-5xl large:rf-text-6xl rf-font-bold'>
                  112
                </p>
              </div>
            </div>
            <div
              className={`rf-flex rf-items-start ${
                isMobile ? 'rf-flex-col' : 'rf-gap-12'
              } `}
            >
              <div>
                {municipalPoliceNumber && municipalPoliceNumber.length > 0 && (
                  <p className='rf-text-xl rf-py-2'>
                    Police municipale :{' '}
                    <span className='rf-font-bold'>
                      {municipalPoliceNumber}
                    </span>
                  </p>
                )}
                <p className='rf-text-xl rf-py-2'>
                  Sans abris (appel gratuit) :{' '}
                  <span className='rf-font-bold'>115</span>
                </p>
                <p className='rf-text-xl rf-py-2'>
                  Urgences européennes :{' '}
                  <span className='rf-font-bold'>118</span>
                </p>
              </div>
              <div>
                {localHospitalName && localHospitalName.length > 0 && (
                  <p className='rf-text-xl rf-py-2'>
                    Hôpital {localHospitalName} :{' '}
                    <span className='rf-font-bold'>{localHospitalNumber}</span>
                  </p>
                )}
                {poisonControlNumber && poisonControlNumber.length > 0 && (
                  <p className='rf-text-xl rf-py-2'>
                    Centre anti-poison :{' '}
                    <span className='rf-font-bold'>{poisonControlNumber}</span>
                  </p>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      {!isMobile && <div className='rf-flex-1' />}
    </>
  )
}

export { EmergencyContent }
