{
  "_comment": "[CI-LOCKED] Der Inhalt dieser Datei ist gesperrt. Beiträge sind willkommen, die Änderungsüberprüfung kann jedoch verzögert werden.",
  "ACCESS_KEY_ALT": "ALT UMSCHALT $1",
  "ACCESS_KEY_FOR_MAC_ALT": "CONTROL OPTION $1",
  "ACTIVITY_BOT_SAID_ALT": "Bot $1 sagte:",
  "ACTIVITY_YOU_SAID_ALT": "Sie sagten:",
  "ACTIVITY_BOT_ATTACHED_ALT": "Bot angefügt:",
  "ACTIVITY_ERROR_BOX_TITLE": "Fehlermeldung",
  "ACTIVITY_INTERACTIVE_FOOTNOTE_ALT": "Zum Interagieren klicken.",
  "ACTIVITY_INTERACTIVE_REASON_INTERACTIVE_CONTENT_ALT": "Die Nachricht ist interaktiv.",
  "ACTIVITY_INTERACTIVE_REASON_LINK_ALT": "Die Nachricht enthält mindestens einen Link.",
  "ACTIVITY_INTERACTIVE_REASON_SEND_FAILED_ALT": "Fehler beim Senden.",
  "ACTIVITY_YOU_ATTACHED_ALT": "Sie haben angefügt:",
  "ACTIVITY_NUM_ATTACHMENTS_ONE_ALT": "1 Anhang.",
  "ACTIVITY_NUM_ATTACHMENTS_FEW_ALT": "$1 Anhänge.",
  "ACTIVITY_NUM_ATTACHMENTS_MANY_ALT": "$1 Anhänge.",
  "ACTIVITY_NUM_ATTACHMENTS_OTHER_ALT": "$1 Anhänge.",
  "ACTIVITY_NUM_ATTACHMENTS_TWO_ALT": "$1 Anhänge.",
  "ACTIVITY_STATUS_SEND_FAILED_RETRY": "Fehler beim Senden. [Wiederholen][RETRY].",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENDING": "Wird gesendet ...",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENT_AT": "Gesendet um $1",
  "ACTIVITY_STATUS_TIMESTAMP_JUST_NOW": "Jetzt",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_HOUR_AGO": "Vor einer Stunde",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_MINUTE_AGO": "Vor einer Minute",
  "ACTIVITY_STATUS_TIMESTAMP_TODAY": "Heute",
  "ACTIVITY_STATUS_TIMESTAMP_YESTERDAY": "Gestern",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_PARSE": "Fehler beim Analysieren der adaptiven Karte",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_RENDER": "Fehler beim Rendern der adaptiven Karte",
  "ATTACHMENT_AUDIO": "Ein Audioclip.",
  "ATTACHMENT_CARD": "Eine Karte: $1 $2 $3",
  "ATTACHMENT_FILE": "Eine Datei: $1",
  "ATTACHMENT_IMAGE": "Ein Bild",
  "ATTACHMENT_TEXT": "Ein Text: $1",
  "ATTACHMENT_VIDEO": "Ein Videoclip",
  "CAROUSEL_FLIPPER_LEFT_ALT": "Links",
  "CAROUSEL_FLIPPER_RIGHT_ALT": "Berechtigung",
  "CONNECTIVITY_STATUS_ALT_CONNECTED": "Verbunden",
  "CONNECTIVITY_STATUS_ALT_CONNECTING": "Verbindung wird hergestellt ...",
  "CONNECTIVITY_STATUS_ALT_FATAL": "Verbindung konnte nicht aufgebaut werden.",
  "CONNECTIVITY_STATUS_ALT_RECONNECTING": "Netzwerkunterbrechung ist aufgetreten. Verbindung wird wiederhergestellt ...",
  "CONNECTIVITY_STATUS_ALT_RENDER_ERROR": "Fehler beim Rendern. Überprüfen Sie die Konsole, oder wenden Sie sich an den Botentwickler.",
  "CONNECTIVITY_STATUS_ALT_SLOW_CONNECTION": "Der Verbindungsvorgang dauert länger als erwartet.",
  "CONNECTIVITY_STATUS_ALT": "Verbindungsstatus: $1",
  "FILE_CONTENT_ALT": "„$1“",
  "FILE_CONTENT_DOWNLOADABLE_ALT": "Datei „$1“ herunterladen",
  "FILE_CONTENT_DOWNLOADABLE_WITH_SIZE_ALT": "Datei „$1“ mit einer Größe von $2 herunterladen",
  "FILE_CONTENT_WITH_SIZE_ALT": "„$1“ mit einer Größe von $2",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_BODY": "Eingabetaste",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_HEADER": "Auf Elemente in einer Nachricht zugreifen",
  "KEYBOARD_HELP_CHAT_HISTORY_HEADER": "Chatverlauf",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_BODY": "ESC-Taste",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_HEADER": "Meldung schließen",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_BODY": "TAB-Taste\nUMSCHALT + TAB-Taste",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_HEADER": "Zwischen Elementen in einer Nachricht wechseln",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_BODY": "Pfeiltaste nach oben\nPfeiltaste nach unten",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_HEADER": "Zwischen Nachrichten wechseln",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_BODY": "Eingabetaste",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_HEADER": "Aktion durchführen",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_BODY": "TAB-Taste\nUMSCHALT + TAB-Taste",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_HEADER": "Zwischen Elementen im Chatfenster wechseln",
  "KEYBOARD_HELP_CHAT_WINDOW_HEADER": "Chatfenster",
  "KEYBOARD_HELP_CLOSE_BUTTON_ALT": "Schließen",
  "KEYBOARD_HELP_HEADER": "Tastatursteuerung",
  "MARKDOWN_EXTERNAL_LINK_ALT": "Wird in einem neuen Fenster geöffnet, extern.",
  "RECEIPT_CARD_TAX": "Steuer",
  "RECEIPT_CARD_TOTAL": "Gesamt",
  "RECEIPT_CARD_VAT": "Umsatzsteuer",
  "SEND_BOX_IS_EMPTY_TOOLTIP_ALT": "Eine leere Nachricht kann nicht gesendet werden.",
  "SPEECH_INPUT_LISTENING": "Zuhören ...",
  "SPEECH_INPUT_MICROPHONE_BUTTON_CLOSE_ALT": "Mikrofon aus",
  "SPEECH_INPUT_MICROPHONE_BUTTON_OPEN_ALT": "Mikrofon an",
  "SPEECH_INPUT_STARTING": "Wird gestartet ...",
  "SUGGESTED_ACTIONS_LABEL_ALT": "Empfohlene Aktionen",
  "TEXT_INPUT_ALT": "Eingabefeld für Nachricht",
  "TEXT_INPUT_PLACEHOLDER": "Nachricht eingeben",
  "TEXT_INPUT_SEND_BUTTON_ALT": "Senden",
  "TEXT_INPUT_SPEAK_BUTTON_ALT": "Sprechen",
  "TEXT_INPUT_UPLOAD_BUTTON_ALT": "Datei hochladen",
  "TOAST_ACCORDION_FEW": "$1 Benachrichtigungen: Klicken Sie hier, um die Details anzuzeigen.",
  "TOAST_ACCORDION_MANY": "$1 Benachrichtigungen: Klicken Sie hier, um die Details anzuzeigen.",
  "TOAST_ACCORDION_OTHER": "$1 Benachrichtigungen: Klicken Sie hier, um die Details anzuzeigen.",
  "TOAST_ACCORDION_TWO": "$1 Benachrichtigungen: Klicken Sie hier, um die Details anzuzeigen.",
  "TOAST_ALT_ERROR": "Fehler",
  "TOAST_ALT_INFO": "Informationen",
  "TOAST_ALT_SUCCESS": "Erfolg",
  "TOAST_ALT_WARN": "Warnung",
  "TOAST_DISMISS_BUTTON": "Schließen",
  "TOAST_TITLE_ALT": "Benachrichtigung",
  "TRANSCRIPT_ARIA_LABEL_ALT": "Zum Navigieren im Chatverlauf die Pfeiltasten verwenden.",
  "TRANSCRIPT_ARIA_ROLE_ALT": "Chatverlauf",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_LABEL_ALT": "Die Nachricht ist interaktiv. Drücken Sie 2-3 mal UMSCHALT + TAB-Taste, um in den Chatverlauf zu wechseln. Klicken Sie dann auf die Nachricht, um zu interagieren.",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_WITH_LINKS_LABEL_ALT": "Die Nachricht enthält mindestens einen Link. Drücken Sie 2-3 mal UMSCHALT + TAB-Taste, um in den Chatverlauf zu wechseln. Klicken Sie dann auf die Nachricht, um zu interagieren.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_LABEL_ALT": "In der Nachricht sind Aktionen vorgeschlagen worden. Drücken Sie die UMSCHALT + TAB-Taste 2 bis 3 Mal, um sie auszuwählen.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_WITH_ACCESS_KEY_LABEL_ALT": "In der Nachricht sind Aktionen vorgeschlagen worden. Drücken Sie $1, um sie auszuwählen.",
  "TRANSCRIPT_LIVE_REGION_SEND_FAILED_ALT": "Fehler beim Senden der Nachricht.",
  "TRANSCRIPT_MORE_MESSAGES": "Weitere Meldungen",
  "TRANSCRIPT_NEW_MESSAGES": "Neue Nachrichten",
  "TRANSCRIPT_TERMINATOR_TEXT": "Ende des Chatverlaufs",
  "TYPING_INDICATOR_ALT": "Anzeigen des Eingabeindikators",
  "TYPING_INDICATOR_SINGLE_TEXT": "$1 gibt gerade ein.",
  "TYPING_INDICATOR_MULTIPLE_TEXT": "$1 und weitere geben gerade ein."
}