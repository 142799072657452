const checkFileNameInUrl = (url: string): string | undefined => {
  const [lastPart] = url.split('/').slice(-1)
  if (lastPart && lastPart.length > 4 && lastPart.includes('.')) {
    return lastPart
  }

  return undefined
}

export { checkFileNameInUrl }
