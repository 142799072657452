import { hooks } from 'botframework-webchat'
import type { HistoryActivity, RetorikActivity } from '../models/activityTypes'

const { useActivities } = hooks

export default function useMessageActivities(): Array<HistoryActivity> {
  const [allActivities] = useActivities()
  // processedId contains the ids of the activities already processed (because useActivities gives duplicate activities with different state depending on if it's been spoken or not )
  const processedId: Array<string> = []
  const history: HistoryActivity[] = []
  const activities = allActivities
    .slice()
    .filter(({ type }) => type === 'message')
  activities.forEach((activity) => {
    if (activity.id && !processedId.includes(activity.id)) {
      processedId.push(activity.id)
      // Check if this is a question or an answer (questions from the user don't have a replyToId field)
      if (activity.replyToId) {
        const question: HistoryActivity | undefined = history.find(
          (historyItem) => historyItem.question.id === activity.replyToId
        )
        if (question) {
          question.answers.push(activity)
        } else {
          // Get answer's timestamp or create a new one if none present
          const timestamp =
            activity.localTimestamp ||
            activity.timestamp ||
            Date.now().toString()

          const newQuestion: RetorikActivity = {
            id: activity.id,
            type: 'message',
            text: '',
            channelData: {
              'webchat:sequence-id': 1
            },
            timestamp: timestamp,
            localTimestamp: timestamp,
            from: {
              id: 'bot',
              role: 'bot'
            }
          }

          history.push({
            question: newQuestion,
            answers: [activity]
          })
        }
      } else {
        history.push({
          question: activity,
          answers: []
        })
      }
    }
  })

  return history
}
