import React, { useEffect, useMemo, useState, useRef } from 'react'
import { useSpeech } from '../Contexts/SpeechContext'
import { useView } from '../Contexts/ViewContext'
import SpeakNews from './SpeakNews'
import createPonyfill from '@davi/web-speech-cognitive-services/lib/SpeechServices'
import type { Image, Media } from '../../models/attachmentTypes'
import Banner from './Banner'
import Carousel from '../Utils/Carousel'
import useNews from '../../hooks/useNews'
import { useSendEvent } from 'botframework-webchat-api/lib/hooks'

type NewsProps = {
  intervalInSeconds?: number
  onEnd: () => void
  isRetorikNews?: boolean
}

const News = ({
  intervalInSeconds = 3,
  onEnd,
  isRetorikNews
}: NewsProps): JSX.Element => {
  const { newsAttachments, activity } = useNews()
  const { route, isMobile } = useView()
  const { ponyfillCredentials } = useSpeech()
  const news = useMemo(() => {
    return newsAttachments.map((newsAttachement) => newsAttachement.content)
  }, [newsAttachments])
  const sendEvent = useSendEvent()
  const [current, setCurrent] = useState<number>(0)
  const [voicesLoaded, setVoicesLoaded] = useState<boolean>(false)
  const timerRef: React.MutableRefObject<any> = useRef(null)

  const ponyfill = useMemo(
    () => createPonyfill({ credentials: ponyfillCredentials }),
    [ponyfillCredentials]
  )

  useEffect(() => {
    if (!newsAttachments.length) {
      sendEvent('news.getAllNews', null)
    }

    return (): void => timerRef && clearTimeout(timerRef.current)
  }, [])

  const onVoicesChanged = (): void => {
    setVoicesLoaded(
      Array.isArray(ponyfill.speechSynthesis.getVoices()) &&
        ponyfill.speechSynthesis.getVoices().length > 0
    )
  }

  useEffect(() => {
    if (voicesLoaded === true) return
    if (
      ponyfill &&
      Array.isArray(ponyfill.speechSynthesis.getVoices()) &&
      ponyfill.speechSynthesis.getVoices().length > 0
    ) {
      onVoicesChanged()
    } else if (ponyfill) {
      ponyfill.speechSynthesis.onvoiceschanged = onVoicesChanged
    }
  }, [ponyfill])

  const currentMedia = useMemo<Array<Image | Media>>(() => {
    const currentNew = news[current]
    if (currentNew) {
      if (currentNew.media && currentNew.media.length > 0) {
        return currentNew.media
      } else if (currentNew.image && currentNew.image.url) {
        return [currentNew.image]
      }
    }

    return []
  }, [news, current])

  const handleEnd = (): void => {
    if (current < news.length - 1) {
      const delay =
        current < news.length - 1 ? intervalInSeconds : intervalInSeconds * 3
      timerRef && clearTimeout(timerRef.current)
      if (route === 'news' || isRetorikNews) {
        timerRef.current = setTimeout(next, delay * 1000)
      }
    } else {
      onEnd()
    }
  }

  const next = (): void => {
    const next = (current + 1) % news.length
    changeNews(next)
  }

  const changeNews = (next: number): void => {
    setCurrent(next)
  }

  return news.length > 0 && voicesLoaded && current >= 0 ? (
    <React.Fragment>
      {/* Speech process */}
      <SpeakNews
        news={news[current]}
        onEnd={handleEnd}
        ponyfill={ponyfill}
        activity={activity}
      />

      {/* Carousel in landscape / borne display */}
      {!isMobile && (
        <div
          className='rf-relative
            rf-col-start-5 rf-col-span-3 rf-row-start-1 rf-row-span-9
            large-vertical:rf-col-start-2 large-vertical:rf-col-end-8 large-vertical:rf-row-start-7 large-vertical:rf-row-end-11
            rf-flex rf-justify-center rf-items-center'
        >
          {currentMedia.length > 0 && (
            <Carousel
              className='rf-max-h-4/5 large-vertical:rf-max-h-11/12 rf-max-w-full'
              medias={
                news[current].media || [news[current].image || { url: '' }]
              }
            />
          )}
        </div>
      )}

      {/* Lower banner with title / subtitle / text */}
      <Banner news={news[current]} />
    </React.Fragment>
  ) : (
    <React.Fragment />
  )
}

export default News
