import { CDNImagesAddress } from '../models/constants'

const imageFiles = {
  noimage: `${CDNImagesAddress}/no-image.png`,
  logoRetorikBlack: `${CDNImagesAddress}/logo_RETORIK_black.png`,
  logoRetorikWhite: `${CDNImagesAddress}/logo_RETORIK_white.png`,
  mapDeparture: `${CDNImagesAddress}/Leaflet/map_departure.png`,
  mapArrival: `${CDNImagesAddress}/Leaflet/map_arrival.png`,
  leaMini: `${CDNImagesAddress}/lea_mini.png`
}

export { imageFiles }
