import React from 'react'
import { ArrowRightIcon } from '../../Icons/ArrowIcons'
import { DownloadIcon } from '../../Icons/Miscellaneous'

interface ClassicButtonProps {
  url: string
  text?: string
  description?: boolean
  download?: boolean
}

const ClassicButton = ({
  url,
  text,
  description,
  download
}: ClassicButtonProps): JSX.Element => {
  return (
    <div
      className={`rf-w-full rf-flex ${
        description ? 'rf-justify-start' : 'rf-justify-center'
      }`}
    >
      <a
        href={url}
        target='_blank'
        rel='noopener noreferrer'
        className='rf-card-button rf-flex rf-flex-row rf-items-center'
      >
        <div className='rf-h-6 rf-w-6'>
          {download ? <DownloadIcon /> : <ArrowRightIcon />}
        </div>
        <div className='rf-ml-2 rf-truncate'>{text || ''}</div>
      </a>
    </div>
  )
}

export default ClassicButton
