import React, { useEffect, useRef, useState } from 'react'
import type { Ponyfill, Boundary } from '../../models/speechTypes'
import type { SpeechSynthesisUtterance } from '@davi/web-speech-cognitive-services/lib/SpeechServices'
import { useRetorik } from '../Contexts/RetorikContext'
import { useSpeech } from '../Contexts/SpeechContext'
import { useView } from '../Contexts/ViewContext'
import LoaderCallToAction from '../Loader/LoaderCallToAction'

interface RetorikSpeechProps {
  ponyfill: Ponyfill
  onEnd?: () => void
  onError?: () => void
  onStart?: () => void
  utterance?: SpeechSynthesisUtterance
  appAvailable: boolean
}

const RetorikSpeech = ({
  ponyfill,
  onEnd,
  onError,
  onStart,
  utterance,
  appAvailable
}: RetorikSpeechProps): JSX.Element => {
  const { loaderClosed, setLoaderClosed } = useRetorik()
  const { speechAllowed, muted, setBoundaryData } = useSpeech()
  const { route } = useView()
  const timerRef: React.MutableRefObject<any> = useRef(null)
  const [boundaries, setBoundaries] = useState<Array<Boundary>>()

  /**
   * On utterance.text prop change :
   *  - check if the output speech is allowed from SpeechContext's speechAllowed state
   *  - if allowed : add start / end / error behaviour to the utterance, and start it
   *  - if not allowed : call handleStart method, and then handleEnd aftrer a little delay
   */
  useEffect(() => {
    if (utterance) {
      // If speech is allowed, let's play the utterance
      if (speechAllowed) {
        utterance.onstart = (): void => {
          handleStart()
        }

        utterance.onend = (): void => {
          setBoundaries([])
          handleEnd()
        }

        utterance.onerror = (event): void => {
          handleError(event)
        }

        if (route !== 'news') {
          utterance.onsynthesiscompleted = (): void => {
            const endBoundary: Boundary = {
              word: '',
              startTime: 0,
              endTime: 0,
              boundaryType: 'EndBoundary'
            }

            setBoundaries((prevData) => {
              return prevData ? [...prevData, endBoundary] : [endBoundary]
            })
          }

          utterance.onboundary = (event): void => {
            if (event.boundaryType !== 'Viseme') {
              // Get the current word and its start time and end time.
              const word = event.name
              const startTime = event.elapsedTime
              const endTime = event.elapsedTime + event.duration
              const boundaryType = event.boundaryType
              // Update the boundaryData state with the new data.
              setBoundaries((prevData) => {
                return [
                  ...(prevData ?? []),
                  { word, startTime, endTime, boundaryType }
                ]
              })
            }
          }
        }

        /*
        utterance.onmark = (event): void => {
          // TODO
        }

        utterance.onviseme = (event): void => {
          // TODO
        }
        */

        muted && ponyfill.speechSynthesis.mute()
        ponyfill.speechSynthesis.speak(utterance)
      }
      // If speech is not allowed, just call start and then end after a little delay
      else {
        handleStart()
        timerRef && clearTimeout(timerRef.current)
        timerRef.current = setTimeout(() => {
          handleEnd()
        }, 50)
      }
    }

    return (): void => {
      ponyfill.speechSynthesis.cancel()
      timerRef && clearTimeout(timerRef.current)
    }
  }, [utterance?.text])

  useEffect(() => {
    if (boundaries && boundaries.length > 0) {
      setBoundaryData(boundaries)
    } else {
      setBoundaryData([])
    }
  }, [boundaries])

  useEffect(() => {
    if (ponyfill.speechSynthesis) {
      muted
        ? ponyfill.speechSynthesis.mute()
        : ponyfill.speechSynthesis.unmute()
    }
  }, [muted])

  /**
   * On call :
   *  - call parent's onStart method if defined
   */
  const handleStart = (): void => {
    onStart && onStart()
  }

  /**
   * On call :
   *  - call parent's onEnd method if defined
   */
  const handleEnd = (): void => {
    onEnd && onEnd()
  }

  /**
   * On call :
   *  - log the error
   *  - call parent's onError method if defined
   */
  const handleError = (error): void => {
    console.log('Error : ', error)
    onError && onError()
    setBoundaries([])
  }

  /**
   * On call :
   *  - create basic utterance to be read to prime audio output
   *  - MANDATORY FOR SAFARI IN VOCAL MODE
   */
  const primeRetorikSpeech = (): void => {
    const ssml =
      '<speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="https://www.w3.org/2001/mstts" xml:lang="fr-FR"><voice name="Microsoft Server Speech Text to Speech Voice (fr-FR, BrigitteNeural)"><prosody volume="-100%">Bonjour</prosody></voice></speak>'
    const primeUtterance = new ponyfill.SpeechSynthesisUtterance(ssml)
    primeUtterance.onend = (): void => setLoaderClosed(true)
    ponyfill.speechSynthesis.speak(primeUtterance)
    // Send animation start event to secure animation not playing on safari if permissions are not sufficient
    window.dispatchEvent(new Event('retorikSpiritEnginePlay'))
  }

  return appAvailable || loaderClosed ? (
    <React.Fragment />
  ) : (
    <LoaderCallToAction handleValidation={primeRetorikSpeech} />
  )
}

RetorikSpeech.defaultProps = {
  ponyfill: undefined,
  onEnd: undefined,
  onError: undefined,
  onStart: undefined,
  utterance: undefined,
  appAvailable: false
}

export default RetorikSpeech
