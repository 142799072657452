import React, { useEffect, useState } from 'react'
import { useRetorik } from '../Contexts/RetorikContext'
import { Mode } from '../../models/enums'

interface ToggleProps {
  className?: string
  title?: string
  handleChange: (on: boolean) => void
  defaultChecked?: boolean
  lockInTextMode?: boolean
}

const Toggle = ({
  className,
  title,
  handleChange,
  defaultChecked,
  lockInTextMode
}: ToggleProps): JSX.Element => {
  const { mode } = useRetorik()
  const [on, setOn] = useState<boolean>(!!defaultChecked)
  const [transition, setTransition] = useState<string>(
    on ? 'rf-right-1' : 'rf-left-1'
  )

  useEffect(() => {
    setOn(!!defaultChecked)
    setTransition(defaultChecked ? 'rf-right-1' : 'rf-left-1')
  }, [defaultChecked])

  const handleToggle = (): void => {
    if (!(lockInTextMode && mode === Mode.text)) {
      setTransition(on ? 'rf-translate-x-1' : 'rf-translate-x-5')
      handleChange(!on)
      setOn(!on)
    }
  }

  return (
    <div
      className={`rf-flex rf-flex-row large:rf-flex-col rf-items-center rf-justify-between large:rf-justify-start large:rf-gap-1 hover:rf-cursor-pointer ${className}`}
    >
      {/* Title */}
      <div className='rf-capitalize large:rf-uppercase'>{title}</div>

      {/* Toggle */}
      <button
        className='rf-relative rf-w-12 rf-min-w-12 rf-h-6 rf-rounded-6'
        style={{
          background: 'linear-gradient(to left, #E5E6EC, #F2F3F6)',
          boxShadow: 'inset 3px 3px 6px #00000029'
        }}
        onClick={handleToggle}
        aria-label={title}
      >
        <div
          className={`rf-absolute rf-top-1 rf-w-4 rf-h-4 rf-rounded-half ${
            on ? 'rf-bg-secondary' : 'rf-bg-[#F8F8F8]'
          } rf-transition-all ${transition}`}
          style={{
            boxShadow: on ? '-3px -3px 3px #00000029' : '3px 3px 3px #00000029'
          }}
        />
      </button>
    </div>
  )
}

export default Toggle
