import './tailwind.css'

import Weather from './components/Weather'
import renderWeather from './renderWeather'

export default Weather
export { Weather, renderWeather }

window.Retorik = {
  ...window.Retorik,
  renderWeather
}
