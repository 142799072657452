{
  "fr-FR": {
    "needhelp": "Besoin d'aide ?",
    "consult": "Consultez",
    "thetutorial": "le tutoriel"
  },
  "en-US": {
    "needhelp": "Need help ?",
    "consult": "Check out",
    "thetutorial": "the tutorial"
  },
  "es-ES": {
    "needhelp": "Necesitar ayuda ?",
    "consult": "Mira",
    "thetutorial": "el tutorial"
  },
  "de-DE": {
    "needhelp": "Brauche Hilfe ?",
    "consult": "Schauen Sie sich",
    "thetutorial": "das Tutorial an"
  }
}
