const activityMiddleware =
  () =>
  (next) =>
  (...args): JSX.Element | any => {
    const [{ activity }] = args
    if (
      activity &&
      activity.type === 'message' &&
      activity.attachments &&
      activity.attachments.length > 0 &&
      activity.attachments[0].contentType ===
        'application/vnd.davi.retorik.card.suggestedaction' &&
      activity.attachments[0].content?.title?.indexOf(activity.text) > -1
    ) {
      // Hack for Suggested Action : Only show text in card, not in message
      activity.text = ''
      activity.htmlText = ''
    }
    return next(...args)
  }

export default activityMiddleware
