import React, { useEffect, useState } from 'react'
import { useLocaleStore } from '../../Contexts/localeStore'
import { useUtilsStore } from '../../Contexts/utilsStore'
import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter'
import translation from '../../../translations/inputs.json'
import { checkForData } from '../../../utils/localStorageInputsUtils'
import { CurrentSubView } from '../../../models/enums'
import { ArrowDownIcon } from '../../Icons/ArrowIcons'

interface SelectProps {
  id: string
  choices: any
  placeholder?: string
  handleSelect: (choice: Choice) => void
}

interface Choice {
  title: string
  value: string
}

const Select = ({
  id,
  choices,
  placeholder,
  handleSelect
}: SelectProps): JSX.Element => {
  const { locale } = useLocaleStore()
  const { currentSubView } = useUtilsStore()
  const [display, setDisplay] = useState<Array<any>>([])
  const [open, setOpen] = useState<boolean>(false)
  const [chosen, setChosen] = useState<Choice | null>(checkLocalStorage())

  /**
   * On call :
   *  - check if a choice's value matches to one in local storage if there is one
   *  - return either the choice that matches, or null if no match
   * @returns Choice | null
   */
  function checkLocalStorage(): Choice | null {
    const existing = choices.filter(
      (choice: Choice) => choice.value === checkForData(id, null)
    )

    return existing.length > 0 ? existing[0] : null
  }

  /**
   * On choices prop change :
   *  - if choices is defined, fill display state with choices value
   */
  useEffect(() => {
    choices && setDisplay(choices)
  }, [choices])

  /**
   * On call :
   *  - set open state to its contrary
   */
  const toggleOpen = (): void => {
    setOpen(!open)
  }

  /**
   * On call :
   *  - check if the component will be rendered in history, if so bypass every interaction
   *  - fill chosen state with choice value
   *  - call parent's handleSelect method
   *  - call toggleOpen method
   * @param choice : Choice
   */
  const handleChange = (choice: Choice): void => {
    if (currentSubView !== CurrentSubView.history) {
      setChosen(choice)
      handleSelect(choice)
      toggleOpen()
    }
  }

  return display && display.length > 0 ? (
    <div className='rf-w-full rf-mt-4 rf-text-size-auto rf-text-formInputTextDefault'>
      <div
        onClick={toggleOpen}
        className={`rf-w-full rf-flex rf-flex-row rf-justify-between rf-items-center rf-p-4 rf-border-2 rf-rounded ${
          open
            ? 'rf-border-formInputRadioCheckboxCheckedBorder'
            : 'rf-border-formInputRadioCheckboxUncheckedBorder'
        } rf-bg-formInputRadioCheckboxUncheckedBackground`}
      >
        <div className='rf-truncate'>
          {chosen?.title ||
            placeholder ||
            translation[locale]?.choose ||
            translation['fr-FR'].choose}
        </div>
        <div className='rf-h-2'>
          <ArrowDownIcon className='rf-h-full' />
        </div>
      </div>
      {open && (
        <ul
          className='rf-mt-1 rf-max-h-48 rf-pl-0 rf-w-full rf-rounded rf-border-2 rf-border-formInputRadioCheckboxUncheckedBorder rf-bg-formInputRadioCheckboxUncheckedBackground rf-overflow-y-scroll rf-scrollbar-thin'
          style={{
            listStyle: 'none'
          }}
        >
          {display.map((choice, key) => {
            return (
              <li
                key={key}
                onClick={(): void => handleChange(choice)}
                className='rf-px-4 rf-py-2 hover:rf-text-formInputRadioCheckboxCheckedItem hover:rf-bg-formInputRadioCheckboxCheckedBackground'
              >
                {capitalizeFirstLetter(choice.title)}
              </li>
            )
          })}
        </ul>
      )}
    </div>
  ) : (
    <React.Fragment />
  )
}

export default Select
