import React from 'react'
import type { IconDefaultProps } from '../../../models/types'

const DownloadIcon = ({ className, color }: IconDefaultProps): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 30 30'
      className={className || 'rf-h-6 rf-w-6'}
    >
      <path
        d='M20.75 14.38H16V.96c0-.53-.45-.96-1-.96s-1 .43-1 .96v13.42H9.25c-.19 0-.32.2-.22.36l5.75 9.52c.1.16.34.16.44 0l5.75-9.52c.09-.16-.03-.36-.22-.36z'
        fill={color || 'currentColor'}
      />
      <path
        d='M29 19.76V27c0 1.1-.86 2-1.93 2H2.93C1.86 29 1 28.1 1 27v-7.24'
        fill='none'
        stroke={color || 'currentColor'}
      />
    </svg>
  )
}

export default DownloadIcon
