import React from 'react'
// eslint-disable-next-line no-unused-vars
import type { Size } from '../models/types'

type VideoProps = {
  srcMp4: string
  srcWebm: string
  onEnded: any
  size: Size
}

const Video = React.forwardRef<HTMLVideoElement, VideoProps>(
  ({ srcMp4, srcWebm, onEnded, size }, ref) => {
    return (
      <video
        ref={ref}
        className='ses-invisible ses-col-start-1 ses-row-start-1 ses-col-span-1 ses-row-span-1 ses-max-w-none ses-self-start ses-justify-self-center ses-object-cover'
        muted
        preload='auto'
        onEnded={onEnded}
        playsInline
        controls
        style={{
          height: size
        }}
      >
        <source src={srcMp4} type='video/mp4; codecs="hvc1"' />
        <source src={srcWebm} type='video/webm' />
      </video>
    )
  }
)

export default Video
