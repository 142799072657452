import React from 'react'

const EmptyAttachment = (): JSX.Element => {
  return (
    <div className='rf-w-full rf-h-full rf-flex rf-flex-col rf-gap-2 rf-rounded'>
      <div className='rf-relative rf-w-full rf-bg-templateEmptyCard rf-rounded rf-aspect-video' />
    </div>
  )
}

export default EmptyAttachment
