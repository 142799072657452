import { DEFAULT_LANGUAGE_DATA } from '../models/constants'
import type { AddressData, AvailableLanguages } from '../models/types'
import processBaseURI from './studioRetorikAddressFromBaseURI'
import processPrefix from './studioRetorikAddressFromPrefix'

export default async function fetchAvailableLanguages({
  tenant,
  prefix,
  baseURI
}: AddressData): Promise<AvailableLanguages> {
  const fullAddress = `${
    baseURI && baseURI?.length > 0
      ? processBaseURI(baseURI)
      : processPrefix(prefix)
  }/${
    tenant ? tenant + '/' : ''
  }api/graphql?query={siteCultures{culture,default}}`
  const languages: AvailableLanguages = await fetch(fullAddress)
    .then((res) => res.json())
    .then((json) => {
      return processJson(json)
    })
    .catch((error) => {
      console.log(error)
      return DEFAULT_LANGUAGE_DATA
    })

  return languages
}

/* The data received from the fetch are as follows
{
  data: {
    siteCultures: [
      {
        culture: "fr-FR",
        default: true
      },
      {
        culture: "en-US",
        default: false
      },
      {
        culture: "de-DE",
        default: false
      }
    ]
  }
}
*/
const processJson = (json): AvailableLanguages => {
  if (!json.data?.siteCultures || !json.data.siteCultures?.length) {
    return DEFAULT_LANGUAGE_DATA
  }
  const siteCultures = json.data.siteCultures

  const processedLanguages = {
    all: siteCultures.map((siteCulture) => siteCulture.culture),
    default:
      siteCultures.find((siteCulture) => siteCulture.default)?.culture ||
      siteCultures[0].culture
  }
  return processedLanguages
}
