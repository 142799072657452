/* eslint-disable react/self-closing-comp */
import React from 'react'
import type { IconDefaultProps } from '../../../models/types'

const InteractiveMapIcon = ({
  className,
  color
}: IconDefaultProps): JSX.Element => {
  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      className={className || 'rf-h-6 rf-w-6'}
      viewBox='0 0 512 512'
      xmlSpace='preserve'
    >
      <g fill={color || '#1999B1'}>
        <g>
          <path
            d='M256,93.297c-14.021,0-27.516,4.218-39.025,12.198c-3.402,2.359-4.262,7.05-1.921,10.478
          c2.342,3.428,6.998,4.294,10.4,1.936c9.004-6.243,19.566-9.543,30.546-9.543c29.754,0,53.96,24.388,53.96,54.365
          c0,29.977-24.206,54.366-53.96,54.366c-29.754,0-53.96-24.389-53.96-54.366c0-8.843,2.038-17.273,6.057-25.055
          c1.906-3.692,0.482-8.241-3.182-10.161c-3.665-1.919-8.178-0.486-10.085,3.206c-5.067,9.812-7.746,20.881-7.746,32.01
          C187.085,201.018,218,232.166,256,232.166s68.915-31.148,68.915-69.435C324.915,124.445,294,93.297,256,93.297z'
          />
        </g>
      </g>
      <g fill={color || '#1999B1'}>
        <g>
          <path
            d='M511.426,501.571l-73.804-178.665c-1.16-2.809-3.883-4.639-6.903-4.639h-73.547
          c14.305-19.498,26.212-38.919,35.511-57.988c16.48-33.794,24.835-66.613,24.835-97.547C417.517,73.001,345.061,0,256,0
          S94.483,73.001,94.483,162.731c0,30.934,8.355,63.754,24.835,97.547c9.298,19.069,21.205,38.491,35.511,57.988H81.282
          c-3.02,0-5.744,1.831-6.903,4.639L24.087,444.651c-1.587,3.842,0.217,8.252,4.03,9.85c0.94,0.394,1.912,0.581,2.87,0.581
          c2.93,0,5.711-1.746,6.906-4.641l7.176-17.372h50.906l-26.38,63.862H18.69l7.647-18.513c1.587-3.842-0.217-8.252-4.03-9.85
          c-3.815-1.601-8.191,0.219-9.777,4.06L0.575,501.571c-0.961,2.325-0.705,4.981,0.683,7.075c1.386,2.095,3.72,3.354,6.22,3.354
          h497.044c2.499,0,4.834-1.259,6.221-3.354C512.13,506.552,512.387,503.896,511.426,501.571z M109.439,162.731
          c0-81.422,65.747-147.663,146.561-147.663S402.561,81.31,402.561,162.731c0,34.143-11.775,88.703-66.245,158.231
          c-0.055,0.065-0.104,0.134-0.156,0.201c-2.782,3.547-5.672,7.132-8.681,10.757c-29.932,36.062-60.222,62.395-71.48,71.751
          c-11.225-9.324-41.372-35.525-71.283-71.518c-3.084-3.711-6.042-7.38-8.889-11.01c-0.046-0.058-0.089-0.118-0.137-0.175
          C121.222,251.445,109.439,196.887,109.439,162.731z M86.269,333.335h50.907l-18.568,44.948H67.702L86.269,333.335z
           M129.997,496.932l46.741-113.148l133.014,113.148H129.997z M332.935,496.932L332.935,496.932l-154.352-131.3
          c-1.822-1.551-4.27-2.132-6.584-1.566c-2.316,0.565-4.228,2.21-5.143,4.427l-53.057,128.438H85.795l28.296-68.502
          c0.961-2.325,0.705-4.98-0.683-7.075c-1.386-2.095-3.721-3.354-6.221-3.354H51.296l10.182-24.65h62.118
          c3.02,0,5.744-1.831,6.903-4.639l22.876-55.377h12.978c2.253,2.83,4.543,5.661,6.894,8.491
          c38.585,46.43,76.578,76.239,78.177,77.486c1.348,1.051,2.962,1.575,4.576,1.575s3.229-0.525,4.576-1.575
          c0.879-0.685,12.757-10.009,29.673-25.96h84.681c4.13,0,7.478-3.373,7.478-7.534s-3.348-7.534-7.478-7.534h-69.269
          c10.368-10.503,21.7-22.749,33.093-36.457c2.351-2.83,4.642-5.66,6.894-8.491h80.083l18.567,44.948h-39.457
          c-4.13,0-7.478,3.373-7.478,7.534s3.347,7.534,7.478,7.534h45.681l10.182,24.65h-154.07c-3.121,0-5.913,1.952-7.006,4.898
          c-1.091,2.946-0.253,6.265,2.104,8.326l75.133,65.707H332.935z M399.681,496.932l-73.024-63.862H466.93l26.381,63.862H399.681z'
          />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  )
}

export default InteractiveMapIcon
