import type { RetorikActivity } from '../models/activityTypes'

/* Return codes:
  0 => do nothing
  1 => add to queue
  2 => stop current activity + change to the one tested
*/

const checkLastbotActivity = (
  lastbotActivity,
  currentPlaying,
  currentReplyToId,
  queuedActivities,
  endedActivities
): 0 | 1 | 2 => {
  // Check if the activity is the one currently being played
  if (lastbotActivity.id === currentPlaying.id) {
    return 0
  }
  // Check if the activity has already been ended
  if (endedActivities.includes(lastbotActivity.id)) {
    return 0
  }
  // Check if the activity has the same replyToId than the one currently being played
  if (lastbotActivity.replyToId === currentReplyToId) {
    // Check if the activity is already in the queue
    let count = 0
    if (queuedActivities.length > 0) {
      queuedActivities.forEach((activity: RetorikActivity) => {
        if (activity.id === lastbotActivity.id) {
          count++
        }
      })
    }

    if (count === 0) {
      return 1
    } else {
      return 0
    }
  } else {
    return 2
  }
}

export default checkLastbotActivity
