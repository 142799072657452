import React, { useState, useEffect } from 'react'
import type { TemplateDefaultProps } from '../../models/types'
import { Depth } from '../../models/enums'

const FullScreen = ({
  className,
  depth,
  background,
  children
}: TemplateDefaultProps): JSX.Element => {
  const [zindex, setzindex] = useState<string>('rf-z-ui')

  useEffect(() => {
    switch (depth) {
      case Depth.background:
        setzindex('rf-z-background')
        break
      case Depth.util:
        setzindex('rf-z-util')
        break
      case Depth.chatbot:
        setzindex('rf-z-chatbot')
        break
      case Depth.ui:
        setzindex('rf-z-ui')
        break
      case Depth.dropdown:
        setzindex('rf-z-dropdown')
        break
      case Depth.overlay:
        setzindex('rf-z-overlay')
        break
      case Depth.modal:
        setzindex('rf-z-modal')
        break
      case Depth.exitwidget:
        setzindex('rf-z-exitwidget')
        break
      case Depth.menu:
        setzindex('rf-z-menu')
        break
      default:
        setzindex('rf-z-dropdown')
        break
    }
  }, [depth])

  return (
    <div
      className={`rf-relative ${zindex} rf-col-start-1 rf-col-span-full rf-row-start-1 rf-row-span-full rf-text-trueblack ${className}`}
      style={{
        background:
          background || 'linear-gradient(to right bottom, #F8F8FC , #E1E2E8)'
      }}
    >
      {children}
    </div>
  )
}

export default FullScreen
