import React from 'react'
import type { IconDefaultProps } from '../../../models/types'

const LanguageIcon = ({ className, color }: IconDefaultProps): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className || 'w-6'}
      viewBox='0 0 21 21'
    >
      <path
        d='M13.49,3A10.5,10.5,0,1,0,24,13.5,10.495,10.495,0,0,0,13.49,3Zm7.277,6.3h-3.1A16.432,16.432,0,0,0,16.22,5.562,8.431,8.431,0,0,1,20.766,9.3ZM13.5,5.142A14.791,14.791,0,0,1,15.505,9.3H11.494A14.791,14.791,0,0,1,13.5,5.142ZM5.373,15.6a8.213,8.213,0,0,1,0-4.2H8.922a17.341,17.341,0,0,0-.147,2.1,17.341,17.341,0,0,0,.147,2.1Zm.861,2.1h3.1a16.432,16.432,0,0,0,1.449,3.738A8.386,8.386,0,0,1,6.234,17.7Zm3.1-8.4h-3.1A8.386,8.386,0,0,1,10.78,5.562,16.432,16.432,0,0,0,9.331,9.3ZM13.5,21.858A14.791,14.791,0,0,1,11.494,17.7h4.011A14.791,14.791,0,0,1,13.5,21.858ZM15.957,15.6H11.043a15.448,15.448,0,0,1-.168-2.1,15.314,15.314,0,0,1,.168-2.1h4.914a15.314,15.314,0,0,1,.168,2.1A15.448,15.448,0,0,1,15.957,15.6Zm.262,5.838A16.431,16.431,0,0,0,17.668,17.7h3.1a8.431,8.431,0,0,1-4.546,3.738ZM18.078,15.6a17.341,17.341,0,0,0,.147-2.1,17.341,17.341,0,0,0-.147-2.1h3.549a8.213,8.213,0,0,1,0,4.2Z'
        transform='translate(-3 -3)'
        fill={color || '#90939a'}
      />
    </svg>
  )
}

export default LanguageIcon
