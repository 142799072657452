import React from 'react'
import { AvailableViews, AvailableSubViews } from '../../../models/enums'
import * as Icons from '../../Icons/ViewIcons'
import * as ParameterIcons from '../../Icons/ParameterIcons'

interface DisplayIconProps {
  view?: number
  icon?: JSX.Element
  className?: string
}

const DisplayIcon = ({
  view,
  icon,
  className
}: DisplayIconProps): JSX.Element => {
  if (view) {
    return view === AvailableViews.home ? (
      <Icons.HomeIcon className={className} />
    ) : view === AvailableViews.news ? (
      <Icons.NewsChannelIcon className={className} />
    ) : view === AvailableViews.weather ? (
      <Icons.WeatherIcon className={className} />
    ) : view === AvailableViews.emergency ? (
      <Icons.EmergencyIcon className={className} />
    ) : view === AvailableSubViews.history ? (
      <ParameterIcons.HistoryIcon className={className} />
    ) : view === AvailableSubViews.tutorial ? (
      <ParameterIcons.TutorialIcon className={className} />
    ) : (
      <Icons.InteractiveMapIcon className={className} />
    )
  } else if (icon) {
    return <div className={className}>{icon}</div>
  }
  return <></>
}

export default DisplayIcon
