{
  "_comment": "[CI-LOCKED] El contenido de este archivo está bloqueado. Todas las aportaciones son bienvenidas, pero la validación de cambios se puede retrasar.",
  "ACCESS_KEY_ALT": "Alt Mayús $1",
  "ACCESS_KEY_FOR_MAC_ALT": "Control Opción $1",
  "ACTIVITY_BOT_SAID_ALT": "El bot $1 dijo:",
  "ACTIVITY_YOU_SAID_ALT": "Usted dijo:",
  "ACTIVITY_BOT_ATTACHED_ALT": "Bot adjunto:",
  "ACTIVITY_ERROR_BOX_TITLE": "Mensaje de error",
  "ACTIVITY_INTERACTIVE_FOOTNOTE_ALT": "Haga clic para interactuar.",
  "ACTIVITY_INTERACTIVE_REASON_INTERACTIVE_CONTENT_ALT": "El mensaje es interactivo.",
  "ACTIVITY_INTERACTIVE_REASON_LINK_ALT": "Uno o varios vínculos en el mensaje.",
  "ACTIVITY_INTERACTIVE_REASON_SEND_FAILED_ALT": "Errores de envío.",
  "ACTIVITY_YOU_ATTACHED_ALT": "Usted adjuntó:",
  "ACTIVITY_NUM_ATTACHMENTS_ONE_ALT": "1 archivo adjunto.",
  "ACTIVITY_NUM_ATTACHMENTS_FEW_ALT": "$1 datos adjuntos.",
  "ACTIVITY_NUM_ATTACHMENTS_MANY_ALT": "$1 datos adjuntos.",
  "ACTIVITY_NUM_ATTACHMENTS_OTHER_ALT": "$1 datos adjuntos.",
  "ACTIVITY_NUM_ATTACHMENTS_TWO_ALT": "$1 datos adjuntos.",
  "ACTIVITY_STATUS_SEND_FAILED_RETRY": "No se pudo enviar. [Reintentar][RETRY].",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENDING": "Enviando",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENT_AT": "Enviado a las $1",
  "ACTIVITY_STATUS_TIMESTAMP_JUST_NOW": "Ahora mismo",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_HOUR_AGO": "Hace una hora",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_MINUTE_AGO": "Hace un minuto",
  "ACTIVITY_STATUS_TIMESTAMP_TODAY": "Hoy",
  "ACTIVITY_STATUS_TIMESTAMP_YESTERDAY": "Ayer",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_PARSE": "Error de análisis de tarjeta adaptable",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_RENDER": "Error de representación de tarjeta adaptable",
  "ATTACHMENT_AUDIO": "Clip de audio.",
  "ATTACHMENT_CARD": "Tarjeta: $1 $2 $3",
  "ATTACHMENT_FILE": "Archivo: $1",
  "ATTACHMENT_IMAGE": "Imagen",
  "ATTACHMENT_TEXT": "Texto: $1",
  "ATTACHMENT_VIDEO": "Clip de vídeo",
  "CAROUSEL_FLIPPER_LEFT_ALT": "Izquierdo",
  "CAROUSEL_FLIPPER_RIGHT_ALT": "Derecho",
  "CONNECTIVITY_STATUS_ALT_CONNECTED": "Conectado",
  "CONNECTIVITY_STATUS_ALT_CONNECTING": "Conectando…",
  "CONNECTIVITY_STATUS_ALT_FATAL": "No se puede conectar.",
  "CONNECTIVITY_STATUS_ALT_RECONNECTING": "Interrupción de la red. Conectando de nuevo…",
  "CONNECTIVITY_STATUS_ALT_RENDER_ERROR": "Error de representación. Compruebe la consola o póngase en contacto con el desarrollador del bot.",
  "CONNECTIVITY_STATUS_ALT_SLOW_CONNECTION": "Se está tardando más de lo habitual en conectarse.",
  "CONNECTIVITY_STATUS_ALT": "Estado de conexión: $1",
  "FILE_CONTENT_ALT": "'$1'",
  "FILE_CONTENT_DOWNLOADABLE_ALT": "Descargar archivo '$1'",
  "FILE_CONTENT_DOWNLOADABLE_WITH_SIZE_ALT": "Descargar el archivo '$1' de tamaño $2",
  "FILE_CONTENT_WITH_SIZE_ALT": "'$1' de tamaño $2",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_BODY": "Tecla Entrar",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_HEADER": "Acceder a elementos del mensaje",
  "KEYBOARD_HELP_CHAT_HISTORY_HEADER": "Historial de chats",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_BODY": "Tecla Escape",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_HEADER": "Salir del mensaje",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_BODY": "Tecla Tab\nTeclas Mayús + Tab",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_HEADER": "Moverse entre elementos de un mensaje",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_BODY": "Tecla Flecha arriba\nTecla Flecha abajo",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_HEADER": "Moverse entre mensajes",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_BODY": "Tecla Entrar",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_HEADER": "Ejecutar acción",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_BODY": "Tecla Tab\nTeclas Mayús + Tab",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_HEADER": "Moverse entre elementos de la ventana de chat",
  "KEYBOARD_HELP_CHAT_WINDOW_HEADER": "Ventana de chat",
  "KEYBOARD_HELP_CLOSE_BUTTON_ALT": "Cerrar",
  "KEYBOARD_HELP_HEADER": "Controles de teclado",
  "MARKDOWN_EXTERNAL_LINK_ALT": "Se abre en una ventana nueva; externo.",
  "RECEIPT_CARD_TAX": "Impuesto",
  "RECEIPT_CARD_TOTAL": "Total",
  "RECEIPT_CARD_VAT": "IVA",
  "SEND_BOX_IS_EMPTY_TOOLTIP_ALT": "No se puede enviar un mensaje vacío.",
  "SPEECH_INPUT_LISTENING": "Escuchando…",
  "SPEECH_INPUT_MICROPHONE_BUTTON_CLOSE_ALT": "Micrófono desactivado",
  "SPEECH_INPUT_MICROPHONE_BUTTON_OPEN_ALT": "Micrófono activado",
  "SPEECH_INPUT_STARTING": "Iniciando…",
  "SUGGESTED_ACTIONS_LABEL_ALT": "Acciones sugeridas",
  "TEXT_INPUT_ALT": "Cuadro de entrada de mensajes",
  "TEXT_INPUT_PLACEHOLDER": "Escriba su mensaje",
  "TEXT_INPUT_SEND_BUTTON_ALT": "Enviar",
  "TEXT_INPUT_SPEAK_BUTTON_ALT": "Hablar",
  "TEXT_INPUT_UPLOAD_BUTTON_ALT": "Cargar archivo",
  "TOAST_ACCORDION_FEW": "$1 notificaciones: Haga clic aquí para ver más detalles",
  "TOAST_ACCORDION_MANY": "$1 notificaciones: Haga clic aquí para ver más detalles",
  "TOAST_ACCORDION_OTHER": "$1 notificaciones: Haga clic aquí para ver más detalles",
  "TOAST_ACCORDION_TWO": "$1 notificaciones: Haga clic aquí para ver más detalles",
  "TOAST_ALT_ERROR": "Error",
  "TOAST_ALT_INFO": "Información",
  "TOAST_ALT_SUCCESS": "Correcta",
  "TOAST_ALT_WARN": "Advertencia",
  "TOAST_DISMISS_BUTTON": "Descartar",
  "TOAST_TITLE_ALT": "Notificación",
  "TRANSCRIPT_ARIA_LABEL_ALT": "Historial de chats, presione las teclas de dirección para navegar.",
  "TRANSCRIPT_ARIA_ROLE_ALT": "historial de chats",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_LABEL_ALT": "El mensaje es interactivo. Presione Mayús + Tab de 2 a 3 veces para cambiar al historial de chats. Después, haga clic en el mensaje para interactuar.",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_WITH_LINKS_LABEL_ALT": "El mensaje contiene uno o varios vínculos. Presione Mayús +Tab de 2 a 3 veces para cambiar al historial de chats. Después, haga clic en el mensaje para interactuar.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_LABEL_ALT": "El mensaje ha sugerido acciones. Presione Mayús + Tab de 2 a 3 veces para seleccionarlas.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_WITH_ACCESS_KEY_LABEL_ALT": "El mensaje ha sugerido acciones. Presione $1 para seleccionarlas.",
  "TRANSCRIPT_LIVE_REGION_SEND_FAILED_ALT": "No se pudo enviar el mensaje.",
  "TRANSCRIPT_MORE_MESSAGES": "Más mensajes",
  "TRANSCRIPT_NEW_MESSAGES": "Mensajes nuevos",
  "TRANSCRIPT_TERMINATOR_TEXT": "Final del historial de chats",
  "TYPING_INDICATOR_ALT": "Mostrando indicador de escritura",
  "TYPING_INDICATOR_SINGLE_TEXT": "$1 está escribiendo.",
  "TYPING_INDICATOR_MULTIPLE_TEXT": "$1 y otros usuarios están escribiendo."
}