{
  "fr-FR": {
    "comment": "avis clients",
    "route": "itinéraire",
    "nodata": "Aucun contenu ne correspond à votre recherche",
    "précédent": "Page précédente",
    "suivant": "Voir la suite",
    "download": "Télécharger cette fiche",
    "scanQR": "en scannant le QrCode"
  },
  "en-US": {
    "comment": "customer review",
    "route": "route",
    "nodata": "No content corresponds to your search",
    "précédent": "Previous page",
    "suivant": "Next page",
    "download": "Download this sheet",
    "scanQR": "by scanning the QrCode"
  },
  "es-ES": {
    "comment": "customer review",
    "route": "route",
    "nodata": "No content corresponds to your search",
    "précédent": "Previous page",
    "suivant": "Next page",
    "download": "Download this sheet",
    "scanQR": "by scanning the QrCode"
  },
  "de-DE": {
    "comment": "customer review",
    "route": "route",
    "nodata": "No content corresponds to your search",
    "précédent": "Previous page",
    "suivant": "Next page",
    "download": "Download this sheet",
    "scanQR": "by scanning the QrCode"
  }
}
