import React from 'react'
import type { IconDefaultProps } from '../../../models/types'

const FeatherFilterIcon = ({
  className,
  color
}: IconDefaultProps): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='14.333'
      height='13'
      viewBox='0 0 14.333 13'
      className={className || 'rf-w-6 rf-h-6'}
    >
      <path
        d='M16.333,4.5H3l5.333,6.307v4.36L11,16.5V10.807Z'
        transform='translate(-2.5 -4)'
        fill='none'
        stroke={color || '#000'}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1'
      />
    </svg>
  )
}

export default FeatherFilterIcon
