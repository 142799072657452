{
  "fr-FR": {
    "title": "historique de conversation"
  },
  "en-US": {
    "title": "chat history"
  },
  "es-ES": {
    "title": "chat history"
  },
  "de-DE": {
    "title": "chat history"
  }
}
