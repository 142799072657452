{
  "fr-FR": {
    "choose": "Choisissez une réponse",
    "submit": "valider"
  },
  "en-US": {
    "choose": "Choose an answer",
    "submit": "submit"
  },
  "es-ES": {
    "choose": "Choose an answer",
    "submit": "submit"
  },
  "de-DE": {
    "choose": "Choose an answer",
    "submit": "submit"
  }
}
