import React from 'react'
import type { IconDefaultProps } from '../../../models/types'

const FeatherCheckIcon = ({
  className,
  color
}: IconDefaultProps): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='28.676'
      height='20.419'
      viewBox='0 0 28.676 20.419'
      className={className || 'rf-w-4 rf-h-4'}
    >
      <path
        d='M30.434,9l-16.8,16.8L6,18.163'
        transform='translate(-3.879 -6.879)'
        fill='none'
        stroke={color || '#fff'}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='3'
      />
    </svg>
  )
}

export default FeatherCheckIcon
