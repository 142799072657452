import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useView } from '../Contexts/ViewContext'
import { useRetorik } from '../Contexts/RetorikContext'
import { useLocaleStore } from '../Contexts/localeStore'
import { useWidgetStore } from '../Contexts/widgetStore'
import { useUtilsStore, setListClosed } from '../Contexts/utilsStore'
import useActivitiesTextMode from '../../hooks/useActivitiesTextMode'
import type {
  HistoryActivity,
  RetorikActivity
} from '../../models/activityTypes'
import { DeviceType } from '../../models/enums'
import { getTextWithoutSpeechMarkdown } from '../../utils/speechMarkdownUtils'
import Attachments from '../Attachments/Attachments'
import ListTemplate from '../Templates/List'
import { ScrollButton } from './ScrollButton'
import { ConversationHeader } from '../Menu/Mobile/ConversationHeader'

/**
 * On call :
 *  - check if the activity data need a display as list to be triggered
 * @param activity : RetorikActivity
 * @returns boolean
 */
function checkListDisplay(activity?: RetorikActivity): boolean {
  return (
    (activity &&
      (activity.attachmentLayout?.toLowerCase() === 'davilist' ||
        (activity.attachmentLayout !== 'carousel' &&
          activity.attachments &&
          activity.attachments.length > 1))) ||
    false
  )
}

const ConversationPanel = (): JSX.Element => {
  const activities = useActivitiesTextMode()
  const { open } = useWidgetStore()
  const { isMobile, currentDeviceType, showHomeAttachments, mediumLandscape } =
    useView()
  const listClosed = useUtilsStore((state) => state.listClosed)
  const { configuration, agentData } = useRetorik()
  const [list, setList] = useState<boolean>()
  const [scrollButtonDisplay, setScrollButtonDisplay] = useState<boolean>(false)
  const [scrollButtonDirection, setScrollButtonDirection] = useState<
    'top' | 'bottom'
  >('bottom')
  const retorikConversationRef = useRef<HTMLDivElement>(null)
  const [preventScroll, setPreventScroll] = useState<boolean>(false)
  const userQuestion = useMemo<RetorikActivity | undefined>(() => {
    if (activities && activities.length > 0 && !isMobile) {
      const lastActivity = activities[activities.length - 1]

      return lastActivity.question.text ? lastActivity.question : undefined
    } else {
      return undefined
    }
  }, [activities])

  const botResponses = useMemo<Array<RetorikActivity> | undefined>(() => {
    if (activities && activities.length > 0 && !isMobile) {
      const lastActivity = activities[activities.length - 1]

      return lastActivity.answers
    } else {
      return undefined
    }
  }, [activities])

  const botEndRef = useRef<HTMLDivElement>(null)

  const activitiesNumberWithResponse = useMemo<number>(() => {
    let number = 0
    activities.forEach((element) => {
      number += element.answers.length + 1
    })
    return number
  }, [activities])
  const [opacityStyle, setOpacityStyle] = useState<string | undefined>('')

  const handleScroll = (): void => {
    if (retorikConversationRef.current) {
      const scrollHeight = (retorikConversationRef.current as HTMLDivElement)
        .scrollHeight
      const scrollTop = (retorikConversationRef.current as HTMLDivElement)
        .scrollTop
      const offsetHeight = (retorikConversationRef.current as HTMLDivElement)
        .offsetHeight
      const clientHeight = (retorikConversationRef.current as HTMLDivElement)
        .clientHeight
      if (scrollHeight > offsetHeight) {
        setScrollButtonDisplay(true)
        if (scrollHeight - scrollTop === clientHeight) {
          setScrollButtonDirection('top')
        } else if (scrollHeight - scrollTop < clientHeight + 10) {
          setScrollButtonDirection('top')
        } else {
          setScrollButtonDirection('bottom')
        }
      }

      const maxScroll = scrollHeight - clientHeight
      const percentage = Math.ceil((scrollTop / maxScroll) * 15)
      setOpacityStyle(
        `linear-gradient(to top, black ${100 - percentage}%, transparent 100%)`
      )
    }
  }

  /**
   * On call :
   *  - scroll to the botEndRef HTML element at the bottom of the view
   */
  const ScrollHandler = (
    to: 'bottom' | 'top',
    from: 'newMessage' | 'button'
  ): void => {
    if (to === 'bottom') {
      setScrollButtonDirection('top')
    } else {
      setScrollButtonDirection('bottom')
    }
    if (
      (!preventScroll || from === 'button') &&
      botEndRef &&
      botEndRef.current !== null &&
      to === 'bottom'
    ) {
      botEndRef.current.scrollIntoView({
        behavior: 'smooth'
      })
    } else if (
      to === 'top' &&
      retorikConversationRef &&
      retorikConversationRef.current
    ) {
      retorikConversationRef.current.children[1].scrollIntoView({
        behavior: 'smooth'
      })
    }
  }

  /**
   * On retorikConversationRef change :
   *  - check if retorikConversationRef.current is defined
   *  - if scrollHeight > clientHeight, call handleScroll
   *  - if scrollHeight <= clientHeight, set opacityStyle state to undefined
   */
  useEffect(() => {
    if (retorikConversationRef.current) {
      if (
        retorikConversationRef.current.scrollHeight >
        retorikConversationRef.current.clientHeight
      ) {
        handleScroll()
      } else {
        setOpacityStyle(undefined)
      }
    }
  }, [retorikConversationRef])

  /**
   * On call :
   *  - set listClosed state to true
   */
  const onCloseList = (): void => {
    setListClosed(true)
  }

  /**
   * On call :
   *  - scroll to the botEndRef HTML element at the bottom of the view
   *  - check if there is an activity in need of List display
   *  - set list state depending on the check's result
   */
  useEffect(() => {
    setPreventScroll(false)

    if (isMobile) {
      const answers =
        activities && activities.length > 0
          ? activities[activities.length - 1].answers
          : []
      setList(
        answers.some((activity: RetorikActivity) => checkListDisplay(activity))
      )
    } else {
      botResponses &&
        setList(
          botResponses.some((activity: RetorikActivity) =>
            checkListDisplay(activity)
          )
        )
    }

    if (botEndRef.current) {
      const timer = setTimeout(() => {
        ScrollHandler('bottom', 'newMessage')
      }, 100)
      return (): void => timer && clearTimeout(timer)
    }
    return (): void => {}
  }, [activitiesNumberWithResponse])

  useEffect(() => {
    if ((listClosed || showHomeAttachments) && botEndRef.current) {
      const timer = setTimeout(() => {
        ScrollHandler('bottom', 'newMessage')
      }, 100)
      return (): void => timer && clearTimeout(timer)
    }

    return (): void => {}
  }, [listClosed, showHomeAttachments])

  return (
    <>
      {(userQuestion || botResponses || isMobile) &&
        (list && !listClosed ? (
          isMobile ? (
            <ListTemplate
              activity={activities[activities.length - 1].answers.find(
                (activity: RetorikActivity) => checkListDisplay(activity)
              )}
              onClose={onCloseList}
            />
          ) : (
            <ListTemplate
              activity={botResponses?.find((activity: RetorikActivity) =>
                checkListDisplay(activity)
              )}
              onClose={onCloseList}
            />
          )
        ) : (
          <div
            className={`rf-relative rf-max-h-full ${
              isMobile && 'rf-h-full'
            } rf-bg-textModePanelBackground ${
              mediumLandscape ? 'rf-col-start-4' : 'rf-col-start-5'
            } vertical:rf-col-start-1 rf-col-span-full vertical:rf-w-full rf-row-start-4 rf-row-end-13 large-vertical:rf-row-start-7 large-vertical:rf-row-span-6 rf-self-start rf-overflow-hidden
            vertical:rf-row-start-1 vertical:rf-row-span-full vertical:rf-overflow-x-auto rf-justify-self-center large:rf-w-3/5 rf-flex rf-flex-col large:rf-rounded`}
          >
            {isMobile && (
              <ConversationHeader
                agentName={agentData?.name}
                companyName={configuration?.companyName}
                subCompanyMessage={configuration?.subCompanyMessage}
                imageUrl={agentData?.portrait}
              />
            )}
            <div
              ref={retorikConversationRef}
              id='retorik-conversation'
              className={`rf-relative rf-max-h-full rf-bg-textModePanelBackground rf-py-4 rf-overflow-y-scroll rf-scrollbar-thin ${
                isMobile && 'rf-self-auto rf-mb-26 rf-h-full'
              }
              vertical:rf-overflow-x-auto rf-justify-self-center rf-flex rf-flex-col rf-text-size-auto rf-text-trueblack rf-shadow-[3px_3px_6px_#00000029]`}
              style={{
                WebkitMaskImage:
                  currentDeviceType === DeviceType.mobile
                    ? opacityStyle
                    : undefined
              }}
              onScroll={handleScroll}
            >
              <ScrollButton
                direction={scrollButtonDirection}
                display={isMobile && scrollButtonDisplay && open}
                ScrollHandler={ScrollHandler}
                setPreventScroll={setPreventScroll}
                className='rf-fixed rf-z-ui rf-bottom-40 rf-right-6 rf-w-9 rf-h-9 rf-cursor-pointer rf-bg-[#00000050] rf-p-[7px_12px] rf-rounded-[20px] rf-flex'
              />
              {isMobile ? (
                <MultipleActivites history={activities} />
              ) : (
                <SingleMessage
                  userQuestion={userQuestion}
                  botResponses={botResponses}
                />
              )}
              <div ref={botEndRef} />
            </div>
          </div>
        ))}
    </>
  )
}

interface SingleMessageProps {
  userQuestion: RetorikActivity | undefined
  botResponses: Array<RetorikActivity> | undefined
  userClassName?: string
}
const SingleMessage = ({
  userQuestion,
  botResponses,
  userClassName = ''
}: SingleMessageProps): JSX.Element => {
  const locale = useLocaleStore((state) => state.locale)
  const { isMobile, showHomeAttachments } = useView()

  const userQuestionTime = userQuestion
    ? userQuestion.localTimestamp
      ? new Date(userQuestion.localTimestamp)
      : userQuestion.timestamp
      ? new Date(userQuestion.timestamp)
      : new Date()
    : undefined

  return isMobile || !showHomeAttachments ? (
    <React.Fragment>
      {userQuestion && userQuestion.text && (
        <div
          id='retorik-userQuestion'
          className={`rf-text-textModePanelConversationUser rf-animate-slideBottom rf-flex rf-flex-col rf-justify-start rf-text-size-auto rf-gap-2 ${userClassName}`}
          key={userQuestion.id}
        >
          {/* Time */}
          {isMobile && userQuestionTime && (
            <div className='rf-px-4 rf-text-[#9A9A9A]'>
              {userQuestionTime.toLocaleString(locale, {
                hour: 'numeric',
                minute: 'numeric'
              })}
            </div>
          )}

          {/* Text */}
          <div className='rf-px-4'>{userQuestion.text}</div>
        </div>
      )}

      {botResponses && (
        <div
          id='retorik-botResponses'
          className={`${
            userQuestion && 'rf-pt-4'
          } rf-flex rf-flex-col rf-text-size-auto rf-gap-2`}
        >
          {botResponses.map((activity, key) => {
            const answerTime = activity.localTimestamp
              ? new Date(activity.localTimestamp)
              : activity.timestamp
              ? new Date(activity.timestamp)
              : new Date()

            return (
              <React.Fragment key={key}>
                <BotMessageActivity
                  activity={activity}
                  answerTime={answerTime}
                  isMobile={isMobile}
                  mappingKey={key}
                  locale={locale}
                />
              </React.Fragment>
            )
          })}
        </div>
      )}
    </React.Fragment>
  ) : (showHomeAttachments as any).attachments?.length > 0 ? (
    <div className='rf-pt-4 rf-flex rf-flex-col rf-text-size-auto rf-gap-2'>
      <Attachments activity={showHomeAttachments} />
    </div>
  ) : (
    <></>
  )
}

interface MultipleActivitesProps {
  history: Array<HistoryActivity> | undefined
}
const MultipleActivites = ({
  history
}: MultipleActivitesProps): JSX.Element => {
  const { showHomeAttachments } = useView()

  return (
    <>
      {history &&
        history.map((history: HistoryActivity, index: number) => {
          return (
            <React.Fragment key={index}>
              <SingleMessage
                userClassName='rf-pt-3'
                userQuestion={history.question}
                botResponses={history.answers}
              />
            </React.Fragment>
          )
        })}
      {showHomeAttachments &&
        (showHomeAttachments as any).attachments?.length > 0 && (
          <div className='rf-pt-4 rf-flex rf-flex-col rf-text-size-auto rf-gap-2'>
            <Attachments activity={showHomeAttachments} />
          </div>
        )}
    </>
  )
}

const BotMessageActivity = ({
  isMobile,
  answerTime,
  locale,
  activity,
  mappingKey
}): JSX.Element => {
  return (
    <div
      className={`${
        mappingKey > 0 && 'rf-pt-2'
      } rf-text-textModePanelConversationBot rf-animate-slideBottom rf-flex rf-flex-col rf-items-start rf-gap-2`}
    >
      {/* Time */}
      {isMobile && (
        <div className='rf-px-4 rf-text-[#9A9A9A]'>
          {answerTime.toLocaleString(locale, {
            hour: 'numeric',
            minute: 'numeric'
          })}
        </div>
      )}

      {/* Text */}
      {activity.htmlText ? (
        <div
          className='rf-px-4'
          dangerouslySetInnerHTML={{
            __html: activity.htmlText
          }}
        />
      ) : activity.text ? (
        <div className='rf-px-4'>
          {getTextWithoutSpeechMarkdown(activity.text)}
        </div>
      ) : (
        <React.Fragment />
      )}

      {/* Attachments */}
      {!!activity?.attachments?.length && !checkListDisplay(activity) && (
        <div
          className={`rf-w-full ${
            activity.attachments.length <= 1 && 'rf-px-4'
          } rf-flex rf-flex-col rf-gap-2`}
        >
          <Attachments activity={activity} />
        </div>
      )}
    </div>
  )
}

export { ConversationPanel }
