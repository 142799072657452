import React from 'react'
import { AvailableViews } from '../../../models/enums'
import translation from '../../../translations/views.json'
import { useLocaleStore } from '../../Contexts/localeStore'
import * as Icons from '../../Icons/ViewIcons'

interface MobileIconAndTitleProps {
  subView: number
  className?: string
}

const iconClassname = 'rf-h-8 rf-w-8'

const MobileIconAndTitle = ({
  subView,
  className
}: MobileIconAndTitleProps): JSX.Element => {
  const { locale } = useLocaleStore()

  return (
    <div
      className={`large:rf-hidden rf-py-2 rf-flex rf-flex-row rf-items-end ${className}`}
    >
      {/* Icon */}
      {subView === AvailableViews.weather ? (
        <Icons.WeatherIcon className={iconClassname} />
      ) : subView === AvailableViews.emergency ? (
        <Icons.EmergencyIcon className={iconClassname} />
      ) : (
        <React.Fragment />
      )}
      {/* Title */}
      <div className='rf-ml-2 rf-capitalize rf-subtitle-size-auto'>
        {translation[locale]?.[subView] || translation['fr-FR'][subView]}
      </div>
    </div>
  )
}

export default MobileIconAndTitle
