import { hooks } from 'botframework-webchat'

const { useActivities } = hooks

const useShowPOI = (): any => {
  const [activities] = useActivities()

  if (activities && activities.length > 0) {
    const lastActivity = activities.slice(-1)[0] as any
    if (
      lastActivity.type === 'event' &&
      lastActivity.name === 'hospitality.ShowPointOfInterest' &&
      lastActivity.value
    ) {
      return lastActivity.value
    }
  }

  return undefined
}

export default useShowPOI
