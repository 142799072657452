import React, { useEffect } from 'react'
import { useMap } from 'react-leaflet'
import type { LatLngTuple } from 'leaflet'
import type { FeatureCollection } from 'geojson'

type MapUserProps = {
  configurationPosition: LatLngTuple
  coordinates: LatLngTuple
  data: FeatureCollection | undefined
}

const MapUser = ({
  configurationPosition,
  coordinates,
  data
}: MapUserProps): JSX.Element => {
  const map = useMap()

  useEffect(() => {
    if (configurationPosition !== coordinates) {
      const coords1: LatLngTuple = [...configurationPosition]
      const coords2: LatLngTuple = [...coordinates]
      if (configurationPosition[0] > coordinates[0]) {
        coords2[0] -= (configurationPosition[0] - coordinates[0]) / 2
      } else {
        coords1[0] -= (coordinates[0] - configurationPosition[0]) / 2
      }

      map.flyToBounds([coords1, coords2])
    } else {
      map.flyToBounds([configurationPosition, coordinates])
    }
  }, [data])

  return <React.Fragment />
}

export default MapUser
