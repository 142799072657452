import React from 'react'
import type { IconDefaultProps } from '../../../models/types'

const ArrowUpIcon = ({ className, color }: IconDefaultProps): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 28 16.01'
      fill={color || 'currentColor'}
      className={className || 'rf-h-6 rf-w-6'}
    >
      <path d='M14 4.83 3.4 15.43a2 2 0 0 1-2.82 0 2.02 2.02 0 0 1 0-2.84l12-12a2 2 0 0 1 2.76-.06l12.08 12.05a2 2 0 0 1-2.83 2.83Z' />
    </svg>
  )
}

export default ArrowUpIcon
