import React, { useEffect, useState, useMemo, useRef } from 'react'
import { useSpeech } from '../Contexts/SpeechContext'
import { createSpeechSynthesisPonyfill } from '@davi/web-speech-cognitive-services/lib/SpeechServices'
import createUtterance from '../../utils/createUtterance'
import RetorikSpeech from './RetorikSpeech'
import {
  useVoiceSelector,
  useMarkActivityAsSpoken,
  useDictateState
} from 'botframework-webchat-api/lib/hooks'
import { useMicrophoneButtonClick } from 'botframework-webchat-component/lib/hooks'
import { Constants } from 'botframework-webchat-core'
import useLastBotActivity from '../../hooks/useLastBotActivity'
import checkLastbotActivity from '../../utils/checkLastbotActivity'
import type { CreateUtteranceParams } from '../../models/speechTypes'
import type { RetorikActivity } from '../../models/activityTypes'
import { useRetorik } from '../Contexts/RetorikContext'
import { useView } from '../Contexts/ViewContext'
import { useSpeechCancelStore, setCancel } from '../Contexts/speechCancelStore'

const {
  DictateState: { WILL_START }
} = Constants

const SpeechManager = (): JSX.Element | null => {
  const {
    setSpeaking,
    currentPlaying,
    setCurrentPlaying,
    currentReplyToId,
    setCurrentReplyToId,
    queuedActivities,
    setQueuedActivities,
    endedActivities,
    setEndedActivities,
    ponyfillCredentials,
    muted,
    speechAllowed,
    setBoundaryData
  } = useSpeech()
  const { route } = useView()
  const {
    appAvailable,
    configuration: { preventExpectedInputHint }
  } = useRetorik()
  const { cancel } = useSpeechCancelStore()
  const dictateState = useDictateState()[0]
  const microphoneButtonClick = useMicrophoneButtonClick()
  const [lastBotActivity] = useLastBotActivity()
  const markAsSpoken = useMarkActivityAsSpoken()
  const selectVoice = useVoiceSelector(lastBotActivity)
  const [utterance, setUtterance] = useState<SpeechSynthesisUtterance | null>(
    null
  )
  const [utteranceEnded, setUtteranceEnded] = useState<boolean>(false)
  const [currentActivity, setCurrentActivity] = useState<RetorikActivity>()
  const timerRef: React.MutableRefObject<any> = useRef(null)

  const ponyfill = useMemo(() => {
    return createSpeechSynthesisPonyfill({
      credentials: ponyfillCredentials
    })
  }, [ponyfillCredentials])

  /**
   * On call (used with the 'cancelSpeech' event is fired) :
   *  - set speechCancelStore's cancel state to false
   */
  const cancelSpeech = (): void => {
    setCancel(true)
  }

  /**
   * On component mount :
   *  - attach event listener to 'cancelSpeech' event
   * On component unmount :
   *  - reset the timer
   *  - detach the event listener
   */
  useEffect(() => {
    // Event called from the outside to cancel speech
    document.addEventListener('cancelSpeech', cancelSpeech)

    return (): void => {
      timerRef && clearTimeout(timerRef.current)
      document.removeEventListener('cancelSpeech', cancelSpeech)
    }
  }, [])

  /**
   * On ViewContext's route state change :
   *  - when we toggle from a view to another, we reset the data related to speech
   */
  useEffect(() => {
    setSpeaking(false)
    setBoundaryData([])
    const ended = [...endedActivities]
    if (currentPlaying) {
      markAsSpoken(currentPlaying)
      // Add played activity id to context's endedActivities
      ended.length > 10 && ended.splice(0, 1)
      currentPlaying.id && ended.push(currentPlaying.id)
      setEndedActivities(ended)
    }
    setCurrentPlaying(undefined)
    setQueuedActivities([])
    setCancel(false)
    setUtterance(null)
  }, [route])

  /**
   * On RetorikContext's appAvailable state change :
   *  - appAvailable is set to true after the user interacted with the loader and every needed element is loaded
   *  - no utterance is created while appAvailable isn't true
   *  - if during the waiting time, some activities were queued, when appAvailable comes to true, let's begin creating the utterances and playing them
   */
  useEffect(() => {
    if (appAvailable && queuedActivities.length > 0) {
      setCurrentActivity(queuedActivities[0])
      const params: CreateUtteranceParams = {
        ponyfill: ponyfill,
        selectVoice: selectVoice,
        activity: queuedActivities[0],
        muted: muted
      }
      const queue = [...queuedActivities]
      queue.splice(0, 1)
      setQueuedActivities(queue)
      setUtterance(createUtterance({ ...params }))
    }
  }, [appAvailable])

  /**
   * On speechCancelStore's cancel state change :
   *  - if there is currently non utterance being played, reset cancel state to false
   *  - if an utterance is being played, set it to null to stop playing and prevent data in queue from being played
   *  - setting an utterance to null will trigger the handleUtteranceEnded method automatically
   */
  useEffect(() => {
    cancel && !utterance && setCancel(false)
    cancel && utterance && setUtterance(null)
  }, [cancel])

  /**
   * On lastBotActivity, ponyfill, voicesLoaded states change :
   *  - check if the ponyfill is created and the voices loaded
   *  - if the app is not available yet, put the activity in the queue if it is not yet inside
   *  - NB: an activity can be received once from the directline, but can be found several times in the activities in the botframework
   *  this is because it is processed multiple times if there is something to speak (channelDate empty, then channelData with {speak: true})
   *  - if an utterance is being played, process the new one (do nothing / put it in the queue / stop the current one and play the new one)
   *  - if an utterance has to be created, create it and set the utterance state
   */
  useEffect(() => {
    if (ponyfill) {
      if (lastBotActivity && lastBotActivity.id) {
        let createNewUtterance = false
        if (!appAvailable) {
          if (!currentReplyToId) {
            setCurrentReplyToId(lastBotActivity.replyToId)
            setQueuedActivities([lastBotActivity])
          } else {
            if (currentReplyToId === lastBotActivity.replyToId) {
              let count = 0
              if (queuedActivities.length > 0) {
                queuedActivities.forEach((activity: RetorikActivity) => {
                  if (activity.id === lastBotActivity.id) {
                    count++
                  }
                })
              }

              if (count === 0) {
                setQueuedActivities([...queuedActivities, lastBotActivity])
              }
            } else {
              setCurrentReplyToId(lastBotActivity.replyToId)
              setQueuedActivities([lastBotActivity])
            }
          }
        }
        // If there is currently an utterance playing, verify if the replyToId is the same, if so queue the new utterance, else change the utterance with the new one
        else if (currentActivity) {
          const returnCode = checkLastbotActivity(
            lastBotActivity,
            currentActivity,
            currentReplyToId,
            queuedActivities,
            endedActivities
          )

          const queue = [...queuedActivities]
          switch (returnCode) {
            case 0:
              break
            case 1:
              // Add the activity to the queue
              queue.push(lastBotActivity)
              setQueuedActivities(queue)
              break
            case 2:
              // Stop current activity and play the new one
              setQueuedActivities([])
              createNewUtterance = true
              break
          }
        } else {
          createNewUtterance = !endedActivities.includes(lastBotActivity.id)
        }

        if (createNewUtterance) {
          setCurrentActivity(lastBotActivity)
          setCurrentReplyToId(lastBotActivity.replyToId)
          const params: CreateUtteranceParams = {
            ponyfill: ponyfill,
            selectVoice: selectVoice,
            activity: lastBotActivity,
            muted: muted
          }

          setUtterance(createUtterance({ ...params }))
        }
      }
    }
  }, [lastBotActivity, ponyfill])

  /**
   * On utteranceEnded state change :
   *  - if the state is true, an utterance just ended, so the steps are :
   *  - call the handleEnded method
   *  - set utteranceEnded state to false to wait for another utterance end
   */
  useEffect(() => {
    if (utteranceEnded) {
      handleEnded()
      setUtteranceEnded(false)
    }
  }, [utteranceEnded])

  /**
   * On call :
   *  - set speaking state depending on the speechAllowed state
   *  - clear timerref timeout
   *  - set currentPlaying adn currentReplyToId states after a small delay to prevent make sure that the display will happen after the speech has begun
   */
  const handleUtteranceStart = (): void => {
    setSpeaking(speechAllowed)
    timerRef && clearTimeout(timerRef.current)
    timerRef.current = setTimeout(() => {
      setCurrentPlaying(currentActivity)
    }, 10)
  }

  /**
   * On call :
   *  - set speaking state to false
   *  - set utteranceEnded state to true
   */
  const handleUtteranceEnd = (): void => {
    setSpeaking(false)
    setUtteranceEnded(true)
  }

  /**
   * On call :
   *  - check if opening the microphone by using inputHint: "expectingInput" is not disabled
   *  - check if the current dictate state is WILL_START
   *  - if all conditions are fulfilled, call microphoneButtonClick hook
   *  - if the microphone isn't allowed in the navigator, the method will fail
   */
  const checkDictateState = (): void => {
    !preventExpectedInputHint &&
      dictateState === WILL_START &&
      microphoneButtonClick()
  }

  /**
   * On call :
   *  - update the states related to the ende activity
   *  - check if the end of the utterance was called by a cancel or not
   *  - if it wasn't a cancel, deal with the queue
   *  - if this was the last activity of the queue, check if the microphone has to be opened automatically (inputHint = expectingInput)
   */
  const handleEnded = (): void => {
    let nextUtterance: SpeechSynthesisUtterance | null = null
    const ended = [...endedActivities]
    if (currentPlaying) {
      // Add played activity id to context's endedActivities
      ended.length > 10 && ended.splice(0, 1)
      currentPlaying.id && ended.push(currentPlaying.id)
    }
    setCurrentPlaying(undefined)
    setEndedActivities(ended)
    // Check if the audio ended because of a cancel call
    if (cancel) {
      setQueuedActivities([])
      setCancel(false)
    } else {
      // Launch next activity in the queue and remove it from the queue
      if (queuedActivities.length > 0) {
        setCurrentActivity(queuedActivities[0])
        const params: CreateUtteranceParams = {
          ponyfill: ponyfill,
          selectVoice: selectVoice,
          activity: queuedActivities[0],
          muted: muted
        }
        nextUtterance = createUtterance({ ...params })
        const queue = [...queuedActivities]
        queue.splice(0, 1)
        setQueuedActivities(queue)
      } else {
        checkDictateState()
      }
    }

    setUtterance(nextUtterance)
  }

  return appAvailable ? (
    utterance ? (
      <div id='rf-retorik-speech'>
        <RetorikSpeech
          ponyfill={ponyfill}
          utterance={utterance}
          onStart={handleUtteranceStart}
          onError={handleUtteranceEnd}
          onEnd={handleUtteranceEnd}
        />
      </div>
    ) : null
  ) : (
    <div id='rf-retorik-speech'>
      <RetorikSpeech ponyfill={ponyfill} appAvailable={appAvailable} />
    </div>
  )
}

export default SpeechManager
