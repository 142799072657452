import React, { useState, useEffect } from 'react'
import type { WithChildren } from '../../models/utils'

type SwiperProps = WithChildren<{
  direction: 'horizontal' | 'vertical'
  onSwiping: (distance: number) => void
  onSwipeLeft: (distance: number, elapsedTime: number) => void
  onSwipeRight: (distance: number, elapsedTime: number) => void
  onSwipeTop: (distance: number, elapsedTime: number) => void
  onSwipeBottom: (distance: number, elapsedTime: number) => void
  className?: string
}>

// The required distance between touchStart and touchEnd to be detected as a swipe
const minSwipeDistance = 30

const Swiper = ({
  direction,
  onSwiping,
  onSwipeLeft,
  onSwipeRight,
  onSwipeTop,
  onSwipeBottom,
  className,
  children
}: SwiperProps): JSX.Element => {
  const [moveStart, setMoveStart] = useState<number | null>(null)
  const [moveEnd, setMoveEnd] = useState<number | null>(null)
  const [timeStart, setTimeStart] = useState<number>(0)

  /**
   * On moveEnd state change :
   *  - set swiping distance to moveStart - moveEnd
   */
  useEffect(() => {
    if (moveStart && moveEnd) {
      const distance = moveStart - moveEnd
      onSwiping(distance)
    }
  }, [moveEnd])

  /**
   * On PointerDown event fire :
   *  - set timeStart state to current timestamp
   *  - reset moveEnd state to null to prevent activation on single touch
   *  - set moveStart state with the current X or Y position depending on the direction prop
   * @param e : React.PointerEvent
   */
  const onPointerDown = (e: React.PointerEvent): void => {
    setTimeStart(Date.now())
    setMoveEnd(null)
    setMoveStart(direction === 'horizontal' ? e.clientX : e.clientY)
  }

  /**
   * On PointerMove event fire :
   *  - set moveEnd state with the current X or Y position depending on the direction prop
   * @param e : React.PointerEvent
   */
  const onPointerMove = (e: React.PointerEvent): void => {
    setMoveEnd(direction === 'horizontal' ? e.clientX : e.clientY)
  }

  /**
   * On PointerUp event fire :
   *  - calculate the distance between move start and end
   *  - prevent default behaviour and propagation of the event if the distance is large enough (prevent unwanted touch/click at the end of a slide)
   *  - call the parent method depending on the distance and the direction, or reset position if the distance is too small
   *  - reset moveStart state to null
   */
  const onPointerUp = (e: React.PointerEvent): void => {
    if (moveStart && moveEnd) {
      const elapsedTime = Date.now() - timeStart
      const distance = moveStart - moveEnd
      if (distance > minSwipeDistance) {
        e.preventDefault()
        e.stopPropagation()
        direction === 'horizontal'
          ? onSwipeLeft(distance, elapsedTime)
          : onSwipeTop(distance, elapsedTime)
      } else if (distance < -minSwipeDistance) {
        e.preventDefault()
        e.stopPropagation()
        direction === 'horizontal'
          ? onSwipeRight(distance, elapsedTime)
          : onSwipeBottom(distance, elapsedTime)
      } else {
        onSwiping(0)
      }
    }

    setMoveStart(null)
  }

  return (
    <div
      className={`rf-relative rf-w-full rf-overflow-hidden ${className}`}
      onPointerDown={onPointerDown}
      onPointerUp={onPointerUp}
      onPointerMove={onPointerMove}
      onPointerLeave={onPointerUp}
    >
      {children}
    </div>
  )
}

export default Swiper
