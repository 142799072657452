import React from 'react'
import type { IconDefaultProps } from '../../../models/types'

const ChevronRightIcon = ({
  className,
  color
}: IconDefaultProps): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className || 'rf-h-6 rf-w-4'}
      viewBox='0 0 10.131 17.719'
    >
      <path
        d='M8.863,7.077l6.7-6.705a1.261,1.261,0,0,1,1.788,0,1.277,1.277,0,0,1,0,1.794L9.76,9.762A1.264,1.264,0,0,1,8.014,9.8L.369,2.171A1.266,1.266,0,0,1,2.158.377Z'
        transform='translate(0 17.719) rotate(-90)'
        fill={color || '#818181'}
      />
    </svg>
  )
}

export default ChevronRightIcon
