{
  "_.comment": "The content of this file is contributed by the community.",
  "ACCESS_KEY_ALT": "Alt Shift $1",
  "ACCESS_KEY_MAC_ALT": "Ctrl Option $1",
  "ACTIVITY_BOT_ATTACHED_ALT": "Bot 嘅附件：",
  "ACTIVITY_BOT_SAID_ALT": "Bot $1 話：",
  "ACTIVITY_ERROR_BOX_TITLE": "錯嘅信息",
  "ACTIVITY_INTERACTIVE_FOOTNOTE_ALT": "襟呢度前往信息入面嘅項目。",
  "ACTIVITY_INTERACTIVE_REASON_INTERACTIVE_CONTENT_ALT": "信息可以互動。",
  "ACTIVITY_INTERACTIVE_REASON_LINK_ALT": "信息入面有網頁連結。",
  "ACTIVITY_INTERACTIVE_REASON_SEND_FAILED_ALT": "傳送唔倒。",
  "ACTIVITY_NUM_ATTACHMENTS_FEW_ALT": "$1 件附件。",
  "ACTIVITY_NUM_ATTACHMENTS_MANY_ALT": "$1 件附件。",
  "ACTIVITY_NUM_ATTACHMENTS_ONE_ALT": "一件附件。",
  "ACTIVITY_NUM_ATTACHMENTS_OTHER_ALT": "$1 件附件。",
  "ACTIVITY_NUM_ATTACHMENTS_TWO_ALT": "兩件附件。",
  "ACTIVITY_STATUS_SEND_FAILED_RETRY": "傳送唔倒。[再嚟一次][RETRY]。",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENDING": "而家傳送緊",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENT_AT": "送出嘅時間：$1",
  "ACTIVITY_STATUS_TIMESTAMP_JUST_NOW": "啱啱",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_HOUR_AGO": "一個鐘前",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_MINUTE_AGO": "一分鐘前",
  "ACTIVITY_STATUS_TIMESTAMP_TODAY": "今日",
  "ACTIVITY_STATUS_TIMESTAMP_YESTERDAY": "尋日",
  "ACTIVITY_YOU_ATTACHED_ALT": "你嘅附件：",
  "ACTIVITY_YOU_SAID_ALT": "你話：",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_PARSE": "Adaptive Card 解析出事",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_RENDER": "Adaptive Card render 出事",
  "ATTACHMENT_AUDIO": "一段錄音",
  "ATTACHMENT_CARD": "一張卡：$1 $2 $3",
  "ATTACHMENT_FILE": "一個檔案：$1",
  "ATTACHMENT_IMAGE": "一幅圖",
  "ATTACHMENT_TEXT": "一段文字：$1",
  "ATTACHMENT_VIDEO": "一段錄像",
  "CAROUSEL_FLIPPER_NEXT_ALT": "後",
  "CAROUSEL_FLIPPER_PREVIOUS_ALT": "前",
  "CONNECTIVITY_STATUS_ALT_CONNECTED": "接駁到",
  "CONNECTIVITY_STATUS_ALT_CONNECTING": "接駁緊…",
  "CONNECTIVITY_STATUS_ALT_FATAL": "接駁唔倒。",
  "CONNECTIVITY_STATUS_ALT_RECONNECTING": "網絡暫時斷咗，試緊再駁返…",
  "CONNECTIVITY_STATUS_ALT_RENDER_ERROR": "Render 出事，請睇下 console 或者同 bot 開發人員聯絡。",
  "CONNECTIVITY_STATUS_ALT_SLOW_CONNECTION": "接駁嘅時間比平時長。",
  "CONNECTIVITY_STATUS_ALT": "接駁情況：$1",
  "FILE_CONTENT_ALT": "'$1'",
  "FILE_CONTENT_DOWNLOADABLE_ALT": "下載檔案 '$1'",
  "FILE_CONTENT_DOWNLOADABLE_WITH_SIZE_ALT": "下載檔案 '$1' 檔案大小 $2",
  "FILE_CONTENT_WITH_SIZE_ALT": "'$1' 檔案大小 $2",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_BODY": "Enter 掣",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_HEADER": "前往信息入面嘅項目",
  "KEYBOARD_HELP_CHAT_HISTORY_HEADER": "聊天記錄",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_BODY": "Escape 掣",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_HEADER": "離開信息",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_BODY": "Tab 掣\nShift 加 tab 掣",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_HEADER": "喺信息入面揀項目",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_BODY": "向上箭咀\n向下箭咀",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_HEADER": "揀信息",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_BODY": "Enter 掣",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_HEADER": "執行動作",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_BODY": "Tab 掣\nShift 加 tab 掣",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_HEADER": "喺聊天視窗入面揀項目",
  "KEYBOARD_HELP_CHAT_WINDOW_HEADER": "聊天視窗",
  "KEYBOARD_HELP_CLOSE_BUTTON_ALT": "閂咗佢",
  "KEYBOARD_HELP_HEADER": "鍵盤控制",
  "MARKDOWN_EXTERNAL_LINK_ALT": "喺新嘅視窗開啟外部連結。",
  "RECEIPT_CARD_TAX": "稅",
  "RECEIPT_CARD_TOTAL": "總共",
  "RECEIPT_CARD_VAT": "消費稅",
  "SEND_BOX_IS_EMPTY_TOOLTIP_ALT": "唔可以傳送空白嘅訊息。",
  "SPEECH_INPUT_LISTENING": "聽緊你講嘢…",
  "SPEECH_INPUT_MICROPHONE_BUTTON_CLOSE_ALT": "閂咪",
  "SPEECH_INPUT_MICROPHONE_BUTTON_OPEN_ALT": "開咪",
  "SPEECH_INPUT_STARTING": "開始緊…",
  "SUGGESTED_ACTIONS_FLIPPER_NEXT_ALT": "後",
  "SUGGESTED_ACTIONS_FLIPPER_PREVIOUS_ALT": "前",
  "SUGGESTED_ACTIONS_LABEL_ALT": "建議㩒嘅掣",
  "TEXT_INPUT_ALT": "訊息輸入盒",
  "TEXT_INPUT_PLACEHOLDER": "輸入你嘅訊息",
  "TEXT_INPUT_SEND_BUTTON_ALT": "傳送",
  "TEXT_INPUT_SPEAK_BUTTON_ALT": "講嘢",
  "TEXT_INPUT_UPLOAD_BUTTON_ALT": "上載檔案",
  "TOAST_ACCORDION_FEW": "$1 項通知：襟呢度睇詳情",
  "TOAST_ACCORDION_MANY": "$1 項通知：襟呢度睇詳情",
  "TOAST_ACCORDION_OTHER": "$1 項通知：襟呢度睇詳情",
  "TOAST_ACCORDION_TWO": "兩項通知：襟呢度睇詳情",
  "TOAST_ALT_ERROR": "錯誤：$1",
  "TOAST_ALT_INFO": "資訊：$1",
  "TOAST_ALT_SUCCESS": "成功：$1",
  "TOAST_ALT_WARN": "警告：$1",
  "TOAST_DISMISS_BUTTON": "閂咗佢",
  "TOAST_TITLE_ALT": "通知",
  "TRANSCRIPT_ARIA_LABEL_ALT": "聊天記錄，襟方向箭咀嚟瀏覽。",
  "TRANSCRIPT_ARIA_ROLE_ALT": "聊天記錄",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_LABEL_ALT": "信息可以互動，襟 shift tab 兩三下去聊天記錄，再襟個信息前往佢入面嘅項目。",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_WITH_LINKS_LABEL_ALT": "信息入面有網頁連結，襟 shift tab 兩三下去聊天記錄，再襟個信息前往佢入面嘅項目。",
  "TRANSCRIPT_LIVE_REGION_SEND_FAILED_ALT": "有信息傳送唔倒。",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_LABEL_ALT": "信息有建議㩒嘅掣，可以襟 shift tab 兩三下揀佢哋。",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_WITH_ACCESS_KEY_LABEL_ALT": "信息有建議㩒嘅掣，可以襟 $1 揀佢哋。",
  "TRANSCRIPT_MORE_MESSAGES": "更多訊息",
  "TRANSCRIPT_NEW_MESSAGES": "新訊息",
  "TRANSCRIPT_TERMINATOR_TEXT": "聊天記錄結束",
  "TYPING_INDICATOR_ALT": "對方打緊字"
}
