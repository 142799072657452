import React, { useEffect, useRef, useState } from 'react'
import type { Media } from '../../models/attachmentTypes'
import { ChevronLeftIcon, ChevronRightIcon } from '../Icons/ChevronIcons'

interface CarouselProps {
  className?: string
  medias: Array<Media>
  controls?: boolean
  timer?: number
  color?: string
  maxHeight?: string | number
}

/**
 * Media types enum
 */
enum MediaTypes {
  image = 1,
  video
}

const images = ['png', 'gif', 'jpg', 'jpeg']
const videos = ['mp4', 'webm', '3gp', 'ogg']

const Carousel = ({
  className,
  medias,
  controls,
  timer,
  color,
  maxHeight
}: CarouselProps): JSX.Element => {
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const [currentMedia, setCurrentMedia] = useState<Media>()
  const timerRef: React.MutableRefObject<any> = useRef(null)

  /**
   * On medias state change :
   *  - clear timerRef interval if set
   *  - call startNewMedia to reset medias
   *  - if there are more than 1 media, set a timeout and launch it
   * On component unmount :
   *  - clear timerRef interval if set
   */
  useEffect(() => {
    // Clear interval
    timerRef.current && clearInterval(timerRef.current)

    // Call the reset function to set currentIndex and currentMedia states
    startNewMedias()

    // Launch timer only if there are more than 1 media
    if (medias.length > 1 && timer !== 0) {
      timerRef.current = setTimeout(() => {
        handleMore()
      }, timer || 3000)
    }

    return (): void => timerRef.current && clearTimeout(timerRef.current)
  }, [medias])

  /**
   * On currentIndex state change :
   *  - check type of media in medias array at current index by calling checkType method
   *  - set currentMedia state
   */
  useEffect(() => {
    if (currentIndex !== undefined) {
      const index = currentIndex % medias.length
      const type = checkType(medias[index].url)
      if (type) {
        setCurrentMedia({
          ...medias[index],
          type: type
        })

        // Reset timer only if there are more than 1 media
        if (medias.length > 1 && timer !== 0) {
          timerRef.current && clearTimeout(timerRef.current)
          timerRef.current = setTimeout(() => {
            handleMore()
          }, timer || 3000)
        }
      }
    }
  }, [currentIndex])

  /**
   * On call :
   *  - check if the currentIndex state is 0
   *  - if so, if we just update this state to 0 it will remain the same and won't change the currentMedia state. We just leave currentIndex as is and set currentMedia state instead.
   *  - otherwise, set the currentIndex state to 0
   */
  const startNewMedias = (): void => {
    if (currentIndex === 0) {
      const type = checkType(medias[0].url)
      if (type) {
        setCurrentMedia({
          ...medias[0],
          type: type
        })
      }
    } else {
      setCurrentIndex(0)
    }
  }

  /**
   * On call :
   *  - retrieve the extension of the media
   *  - return if it's either an image, or a video, or null
   * @param src : string
   * @returns number | null
   */
  const checkType = (src: string): number | null => {
    const extension = src.split('.').slice(-1)[0]
    return images.includes(extension.toLowerCase())
      ? MediaTypes.image
      : videos.includes(extension.toLowerCase())
      ? MediaTypes.video
      : null
  }

  const handleChoice = (choice: number): void => {
    setCurrentIndex(choice)
  }

  const handleLess = (): void => {
    currentIndex === 0
      ? setCurrentIndex(medias.length - 1)
      : setCurrentIndex((currentIndex) => currentIndex - 1)
  }

  const handleMore = (): void => {
    currentIndex === medias.length - 1
      ? setCurrentIndex(0)
      : setCurrentIndex((currentIndex) => currentIndex + 1)
  }

  return currentMedia && currentMedia.type ? (
    <React.Fragment>
      {currentMedia.type === MediaTypes.image ? (
        <img
          className={className}
          src={currentMedia.url}
          alt={currentMedia.alt || 'image'}
          style={{
            maxHeight: maxHeight
          }}
        />
      ) : (
        <video
          className={className}
          style={{
            maxHeight: maxHeight
          }}
        >
          <source src={currentMedia.url} />
        </video>
      )}
      {controls && medias.length > 1 && (
        <React.Fragment>
          {/* Bubbles at the bottom */}
          <div className='rf-absolute rf-bottom-4 rf-left-1/2 -rf-translate-x-1/2 rf-flex rf-flex-row rf-items-center rf-gap-2'>
            {medias.map((media, key) => {
              return (
                <div
                  key={`${media.url}${key}`}
                  className='rf-h-3 rf-w-3 rf-rounded-half rf-border rf-border-truewhite rf-cursor-pointer'
                  onClick={(): void => handleChoice(key)}
                  style={{
                    backgroundColor:
                      currentIndex === key ? color : 'transparent'
                  }}
                />
              )
            })}
          </div>

          {/* Left and right controls */}
          <div
            className='rf-absolute rf-left-4 rf-top-1/2 -rf-translate-y-1/2 rf-cursor-pointer'
            onClick={handleLess}
          >
            <ChevronLeftIcon className='rf-h-6 large:rf-h-8' color='#FFF' />
          </div>
          <div
            className='rf-absolute rf-right-4 rf-top-1/2 -rf-translate-y-1/2 rf-cursor-pointer'
            onClick={handleMore}
          >
            <ChevronRightIcon className='rf-h-6 large:rf-h-8' color='#FFF' />
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  ) : (
    <React.Fragment />
  )
}

export default Carousel
