{
  "_comment": "[CI-LOCKED] Obsah tohoto souboru je uzamčen. Příspěvky jsou vítány, ale ověření změny může být zpožděno.",
  "ACCESS_KEY_ALT": "Alt + Shift + $1",
  "ACCESS_KEY_FOR_MAC_ALT": "Control + Option + $1",
  "ACTIVITY_BOT_SAID_ALT": "Robot $1 řekl:",
  "ACTIVITY_YOU_SAID_ALT": "Řekli jste:",
  "ACTIVITY_BOT_ATTACHED_ALT": "Robot byl připojen:",
  "ACTIVITY_ERROR_BOX_TITLE": "Chybová zpráva",
  "ACTIVITY_INTERACTIVE_FOOTNOTE_ALT": "Kliknutím provedete interakci.",
  "ACTIVITY_INTERACTIVE_REASON_INTERACTIVE_CONTENT_ALT": "Zpráva je interaktivní.",
  "ACTIVITY_INTERACTIVE_REASON_LINK_ALT": "Zpráva obsahuje nejméně jeden odkaz.",
  "ACTIVITY_INTERACTIVE_REASON_SEND_FAILED_ALT": "Odeslání se nezdařilo.",
  "ACTIVITY_YOU_ATTACHED_ALT": "Připojili jste:",
  "ACTIVITY_NUM_ATTACHMENTS_ONE_ALT": "1 příloha",
  "ACTIVITY_NUM_ATTACHMENTS_FEW_ALT": "$1 přílohy",
  "ACTIVITY_NUM_ATTACHMENTS_MANY_ALT": "$1 příloh",
  "ACTIVITY_NUM_ATTACHMENTS_OTHER_ALT": "$1 příloh",
  "ACTIVITY_NUM_ATTACHMENTS_TWO_ALT": "$1 přílohy",
  "ACTIVITY_STATUS_SEND_FAILED_RETRY": "Odeslání se nezdařilo. [Opakovat][RETRY]",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENDING": "Odesílání",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENT_AT": "Odesláno v $1",
  "ACTIVITY_STATUS_TIMESTAMP_JUST_NOW": "Právě teď",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_HOUR_AGO": "Před 1 hodinou",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_MINUTE_AGO": "Před 1 minutou",
  "ACTIVITY_STATUS_TIMESTAMP_TODAY": "Dnes",
  "ACTIVITY_STATUS_TIMESTAMP_YESTERDAY": "Včera",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_PARSE": "Chyba analyzování adaptivní karty",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_RENDER": "Chyba vykreslování adaptivní karty",
  "ATTACHMENT_AUDIO": "Zvukový klip",
  "ATTACHMENT_CARD": "Karta: $1 $2 $3",
  "ATTACHMENT_FILE": "Soubor: $1",
  "ATTACHMENT_IMAGE": "Obrázek",
  "ATTACHMENT_TEXT": "Text: $1",
  "ATTACHMENT_VIDEO": "Videoklip",
  "CAROUSEL_FLIPPER_LEFT_ALT": "Vlevo",
  "CAROUSEL_FLIPPER_RIGHT_ALT": "Vpravo",
  "CONNECTIVITY_STATUS_ALT_CONNECTED": "Připojeno",
  "CONNECTIVITY_STATUS_ALT_CONNECTING": "Připojování…",
  "CONNECTIVITY_STATUS_ALT_FATAL": "Nelze navázat připojení.",
  "CONNECTIVITY_STATUS_ALT_RECONNECTING": "Došlo k přerušení sítě. Znovu se připojuje…",
  "CONNECTIVITY_STATUS_ALT_RENDER_ERROR": "Chyba vykreslování. Zkontrolujte konzolu nebo kontaktujte vývojáře botů.",
  "CONNECTIVITY_STATUS_ALT_SLOW_CONNECTION": "Připojení trvá déle než obvykle.",
  "CONNECTIVITY_STATUS_ALT": "Stav připojení: $1",
  "FILE_CONTENT_ALT": "$1",
  "FILE_CONTENT_DOWNLOADABLE_ALT": "Stáhnout soubor $1",
  "FILE_CONTENT_DOWNLOADABLE_WITH_SIZE_ALT": "Stáhnout soubor $1 o velikosti $2",
  "FILE_CONTENT_WITH_SIZE_ALT": "$1 o velikosti $2",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_BODY": "Enter",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_HEADER": "Přístup k položkám ve zprávě",
  "KEYBOARD_HELP_CHAT_HISTORY_HEADER": "Historie chatu",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_BODY": "Escape",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_HEADER": "Opustit zprávu",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_BODY": "Tabulátor\nShift + tabulátor",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_HEADER": "Pohyb mezi položkami ve zprávě",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_BODY": "Šipka nahoru\nŠipka dolů",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_HEADER": "Pohyb mezi zprávami",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_BODY": "Enter",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_HEADER": "Provedení akce",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_BODY": "Tabulátor\nShift + tabulátor",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_HEADER": "Pohyb mezi položkami v okně chatu",
  "KEYBOARD_HELP_CHAT_WINDOW_HEADER": "Okno chatu",
  "KEYBOARD_HELP_CLOSE_BUTTON_ALT": "Zavřít",
  "KEYBOARD_HELP_HEADER": "Ovládání pomocí klávesnice",
  "MARKDOWN_EXTERNAL_LINK_ALT": "Otevře se v novém okně; externí.",
  "RECEIPT_CARD_TAX": "Daň",
  "RECEIPT_CARD_TOTAL": "Celkem",
  "RECEIPT_CARD_VAT": "DPH",
  "SEND_BOX_IS_EMPTY_TOOLTIP_ALT": "Prázdnou zprávu nelze odeslat.",
  "SPEECH_INPUT_LISTENING": "Naslouchání…",
  "SPEECH_INPUT_MICROPHONE_BUTTON_CLOSE_ALT": "Mikrofon vypnout",
  "SPEECH_INPUT_MICROPHONE_BUTTON_OPEN_ALT": "Mikrofon zapnout",
  "SPEECH_INPUT_STARTING": "Spouštění…",
  "SUGGESTED_ACTIONS_LABEL_ALT": "Navrhované akce",
  "TEXT_INPUT_ALT": "Pole pro zadání zprávy",
  "TEXT_INPUT_PLACEHOLDER": "Zadejte svou zprávu",
  "TEXT_INPUT_SEND_BUTTON_ALT": "Odeslat",
  "TEXT_INPUT_SPEAK_BUTTON_ALT": "Mluvit",
  "TEXT_INPUT_UPLOAD_BUTTON_ALT": "Nahrát soubor",
  "TOAST_ACCORDION_FEW": "$1 oznámení: kliknutím sem zobrazíte podrobnosti.",
  "TOAST_ACCORDION_MANY": "$1 oznámení: kliknutím sem zobrazíte podrobnosti.",
  "TOAST_ACCORDION_OTHER": "$1 oznámení: kliknutím sem zobrazíte podrobnosti.",
  "TOAST_ACCORDION_TWO": "$1 oznámení: kliknutím sem zobrazíte podrobnosti.",
  "TOAST_ALT_ERROR": "Chyba",
  "TOAST_ALT_INFO": "Informace",
  "TOAST_ALT_SUCCESS": "Úspěch",
  "TOAST_ALT_WARN": "Upozornění",
  "TOAST_DISMISS_BUTTON": "Zavřít",
  "TOAST_TITLE_ALT": "Oznámení",
  "TRANSCRIPT_ARIA_LABEL_ALT": "Historie chatu, k navigaci použijte šipkové klávesy.",
  "TRANSCRIPT_ARIA_ROLE_ALT": "historie chatu",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_LABEL_ALT": "Zpráva je interaktivní. Dvojím až trojím stisknutím kláves Shift + tabulátor přejdete do historie chatu. Interakci zahájíte kliknutím na zprávu.",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_WITH_LINKS_LABEL_ALT": "Zpráva obsahuje nejméně jeden odkaz. Dvojím až trojím stisknutím kláves Shift + tabulátor přejdete do historie chatu. Interakci zahájíte kliknutím na zprávu.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_LABEL_ALT": "Zpráva obsahuje navrhované akce. Pokud je chcete vybrat, stiskněte 2 až 3krát klávesy Shift + tabulátor.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_WITH_ACCESS_KEY_LABEL_ALT": "Zpráva obsahuje navrhované akce. Pokud je chcete vybrat, stiskněte klávesu $1.",
  "TRANSCRIPT_LIVE_REGION_SEND_FAILED_ALT": "Zprávu se nepodařilo odeslat.",
  "TRANSCRIPT_MORE_MESSAGES": "Další zprávy",
  "TRANSCRIPT_NEW_MESSAGES": "Nové zprávy",
  "TRANSCRIPT_TERMINATOR_TEXT": "Konec historie chatu",
  "TYPING_INDICATOR_ALT": "Zobrazuje se indikátor psaní",
  "TYPING_INDICATOR_SINGLE_TEXT": "$1 píše.",
  "TYPING_INDICATOR_MULTIPLE_TEXT": "$1 a další píšou."
}