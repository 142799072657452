import React from 'react'
import type { WithChildren } from '../../models/utils'

const Grid = ({ children }: WithChildren): JSX.Element => {
  return (
    <div className='rf-grid rf-grid-cols-2 large:rf-grid-cols-5 large-vertical:rf-grid-cols-3 rf-gap-4 large:rf-gap-6'>
      {React.Children.map(children, (child) => {
        return child
      })}
    </div>
  )
}

export default Grid
