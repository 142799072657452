import React, { useEffect, useRef, useState, useMemo } from 'react'
import { useRetorik } from '../../Contexts/RetorikContext'
import { useView } from '../../Contexts/ViewContext'
import DownloadSheet from './DownloadSheet'
import DownloadQR from './DownloadQR'
import { DeviceType } from '../../../models/enums'

type DetailedTextProps = {
  text?: string
  otherInformations?: Array<{
    title: string
    content: string | Array<string>
  }>
  color?: string
  url?: string
}

const DetailedText = ({
  text,
  otherInformations,
  color,
  url
}: DetailedTextProps): JSX.Element => {
  const { configuration } = useRetorik()
  const { currentDeviceType } = useView()
  const textRef = useRef<HTMLDivElement>(null)
  const [opacityStyle, setOpacityStyle] = useState<string | undefined>('')
  const processedText = useMemo<string>(() => {
    if (text) {
      return text.replaceAll('\n', '<br>')
    }

    return ''
  }, [text])

  /**
   * On textRef change :
   *  - check if textRef.current is defined
   *  - if scrollHeight > clientHeight, call handleScroll
   *  - if scrollHeight <= clientHeight, set opacityStyle state to undefined
   */
  useEffect(() => {
    if (textRef.current) {
      if (textRef.current.scrollHeight > textRef.current.clientHeight) {
        handleScroll()
      } else {
        setOpacityStyle(undefined)
      }
    }
  }, [textRef])

  /**
   * On call :
   *  - check if textRef.current is defined
   *  - set opacityStyle state depending on scroll height and current scrolling
   */
  const handleScroll = (): void => {
    if (textRef.current) {
      const maxScroll =
        textRef.current.scrollHeight - textRef.current.clientHeight
      const percentage = Math.ceil((textRef.current.scrollTop / maxScroll) * 50)
      setOpacityStyle(
        `linear-gradient(to bottom, black ${
          50 + percentage
        }%, transparent 100%)`
      )
    }
  }

  return (
    <div className='rf-flex rf-flex-col large:rf-pr-4 large:rf-min-w-1/2 rf-items-start'>
      {processedText && (
        <div
          ref={textRef}
          className='rf-max-h-[40vh] rf-font-medium rf-overflow-y-scroll rf-mb-4 rf-scroll-pb-8 rf-scrollbar-hidden'
          style={{
            WebkitMaskImage: opacityStyle
          }}
          onScroll={handleScroll}
          dangerouslySetInnerHTML={{ __html: processedText }}
        />
      )}
      {otherInformations && otherInformations.length > 0 && (
        <div className='rf-my-4 rf-flex rf-flex-col rf-justify-start rf-text-lightgray'>
          {otherInformations.map((information, key) => {
            return (
              <React.Fragment key={key}>
                <div
                  className='rf-mt-4'
                  style={{
                    color: color || 'rgb(25, 153, 177)'
                  }}
                >
                  {information.title}
                </div>
                <div>
                  {Array.isArray(information.content)
                    ? information.content.join(' | ')
                    : information.content}
                </div>
              </React.Fragment>
            )
          })}
        </div>
      )}

      {/* Downloading button or Qrcode in landscape mode */}
      {currentDeviceType === DeviceType.landscape &&
        url &&
        (configuration.isUsedOnBorne ? (
          <DownloadQR url={url} />
        ) : (
          <DownloadSheet url={url} />
        ))}
    </div>
  )
}

export default DetailedText
