import React, { useEffect, useState } from 'react'
import { useView } from '../Contexts/ViewContext'
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter'
import translation from '../../translations/inputs.json'
import { useLocaleStore } from '../Contexts/localeStore'
import { DEFAULT_LANGUAGE_DATA } from '../../models/constants'
import { ArrowRightIcon } from '../Icons/ArrowIcons'
import { useUtilsStore } from '../Contexts/utilsStore'
import { CurrentSubView } from '../../models/enums'

interface ActionButtonProps {
  text?: string
  fullWidth: boolean
  position: string
  display: string
  onClick: () => void
}

const ActionButton = ({
  text,
  fullWidth,
  position,
  display,
  onClick
}: ActionButtonProps): JSX.Element => {
  const { currentSubView } = useUtilsStore()
  const { locale } = useLocaleStore()
  const { themeColors } = useView()
  const [style, setStyle] = useState<string>()
  const [hover, setHover] = useState<boolean>(false)

  useEffect(() => {
    // Set width : full or fit
    let temp = fullWidth ? 'rf-w-full' : 'rf-w-fit'
    // Set position if not in full width
    if (!fullWidth && position) {
      switch (position) {
        case 'right':
          temp += ' rf-float-right'
          break
        case 'center':
          temp += ' rf-mx-auto'
          break
        case 'left':
        default:
          break
      }
    }

    setStyle(temp)
  }, [])

  const handleClick = (): void => {
    if (currentSubView !== CurrentSubView.history) {
      setHover(true)
      onClick()
    }
  }

  const handleEnter = (): void => {
    if (currentSubView !== CurrentSubView.history) {
      setHover(true)
    }
  }

  const handleLeave = (): void => {
    if (currentSubView !== CurrentSubView.history) {
      setHover(false)
    }
  }

  return text && display.toLowerCase() === 'link' ? (
    <div
      className={`rf-w-full rf-mt-2 rf-flex rf-justify-start rf-items-center rf-text-cardButtonTextDefault ${
        currentSubView !== CurrentSubView.history && 'rf-cursor-pointer'
      }`}
      onPointerEnter={handleEnter}
      onPointerLeave={handleLeave}
      onClick={handleClick}
    >
      <div
        className={`rf-h-6 rf-w-6 rf-rounded ${
          hover
            ? 'rf-bg-cardButtonBackgroundHover'
            : 'rf-bg-cardButtonBackgroundDefault'
        }`}
      >
        <ArrowRightIcon
          className='rf-h-6 rf-p-1 rf-border rf-rounded rf-border-cardButtonBorderDefault'
          color={
            hover
              ? themeColors.card.button.text.hover
              : themeColors.card.button.text.default
          }
        />
      </div>
      <div className='rf-ml-4'>{capitalizeFirstLetter(text)}</div>
    </div>
  ) : (
    <div
      className={` ${
        currentSubView !== CurrentSubView.history
          ? 'rf-card-button'
          : 'rf-card-button-history'
      } rf-mt-2 ${fullWidth ? 'rf-text-center' : 'rf-text-left'} ${style}`}
      onClick={handleClick}
    >
      {capitalizeFirstLetter(
        text ||
          translation[locale]?.submit ||
          translation[DEFAULT_LANGUAGE_DATA.default].submit
      )}
    </div>
  )
}

export default ActionButton
