import React from 'react'
import type { IconDefaultProps } from '../../../models/types'

const ArrowDownIcon = ({ className, color }: IconDefaultProps): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 28 16.01'
      fill={color || 'currentColor'}
      className={className || 'rf-h-6 rf-w-6'}
    >
      <path d='M14 11.18 24.6.58a2 2 0 0 1 2.82 0 2.02 2.02 0 0 1 0 2.84l-12 12a2 2 0 0 1-2.76.05L.58 3.44A2 2 0 0 1 3.41.6Z' />
    </svg>
  )
}

export default ArrowDownIcon
