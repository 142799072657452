import React from 'react'
import { useView } from '../../Contexts/ViewContext'
import { useLocaleStore } from '../../Contexts/localeStore'
import languageInMotherTongue from '../../../translations/languageCodeToMotherTongue.json'
import * as Icons from '../../Icons/ParameterIcons'
import { ChevronRightIcon } from '../../Icons/ChevronIcons'

interface ParameterButtonProps {
  title: string
  color?: string
  icon: string
  onClick: () => void
}

const ParameterButton = ({
  title,
  color,
  icon,
  onClick
}: ParameterButtonProps): JSX.Element => {
  const { locale } = useLocaleStore()
  const { isMobile } = useView()

  return isMobile ? (
    <button
      className={`rf-w-full rf-py-4 rf-flex rf-flex-row ${
        icon === 'lang' ? 'rf-justify-between' : 'rf-justify-start'
      } rf-items-center rf-border-t rf-border-t-menuBorder rf-capitalize`}
      onClick={onClick}
      aria-label={title}
    >
      {title}
      {icon === 'lang' && (
        <div className='rf-flex rf-flex-row rf-items-center'>
          <div className='rf-mr-3 rf-text-lightgray rf-normal-case'>
            {languageInMotherTongue[locale]}
          </div>
          <ChevronRightIcon className='rf-h-4' />
        </div>
      )}
    </button>
  ) : (
    <button
      className='rf-width-fill-available rf-mx-3 rf-py-2 rf-flex rf-flex-row rf-justify-center rf-items-center rf-rounded-lg'
      style={{
        color: color || '#A4A7AF',
        background: 'linear-gradient(to left top, #F2F3F6, #E5E6EC)',
        boxShadow: '2px 2px 6px #00000029'
      }}
      onClick={onClick}
      aria-label={title}
    >
      {/* Icon */}
      {icon === 'tutorial' ? (
        <Icons.TutorialIcon className='rf-h-3 tactile:rf-h-4' color={color} />
      ) : icon === 'history' ? (
        <Icons.HistoryIcon className='rf-h-3 tactile:rf-h-4' color={color} />
      ) : icon === 'lang' ? (
        <Icons.LanguageIcon className='rf-h-3 tactile:rf-h-4' color={color} />
      ) : (
        <React.Fragment />
      )}

      {/* Title */}
      <div className='rf-ml-1 rf-uppercase'>{title}</div>
    </button>
  )
}

export default ParameterButton
