import React from 'react'
import translation from '../../../translations/menu.json'
import { useLocaleStore } from '../../Contexts/localeStore'
import { BackButton } from '../../Menu/Common'
import MobileIconAndTitle from './MobileIconAndTitle'

interface MobileTopContainerProps {
  subView?: number
  className?: string
  handleBack: () => void
}

const MobileTopContainer = ({
  subView,
  className,
  handleBack
}: MobileTopContainerProps): JSX.Element => {
  const { locale } = useLocaleStore()

  return (
    <React.Fragment>
      <BackButton
        title={translation[locale]?.back || translation['fr-FR'].back}
        className='rf-text-sm'
        handleBack={handleBack}
      />
      {subView && (
        <MobileIconAndTitle subView={subView} className={className} />
      )}
    </React.Fragment>
  )
}

export default MobileTopContainer
