import type { AgentData } from '../models/types'

// Get data from the json file located at the root of the folder at the address agentUrl
export default async (agentUrl): Promise<AgentData> => {
  const responseData = await fetch(`${agentUrl}/manifest.json`)
    .then((data) => {
      return data.json()
    })
    .then((json) => {
      return json
    })
    .catch((error) => {
      console.log(error)
      return null
    })

  // Set urls to retrieve the animations and a portrait of the agent
  if (responseData !== null) {
    responseData.url = agentUrl
    responseData.portrait = agentUrl + '/' + responseData.portrait
  }

  return responseData
}
