import React, { useEffect, useState } from 'react'

const Loader = (props: { errorCode: number }) => {
  const [message, setMessage] = useState<string>()

  /* Error codes
    0: no error
    1: no licence
    2: incorrect licence
    3: no data for this product
    4: outdated licence
  */

  useEffect(() => {
    switch (props.errorCode) {
      case 1:
        setMessage('No licence found')
        break
      case 2:
        setMessage('Invalid licence')
        break
      case 3:
        setMessage('No data found for this product')
        break
      case 4:
        setMessage('Licence expired')
        break
      case 0:
      default:
        setMessage('')
        break
    }
  }, [props.errorCode])

  return (
    <div className='relative h-full w-full flex flex-col justify-center items-center'>
      <div className='mb-8 font-bold text-lg'>{message}</div>
      <div className='border-box border-b border-t border-r animate-spin w-16 h-16 border-4 rounded-full' />
    </div>
  )
}

export default Loader
