import { SpeechMarkdown } from '@davi/speechmarkdown'
import type { CreateUtteranceParams } from '../models/speechTypes'

const buildSSML = (activity, speechMarkdownParser): string => {
  const textParsedAsSSML = speechMarkdownParser.toSSML(
    activity.speak || activity.text
  )

  return (
    `<speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="https://www.w3.org/2001/mstts" xmlns:emo="http://www.w3.org/2009/10/emotionml" xml:lang="${activity.locale}">` +
    textParsedAsSSML +
    '</speak>'
  )
}

export default function createUtterance({
  ponyfill,
  selectVoice,
  activity
}: CreateUtteranceParams): SpeechSynthesisUtterance {
  const { speechSynthesis, SpeechSynthesisUtterance } = ponyfill

  // If there is no text to be read, create an empty utterance that will be put in the array of ended activities
  if (!activity.speak && !activity.text) {
    return new SpeechSynthesisUtterance()
  }
  const voice = selectVoice(speechSynthesis.getVoices(), activity)

  const options = {
    platform: 'microsoft-azure',
    includeSpeakTag: false,
    globalVoiceAndLang: {
      voice: voice.name,
      lang: voice.name.toLowerCase().includes('multilingual')
        ? activity.locale
        : undefined
    }
  }
  const speechMarkdownParser = new SpeechMarkdown(options)

  const utterance = new SpeechSynthesisUtterance(
    buildSSML(activity, speechMarkdownParser)
  )

  return utterance
}
