import {
  studioRetorik,
  studioRetorikDev,
  studioRetorikStaging,
  studioRetorikExperimental
} from '../models/constants'

const processPrefix = (prefix): string => {
  switch (prefix) {
    case 'dev':
      return studioRetorikDev
    case 'staging':
    case 'preview':
      return studioRetorikStaging
    case 'experimental':
      return studioRetorikExperimental
    default:
      return studioRetorik
  }
}

export default processPrefix
