import React from 'react'
import type { IconDefaultProps } from '../../../models/types'

const FavoriteLightIcon = ({ className }: IconDefaultProps): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 56 55'
      className={className || 'rf-h-full'}
    >
      <g transform='translate(0.465 -0.307)'>
        <ellipse
          cx='28'
          cy='27.5'
          rx='28'
          ry='27.5'
          transform='translate(-0.465 0.307)'
          fill='#fff'
        />
        <path
          d='M449.065,485.074a24.475,24.475,0,1,0,24.476,24.476A24.475,24.475,0,0,0,449.065,485.074Zm12.272,31.086a51.307,51.307,0,0,1-9.969,9.625l-2.3,1.659-2.3-1.659a51.425,51.425,0,0,1-9.966-9.625,17.755,17.755,0,0,1-3.474-9.857,10.391,10.391,0,0,1,10.26-10.353h.1a10.28,10.28,0,0,1,5.387,1.52,10.291,10.291,0,0,1,5.39-1.52h.1A10.388,10.388,0,0,1,464.808,506.3,17.75,17.75,0,0,1,461.337,516.16Z'
          transform='translate(-421.48 -482.143)'
          fill='#0c89a2'
        />
        <path
          d='M445.93,489.861h-.056a6.46,6.46,0,0,0-5.39,2.947,6.45,6.45,0,0,0-5.387-2.947h-.059a6.412,6.412,0,0,0-6.353,6.409A13.817,13.817,0,0,0,431.4,503.8a47.5,47.5,0,0,0,9.086,8.752,47.479,47.479,0,0,0,9.09-8.752,13.817,13.817,0,0,0,2.712-7.529A6.413,6.413,0,0,0,445.93,489.861Z'
          transform='translate(-412.899 -472.11)'
          fill='#cb0038'
        />
      </g>
    </svg>
  )
}

export default FavoriteLightIcon
