import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useRetorik } from '../Contexts/RetorikContext'
import { useSpeech } from '../Contexts/SpeechContext'

const Subtitle = (): JSX.Element | null => {
  const { displaySubtitles } = useRetorik()
  const { speechAllowed, boundaryData, speaking } = useSpeech()

  const [sentenceToDisplay, setSentenceToDisplay] = useState<string>('')
  const timer = useRef<NodeJS.Timeout | null>(null)
  const [index, setIndex] = useState<number>(-1)

  // Generate an array of sentences from boundaryData
  const sentences: Array<{ sentence: string; duration: number }> =
    useMemo(() => {
      // If no boundary data is available, return an empty array
      if (!boundaryData || boundaryData.length === 0) {
        setSentenceToDisplay('')
        return []
      }

      // Prepare an array to store sentences and their durations
      const sentencesArray: { sentence: string; duration: number }[] = []
      let currentSentence = ''
      let currentStartTime = boundaryData[0]?.startTime || 0

      // Loop over each boundary data item
      for (let i = 0; i < boundaryData.length; i++) {
        const boundary = boundaryData[i]

        // Prepare a string based on the word at the boundary
        let boundaryString = ''
        if (['.', ',', '...'].includes(boundary.word)) {
          boundaryString = boundary.word
        } else if ([':', ';', '!', '?'].includes(boundary.word)) {
          boundaryString = '\u00A0' + boundary.word
        } else {
          boundaryString = i === 0 ? boundary.word : ' ' + boundary.word
        }

        // Construct a new sentence
        const newSentence = currentSentence + boundaryString

        // If it's the last boundary of the current array or the end of the boundaries (type 'EndBoundary')
        if (
          i === boundaryData.length - 1 ||
          boundary.boundaryType === 'EndBoundary'
        ) {
          // Calculate the sentence duration
          let sentenceDuration = 0
          if (boundary.boundaryType === 'EndBoundary') {
            const previousBoundary = i === 0 ? boundary : boundaryData[i - 1]
            const end = previousBoundary.endTime
            sentenceDuration = (end - currentStartTime) / 10000
          } else {
            sentenceDuration = (boundary.endTime - currentStartTime) / 10000
          }

          // If there is a sentence to push, add it to the array
          if (newSentence.trim() !== '') {
            sentencesArray.push({
              sentence: newSentence,
              duration: sentenceDuration
            })
          }
        } else if (newSentence.length > 50) {
          // If the current word is a punctuation, add it even if it goes beyond 50 characters
          if (boundary.boundaryType === 'PunctuationBoundary') {
            // Calculate the sentence duration
            const sentenceDuration =
              (boundary.endTime - currentStartTime) / 10000

            if (newSentence.trim() !== '') {
              sentencesArray.push({
                sentence: newSentence,
                duration: sentenceDuration
              })
            }

            currentStartTime =
              i === boundaryData.length - 1
                ? boundary.startTime
                : boundaryData[i + 1].startTime
            currentSentence = ''
          } else {
            // Endtime is the one from the previous boundary, or the current one if there isn't
            const previousBoundary = i === 0 ? boundary : boundaryData[i - 1]
            const sentenceDuration =
              (previousBoundary.endTime - currentStartTime) / 10000

            if (newSentence.trim() !== '') {
              sentencesArray.push({
                sentence: currentSentence,
                duration: sentenceDuration
              })
            }

            currentStartTime = boundary.startTime
            currentSentence = boundary.word
          }
        } else {
          // Continue the current sentence
          currentSentence = newSentence
        }
      }

      // Return the array of sentences and their durations
      return sentencesArray
    }, [boundaryData])

  useEffect(() => {
    setIndex(speaking ? 0 : -1)
  }, [speaking])

  useEffect(() => {
    if (index !== -1) {
      // Set the current sentence
      const sentence = sentences[index]
      setSentenceToDisplay(sentence?.sentence || '')

      // Set a new timer to update the index after the sentence's duration
      timer.current = setTimeout(() => {
        setIndex((i) => i + 1)
      }, sentence?.duration || 1000)
    } else {
      if (timer.current) {
        clearTimeout(timer.current)
      }
      setSentenceToDisplay('')
    }
  }, [index])

  return sentenceToDisplay && speechAllowed && displaySubtitles ? (
    <div className='rf-relative rf-col-start-1 vertical:rf-col-span-full rf-col-span-6 rf-row-start-9 rf-row-span-full vertical:rf-row-start-11 vertical:rf-row-span-1 rf-justify-self-center rf-self-center rf-overflow-y-auto rf-scrollbar-thin rf-w-1/2 vertical:rf-w-5/6 rf-max-h-full rf-py-1 large:rf-py-4'>
      <div
        className='rf-relative rf-max-h-full rf-bg-vocalModeSubtitlesBackground rf-text-vocalModeSubtitlesText rf-justify-self-center rf-self-center rf-p-2 large:rf-p-4 rf-text-size-auto rf-text-center'
        id='retorik-subtitles'
      >
        {sentenceToDisplay}
      </div>
    </div>
  ) : null
}

export default Subtitle
