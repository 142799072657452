{
  "fr-FR": {
    "result": "résultat",
    "results": "résultats",
    "all": "tous",
    "filter": "Filtrer",
    "reset": "effacer tout",
    "showresults": "Afficher les résultats",
    "suggestiontitle": "Suggestions de recherches",
    "reminder": "N'oubliez pas,\n je peux répondre à vos questions."
  },
  "en-US": {
    "result": "result",
    "results": "results",
    "all": "all",
    "filter": "Filter",
    "reset": "reset filters",
    "showresults": "Show results",
    "suggestiontitle": "Research suggestions",
    "reminder": "Don't forget,\n I can answer your questions."
  },
  "es-ES": {
    "result": "result",
    "results": "results",
    "all": "all",
    "filter": "Filter",
    "reset": "reset filters",
    "showresults": "Show results",
    "suggestiontitle": "Research suggestions",
    "reminder": "Don't forget,\n I can answer your questions."
  },
  "de-DE": {
    "result": "result",
    "results": "results",
    "all": "all",
    "filter": "Filter",
    "reset": "reset filters",
    "showresults": "Show results",
    "suggestiontitle": "Research suggestions",
    "reminder": "Don't forget,\n I can answer your questions."
  }
}