import root from 'react-shadow'
import React from 'react'
import tailwind from 'bundle-text:../../tailwind.css'
import spiritEngine from 'bundle-text:../../../node_modules/@davi/spirit-engine-sprite/dist/index.css'
import leaflet from 'bundle-text:../../../node_modules/leaflet/dist/leaflet.css'
import { imageFiles } from '../../utils/imageFiles'

// These lines allow correct default icon display, either way they are searched by relative path and not found
import L from 'leaflet'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'

/**
 * STORYBOOK : there is a conflict between parcel and webpack. Here we use bundle-text from parcel and it's not recognized in webpack, that is used in storybook.
 * To prevent conflict, you need to comment the 3 imports unsign 'bundle-text' above, and the 3 'style' tags in the HTML below.
 */

const DefaultIcon = L.icon({
  iconUrl: `${imageFiles.mapArrival}`,
  shadowUrl: iconShadow,
  iconSize: [32, 41],
  iconAnchor: [10, 41]
})
L.Marker.prototype.options.icon = DefaultIcon

const ShadowRoot = ({ children }): JSX.Element => {
  return (
    <root.div
      id='retorik-shadow'
      className='rf-w-full rf-h-full'
      style={{
        position: 'relative'
      }}
    >
      {children}
      <style type='text/css'>{`${tailwind}`}</style>
      <style type='text/css'>{`${spiritEngine}`}</style>
      <style type='text/css'>{`${leaflet}`}</style>
    </root.div>
  )
}

export default ShadowRoot
