import { createRoot } from 'react-dom/client'

const jsxToHtmlElement = (element: any): HTMLElement => {
  const div = document.createElement('div')
  div.style.width = '100%'
  const root = createRoot(div)
  root.render(element)
  return div
}

export { jsxToHtmlElement }
