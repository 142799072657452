/* eslint-disable react/jsx-boolean-value */
import React from 'react'
import { useView } from '../Contexts/ViewContext'
import type { TemplateDefaultProps } from '../../models/types'
import { Depth, DeviceType } from '../../models/enums'
import { Desktop, FullScreenMobile, FullScreenRemote } from '.'
import Logo from '../Common/Logo'
import AbsoluteLargeClosingButton from '../Utils/AbsoluteLargeClosingButton'

const SubMenu = ({
  background,
  onClick,
  children
}: TemplateDefaultProps): JSX.Element => {
  const { currentDeviceType, isMobile } = useView()

  return (
    <React.Fragment>
      {/* Closing button over remote on large displays */}
      {!isMobile && onClick && (
        <AbsoluteLargeClosingButton
          dashboardVisible={true}
          zIndexClassname='rf-z-util'
          onClick={onClick}
        />
      )}

      {currentDeviceType === DeviceType.borne ? (
        <FullScreenRemote
          className='rf-z-dropdown rf-flex rf-flex-col rf-pt-[25vh] rf-pb-[15vh] rf-justify-start rf-items-center rf-shadow-[20px_3px_20px_#00000029] rf-overflow-y-scroll rf-scrollbar-hidden'
          background={background || '#FFFFFF'}
        >
          <Logo />
          {children}
        </FullScreenRemote>
      ) : currentDeviceType === DeviceType.landscape ? (
        <Desktop background={background || '#FFFFFF'}>{children}</Desktop>
      ) : (
        <FullScreenMobile depth={Depth.dropdown}>{children}</FullScreenMobile>
      )}
    </React.Fragment>
  )
}

export default SubMenu
