import React from 'react'
import type { IconDefaultProps } from '../../../models/types'

const DashboardIcon = ({ className, color }: IconDefaultProps): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill={color || 'currentColor'}
      className={className || 'w-6'}
      viewBox='0 0 27.93 16.72'
    >
      <path d='M1.23 2.46a1.23 1.23 0 1 1 0-2.46h25.46a1.23 1.23 0 1 1 0 2.46H1.23ZM1.23 9.59a1.23 1.23 0 1 1 0-2.46h25.46a1.23 1.23 0 1 1 0 2.46H1.23ZM1.23 16.72a1.23 1.23 0 1 1 0-2.46h25.46a1.23 1.23 0 1 1 0 2.46H1.23Z' />
    </svg>
  )
}

export default DashboardIcon
