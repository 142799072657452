import { create } from 'zustand'

interface SpeechCancelStore {
  cancel: boolean
}

export const useSpeechCancelStore = create<SpeechCancelStore>()(() => {
  return {
    cancel: false
  }
})

export const setCancel = (cancel: boolean): void => {
  useSpeechCancelStore.setState({ cancel: cancel })
}
