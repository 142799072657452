import { useState, useEffect } from 'react'
import { useRetorik } from '../components/Contexts/RetorikContext'
import { useView } from '../components/Contexts/ViewContext'
import { DeviceType } from '../models/enums'

export default function useCurrentPagination(): number {
  const { configuration } = useRetorik()
  const { currentDeviceType } = useView()
  const [currentPagination, setCurrentPagination] = useState<number>(10)

  useEffect(() => {
    if (configuration && currentDeviceType) {
      switch (currentDeviceType) {
        case DeviceType.borne:
          setCurrentPagination(configuration.pagination?.borne || 15)
          break
        case DeviceType.landscape:
          setCurrentPagination(configuration.pagination?.landscape || 20)
          break
        case DeviceType.mobile:
        case DeviceType.widget:
          setCurrentPagination(configuration.pagination?.mobile || 12)
          break
        default:
          break
      }
    } else {
      setCurrentPagination(10)
    }
  }, [configuration, currentDeviceType])

  return currentPagination
}
