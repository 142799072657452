import React, { useEffect, useState } from 'react'
import { useUtilsStore } from '../../Contexts/utilsStore'
import { useView } from '../../Contexts/ViewContext'
import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter'
import { checkForData } from '../../../utils/localStorageInputsUtils'
import { CurrentSubView } from '../../../models/enums'
import { FeatherCheckIcon } from '../../Icons/Miscellaneous'

interface CheckboxProps {
  id: string
  choices: any
  split?: boolean
  handleChangeCheckbox: (value: Array<string>) => void
}

const Checkbox = ({
  id,
  choices,
  split,
  handleChangeCheckbox
}: CheckboxProps): JSX.Element => {
  const { themeColors } = useView()
  const { currentSubView } = useUtilsStore()
  const [display, setDisplay] = useState<Array<any>>([])
  const [chosen, setChosen] = useState<Array<string>>(checkForData(id, []))

  /**
   * On choices prop change :
   *  - if choices is defined, fill display state with choices value
   */
  useEffect(() => {
    if (choices) {
      if (split) {
        const nbRows = Math.ceil(choices.length / 2)
        setDisplay([choices.slice(0, nbRows), choices.slice(nbRows)])
      } else {
        setDisplay(choices)
      }
    }
  }, [choices])

  /**
   * On chosen state change :
   *  - call parent's handleChangeCheckbox method
   */
  useEffect(() => {
    handleChangeCheckbox(chosen)
  }, [chosen])

  /**
   * On call :
   *  - check if the component will be rendered in history, if so bypass every interaction
   *  - check for checked / unchecked values and fill chosen state with these
   *  - call parent's handleChangeCheckbox method
   * @param value : string
   */
  const handleChange = (value: string): void => {
    if (currentSubView !== CurrentSubView.history) {
      let tempChosen = [...chosen]
      if (tempChosen.includes(value)) {
        tempChosen = tempChosen.filter((chosenValue) => chosenValue !== value)
      } else {
        tempChosen = [...tempChosen, value]
      }
      setChosen(tempChosen)
    }
  }

  return display && display.length > 0 ? (
    split ? (
      <div className='rf-w-full rf-mt-4 rf-flex rf-flex-row rf-text-size-auto rf-text-white'>
        {/* First column */}
        <div className='rf-w-1/2 rf-pr-2 rf-flex rf-flex-col rf-items-start'>
          {display[0].map((choice, key) => {
            return (
              <div key={key} className='rf-relative rf-mb-3'>
                <label className='rf-w-full rf-flex rf-flex-row rf-items-start'>
                  <input
                    type='checkbox'
                    value={choice.value}
                    checked={chosen.includes(choice.value)}
                    onChange={(): void => handleChange(choice.value)}
                    className='rf-checkbox-custom'
                  />
                  {chosen.includes(choice.value) && (
                    <FeatherCheckIcon
                      className='rf-absolute rf-left-1 rf-top-1 rf-h-4 rf-w-4 large:rf-h-5 large:rf-w-5 large-vertical:rf-h-6 large-vertical:rf-w-6 rf-transition-all'
                      color={
                        themeColors.formInput.inputRadioCheckbox.checked.item
                      }
                    />
                  )}
                  <div className='rf-input-label rf-mr-2 rf-max-w-[calc(100%-2rem)]'>
                    {capitalizeFirstLetter(choice.title)}
                  </div>
                </label>
              </div>
            )
          })}
        </div>

        {/* Second column */}
        <div className='rf-w-1/2 rf-flex rf-flex-col rf-items-start'>
          {display[1].map((choice, key) => {
            return (
              <div key={key} className='rf-relative rf-mb-3'>
                <label className='rf-w-full rf-flex rf-flex-row rf-items-start'>
                  <input
                    type='checkbox'
                    value={choice.value}
                    checked={chosen.includes(choice.value)}
                    onChange={(): void => handleChange(choice.value)}
                    className='rf-checkbox-custom'
                  />
                  {chosen.includes(choice.value) && (
                    <FeatherCheckIcon
                      className='rf-absolute rf-left-1 rf-top-1 rf-h-4 rf-w-4 large:rf-h-5 large:rf-w-5 large-vertical:rf-h-6 large-vertical:rf-w-6 rf-transition-all'
                      color={
                        themeColors.formInput.inputRadioCheckbox.checked.item
                      }
                    />
                  )}
                  <div className='rf-input-label rf-mr-2 rf-max-w-[calc(100%-2rem)]'>
                    {capitalizeFirstLetter(choice.title)}
                  </div>
                </label>
              </div>
            )
          })}
        </div>
      </div>
    ) : (
      <div className='rf-w-full rf-mt-4 rf-flex rf-flex-col rf-items-start rf-text-size-auto rf-text-white'>
        {display.map((choice, key) => {
          return (
            <div key={key} className='rf-relative rf-mb-3'>
              <label className='rf-w-full rf-flex rf-flex-row rf-items-start'>
                <input
                  type='checkbox'
                  value={choice.value}
                  checked={chosen.includes(choice.value)}
                  onChange={(): void => handleChange(choice.value)}
                  className='rf-checkbox-custom'
                />
                {chosen.includes(choice.value) && (
                  <FeatherCheckIcon
                    className='rf-absolute rf-left-1 rf-top-1 rf-h-4 rf-w-4 large:rf-h-5 large:rf-w-5 large-vertical:rf-h-6 large-vertical:rf-w-6 rf-transition-all'
                    color={
                      themeColors.formInput.inputRadioCheckbox.checked.item
                    }
                  />
                )}
                <div className='rf-input-label rf-mr-2 rf-max-w-[calc(100%-2rem)]'>
                  {capitalizeFirstLetter(choice.title)}
                </div>
              </label>
            </div>
          )
        })}
      </div>
    )
  ) : (
    <React.Fragment />
  )
}

export default Checkbox
