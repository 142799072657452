import React from 'react'
import type { IconDefaultProps } from '../../../models/types'

const ArrowLeftIcon = ({ className, color }: IconDefaultProps): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className || 'rf-h-6 rf-w-6'}
      fill='none'
      viewBox='0 0 24 24'
      stroke={color || 'currentColor'}
      strokeWidth={2}
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M11 17l-5-5m0 0l5-5m-5 5h12'
      />
    </svg>
  )
}

export default ArrowLeftIcon
