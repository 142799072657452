import React from 'react'
import { useView } from '../Contexts/ViewContext'
import useCloseButtonTop from '../../hooks/useCloseButtonTop'
import DashboardButton from '../Menu/Remote/DashboardButton'

interface AbsoluteLargeClosingButtonProps {
  dashboardVisible: boolean
  zIndexClassname?: string
  onClick: () => void
}

const AbsoluteLargeClosingButton = ({
  dashboardVisible,
  zIndexClassname,
  onClick
}: AbsoluteLargeClosingButtonProps): JSX.Element => {
  const { isMobile } = useView()
  const top = useCloseButtonTop()

  return isMobile ? (
    <React.Fragment />
  ) : (
    <div
      className={`rf-absolute rf-w-24 rf-flex rf-justify-center rf-right-0 ${zIndexClassname}`}
      style={{
        top: top
      }}
    >
      <DashboardButton dashboardVisible={dashboardVisible} onClick={onClick} />
    </div>
  )
}

export default AbsoluteLargeClosingButton
