const getMicrophonePermission = async (): Promise<boolean> => {
  try {
    return navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        return !!stream
      })
  } catch (error) {
    console.log(error)
    return false
  }
}

export default getMicrophonePermission
