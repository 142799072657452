const getTimeAsString = (time: number | undefined): string => {
  if (typeof time !== 'number') {
    return 'NC'
  }

  if (time < 1) return 'NC'
  if (time < 60) return `${time} s`
  if (time < 3600) return `${Math.ceil(time / 60)} min`
  if (time === 3600) return `1 H`
  if (time > 3600) {
    const hours = Math.floor(time / 3600)
    return `${hours} H ${Math.ceil((time - hours * 3600) / 60)} min`
  }

  return 'NC'
}

export default getTimeAsString
