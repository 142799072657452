{
  "v": "5.7.4",
  "fr": 29.9700012207031,
  "ip": 0,
  "op": 45.0000018328876,
  "w": 256,
  "h": 256,
  "nm": "vibe",
  "ddd": 0,
  "assets": [],
  "layers": [
    {
      "ddd": 0,
      "ind": 1,
      "ty": 4,
      "nm": "Calque de forme 1",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [128, 128, 0],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [0, 0, 0],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [100, 100, 100],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "i": {
                      "x": 0.833,
                      "y": 0.833
                    },
                    "o": {
                      "x": 0.167,
                      "y": 0.167
                    },
                    "t": 0,
                    "s": [
                      {
                        "i": [
                          [30.504, 0],
                          [26.499, -0.285],
                          [23.25, 0],
                          [0, 0],
                          [0, -1],
                          [0, 0],
                          [0, 0],
                          [20.501, 0],
                          [22.747, -0.37]
                        ],
                        "o": [
                          [-29.75, 0],
                          [-23.25, 0.25],
                          [-22.103, 0],
                          [0, 0],
                          [0, 1],
                          [0, 0],
                          [0, 0],
                          [-18.75, 0],
                          [-30.75, 0.5]
                        ],
                        "v": [
                          [3.25, -10.25],
                          [-49.5, 103],
                          [-92.5, 39],
                          [-129, 128.25],
                          [-129.5, 167.5],
                          [128.75, 171.75],
                          [129.75, 127.75],
                          [100.25, 65.5],
                          [54.75, 110]
                        ],
                        "c": true
                      }
                    ]
                  },
                  {
                    "i": {
                      "x": 0.833,
                      "y": 0.833
                    },
                    "o": {
                      "x": 0.167,
                      "y": 0.167
                    },
                    "t": 8,
                    "s": [
                      {
                        "i": [
                          [30.504, 0],
                          [26.499, -0.285],
                          [23.25, 0],
                          [0, 0],
                          [0, -1],
                          [0, 0],
                          [0, 0],
                          [20.501, 0],
                          [22.747, -0.37]
                        ],
                        "o": [
                          [-29.75, 0],
                          [-23.25, 0.25],
                          [-22.103, 0],
                          [0, 0],
                          [0, 1],
                          [0, 0],
                          [0, 0],
                          [-18.75, 0],
                          [-30.75, 0.5]
                        ],
                        "v": [
                          [3.25, 31.25],
                          [-49.5, 103],
                          [-92.5, 81],
                          [-129, 128.25],
                          [-129.5, 167.5],
                          [128.75, 171.75],
                          [129.75, 127.75],
                          [100.25, 18],
                          [54.75, 110]
                        ],
                        "c": true
                      }
                    ]
                  },
                  {
                    "i": {
                      "x": 0.833,
                      "y": 0.833
                    },
                    "o": {
                      "x": 0.167,
                      "y": 0.167
                    },
                    "t": 16,
                    "s": [
                      {
                        "i": [
                          [30.504, 0],
                          [26.499, -0.285],
                          [23.25, 0],
                          [0, 0],
                          [0, -1],
                          [0, 0],
                          [0, 0],
                          [20.501, 0],
                          [22.747, -0.37]
                        ],
                        "o": [
                          [-29.75, 0],
                          [-23.25, 0.25],
                          [-22.103, 0],
                          [0, 0],
                          [0, 1],
                          [0, 0],
                          [0, 0],
                          [-18.75, 0],
                          [-30.75, 0.5]
                        ],
                        "v": [
                          [3.247, 87.636],
                          [-49.5, 62.5],
                          [-92.5, 8.144],
                          [-129, 128.25],
                          [-129.5, 167.5],
                          [128.75, 171.75],
                          [129.75, 10.5],
                          [100.25, 84.403],
                          [54.75, 36.25]
                        ],
                        "c": true
                      }
                    ]
                  },
                  {
                    "i": {
                      "x": 0.833,
                      "y": 0.833
                    },
                    "o": {
                      "x": 0.167,
                      "y": 0.167
                    },
                    "t": 23,
                    "s": [
                      {
                        "i": [
                          [30.504, 0],
                          [26.499, -0.285],
                          [23.25, 0],
                          [0, 0],
                          [0, -1],
                          [0, 0],
                          [0, 0],
                          [20.501, 0],
                          [22.747, -0.37]
                        ],
                        "o": [
                          [-29.75, 0],
                          [-23.25, 0.25],
                          [-22.103, 0],
                          [0, 0],
                          [0, 1],
                          [0, 0],
                          [0, 0],
                          [-18.75, 0],
                          [-30.75, 0.5]
                        ],
                        "v": [
                          [3.249, 51.758],
                          [-49.5, 103],
                          [-92.5, 48.989],
                          [-129, -22],
                          [-129.5, 167.5],
                          [128.75, 171.75],
                          [129.75, 19.75],
                          [100.25, 93.847],
                          [54.75, -2]
                        ],
                        "c": true
                      }
                    ]
                  },
                  {
                    "i": {
                      "x": 0.833,
                      "y": 0.833
                    },
                    "o": {
                      "x": 0.167,
                      "y": 0.167
                    },
                    "t": 31,
                    "s": [
                      {
                        "i": [
                          [30.504, 0],
                          [26.498, -0.285],
                          [23.25, 0],
                          [0, 0],
                          [0, -1],
                          [0, 0],
                          [0, 0],
                          [20.501, 0],
                          [22.747, -0.37]
                        ],
                        "o": [
                          [-29.75, 0],
                          [-23.25, 0.25],
                          [-22.103, 0],
                          [0, 0],
                          [0, 1],
                          [0, 0],
                          [0, 0],
                          [-18.75, 0],
                          [-30.75, 0.5]
                        ],
                        "v": [
                          [3.249, -34.148],
                          [-49.5, 39.75],
                          [-92.5, 90.277],
                          [-129, 128.25],
                          [-129.5, 167.5],
                          [128.75, 171.75],
                          [129.75, 127.75],
                          [100.25, 33.052],
                          [54.75, 110]
                        ],
                        "c": true
                      }
                    ]
                  },
                  {
                    "i": {
                      "x": 0.833,
                      "y": 0.833
                    },
                    "o": {
                      "x": 0.167,
                      "y": 0.167
                    },
                    "t": 38,
                    "s": [
                      {
                        "i": [
                          [30.504, 0],
                          [26.499, -0.285],
                          [23.25, 0],
                          [0, 0],
                          [0, -1],
                          [0, 0],
                          [0, 0],
                          [20.501, 0],
                          [22.747, -0.37]
                        ],
                        "o": [
                          [-29.75, 0],
                          [-23.25, 0.25],
                          [-22.103, 0],
                          [0, 0],
                          [0, 1],
                          [0, 0],
                          [0, 0],
                          [-18.75, 0],
                          [-30.75, 0.5]
                        ],
                        "v": [
                          [3.249, 23.747],
                          [-49.5, -21.5],
                          [-92.5, 115.754],
                          [-129, 62.5],
                          [-129.5, 167.5],
                          [128.75, 171.75],
                          [129.75, 127.75],
                          [100.25, 90.836],
                          [54.75, 110]
                        ],
                        "c": true
                      }
                    ]
                  },
                  {
                    "t": 45.0000018328876,
                    "s": [
                      {
                        "i": [
                          [30.504, 0],
                          [26.499, -0.285],
                          [23.25, 0],
                          [0, 0],
                          [0, -1],
                          [0, 0],
                          [0, 0],
                          [20.501, 0],
                          [22.747, -0.37]
                        ],
                        "o": [
                          [-29.75, 0],
                          [-23.25, 0.25],
                          [-22.103, 0],
                          [0, 0],
                          [0, 1],
                          [0, 0],
                          [0, 0],
                          [-18.75, 0],
                          [-30.75, 0.5]
                        ],
                        "v": [
                          [3.25, -10.25],
                          [-49.5, 103],
                          [-92.5, 39],
                          [-129, 128.25],
                          [-129.5, 167.5],
                          [128.75, 171.75],
                          [129.75, 127.75],
                          [100.25, 65.5],
                          [54.75, 110]
                        ],
                        "c": true
                      }
                    ]
                  }
                ],
                "ix": 2
              },
              "nm": "Tracé 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [1, 1, 1, 1],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 19,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "lc": 1,
              "lj": 1,
              "ml": 4,
              "bm": 0,
              "nm": "Contour 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0, 0, 0, 1],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 19,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fond 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [0, 0],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [0, 0],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [100, 100],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transformer "
            }
          ],
          "nm": "Forme 1",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 45.0000018328876,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 2,
      "ty": 4,
      "nm": "Calque de forme 2",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [128, 128, 0],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [0, 0, 0],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [100, 100, 100],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "i": {
                      "x": 0.833,
                      "y": 0.833
                    },
                    "o": {
                      "x": 0.167,
                      "y": 0.167
                    },
                    "t": 0,
                    "s": [
                      {
                        "i": [
                          [30.504, 0],
                          [26.499, -0.285],
                          [23.25, 0],
                          [0, 0],
                          [0, -1],
                          [0, 0],
                          [0, 0],
                          [20.501, 0],
                          [22.747, -0.37]
                        ],
                        "o": [
                          [-29.75, 0],
                          [-23.25, 0.25],
                          [-22.103, 0],
                          [0, 0],
                          [0, 1],
                          [0, 0],
                          [0, 0],
                          [-18.75, 0],
                          [-30.75, 0.5]
                        ],
                        "v": [
                          [3.25, 104.5],
                          [-49.5, 68.5],
                          [-92.5, 108.5],
                          [-129, 4.75],
                          [-129.5, 167.5],
                          [128.75, 171.75],
                          [129.75, 53],
                          [100.25, 110.5],
                          [54.75, 38.25]
                        ],
                        "c": true
                      }
                    ]
                  },
                  {
                    "i": {
                      "x": 0.833,
                      "y": 0.833
                    },
                    "o": {
                      "x": 0.167,
                      "y": 0.167
                    },
                    "t": 8,
                    "s": [
                      {
                        "i": [
                          [30.504, 0],
                          [26.499, -0.285],
                          [23.25, 0],
                          [0, 0],
                          [0, -1],
                          [0, 0],
                          [0, 0],
                          [20.501, 0],
                          [22.747, -0.37]
                        ],
                        "o": [
                          [-29.75, 0],
                          [-23.25, 0.25],
                          [-22.103, 0],
                          [0, 0],
                          [0, 1],
                          [0, 0],
                          [0, 0],
                          [-18.75, 0],
                          [-30.75, 0.5]
                        ],
                        "v": [
                          [3.25, 48.5],
                          [-49.5, -11.5],
                          [-92.5, 44],
                          [-129, 116],
                          [-129.5, 167.5],
                          [128.75, 171.75],
                          [129.75, 125.5],
                          [100.25, 62.5],
                          [54.749, 95.5]
                        ],
                        "c": true
                      }
                    ]
                  },
                  {
                    "i": {
                      "x": 0.833,
                      "y": 0.833
                    },
                    "o": {
                      "x": 0.167,
                      "y": 0.167
                    },
                    "t": 16,
                    "s": [
                      {
                        "i": [
                          [30.504, 0],
                          [26.499, -0.285],
                          [23.25, 0],
                          [0, 0],
                          [0, -1],
                          [0, 0],
                          [0, 0],
                          [20.501, 0],
                          [22.747, -0.37]
                        ],
                        "o": [
                          [-29.75, 0],
                          [-23.25, 0.25],
                          [-22.103, 0],
                          [0, 0],
                          [0, 1],
                          [0, 0],
                          [0, 0],
                          [-18.75, 0],
                          [-30.75, 0.5]
                        ],
                        "v": [
                          [3.25, 27],
                          [-49.502, 94.547],
                          [-92.5, 55],
                          [-129, -19.068],
                          [-129.5, 167.5],
                          [128.75, 171.75],
                          [129.75, 125],
                          [100.25, 110.5],
                          [54.749, -63.236]
                        ],
                        "c": true
                      }
                    ]
                  },
                  {
                    "i": {
                      "x": 0.833,
                      "y": 0.833
                    },
                    "o": {
                      "x": 0.167,
                      "y": 0.167
                    },
                    "t": 23,
                    "s": [
                      {
                        "i": [
                          [30.504, 0],
                          [26.499, -0.285],
                          [23.25, 0],
                          [0, 0],
                          [0, -1],
                          [0, 0],
                          [0, 0],
                          [20.501, 0],
                          [22.747, -0.37]
                        ],
                        "o": [
                          [-29.75, 0],
                          [-23.25, 0.25],
                          [-22.103, 0],
                          [0, 0],
                          [0, 1],
                          [0, 0],
                          [0, 0],
                          [-18.75, 0],
                          [-30.75, 0.5]
                        ],
                        "v": [
                          [3.25, 70],
                          [-49.501, 1.079],
                          [-92.5, 120.5],
                          [-129, 57.802],
                          [-129.5, 167.5],
                          [128.75, 171.75],
                          [129.75, -0.19],
                          [100.25, 65.5],
                          [54.749, 45.01]
                        ],
                        "c": true
                      }
                    ]
                  },
                  {
                    "i": {
                      "x": 0.833,
                      "y": 0.833
                    },
                    "o": {
                      "x": 0.167,
                      "y": 0.167
                    },
                    "t": 31,
                    "s": [
                      {
                        "i": [
                          [30.504, 0],
                          [26.499, -0.285],
                          [23.25, 0],
                          [0, 0],
                          [0, -1],
                          [0, 0],
                          [0, 0],
                          [20.501, 0],
                          [22.747, -0.37]
                        ],
                        "o": [
                          [-29.75, 0],
                          [-23.25, 0.25],
                          [-22.103, 0],
                          [0, 0],
                          [0, 1],
                          [0, 0],
                          [0, 0],
                          [-18.75, 0],
                          [-30.75, 0.5]
                        ],
                        "v": [
                          [3.25, 22.75],
                          [-49.501, 80.531],
                          [-92.5, 33],
                          [-129, 114.922],
                          [-129.5, 167.5],
                          [128.75, 171.75],
                          [129.75, -17.129],
                          [100.25, 106.25],
                          [54.747, 69.109]
                        ],
                        "c": true
                      }
                    ]
                  },
                  {
                    "i": {
                      "x": 0.833,
                      "y": 0.833
                    },
                    "o": {
                      "x": 0.167,
                      "y": 0.167
                    },
                    "t": 38,
                    "s": [
                      {
                        "i": [
                          [30.504, 0],
                          [26.499, -0.285],
                          [23.25, 0],
                          [0, 0],
                          [0, -1],
                          [0, 0],
                          [0, 0],
                          [20.501, 0],
                          [22.747, -0.37]
                        ],
                        "o": [
                          [-29.75, 0],
                          [-23.25, 0.25],
                          [-22.103, 0],
                          [0, 0],
                          [0, 1],
                          [0, 0],
                          [0, 0],
                          [-18.75, 0],
                          [-30.75, 0.5]
                        ],
                        "v": [
                          [3.25, 104.5],
                          [-49.501, 68.115],
                          [-92.5, 106.017],
                          [-129, 129.414],
                          [-129.5, 167.5],
                          [128.75, 171.75],
                          [129.75, 63.44],
                          [100.25, -1.2],
                          [54.748, 66.851]
                        ],
                        "c": true
                      }
                    ]
                  },
                  {
                    "t": 45.0000018328876,
                    "s": [
                      {
                        "i": [
                          [30.504, 0],
                          [26.499, -0.285],
                          [23.25, 0],
                          [0, 0],
                          [0, -1],
                          [0, 0],
                          [0, 0],
                          [20.501, 0],
                          [22.747, -0.37]
                        ],
                        "o": [
                          [-29.75, 0],
                          [-23.25, 0.25],
                          [-22.103, 0],
                          [0, 0],
                          [0, 1],
                          [0, 0],
                          [0, 0],
                          [-18.75, 0],
                          [-30.75, 0.5]
                        ],
                        "v": [
                          [3.25, 104.5],
                          [-49.5, 68.5],
                          [-92.5, 108.5],
                          [-129, 4.75],
                          [-129.5, 167.5],
                          [128.75, 171.75],
                          [129.75, 53],
                          [100.25, 110.5],
                          [54.75, 38.25]
                        ],
                        "c": true
                      }
                    ]
                  }
                ],
                "ix": 2
              },
              "nm": "Tracé 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [1, 1, 1, 1],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 19,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "lc": 1,
              "lj": 1,
              "ml": 4,
              "bm": 0,
              "nm": "Contour 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0, 0, 0, 1],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 19,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fond 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [0, 0],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [0, 0],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [100, 100],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transformer "
            }
          ],
          "nm": "Forme 1",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 45.0000018328876,
      "st": 0,
      "bm": 0
    }
  ],
  "markers": []
}
