{
  "_comment": "[CI-LOCKED] Sadržaj ove datoteke blokiran je. Doprinosi su dobrodošli, ali provjera valjanosti promjena može biti odgođena.",
  "ACCESS_KEY_ALT": "Alt Shift $1",
  "ACCESS_KEY_FOR_MAC_ALT": "Control Option $1",
  "ACTIVITY_BOT_SAID_ALT": "Bot $1 rekao je:",
  "ACTIVITY_YOU_SAID_ALT": "Vi ste rekli:",
  "ACTIVITY_BOT_ATTACHED_ALT": "Priloženi bot:",
  "ACTIVITY_ERROR_BOX_TITLE": "Poruka o pogrešci",
  "ACTIVITY_INTERACTIVE_FOOTNOTE_ALT": "Kliknite za interakciju.",
  "ACTIVITY_INTERACTIVE_REASON_INTERACTIVE_CONTENT_ALT": "Poruka je interaktivna.",
  "ACTIVITY_INTERACTIVE_REASON_LINK_ALT": "U poruci se nalazi jedna ili više veza.",
  "ACTIVITY_INTERACTIVE_REASON_SEND_FAILED_ALT": "Slanje nije uspjelo.",
  "ACTIVITY_YOU_ATTACHED_ALT": "Priložili ste:",
  "ACTIVITY_NUM_ATTACHMENTS_ONE_ALT": "1 privitak.",
  "ACTIVITY_NUM_ATTACHMENTS_FEW_ALT": "$1 privitka.",
  "ACTIVITY_NUM_ATTACHMENTS_MANY_ALT": "$1 privitaka.",
  "ACTIVITY_NUM_ATTACHMENTS_OTHER_ALT": "$1 privitaka.",
  "ACTIVITY_NUM_ATTACHMENTS_TWO_ALT": "$1 privitka.",
  "ACTIVITY_STATUS_SEND_FAILED_RETRY": "Slanije nije uspjelo. [Pokušaj ponovno][RETRY].",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENDING": "Slanje",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENT_AT": "Poslano u $1",
  "ACTIVITY_STATUS_TIMESTAMP_JUST_NOW": "Upravo sada",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_HOUR_AGO": "Prije 1 h",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_MINUTE_AGO": "Prije 1 minute",
  "ACTIVITY_STATUS_TIMESTAMP_TODAY": "Danas",
  "ACTIVITY_STATUS_TIMESTAMP_YESTERDAY": "Jučer",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_PARSE": "Pogreška pri raščlambi prilagodljive kartice",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_RENDER": "Pogreška pri renderiranju prilagodljive kartice",
  "ATTACHMENT_AUDIO": "Audioisječak.",
  "ATTACHMENT_CARD": "Kartica: $1 $2 $3",
  "ATTACHMENT_FILE": "Datoteka: $1",
  "ATTACHMENT_IMAGE": "Slika",
  "ATTACHMENT_TEXT": "Tekst: $1",
  "ATTACHMENT_VIDEO": "Videoisječak",
  "CAROUSEL_FLIPPER_LEFT_ALT": "Lijevo",
  "CAROUSEL_FLIPPER_RIGHT_ALT": "Desno",
  "CONNECTIVITY_STATUS_ALT_CONNECTED": "Povezano",
  "CONNECTIVITY_STATUS_ALT_CONNECTING": "Povezivanje u tijeku...",
  "CONNECTIVITY_STATUS_ALT_FATAL": "Povezivanje nije moguće.",
  "CONNECTIVITY_STATUS_ALT_RECONNECTING": "Došlo je do prekida mreže. U tijeku je ponovno povezivanje...",
  "CONNECTIVITY_STATUS_ALT_RENDER_ERROR": "Pogreška pri renderiranju. Provjerite konzolu ili se obratite razvojnom inženjeru bota.",
  "CONNECTIVITY_STATUS_ALT_SLOW_CONNECTION": "Povezivanje traje dulje nego obično.",
  "CONNECTIVITY_STATUS_ALT": "Status veze: $1",
  "FILE_CONTENT_ALT": "„$1”",
  "FILE_CONTENT_DOWNLOADABLE_ALT": "Preuzimanje datoteke „$1”",
  "FILE_CONTENT_DOWNLOADABLE_WITH_SIZE_ALT": "Preuzimanje datoteke „$1” veličine $2",
  "FILE_CONTENT_WITH_SIZE_ALT": "„$1” veličine $2",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_BODY": "Tipka Enter",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_HEADER": "Pristup stavkama u poruci",
  "KEYBOARD_HELP_CHAT_HISTORY_HEADER": "Povijest čavrljanja",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_BODY": "Tipka Esc",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_HEADER": "Izađi iz poruke",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_BODY": "Tipka Tab\nTipka Shift + Tab",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_HEADER": "Pomicanje među stavkama u poruci",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_BODY": "Tipka sa strelicom prema gore\nTipka sa strelicom prema dolje",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_HEADER": "Pomicanje među porukama",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_BODY": "Tipka Enter",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_HEADER": "Izvršavanje radnje",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_BODY": "Tipka Tab\nTipka Shift + Tab",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_HEADER": "Pomicanje među stavkama u prozoru za čavrljanje",
  "KEYBOARD_HELP_CHAT_WINDOW_HEADER": "Prozor čavrljanja",
  "KEYBOARD_HELP_CLOSE_BUTTON_ALT": "Zatvori",
  "KEYBOARD_HELP_HEADER": "Kontrole na tipkovnici",
  "MARKDOWN_EXTERNAL_LINK_ALT": "Otvara se u novom prozoru, vanjskom.",
  "RECEIPT_CARD_TAX": "Porez",
  "RECEIPT_CARD_TOTAL": "Ukupno",
  "RECEIPT_CARD_VAT": "PDV",
  "SEND_BOX_IS_EMPTY_TOOLTIP_ALT": "Prazna poruka se ne može poslati.",
  "SPEECH_INPUT_LISTENING": "Slušanje...",
  "SPEECH_INPUT_MICROPHONE_BUTTON_CLOSE_ALT": "Mikrofon je isključen",
  "SPEECH_INPUT_MICROPHONE_BUTTON_OPEN_ALT": "Mikrofon je uključen",
  "SPEECH_INPUT_STARTING": "Započinje...",
  "SUGGESTED_ACTIONS_LABEL_ALT": "Predložene akcije",
  "TEXT_INPUT_ALT": "Okvir za unos poruke",
  "TEXT_INPUT_PLACEHOLDER": "Unesite poruku",
  "TEXT_INPUT_SEND_BUTTON_ALT": "Pošalji",
  "TEXT_INPUT_SPEAK_BUTTON_ALT": "Govor",
  "TEXT_INPUT_UPLOAD_BUTTON_ALT": "Prijenos datoteke",
  "TOAST_ACCORDION_FEW": "Obavijesti: $1. Kliknite ovdje za prikaz pojedinosti",
  "TOAST_ACCORDION_MANY": "Obavijesti: $1. Kliknite ovdje za prikaz pojedinosti",
  "TOAST_ACCORDION_OTHER": "Obavijesti: $1. Kliknite ovdje za prikaz pojedinosti",
  "TOAST_ACCORDION_TWO": "Obavijesti: $1. Kliknite ovdje za prikaz pojedinosti",
  "TOAST_ALT_ERROR": "Pogreška",
  "TOAST_ALT_INFO": "Informacije",
  "TOAST_ALT_SUCCESS": "Uspjeh",
  "TOAST_ALT_WARN": "Upozorenje",
  "TOAST_DISMISS_BUTTON": "Odbaci",
  "TOAST_TITLE_ALT": "Obavijest",
  "TRANSCRIPT_ARIA_LABEL_ALT": "Povijest čavrljanja, pritisnite tipke sa strelicama za navigaciju.",
  "TRANSCRIPT_ARIA_ROLE_ALT": "povijest čavrljanja",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_LABEL_ALT": "Poruka je interaktivna. Pritisnite tipke Shift i Tab 2 do 3 puta da biste se prebacili na povijest čavrljanja. Zatim za interakciju kliknite poruku.",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_WITH_LINKS_LABEL_ALT": "U poruci se nalazi jedna ili više veza. Pritisnite tipke Shift i Tab 2 do 3 puta da biste se prebacili na povijest čavrljanja. Zatim za interakciju kliknite poruku.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_LABEL_ALT": "Poruka sadrži predložene akcije. Pritisnite shift i tabulator 2 do 3 puta da biste ih odabrali.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_WITH_ACCESS_KEY_LABEL_ALT": "Poruka sadrži predložene akcije. Pritisnite $1 da biste ih odabrali.",
  "TRANSCRIPT_LIVE_REGION_SEND_FAILED_ALT": "Slanje poruke nije uspjelo.",
  "TRANSCRIPT_MORE_MESSAGES": "Više poruka",
  "TRANSCRIPT_NEW_MESSAGES": "Nove poruke",
  "TRANSCRIPT_TERMINATOR_TEXT": "Kraj povijesti čavrljanja",
  "TYPING_INDICATOR_ALT": "Prikaz pokazatelja tipkanja",
  "TYPING_INDICATOR_SINGLE_TEXT": "$1 tipka.",
  "TYPING_INDICATOR_MULTIPLE_TEXT": "$1 i drugi tipkaju."
}