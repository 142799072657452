import React from 'react'
import type { TemplateDefaultProps } from '../../models/types'

const Desktop = ({
  className,
  background,
  children
}: TemplateDefaultProps): JSX.Element => {
  return (
    <div
      className={`rf-z-dropdown rf-relative rf-col-start-5 rf-col-end-13 rf-row-start-1 rf-row-span-full rf-w-3/5 rf-justify-self-center tactile:rf-pt-1/5 rf-flex rf-flex-col rf-justify-start rf-items-center rf-bg-templateBackground rf-shadow-[3px_3px_6px_#00000029] rf-overflow-y-scroll rf-scrollbar-hidden ${className}`}
      style={{ background: background || '' }}
    >
      {children}
    </div>
  )
}

export default Desktop
