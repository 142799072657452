import React from 'react'
import { useLocaleStore } from '../Contexts/localeStore'
import { hooks } from 'botframework-webchat-component'
import translation from '../../translations/poi.json'

const { useSendPostBack } = hooks

interface PaginationAttachmentProps {
  body: Array<{
    text: string
  }>
  selectAction: {
    data: any
  }
}

const PaginationAttachment = ({
  body,
  selectAction
}: PaginationAttachmentProps): JSX.Element => {
  const locale = useLocaleStore((state) => state.locale)
  const sendPostBack = useSendPostBack()

  const handleClick = (): void => {
    selectAction.data && sendPostBack(selectAction.data)
  }

  return (
    <div className='rf-w-full rf-rounded' onClick={handleClick}>
      <div className='rf-relative rf-w-full rf-flex rf-items-center rf-justify-center rf-bg-truewhite rf-rounded rf-aspect-video rf-underline rf-cursor-pointer'>
        {translation[locale]?.[body[0].text.toLowerCase()] ||
          translation['fr-FR'][body[0].text.toLowerCase()]}
      </div>
    </div>
  )
}

export default PaginationAttachment
