/* eslint-disable react/jsx-boolean-value */
import * as AC from 'adaptivecards'

export class ChoiceWithUrl extends AC.SerializableObject {
  static readonly JsonTypeName = 'ChoiceWithUrl'

  getJsonTypeName(): string {
    return ChoiceWithUrl.JsonTypeName
  }

  getSchemaKey(): string {
    return ChoiceWithUrl.JsonTypeName
  }

  static readonly titleProperty = new AC.StringProperty(
    AC.Versions.v1_0,
    'title',
    true
  )

  static readonly valueProperty = new AC.StringProperty(
    AC.Versions.v1_0,
    'value',
    true
  )

  // Add url property to schema
  static readonly urlProperty = new AC.StringProperty(
    AC.Versions.v1_0,
    'url',
    true
  )

  @AC.property(ChoiceWithUrl.titleProperty)
  get title(): string {
    return this.getValue(ChoiceWithUrl.titleProperty) || ''
  }

  set title(val: string) {
    if (this.title !== val) {
      this.setValue(ChoiceWithUrl.titleProperty, val)
    }
  }

  @AC.property(ChoiceWithUrl.valueProperty)
  get value(): string {
    return this.getValue(ChoiceWithUrl.valueProperty) || ''
  }

  set value(val: string) {
    if (this.value !== val) {
      this.setValue(ChoiceWithUrl.valueProperty, val)
    }
  }

  @AC.property(ChoiceWithUrl.urlProperty)
  get url(): string {
    return this.getValue(ChoiceWithUrl.urlProperty) || ''
  }

  set url(val: string) {
    if (this.url !== val) {
      this.setValue(ChoiceWithUrl.urlProperty, val)
    }
  }

  constructor(title?: string, value?: string, url?: string) {
    super()
    this.title = title || ''
    this.value = value || ''
    this.url = url || ''
  }
}
