import React, { useEffect, useRef, useState } from 'react'
import useRefDimensions from '../../hooks/useRefDimensions'
import { useView } from '../Contexts/ViewContext'
import { useRetorik } from '../Contexts/RetorikContext'
import type { ThemeColors } from '../../models/colorTypes'
import { ContainerParent, DeviceType } from '../../models/enums'
import useCurrentPagination from '../../hooks/useCurrentPagination'
import { mobileBreakpoint } from '../../models/constants'
import type { WithChildren } from '../../models/utils'
import { isMobile } from 'react-device-detect'

type ContainerProps = WithChildren<{
  fullSize: boolean
  width?: number | string
  height?: number | string
  colors: ThemeColors
  parent: number
}>

const Container = ({
  fullSize,
  width,
  height,
  children,
  colors,
  parent
}: ContainerProps): JSX.Element => {
  const {
    currentDeviceType,
    overlayOpacity,
    isTactile,
    setCurrentDeviceType,
    setCurrentHeight,
    setCurrentWidth
  } = useView()
  const {
    configuration: { doNotDetectDeviceFromUserAgent }
  } = useRetorik()
  const container = useRef<HTMLDivElement>(null)
  const dimensions = useRefDimensions(container)
  const [portrait, setPortrait] = useState<string>('')
  const [large, setLarge] = useState<string>('')
  // Call useCurrentPagination to update data sent to store. Do not remove
  const pagination = useCurrentPagination()

  /**
   * On pagination change :
   *  - save current pagination in localstorage to be used in custom store
   */
  useEffect(() => {
    // Add pagination to local storage to be used in botframework store
    localStorage.setItem(
      'Retorik.Framework.Pagination',
      JSON.stringify(pagination)
    )
  }, [pagination])

  let widthStyle = {}
  if (width === 'full') {
    widthStyle = { width: '100%' }
  } else if (typeof width === 'number') {
    widthStyle = { width: `${width}px` }
  } else if (typeof width === 'string') {
    widthStyle = { width: width }
  }

  let heightStyle = {}
  if (height === 'full') {
    heightStyle = { height: '100%', flexGrow: 1 }
  } else if (typeof height === 'number') {
    heightStyle = { height: `${height}px` }
  } else if (typeof height === 'string') {
    heightStyle = { height: height }
  }

  if (currentDeviceType === DeviceType.mobile) {
    heightStyle = {
      height: window.innerHeight,
      minHeight: window.innerHeight,
      maxHeight: window.innerHeight
    }
  }

  const checkPortrait = (width: number, height: number): boolean => {
    if (width && height && width < height) {
      setPortrait('rf-portrait')
    } else {
      setPortrait('')
      return false
    }

    return true
  }

  /**
   * On dimensions change :
   *  - check and set the classes in the main div that will allow us to use tailwind variants
   *  - set the main div dimensions in context
   */
  useEffect(() => {
    // Widget mode => portrait only
    if (parent === ContainerParent.widget) {
      setPortrait('rf-portrait')
      setLarge('')
      setCurrentDeviceType(DeviceType.widget)
    }
    // If we don't want to use the userAgent to get the device type
    else if (doNotDetectDeviceFromUserAgent) {
      const isPortrait = checkPortrait(dimensions.width, dimensions.height)

      if (isPortrait) {
        if (dimensions.width > mobileBreakpoint) {
          setLarge('rf-large')
          setCurrentDeviceType(DeviceType.borne)
        } else {
          setLarge('')
          setCurrentDeviceType(DeviceType.mobile)
        }
      } else {
        if (dimensions.height > mobileBreakpoint) {
          setLarge('rf-large')
          setCurrentDeviceType(DeviceType.landscape)
        } else {
          setLarge('')
          setCurrentDeviceType(DeviceType.mobile)
        }
      }
    }
    // If we use the userAgent to get the device type
    else {
      if (isMobile) {
        setPortrait('rf-portrait')
        setLarge('')
        setCurrentDeviceType(DeviceType.mobile)
      } else {
        const isPortrait = checkPortrait(dimensions.width, dimensions.height)

        // Check for 'rf-large' class
        setLarge('rf-large')
        setCurrentDeviceType(
          isPortrait ? DeviceType.borne : DeviceType.landscape
        )
      }
    }

    dimensions.height && setCurrentHeight(dimensions.height)
    dimensions.width && setCurrentWidth(dimensions.width)
  }, [dimensions])

  const cssVariables = {
    '--rf-opacity-overlay': overlayOpacity !== undefined ? overlayOpacity : 0.5
  } as React.CSSProperties

  const customColors = {
    '--rf-color-primary': colors.primary,
    '--rf-color-secondary': colors.secondary,
    '--rf-color-black': colors.black,
    '--rf-color-whereToEatColor': colors.whereToEatColor,
    '--rf-color-whereToSleepColor': colors.whereToSleepColor,
    '--rf-color-tobeSeenColor': colors.tobeSeenColor,
    '--rf-color-tobeDoneColor': colors.tobeDoneColor,
    '--rf-color-localProductsColor': colors.localProductsColor,
    '--rf-color-servicesColor': colors.servicesColor,
    '--rf-color-cardFrameBackground': colors.card.frame.background,
    '--rf-color-cardFrameBorder': colors.card.frame.border,
    '--rf-color-cardFrameText': colors.card.frame.text,
    '--rf-color-cardButtonBackgroundDefault':
      colors.card.button.background.default,
    '--rf-color-cardButtonBackgroundHover': colors.card.button.background.hover,
    '--rf-color-cardButtonBorderDefault': colors.card.button.border.default,
    '--rf-color-cardButtonBorderHover': colors.card.button.border.hover,
    '--rf-color-cardButtonTextDefault': colors.card.button.text.default,
    '--rf-color-cardButtonTextHover': colors.card.button.text.hover,
    '--rf-color-cardButtonDiscoverBackgroundDefault':
      colors.card.discoverButton.background.default,
    '--rf-color-cardButtonDiscoverBackgroundHover':
      colors.card.discoverButton.background.hover,
    '--rf-color-cardButtonDiscoverBorderDefault':
      colors.card.discoverButton.border.default,
    '--rf-color-cardButtonDiscoverBorderHover':
      colors.card.discoverButton.border.hover,
    '--rf-color-cardButtonDiscoverTextDefault':
      colors.card.discoverButton.text.default,
    '--rf-color-cardButtonDiscoverTextHover':
      colors.card.discoverButton.text.hover,
    '--rf-color-textModePanelBackground': colors.textMode.panel.background,
    '--rf-color-textModePanelBorder': colors.textMode.panel.border,
    '--rf-color-textModePanelConversationUser':
      colors.textMode.panel.conversationUser,
    '--rf-color-textModePanelConversationBot':
      colors.textMode.panel.conversationBot,
    '--rf-color-vocalModeSubtitlesText': colors.vocalMode.subtitles.text,
    '--rf-color-vocalModeSubtitlesBackground':
      colors.vocalMode.subtitles.background,
    '--rf-color-formInputTextDefault': colors.formInput.text.default,
    '--rf-color-formInputTextHover': colors.formInput.text.hover,
    '--rf-color-formInputRadioCheckboxUncheckedBackground':
      colors.formInput.inputRadioCheckbox.unchecked.background,
    '--rf-color-formInputRadioCheckboxUncheckedBorder':
      colors.formInput.inputRadioCheckbox.unchecked.border,
    '--rf-color-formInputRadioCheckboxCheckedBackground':
      colors.formInput.inputRadioCheckbox.checked.background,
    '--rf-color-formInputRadioCheckboxCheckedBorder':
      colors.formInput.inputRadioCheckbox.checked.border,
    '--rf-color-formInputRadioCheckboxCheckedItem':
      colors.formInput.inputRadioCheckbox.checked.item,
    '--rf-color-formInputButtonBackgroundDefault':
      colors.formInput.inputButton.background.default,
    '--rf-color-formInputButtonBackgroundHover':
      colors.formInput.inputButton.background.hover,
    '--rf-color-formInputButtonBorderDefault':
      colors.formInput.inputButton.border.default,
    '--rf-color-formInputButtonBorderHover':
      colors.formInput.inputButton.border.hover,
    '--rf-color-formInputButtonTextDefault':
      colors.formInput.inputButton.text.default,
    '--rf-color-formInputButtonTextHover':
      colors.formInput.inputButton.text.hover
  } as React.CSSProperties

  return (
    <div
      id='retorik-container'
      ref={container}
      className={`rf-relative ${
        fullSize ? 'rf-w-screen rf-h-screen' : 'rf-w-full rf-h-full'
      } rf-max-w-screen rf-max-h-screen rf-grid rf-grid-cols-8 rf-grid-rows-container rf-bg-transparent rf-text-trueblack rf-font-default ${portrait} ${large} ${
        isTactile && 'rf-tactile'
      } rf-overflow-hidden`}
      style={{
        ...widthStyle,
        ...heightStyle,
        ...cssVariables,
        ...customColors
      }}
    >
      {children}
    </div>
  )
}

export default Container
