import React from 'react'
import type { IconDefaultProps } from '../../../models/types'

const ChevronLeftIcon = ({
  className,
  color
}: IconDefaultProps): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className || 'rf-h-6 rf-w-4'}
      viewBox='0 0 7.414 12.828'
    >
      <path
        d='M1429.081,2861.407l5-5-5-5'
        transform='translate(1435.081 2862.822) rotate(180)'
        fill='none'
        stroke={color || '#fff'}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
      />
    </svg>
  )
}

export default ChevronLeftIcon
