import { TextSize } from 'adaptivecards'
import React, { useEffect, useState } from 'react'
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter'

interface TextBlockProps {
  text?: string
  isTitle?: boolean
  isSubtitle?: boolean
  size?: number
  horizontalAlignment?: number
  wrap?: boolean
  maxLines?: number
}

const getSizeFromEnum = (size: number): string => {
  switch (size) {
    case TextSize.Small:
      return 'rf-small-size-auto'
    case TextSize.Medium:
      return 'rf-text-medium-size-auto'
    case TextSize.Large:
      return 'rf-text-large-size-auto'
    case TextSize.ExtraLarge:
      return 'rf-text-extralarge-size-auto'
    case TextSize.Default:
    default:
      return 'rf-text-size-auto'
  }
}

const TextBlock = ({
  text,
  isTitle,
  isSubtitle,
  size,
  horizontalAlignment,
  wrap,
  maxLines
}: TextBlockProps): JSX.Element => {
  const [style, setStyle] = useState<string>('')

  useEffect(() => {
    if (text) {
      // Text size
      let styleToApply = isTitle
        ? 'rf-title-size-auto'
        : isSubtitle
        ? 'rf-subtitle-size-auto'
        : size
        ? getSizeFromEnum(size)
        : 'rf-text-size-auto'

      // Text horizontal alignment
      if (horizontalAlignment) {
        switch (horizontalAlignment) {
          case 1:
            styleToApply += ' rf-text-center'
            break
          case 2:
            styleToApply += ' rf-text-right'
            break
          case 0:
          default:
            styleToApply += ' rf-text-left'
            break
        }
      } else {
        styleToApply += ' rf-text-left'
      }

      // Wrap ?
      wrap
        ? (isTitle || isSubtitle) && (styleToApply += ' rf-line-clamp-2')
        : (styleToApply += ' rf-truncate')

      // Max lines ? If this is a title it has already been set to 2 lines if wrap is not false
      !isTitle &&
        maxLines &&
        maxLines > 0 &&
        maxLines <= 10 &&
        (styleToApply += ` rf-line-clamp-${maxLines}`)

      setStyle(styleToApply)
    }
  }, [])

  return text ? (
    <div className={`rf-w-full rf-text-cardFrameText ${style}`}>
      {capitalizeFirstLetter(text)}
    </div>
  ) : (
    <React.Fragment />
  )
}

export default TextBlock
