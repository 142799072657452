import { useState, useEffect } from 'react'
import { useRetorik } from '../components/Contexts/RetorikContext'
import { useView } from '../components/Contexts/ViewContext'
import { DeviceType } from '../models/enums'

const useCloseButtonTop = (): string | number => {
  const { configuration } = useRetorik()
  const { currentDeviceType, isTactile } = useView()
  const [top, setTop] = useState<number | string>(200)

  /**
   * On currentDeviceType change :
   *  - switch on currentDeviceType and check for tactile
   *  - set top state to the value in configuration if given, or a default one
   */
  useEffect(() => {
    switch (currentDeviceType) {
      case DeviceType.landscape: {
        if (isTactile) {
          setTop(configuration.remote?.desktopTactile || '9.75rem')
        } else {
          setTop(configuration.remote?.desktop || '9.75rem')
        }
        break
      }
      case DeviceType.borne:
        setTop(configuration.remote?.verticalTactile || 'calc(50% - 11.25rem)')
        break
      default:
        setTop(200)
        break
    }
  }, [currentDeviceType])

  return top
}

export default useCloseButtonTop
