/* eslint-disable react/jsx-boolean-value */
import React, { useEffect, useRef } from 'react'
import { useRetorik } from '../Contexts/RetorikContext'
import { useLocaleStore } from '../Contexts/localeStore'
import useMessageActivities from '../../hooks/useMessageActivities'
import translation from '../../translations/history.json'
import { SubMenuClosing as Template } from '../Templates'
import HistoryItem from './HistoryItem'
import { CurrentSubView, DeviceType } from '../../models/enums'
import { useView } from '../Contexts/ViewContext'

const HistoryList = (): JSX.Element => {
  const { setCurrentSubView } = useRetorik()
  const { currentDeviceType } = useView()
  const { locale } = useLocaleStore()
  const activities = useMessageActivities()
  const scrollRef = useRef<HTMLDivElement>(null)

  /**
   * On call :
   *  - scroll to the scrollRef HTML element at the bottom of the view
   */
  const scrollToRef = (): void => {
    if (scrollRef && scrollRef.current !== null) {
      scrollRef.current.scrollIntoView({
        behavior: 'smooth'
      })
    }
  }

  /**
   * On component mount :
   *  - call scrollToRef method
   */
  useEffect(() => {
    setTimeout(() => {
      scrollToRef()
    }, 200)
  }, [])

  const handleClose = (): void => {
    setCurrentSubView(CurrentSubView.none)
  }

  return (
    <Template
      handleClose={handleClose}
      backIsConversation={true}
      title={translation[locale]?.title || translation['fr-FR'].title}
    >
      <div
        className={`rf-pb-4 rf-overflow-y-scroll ${
          currentDeviceType === DeviceType.borne
            ? 'rf-scrollbar-thin'
            : 'rf-scrollbar-hidden'
        }`}
      >
        {/* History items */}
        {activities.map((activity, key) => {
          return <HistoryItem key={key} activity={activity} />
        })}
        <div ref={scrollRef} />
      </div>
    </Template>
  )
}

export default HistoryList
