import type { AddressData } from '../models/types'
import { webchatTokenRetrievalAddress } from '../models/constants'
import processPrefix from './studioRetorikAddressFromPrefix'

async function fetchDirectLineTokenWithKey(key: string): Promise<string> {
  const data = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${key}`
    }
  }
  const res = await fetch(
    'https://directline.botframework.com/v3/directline/tokens/generate',
    data
  )
  const { token } = await res.json()

  return token
}

async function fetchDirectLineToken(data: AddressData): Promise<string> {
  // baseURI is used for retrocompatibility
  let fullAddress = ''
  if (data.baseURI) {
    fullAddress = data.baseURI
  } else {
    fullAddress = `${processPrefix(data.prefix)}/${
      data.tenant
    }/${webchatTokenRetrievalAddress}`
  }

  const res = await fetch(fullAddress)
  const token = await res.json()

  return token
}

export { fetchDirectLineTokenWithKey, fetchDirectLineToken }
