import React, { useEffect } from 'react'
import useLastEventActivity from '../../hooks/useLastEventActivity'
import { CurrentSubView, Mode } from '../../models/enums'
import type { ChatbotData } from '../../models/types'
import { useRetorik } from '../Contexts/RetorikContext'
import { useView } from '../Contexts/ViewContext'
import HomeView from '../Views/HomeView'
import NewsView from '../Views/NewsView'
import WarningBeforeNewsView from '../Views/WarningBeforeNewsView'
import {
  useActivities,
  useGetKeyByActivity
} from 'botframework-webchat-api/lib/hooks'
import { WebChatActivity } from 'botframework-webchat-core'
import { RetorikActivity } from '../../models/activityTypes'

interface RetorikRouterProps {
  chatbotData: ChatbotData
  externalComponents?: Array<{ name: string; component: JSX.Element }>
}

const RetorikRouter = (props: RetorikRouterProps): JSX.Element => {
  const {
    setCurrentSubView,
    mode,
    configuration: { isUsedOnBorne }
  } = useRetorik()
  const {
    route,
    setRoute,
    homeActivityKey,
    setHomeActivityKey,
    setShowHomeAttachments
  } = useView()
  const getKeyByActivity = useGetKeyByActivity()
  const [activities] = useActivities()
  const lastEvent = useLastEventActivity()

  useEffect(() => {
    if (lastEvent && lastEvent.type === 'event') {
      switch (lastEvent.name) {
        case 'news.ShowFlashInfo':
          setRoute('news')
          break
        case 'weather.ShowWeather':
          setCurrentSubView(CurrentSubView.weather)
          break
        default:
          break
      }
    }
  }, [lastEvent])

  useEffect(() => {
    if (!homeActivityKey) {
      const firstActivityId = activities
        .slice()
        .find(
          (activity) =>
            activity.type === 'message' && activity.from?.role === 'bot'
        )?.replyToId
      let attachments: WebChatActivity | undefined
      if (firstActivityId) {
        attachments = activities
          .filter((activity) => activity.replyToId === firstActivityId)
          .reverse()
          .find(
            (act: RetorikActivity) =>
              act.attachments && act.attachments.length > 0
          )
        const key = attachments ? getKeyByActivity(attachments) : undefined
        setHomeActivityKey(key)
      }
    }

    activities &&
      activities.length > 0 &&
      (activities[activities.length - 1].from?.role === 'bot' ||
        activities.some((a) => (a as any).name === 'setUser')) &&
      setShowHomeAttachments(undefined)
  }, [activities])

  return (
    <React.Fragment>
      {route === 'home' && <HomeView {...props} />}
      {route === 'news' &&
        (mode === Mode.vocal || !!isUsedOnBorne ? (
          <NewsView {...props} />
        ) : (
          <WarningBeforeNewsView propsToTransfer={props} />
        ))}
    </React.Fragment>
  )
}

export default RetorikRouter
