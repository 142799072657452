/* eslint-disable react/jsx-boolean-value */
import React from 'react'
import type { HistoryActivity } from '../../models/activityTypes'
import HistoryQA from './HistoryQA'

interface HistoryItemProps {
  activity: HistoryActivity
}

const HistoryItem = ({ activity }: HistoryItemProps): JSX.Element => {
  return (
    <div className='rf-mb-4 rf-flex rf-flex-col rf-justify-start rf-items-start rf-gap-4 rf-text-size-auto'>
      {/* Question */}
      {activity?.question && activity.question.type === 'message' && (
        <HistoryQA activity={activity.question} isQuestion={true} />
      )}

      {/* Answers */}
      {activity.answers.map((answer, j) => {
        return answer.type === 'message' ? (
          <HistoryQA key={j} activity={answer} isQuestion={false} />
        ) : (
          <React.Fragment />
        )
      })}
    </div>
  )
}

export default HistoryItem
