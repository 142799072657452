import { create } from 'zustand'
import type { AddressData, AvailableLanguages } from '../../models/types'
import fetchAvailableLanguages from '../../utils/fetchAvailableLanguages'

interface LocaleStore {
  locale: string
  defaultLocale: string
  supported: string[]
}

export const useLocaleStore = create<LocaleStore>()(() => {
  return {
    locale: '',
    defaultLocale: 'fr-FR',
    supported: []
  }
})

export const getLocale = (): string => {
  return useLocaleStore((state) => state.locale)
}

export const setLocale = (locale: string): void => {
  useLocaleStore.setState({ locale: locale })
}

export const fetchSupportedLanguages = async (
  addressData: AddressData
): Promise<AvailableLanguages> => {
  return fetchAvailableLanguages(addressData).then((languages) => {
    useLocaleStore.setState({
      defaultLocale: languages.default,
      supported: languages.all
    })
    return languages
  })
}
