import CommentsPennantIcon from './CommentsPennantIcon'
import FavoriteIcon from './FavoriteIcon'
import FavoriteLightIcon from './FavoriteLightIcon'
import PhoneIcon from './PhoneIcon'
import PhotoCameraIcon from './PhotoCameraIcon'

export {
  CommentsPennantIcon,
  FavoriteIcon,
  FavoriteLightIcon,
  PhoneIcon,
  PhotoCameraIcon
}
