import React from 'react'
import { jsxToHtmlElement } from '../../../utils/jsxToHtmlElement'
import { TextBlock } from 'adaptivecards/lib/card-elements'
import CustomTextBlock from '../TextBlock'
import { TextSize } from 'adaptivecards'

export class TextBlockClass extends TextBlock {
  protected internalRender(): HTMLElement {
    const element = jsxToHtmlElement(this.renderReact())
    return element
  }

  protected renderReact = (): JSX.Element => {
    let isTitle = false
    let isSubtitle = false
    if (this.size && this.size === TextSize.Large) {
      isTitle = true
    } else if (this.size && this.size === TextSize.Medium) {
      isSubtitle = true
    }

    const props = {
      text: this.text,
      isTitle: isTitle,
      isSubtitle: isSubtitle,
      size: this.size,
      horizontalAlignment: this.horizontalAlignment,
      wrap: this.wrap,
      maxLines: this.maxLines
    }
    return <CustomTextBlock {...props} />
  }
}
