import * as AC from 'adaptivecards'
import { initializeIcons } from '@fluentui/react'
import { TextBlockClass } from './Classes/TextBlockClass'
import { ToggleInputClass } from './Classes/ToggleInputClass'
import { DateInputClass } from './Classes/DateInputClass'
import { TimeInputClass } from './Classes/TimeInputClass'
import { ChoiceSetClass } from './Classes/ChoiceSetClass'
import { LikertClass } from './Classes/LikertClass'
import { ImageChoiceSetClass } from './Classes/ImageChoiceSetClass'
import { SliderClass } from './Classes/SliderClass'

import { ActionSubmitClass } from './Classes/ActionSubmitClass'
import { ActionOpenUrlClass } from './Classes/ActionOpenUrlClass'
import { ActionShowCardClass } from './Classes/ActionShowCardClass'
import { ActionToggleVisibilityClass } from './Classes/ActionToggleVisibilityClass'

let iconsInitialized = false

/**
 * STORYBOOK : elements that have no decorator and thus don't have to be commented to build storybook :
 *  - TextBlock
 *  - Input.Toggle
 * Every other element has to be commented otherwise storybook build will fail
 */

const useRetorikUI = (): void => {
  if (!iconsInitialized) {
    initializeIcons()
    iconsInitialized = true
  }

  // Override existing elements
  AC.GlobalRegistry.elements.register('TextBlock', TextBlockClass)
  AC.GlobalRegistry.elements.register('Input.Toggle', ToggleInputClass)
  AC.GlobalRegistry.elements.register('Input.Date', DateInputClass)
  AC.GlobalRegistry.elements.register('Input.Time', TimeInputClass)
  AC.GlobalRegistry.elements.register('Input.ChoiceSet', ChoiceSetClass)

  // Register new elements
  AC.GlobalRegistry.elements.register(
    'Retorik.ImageChoiceSet',
    ImageChoiceSetClass
  )
  AC.GlobalRegistry.elements.register('Retorik.Likert', LikertClass)
  AC.GlobalRegistry.elements.register('Retorik.Slider', SliderClass)

  // Override existing actions
  AC.GlobalRegistry.actions.register('Action.Submit', ActionSubmitClass)
  AC.GlobalRegistry.actions.register('Action.OpenUrl', ActionOpenUrlClass)
  AC.GlobalRegistry.actions.register('Action.ShowCard', ActionShowCardClass)
  AC.GlobalRegistry.actions.register(
    'Action.ToggleVisibility',
    ActionToggleVisibilityClass
  )
}

export { useRetorikUI }
