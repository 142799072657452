import React, { useEffect, useRef } from 'react'
import type { WithChildren } from '../../models/utils'

type WheelerProps = WithChildren<{
  className?: string
  handleWheel: (
    delta: number | { x: number; y: number },
    type?: string | number
  ) => void
  paramInHandle?: string | number
  handleBoth?: boolean
  xAxisOnly?: boolean
}>

const Wheeler = ({
  className,
  handleWheel,
  paramInHandle,
  handleBoth,
  xAxisOnly,
  children
}: WheelerProps): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null)

  /**
   * On mouse wheel :
   *  - check if the event has to be taken in account
   *  - if  yes, retrieve the wanted data
   *  - prevent event default
   *  - stop event propagation
   *  - call parent function with delta value and paramInHandle from props if defined
   * @param e : WheelEvent
   */
  const internalHandleWheel = (e: WheelEvent): void => {
    if (xAxisOnly && e.deltaY) {
      return
    }

    const delta = handleBoth
      ? { x: e.deltaX, y: e.deltaY }
      : xAxisOnly
      ? e.deltaX
      : e.deltaY
    e.preventDefault()
    e.stopImmediatePropagation()
    paramInHandle ? handleWheel(delta, paramInHandle) : handleWheel(delta)
  }

  /**
   * On ref change :
   *  - add wheel event listener, with passive = false to allow preventDefault in processing
   * On component unmount :
   *  - unbind wheel event listener
   */
  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener(
        'wheel',
        (e): void => internalHandleWheel(e),
        { passive: false }
      )
    }

    return (): void => {
      ref.current &&
        ref.current.removeEventListener('wheel', (e) => internalHandleWheel(e))
    }
  }, [ref.current])

  return (
    <div className={className} ref={ref}>
      {children}
    </div>
  )
}

export default Wheeler
