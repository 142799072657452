import React from 'react'
import type { IconDefaultProps } from '../../../models/types'

const InfoWithCircleIcon = ({
  className,
  color
}: IconDefaultProps): JSX.Element => {
  return (
    <div className={className || 'rf-h-6'}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 33 33'
        className='rf-h-full'
      >
        <g transform='translate(-1.5 -1.5)'>
          <path
            d='M33,18A15,15,0,1,1,18,3,15,15,0,0,1,33,18Z'
            fill='none'
            stroke={color || '#00d7ff'}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='3'
          />
          <path
            d='M18,24V18'
            fill='none'
            stroke={color || '#00d7ff'}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='3'
          />
          <path
            d='M18,12h0'
            fill='none'
            stroke={color || '#00d7ff'}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='3'
          />
        </g>
      </svg>
    </div>
  )
}

export default InfoWithCircleIcon
