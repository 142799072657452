import React, { useEffect, useState } from 'react'
import { useView } from '../Contexts/ViewContext'
import { useLocaleStore } from '../Contexts/localeStore'
import translation from '../../translations/news.json'
import backButtonTranslation from '../../translations/menu.json'
import type { ChatbotData } from '../../models/types'
import NewsView from './NewsView'
import { BackButton } from '../Menu/Common'

interface WarningBeforeNewsViewProps {
  propsToTransfer: {
    chatbotData: ChatbotData
    hideMenu?: boolean
    isRetorikNews?: boolean
    handleEndedWithoutloop?: () => void
  }
}

const WarningBeforeNewsView = ({
  propsToTransfer
}: WarningBeforeNewsViewProps): JSX.Element => {
  const { setRoute } = useView()
  const locale = useLocaleStore((state) => state.locale)
  const [waiting, setWaiting] = useState<boolean>(true)

  useEffect(() => {
    setWaiting(true)
  }, [])

  const handleClick = (goHome: boolean): void => {
    goHome ? setRoute('home') : setWaiting(false)
  }

  return waiting ? (
    <div className='rf-relative rf-z-util rf-col-start-1 rf-col-span-full rf-row-start-1 rf-row-span-full rf-flex rf-flex-col rf-gap-4 rf-justify-center rf-items-center rf-bg-truewhite'>
      <BackButton
        title={
          backButtonTranslation[locale]?.back ||
          backButtonTranslation['fr-FR'].back
        }
        handleBack={(): void => handleClick(true)}
        className='rf-absolute rf-top-4 rf-left-4 rf-text-size-auto'
      />

      <p className='rf-px-4 rf-text-center'>
        {translation[locale]?.warningtext || translation['fr-FR'].warningtext}
      </p>
      <button
        className='rf-card-button-secondary'
        onClick={(): void => handleClick(false)}
      >
        {translation[locale]?.warningcontinue ||
          translation['fr-FR'].warningcontinue}
      </button>
      <button
        className='rf-card-button-secondary'
        onClick={(): void => handleClick(true)}
      >
        {translation[locale]?.warningback || translation['fr-FR'].warningback}
      </button>
    </div>
  ) : (
    <NewsView {...propsToTransfer} />
  )
}

export default WarningBeforeNewsView
