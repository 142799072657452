import React, { useEffect, useState } from 'react'
import { useView } from '../../Contexts/ViewContext'
import { useRetorik } from '../../Contexts/RetorikContext'
import { useLocaleStore } from '../../Contexts/localeStore'
import translation from '../../../translations/menu.json'
import languageInMotherTongue from '../../../translations/languageCodeToMotherTongue.json'
import { getMainLocale } from '../../../utils/checkLocale'
import ParameterButton from './ParameterButton'
import { CurrentSubView } from '../../../models/enums'
import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter'

const LanguageButton = (): JSX.Element => {
  const { locale, supported } = useLocaleStore()
  const { themeColors, isMobile } = useView()
  const { currentSubView, setCurrentSubView } = useRetorik()
  const [mainLocale, setMainLocale] = useState<string>(
    getMainLocale(locale).toUpperCase()
  )

  useEffect(() => {
    setMainLocale(getMainLocale(locale).toUpperCase())
  }, [locale])

  /**
   * On call :
   *  - change RetorikContext's currentSubView state
   */
  const handleClick = (): void => {
    setCurrentSubView(
      currentSubView === CurrentSubView.languages
        ? CurrentSubView.none
        : CurrentSubView.languages
    )
  }

  return supported.length > 1 ? (
    isMobile ? (
      <ParameterButton
        title={translation[locale]?.lang || translation['fr-FR'].lang}
        icon='lang'
        color={
          currentSubView === CurrentSubView.languages
            ? themeColors.secondary
            : '#A4A7AF'
        }
        onClick={handleClick}
      />
    ) : (
      <button
        className='rf-absolute rf-bottom-16 rf-right-0 rf-px-3 rf-pl-2 rf-pr-8 rf-flex rf-flex-row rf-items-center rf-rounded-l-lg'
        style={{
          background: 'linear-gradient(#F8F8FC, #EDEEF3)'
        }}
        onClick={handleClick}
      >
        {/* Flag */}
        <div className='rf-h-8 rf-mr-2'>
          <img
            className='rf-h-full'
            src={`https://flagsapi.com/${mainLocale}/flat/64.png`}
          />
        </div>

        {/* Texts */}
        <div className='rf-flex rf-flex-col rf-items-start rf-text-xsm'>
          <div className='rf-text-trueblack'>
            {capitalizeFirstLetter(
              translation[locale]?.singleLang || translation['fr-FR'].singleLang
            )}
          </div>
          <div className='rf-text-lightgray'>
            {capitalizeFirstLetter(languageInMotherTongue[locale])}
          </div>
        </div>
      </button>
    )
  ) : (
    <React.Fragment />
  )
}

export default LanguageButton
