/* eslint-disable react/jsx-boolean-value */
import React, { useEffect, useMemo, useState } from 'react'
import type { Review } from '../../../models/attachmentTypes'
import { useView } from '../../Contexts/ViewContext'
import { imageFiles } from '../../../utils/imageFiles'
import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter'
import { Carousel } from '../../Utils'
import { DeviceType } from '../../../models/enums'

interface DetailedMediaProps {
  media?: [
    {
      url?: string
      title?: string
      credits?: string
    }
  ]
  review?: Review
  color?: string
}

interface Media {
  type?: number
  url: string
  alt?: string
}

const DetailedMedia = ({
  media,
  review,
  color
}: DetailedMediaProps): JSX.Element => {
  const { currentWidth, isMobile, currentDeviceType } = useView()
  const [landscapeImage, setLandscapeImage] = useState<boolean>(false)
  const [carouselMedia, setCarouselMedia] = useState<Array<Media>>([])
  const maxHeight = useMemo<string | number>(() => {
    const remToPx = parseFloat(
      getComputedStyle(document.documentElement).fontSize
    )

    return isMobile
      ? (currentWidth - 2 * remToPx) * (10 / 16)
      : currentDeviceType === DeviceType.borne
      ? (currentWidth / 2 - 3 * remToPx) * (10 / 16)
      : '33vh'
  }, [currentDeviceType])

  useEffect(() => {
    if (media && media.length > 0) {
      let tempMedia: Array<Media> = []
      media.forEach((med) => {
        med.url &&
          (tempMedia = [
            ...tempMedia,
            { url: med.url, alt: med.title || 'media' }
          ])
      })
      setCarouselMedia(tempMedia)
    }
  }, [media])

  /**
   * On review change :
   *  - check if the review contains a picture url
   *  - if so, create an Image with this url, and check if its width is larger than its height
   *  - set landscapeImage state with the result
   */
  useEffect(() => {
    if (review?.picture?.url) {
      const getImageData = async (): Promise<void> => {
        const image = new Image()
        image.onload = (): void => {
          setLandscapeImage(image.width > image.height)
        }
        image.src = review?.picture?.url || ''
      }
      getImageData()
    }
  }, [review])

  return (
    <div className='large:rf-min-w-1/2 large:rf-pl-4 rf-flex rf-flex-col rf-items-center'>
      {/* Image */}
      {carouselMedia && carouselMedia.length > 0 && (
        <div className='rf-relative rf-flex rf-justify-center rf-mb-4 rf-overflow-x-hidden'>
          <Carousel
            medias={carouselMedia}
            controls={true}
            timer={3000}
            color={color}
            maxHeight={maxHeight}
          />
        </div>
      )}

      {/* Comment data */}
      {review && review.comment && (
        <div className='rf-w-full rf-p-4 large:rf-mb-4 rf-flex rf-flex-col rf-gap-4 rf-bg-truewhite rf-rounded-lg rf-shadow-[0_3px_6px_#00000029]'>
          {/* Comment */}
          <div className='rf-text-lightgray'>{review.comment}</div>

          {/* Author image / name / capacity */}
          {(review.picture || review.author) && (
            <div className='rf-flex rf-flex-row rf-items-center'>
              {review.picture && (
                <div className='rf-h-12 rf-w-12 rf-min-w-12 large:rf-h-16 large:rf-w-16 large:rf-min-w-16 large-vertical:rf-h-24 large-vertical:rf-w-24 large-vertical:rf-min-w-24 rf-mr-4 rf-flex rf-items-center rf-rounded-half rf-overflow-hidden'>
                  <img
                    src={review.picture.url || imageFiles.noimage}
                    alt={review.picture.description || 'auteur'}
                    className={
                      landscapeImage ? 'rf-h-full rf-max-w-none' : 'rf-w-full'
                    }
                  />
                </div>
              )}
              {review.author && (
                <div className='rf-flex rf-flex-col rf-items-start'>
                  <p className='rf-font-bold'>
                    {capitalizeFirstLetter(review.author.name || '')}
                  </p>
                  <p className='rf-italic'>
                    {capitalizeFirstLetter(review.author.capacity || '')}
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default DetailedMedia
