import React from 'react'
import type { RetorikMainComponentProps } from '../models/retorikTypes'
import ShadowRoot from './Common/ShadowRoot'
import RetorikContainer from './Common/RetorikContainer'
import { ContainerParent } from '../models/enums'

const RetorikAgent = (props: RetorikMainComponentProps): JSX.Element => {
  // --------- /!\ -----------
  // Ne mettre ici que de la logique spécifique au retorikAgent
  // Ce qui est commun avec RetorikWidget ou autres doit aller dans RetorikComposer
  // -------------------------

  return (
    <ShadowRoot>
      <RetorikContainer {...{ parent: ContainerParent.agent, ...props }} />
    </ShadowRoot>
  )
}

export default RetorikAgent
