import React from 'react'
import { useList } from '../../../Contexts/ListContext'
import { useLocaleStore } from '../../../Contexts/localeStore'
import { capitalizeFirstLetter } from '../../../../utils/capitalizeFirstLetter'
import CheckBox from '../../../Utils/CheckBox'

type SubFilterProps = {
  className?: string
  name: string
  parent: {
    id: string
    title: string
  }
}

const SubFilter = ({
  className,
  name,
  parent
}: SubFilterProps): JSX.Element => {
  const { selectedFilters, processFiltersOnChange } = useList()
  const { locale } = useLocaleStore((state) => ({
    locale: state.locale
  }))

  return (
    <div
      className={`rf-w-full rf-pt-4 large:rf-py-1 rf-flex rf-justify-between rf-items-center ${className}`}
    >
      {capitalizeFirstLetter(name, locale)}
      <CheckBox
        className='rf-rounded large:rf-ml-8'
        iconClassName='rf-w-4 rf-h-4 large:rf-p-min1 large:rf-w-6 large:rf-h-6'
        checked={
          selectedFilters.filter(
            (filter) =>
              filter.title === parent.title && filter.items.includes(name)
          ).length === 1
        }
        onClick={(): void =>
          processFiltersOnChange([
            {
              ...parent,
              items: [name]
            }
          ])
        }
      />
    </div>
  )
}

export default SubFilter
