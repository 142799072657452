import coreRenderAgent from './renderAgent'
import coreRenderWidget from './renderWidget'
import { RetorikAgent, RetorikWidget, RetorikNews } from './components'
import { characters } from './utils/defaultCharacters'
import useSpeechEnded from './hooks/useSpeechEnded'
import fetchAvailableLanguages from './utils/fetchAvailableLanguages'
import {
  useLocaleStore,
  getLocale,
  setLocale
} from './components/Contexts/localeStore'
import {
  openWidget,
  closeWidget,
  toggleWidget,
  toggleDisplay
} from './components/Contexts/widgetStore'
import {
  useUtilsStore,
  setCurrentCustomView,
  setCurrentSubView,
  showAgent,
  hideAgent,
  sendActivity
} from './components/Contexts/utilsStore'

const renderAgent = coreRenderAgent.bind(null, RetorikAgent)
const renderWidget = coreRenderWidget.bind(null, RetorikWidget)

export default RetorikAgent

export {
  RetorikAgent,
  RetorikWidget,
  RetorikNews,
  characters,
  renderAgent,
  useSpeechEnded,
  useUtilsStore,
  setCurrentCustomView,
  setCurrentSubView,
  sendActivity,
  useLocaleStore,
  fetchAvailableLanguages,
  getLocale,
  setLocale,
  openWidget,
  closeWidget,
  toggleWidget,
  toggleDisplay,
  showAgent,
  hideAgent
}

export type {
  AvailableLanguages,
  Configuration,
  ViewsConfiguration,
  View,
  BackgroundOptions,
  ChatbotData,
  AgentData,
  PonyfillFactoryCredentials,
  AddressData,
  UserData,
  Queue,
  CustomVoice,
  NewsConfig,
  WidgetConfiguration,
  CustomMenu
} from './models/types'

export type { RetorikThemeColors } from './models/colorTypes'

export {
  CONTENT_TYPE_POI,
  CONTENT_TYPE_NEWS,
  CONTENT_TYPE_ADAPTIVE_CARD
} from './models/attachmentTypes'

export {
  AvailableViews,
  AvailableSubViews,
  RetorikEvent,
  CurrentSubView
} from './models/enums'

window.Retorik = {
  ...window.Retorik,
  renderAgent,
  renderWidget,
  showAgent,
  hideAgent,
  setLocale,
  sendActivity
}
