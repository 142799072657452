import React from 'react'
import type { IconDefaultProps } from '../../../models/types'

const KeyboardIcon = ({ className, color }: IconDefaultProps): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 22.5 15'
      className={className || 'w-6'}
    >
      <defs>
        <filter id='Icon_awesome-keyboard'>
          <feOffset dy='3' in='SourceAlpha' />
          <feGaussianBlur stdDeviation='3' result='blur' />
          <feFlood floodOpacity='0.161' result='color' />
          <feComposite operator='out' in='SourceGraphic' in2='blur' />
          <feComposite operator='in' in='color' />
          <feComposite operator='in' in2='SourceGraphic' />
        </filter>
      </defs>
      <g>
        <path
          d='M20.625,19.5H1.875A1.875,1.875,0,0,1,0,17.625V6.375A1.875,1.875,0,0,1,1.875,4.5h18.75A1.875,1.875,0,0,1,22.5,6.375v11.25A1.875,1.875,0,0,1,20.625,19.5ZM5,9.031V7.469A.469.469,0,0,0,4.531,7H2.969a.469.469,0,0,0-.469.469V9.031a.469.469,0,0,0,.469.469H4.531A.469.469,0,0,0,5,9.031Zm3.75,0V7.469A.469.469,0,0,0,8.281,7H6.719a.469.469,0,0,0-.469.469V9.031a.469.469,0,0,0,.469.469H8.281A.469.469,0,0,0,8.75,9.031Zm3.75,0V7.469A.469.469,0,0,0,12.031,7H10.469A.469.469,0,0,0,10,7.469V9.031a.469.469,0,0,0,.469.469h1.563A.469.469,0,0,0,12.5,9.031Zm3.75,0V7.469A.469.469,0,0,0,15.781,7H14.219a.469.469,0,0,0-.469.469V9.031a.469.469,0,0,0,.469.469h1.563A.469.469,0,0,0,16.25,9.031Zm3.75,0V7.469A.469.469,0,0,0,19.531,7H17.969a.469.469,0,0,0-.469.469V9.031a.469.469,0,0,0,.469.469h1.563A.469.469,0,0,0,20,9.031ZM6.875,12.781V11.219a.469.469,0,0,0-.469-.469H4.844a.469.469,0,0,0-.469.469v1.563a.469.469,0,0,0,.469.469H6.406A.469.469,0,0,0,6.875,12.781Zm3.75,0V11.219a.469.469,0,0,0-.469-.469H8.594a.469.469,0,0,0-.469.469v1.563a.469.469,0,0,0,.469.469h1.563A.469.469,0,0,0,10.625,12.781Zm3.75,0V11.219a.469.469,0,0,0-.469-.469H12.344a.469.469,0,0,0-.469.469v1.563a.469.469,0,0,0,.469.469h1.562A.469.469,0,0,0,14.375,12.781Zm3.75,0V11.219a.469.469,0,0,0-.469-.469H16.094a.469.469,0,0,0-.469.469v1.563a.469.469,0,0,0,.469.469h1.563A.469.469,0,0,0,18.125,12.781ZM5,16.531V14.969a.469.469,0,0,0-.469-.469H2.969a.469.469,0,0,0-.469.469v1.563A.469.469,0,0,0,2.969,17H4.531A.469.469,0,0,0,5,16.531Zm11.25,0V14.969a.469.469,0,0,0-.469-.469H6.719a.469.469,0,0,0-.469.469v1.563A.469.469,0,0,0,6.719,17h9.063A.469.469,0,0,0,16.25,16.531Zm3.75,0V14.969a.469.469,0,0,0-.469-.469H17.969a.469.469,0,0,0-.469.469v1.563a.469.469,0,0,0,.469.469h1.563A.469.469,0,0,0,20,16.531Z'
          transform='translate(0 -4.5)'
          fill={color || '#a4a7af'}
        />
        <g
          transform='matrix(1, 0, 0, 1, 0, 0)'
          filter='url(#Icon_awesome-keyboard)'
        >
          <path
            d='M20.625,19.5H1.875A1.875,1.875,0,0,1,0,17.625V6.375A1.875,1.875,0,0,1,1.875,4.5h18.75A1.875,1.875,0,0,1,22.5,6.375v11.25A1.875,1.875,0,0,1,20.625,19.5ZM5,9.031V7.469A.469.469,0,0,0,4.531,7H2.969a.469.469,0,0,0-.469.469V9.031a.469.469,0,0,0,.469.469H4.531A.469.469,0,0,0,5,9.031Zm3.75,0V7.469A.469.469,0,0,0,8.281,7H6.719a.469.469,0,0,0-.469.469V9.031a.469.469,0,0,0,.469.469H8.281A.469.469,0,0,0,8.75,9.031Zm3.75,0V7.469A.469.469,0,0,0,12.031,7H10.469A.469.469,0,0,0,10,7.469V9.031a.469.469,0,0,0,.469.469h1.563A.469.469,0,0,0,12.5,9.031Zm3.75,0V7.469A.469.469,0,0,0,15.781,7H14.219a.469.469,0,0,0-.469.469V9.031a.469.469,0,0,0,.469.469h1.563A.469.469,0,0,0,16.25,9.031Zm3.75,0V7.469A.469.469,0,0,0,19.531,7H17.969a.469.469,0,0,0-.469.469V9.031a.469.469,0,0,0,.469.469h1.563A.469.469,0,0,0,20,9.031ZM6.875,12.781V11.219a.469.469,0,0,0-.469-.469H4.844a.469.469,0,0,0-.469.469v1.563a.469.469,0,0,0,.469.469H6.406A.469.469,0,0,0,6.875,12.781Zm3.75,0V11.219a.469.469,0,0,0-.469-.469H8.594a.469.469,0,0,0-.469.469v1.563a.469.469,0,0,0,.469.469h1.563A.469.469,0,0,0,10.625,12.781Zm3.75,0V11.219a.469.469,0,0,0-.469-.469H12.344a.469.469,0,0,0-.469.469v1.563a.469.469,0,0,0,.469.469h1.562A.469.469,0,0,0,14.375,12.781Zm3.75,0V11.219a.469.469,0,0,0-.469-.469H16.094a.469.469,0,0,0-.469.469v1.563a.469.469,0,0,0,.469.469h1.563A.469.469,0,0,0,18.125,12.781ZM5,16.531V14.969a.469.469,0,0,0-.469-.469H2.969a.469.469,0,0,0-.469.469v1.563A.469.469,0,0,0,2.969,17H4.531A.469.469,0,0,0,5,16.531Zm11.25,0V14.969a.469.469,0,0,0-.469-.469H6.719a.469.469,0,0,0-.469.469v1.563A.469.469,0,0,0,6.719,17h9.063A.469.469,0,0,0,16.25,16.531Zm3.75,0V14.969a.469.469,0,0,0-.469-.469H17.969a.469.469,0,0,0-.469.469v1.563a.469.469,0,0,0,.469.469h1.563A.469.469,0,0,0,20,16.531Z'
            transform='translate(0 -4.5)'
            fill='#fff'
          />
        </g>
      </g>
    </svg>
  )
}

export default KeyboardIcon
