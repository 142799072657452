import React from 'react'
import { useLocaleStore } from '../../Contexts/localeStore'
import getDistanceAsString from '../../../utils/getDistanceAsString'
import getTimeAsString from '../../../utils/getTimeAsString'
import translation from '../../../translations/poi.json'
import * as Icons from '../../Icons/MapIcons'

interface Summary {
  type: string
  distance?: number
  duration?: number
}

interface RoutesItemProps {
  summary: Summary
  middle?: boolean
  chosen?: boolean
  color?: string
  colorWithTransparency?: string
  onClick: (type: string) => void
}

const RoutesItem = ({
  summary,
  middle,
  chosen,
  color,
  colorWithTransparency,
  onClick
}: RoutesItemProps): JSX.Element => {
  const { locale } = useLocaleStore()
  const topBorders = middle
    ? 'rf-border rf-border-x-silver rf-border-y-transparent'
    : ''
  const bottomBorders = middle
    ? 'rf-border-x-truewhite'
    : 'rf-border-x-transparent'

  return (
    <div
      className='rf-flex rf-flex-col rf-justify-end hover:rf-cursor-pointer'
      onClick={(): void => onClick(summary.type)}
    >
      <div
        className={`rf-h-full rf-pr-2 rf-flex rf-flex-row rf-items-center rf-bg-opacity-50 ${topBorders}`}
        style={{
          backgroundColor: chosen
            ? colorWithTransparency || 'rgba(25, 153, 177, 0.5)'
            : undefined
        }}
      >
        <div className='rf-py-2 rf-flex rf-justify-center rf-items-center'>
          {summary.type === 'drivingCar' && (
            <Icons.DrivingCarIcon className='rf-w-8 rf-mx-2' />
          )}
          {summary.type === 'cyclingRegular' && (
            <Icons.CyclingRegularIcon className='rf-w-8 rf-mx-2' />
          )}
          {summary.type === 'footWalking' && (
            <Icons.FootWalkingIcon className='rf-w-8 rf-ml-1' />
          )}
        </div>
        <div className='rf-flex rf-py-1 rf-flex-col rf-justify-center rf-items-center'>
          <div className='rf-whitespace-nowrap'>
            {getDistanceAsString(summary?.distance)}
          </div>
          <div className='rf-whitespace-nowrap'>
            {getTimeAsString(summary?.duration)}
          </div>
        </div>
      </div>
      <div
        className={`rf-py-[0.1rem] rf-text-center rf-uppercase rf-border rf-border-t-0 rf-text-truewhite ${bottomBorders}`}
        style={{ backgroundColor: color || '#1999B1' }}
      >
        {translation[locale]?.route || translation['fr-FR'].route}
      </div>
    </div>
  )
}

export default RoutesItem
