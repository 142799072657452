import { CategoryId } from '../models/enums'

const getColorFromCategoryId = (
  id: string | undefined,
  themeColors: any
): string => {
  let color = themeColors.secondary
  if (id) {
    switch (id.toLowerCase()) {
      case CategoryId.eat:
        color = themeColors.whereToEatColor
        break
      case CategoryId.sleep:
        color = themeColors.whereToSleepColor
        break
      case CategoryId.see:
        color = themeColors.tobeSeenColor
        break
      case CategoryId.do:
        color = themeColors.tobeDoneColor
        break
      case CategoryId.localproducts:
        color = themeColors.localProductsColor
        break
      case CategoryId.services:
        color = themeColors.servicesColor
        break
      case CategoryId.agenda:
        color = themeColors.agendaColor
        break
      default:
        color = themeColors.secondary
        break
    }
  }

  return color
}

export { getColorFromCategoryId }
