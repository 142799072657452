import { hooks } from 'botframework-webchat'
import type { RetorikActivity } from '../models/activityTypes'

const { useActivities } = hooks

const useLastEventActivity = (): RetorikActivity | undefined => {
  const [activities] = useActivities()

  return activities
    .slice()
    .reverse()
    .find((activity) => activity.type === 'event')
}

export default useLastEventActivity
