import type { LinkAttachment, UrlData } from '../models/linkAttachment'
import QRCode from 'qrcode'
import { CONTENT_TYPE_LINK } from '../models/attachmentTypes'

const createLinkAttachmentCard = async ({
  url,
  title,
  download
}: UrlData): Promise<LinkAttachment> => {
  const qrcode = await QRCode.toDataURL(url)
  const hasTitle =
    title &&
    !title.includes('pause0x0') &&
    !title.includes('pause1x1') &&
    !title.includes('pause2x2')

  return {
    contentType: CONTENT_TYPE_LINK,
    content: {
      urlData: {
        url: url,
        title: hasTitle ? title : undefined,
        download: download
      },
      qrCode: qrcode
    }
  }
}

const createAttachmentsFromMultiLink = async (
  urlDataArray: Array<UrlData>
): Promise<Array<LinkAttachment>> => {
  const splitAttachments: Array<LinkAttachment> = []
  for (const data of urlDataArray) {
    const qrcode = await QRCode.toDataURL(data.url)

    const newLinkAttachment: LinkAttachment = {
      contentType: CONTENT_TYPE_LINK,
      content: {
        urlData: {
          url: data.url,
          title: data.title,
          download: data.download
        },
        qrCode: qrcode
      }
    }

    splitAttachments.push(newLinkAttachment)
  }

  return splitAttachments
}

export default createLinkAttachmentCard

export { createAttachmentsFromMultiLink }
