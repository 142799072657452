import React from 'react'
import { useView } from '../Contexts/ViewContext'
import { ZoomOutIcon } from '../Icons/Miscellaneous'
import { setFullScreenImage } from '../Contexts/utilsStore'

interface DisplayFullScreenButtonProps {
  url: string
}

const DisplayFullScreenButton = ({
  url
}: DisplayFullScreenButtonProps): JSX.Element => {
  const { themeColors } = useView()

  const handleClick = (): void => {
    setFullScreenImage(url)
  }

  return (
    <div
      className='rf-absolute rf-right-2 rf-top-2 rf-p-2 rf-border rf-rounded-lg rf-bg-cardFrameBackground rf-bg-opacity-30 rf-border-cardButtonBorderDefault hover:rf-cursor-pointer'
      onClick={handleClick}
    >
      <ZoomOutIcon
        className='rf-h-4 rf-w-4 large:rf-h-6 large:rf-w-6'
        color={themeColors.card.button.text.default}
      />
    </div>
  )
}

export default DisplayFullScreenButton
