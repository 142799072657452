{
  "_comment": "[CI-LOCKED] Kandungan fail ini telah dikunci. Sumbangan dialu-alukan, tetapi pengesahan perubahan mungkin ditunda.",
  "ACCESS_KEY_ALT": "Alt Shift $1",
  "ACCESS_KEY_FOR_MAC_ALT": "Control Option $1",
  "ACTIVITY_BOT_SAID_ALT": "Bot $1 berkata:",
  "ACTIVITY_YOU_SAID_ALT": "Anda berkata:",
  "ACTIVITY_BOT_ATTACHED_ALT": "Bot lampirkan:",
  "ACTIVITY_ERROR_BOX_TITLE": "Mesej ralat",
  "ACTIVITY_INTERACTIVE_FOOTNOTE_ALT": "Klik untuk berinteraksi.",
  "ACTIVITY_INTERACTIVE_REASON_INTERACTIVE_CONTENT_ALT": "Mesej adalah interaktif.",
  "ACTIVITY_INTERACTIVE_REASON_LINK_ALT": "Satu atau beberapa pautan dalam mesej.",
  "ACTIVITY_INTERACTIVE_REASON_SEND_FAILED_ALT": "Penghantaran telah gagal.",
  "ACTIVITY_YOU_ATTACHED_ALT": "Anda lampirkan:",
  "ACTIVITY_NUM_ATTACHMENTS_ONE_ALT": "1 lampiran.",
  "ACTIVITY_NUM_ATTACHMENTS_FEW_ALT": "$1 lampiran.",
  "ACTIVITY_NUM_ATTACHMENTS_MANY_ALT": "$1 lampiran.",
  "ACTIVITY_NUM_ATTACHMENTS_OTHER_ALT": "$1 lampiran.",
  "ACTIVITY_NUM_ATTACHMENTS_TWO_ALT": "$1 lampiran.",
  "ACTIVITY_STATUS_SEND_FAILED_RETRY": "Gagal dihantar. [Cuba Semula][RETRY].",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENDING": "Menghantar",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENT_AT": "Dihantar pada $1",
  "ACTIVITY_STATUS_TIMESTAMP_JUST_NOW": "Sebentar tadi",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_HOUR_AGO": "Sejam yang lalu",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_MINUTE_AGO": "Seminit yang lalu",
  "ACTIVITY_STATUS_TIMESTAMP_TODAY": "Hari Ini",
  "ACTIVITY_STATUS_TIMESTAMP_YESTERDAY": "Semalam",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_PARSE": "Ralat penguraian Kad Mudah Suai",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_RENDER": "Ralat pemaparan Kad Mudah Suai",
  "ATTACHMENT_AUDIO": "Klip audio.",
  "ATTACHMENT_CARD": "Kad: $1 $2 $3",
  "ATTACHMENT_FILE": "Fail: $1",
  "ATTACHMENT_IMAGE": "Imej",
  "ATTACHMENT_TEXT": "Teks: $1",
  "ATTACHMENT_VIDEO": "Klip video",
  "CAROUSEL_FLIPPER_LEFT_ALT": "Kiri",
  "CAROUSEL_FLIPPER_RIGHT_ALT": "Kanan",
  "CONNECTIVITY_STATUS_ALT_CONNECTED": "Disambungkan",
  "CONNECTIVITY_STATUS_ALT_CONNECTING": "Bersambung…",
  "CONNECTIVITY_STATUS_ALT_FATAL": "Tidak dapat bersambung.",
  "CONNECTIVITY_STATUS_ALT_RECONNECTING": "Gangguan rangkaian telah berlaku. Bersambung semula…",
  "CONNECTIVITY_STATUS_ALT_RENDER_ERROR": "Ralat telah berlaku. Sila semak konsol atau hubungi pembangun bot.",
  "CONNECTIVITY_STATUS_ALT_SLOW_CONNECTION": "Masa yang lebih lama daripada biasa diambil untuk bersambung.",
  "CONNECTIVITY_STATUS_ALT": "Status Sambungan: $1",
  "FILE_CONTENT_ALT": "'$1'",
  "FILE_CONTENT_DOWNLOADABLE_ALT": "Muat turun fail '$1'",
  "FILE_CONTENT_DOWNLOADABLE_WITH_SIZE_ALT": "Muat turun fail '$1' dengan saiz $2",
  "FILE_CONTENT_WITH_SIZE_ALT": "'$1' dengan saiz $2",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_BODY": "Kekunci enter",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_HEADER": "Akses item dalam mesej",
  "KEYBOARD_HELP_CHAT_HISTORY_HEADER": "Sejarah sembang",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_BODY": "Kekunci escape",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_HEADER": "Mesej keluar",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_BODY": "Kekunci tab\nKekunci shift + tab",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_HEADER": "Gerakkan antara item dalam mesej",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_BODY": "Kekunci anak panah atas\nKekunci anak panah bawah",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_HEADER": "Gerakkan antara mesej",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_BODY": "Kekunci enter",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_HEADER": "Lakukan tindakan",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_BODY": "Kekunci tab\nKekunci shift + tab",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_HEADER": "Gerakkan antara item dalam tetingkap sembang",
  "KEYBOARD_HELP_CHAT_WINDOW_HEADER": "Tetingkap sembang",
  "KEYBOARD_HELP_CLOSE_BUTTON_ALT": "Tutup",
  "KEYBOARD_HELP_HEADER": "Kawalan papan kekunci",
  "MARKDOWN_EXTERNAL_LINK_ALT": "Buka dalam tetingkap yang baharu; luaran.",
  "RECEIPT_CARD_TAX": "Cukai",
  "RECEIPT_CARD_TOTAL": "Jumlah",
  "RECEIPT_CARD_VAT": "VAT",
  "SEND_BOX_IS_EMPTY_TOOLTIP_ALT": "Tidak boleh menghantar mesej kosong.",
  "SPEECH_INPUT_LISTENING": "Mendengar…",
  "SPEECH_INPUT_MICROPHONE_BUTTON_CLOSE_ALT": "Mikrofon dimatikan",
  "SPEECH_INPUT_MICROPHONE_BUTTON_OPEN_ALT": "Mikrofon dihidupkan",
  "SPEECH_INPUT_STARTING": "Bermula…",
  "SUGGESTED_ACTIONS_LABEL_ALT": "Tindakan dicadangkan",
  "TEXT_INPUT_ALT": "Kotak input mesej",
  "TEXT_INPUT_PLACEHOLDER": "Taip mesej anda",
  "TEXT_INPUT_SEND_BUTTON_ALT": "Hantar",
  "TEXT_INPUT_SPEAK_BUTTON_ALT": "Cakap",
  "TEXT_INPUT_UPLOAD_BUTTON_ALT": "Muat naik fail",
  "TOAST_ACCORDION_FEW": "$1 Pemberitahuan: Klik di sini untuk melihat butiran",
  "TOAST_ACCORDION_MANY": "$1 Pemberitahuan: Klik di sini untuk melihat butiran",
  "TOAST_ACCORDION_OTHER": "$1 Pemberitahuan: Klik di sini untuk melihat butiran",
  "TOAST_ACCORDION_TWO": "$1 Pemberitahuan: Klik di sini untuk melihat butiran",
  "TOAST_ALT_ERROR": "Ralat",
  "TOAST_ALT_INFO": "Maklumat",
  "TOAST_ALT_SUCCESS": "Berjaya",
  "TOAST_ALT_WARN": "Amaran",
  "TOAST_DISMISS_BUTTON": "Tolak",
  "TOAST_TITLE_ALT": "Pemberitahuan",
  "TRANSCRIPT_ARIA_LABEL_ALT": "Sejarah sembang, tekan kekunci anak panah untuk menavigasi.",
  "TRANSCRIPT_ARIA_ROLE_ALT": "sejarah sembang",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_LABEL_ALT": "Mesej adalah interaktif. Tekan kekunci shift tab 2 hingga 3 kali untuk bertukar ke sejarah sembang. Kemudian klik pada mesej untuk berinteraksi.",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_WITH_LINKS_LABEL_ALT": "Satu atau lebih pautan dalam mesej. Tekan kekunci shift tab 2 hingga 3 kali untuk bertukar ke sejarah sembang. Kemudian klik pada mesej untuk berinteraksi.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_LABEL_ALT": "Mesej mempunyai tindakan dicadangkan. Tekan shift tab 2 hingga 3 kali untuk memilih tindakan.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_WITH_ACCESS_KEY_LABEL_ALT": "Mesej mempunyai tindakan dicadangkan. Tekan $1 untuk memilih tindakan.",
  "TRANSCRIPT_LIVE_REGION_SEND_FAILED_ALT": "Mesej gagal dihantar.",
  "TRANSCRIPT_MORE_MESSAGES": "Lebih banyak mesej",
  "TRANSCRIPT_NEW_MESSAGES": "Mesej baharu",
  "TRANSCRIPT_TERMINATOR_TEXT": "Tamat sejarah sembang",
  "TYPING_INDICATOR_ALT": "Menunjukkan penunjuk menaip",
  "TYPING_INDICATOR_SINGLE_TEXT": "$1 sedang menaip.",
  "TYPING_INDICATOR_MULTIPLE_TEXT": "$1 dan lain-lain sedang menaip."
}