import { CDNCharactersAddress } from '../models/constants'
import { RetorikMainComponentProps } from '../models/retorikTypes'
import processBaseURI from './studioRetorikAddressFromBaseURI'
import processPrefix from './studioRetorikAddressFromPrefix'

const getRetorikConfigs = async (
  props: RetorikMainComponentProps
): Promise<RetorikMainComponentProps> => {
  // eslint-disable-next-line prefer-const
  let retorikProps = { ...props }
  let res: RetorikMainComponentProps
  // Fetch tenant data config
  const baseAddress = `${
    retorikProps.addressData.baseURI &&
    retorikProps.addressData.baseURI?.length > 0
      ? processBaseURI(retorikProps.addressData.baseURI)
      : processPrefix(retorikProps.addressData.prefix)
  }/${
    retorikProps.addressData.tenant ? retorikProps.addressData.tenant + '/' : ''
  }`

  const fullAddress = `${baseAddress}api/integration/framework`
  const retorikConfig: RetorikMainComponentProps | null = await fetch(
    fullAddress
  )
    .then((res) => res.json())
    .then((json) => {
      // Process config
      return processJson(json, retorikProps, baseAddress)
    })
    .catch((error) => {
      console.log(error)
      return retorikProps
    })

  if (retorikConfig) {
    res = deepMerge(retorikConfig, retorikProps)
  } else {
    res = { ...retorikProps }
  }
  return res
}

const processJson = (
  json: any,
  retorikProps: RetorikMainComponentProps,
  baseAddress: string
): RetorikMainComponentProps | null => {
  if (json) {
    if (!json.enabled) {
      return null
    }
    // Get full adress for backgrounds & logo
    let logo, background, newsBackground, personnage
    if (json.personnage) {
      personnage = `${CDNCharactersAddress}/${json.personnage}`
    } else {
      personnage = undefined
    }
    if (
      retorikProps.addressData.tenant &&
      baseAddress.indexOf(`/${retorikProps.addressData.tenant}`)
    ) {
      baseAddress = baseAddress.replace(
        `/${retorikProps.addressData.tenant}`,
        ''
      )
    }
    baseAddress = baseAddress.endsWith('/')
      ? baseAddress.slice(0, -1)
      : baseAddress
    if (json.logo) {
      logo = `${baseAddress}${json.logo}`
    }
    if (json.background) {
      background = `${baseAddress}${json.background}`
    }
    if (json.newsBackground) {
      newsBackground = `${baseAddress}${json.newsBackground}`
    }

    const props: RetorikMainComponentProps = {
      config: {
        fullSize: undefined,
        isUsedOnBorne: undefined,
        locales: {
          default: json.defaultLocale || undefined,
          getDefaultFromBrowser: undefined
        },
        position: {
          searchForLocation: json.detectLocation || undefined,
          latitude: undefined,
          longitude: undefined,
          city: undefined,
          country: undefined
        },
        subtitles: json.subtitles || undefined,
        logo: json.logo ? { src: logo } : undefined,
        answerpanel: json.answerPanel || undefined,
        skipWelcome: json.skipWelcome || undefined,
        preventExpectedInputHint: json.preventExpectedInputHint || undefined,
        pagination: undefined,
        remote: undefined,
        companyName: json.companyName || undefined,
        subCompanyMessage: json.subCompanyMessage || undefined,
        loaderInformationTexts: undefined,
        hideRetorikLogo: json.hideRetorikLogo || undefined,
        baseMenu: undefined,
        customMenu: undefined,
        subMenu: undefined,
        hideMenu: json.hideMenu || undefined,
        hideControls: json.hideControls || undefined,
        doNotDetectDeviceFromUserAgent:
          json.doNotDetectDeviceFromUserAgent || undefined
      },
      viewsConfig: {
        homeRoute: 'home',
        webcamRotation: undefined,
        views: {
          home: {
            background: {
              style: 'image',
              image: background
            }
          },
          news: {
            intervalInSeconds: 2,
            loop: true,
            openingVideo: '',
            background: {
              style: 'image',
              image: newsBackground
            }
          }
        }
      },
      chatbotData: {
        size: undefined,
        height: undefined
      },
      agentData: personnage,
      ponyfillFactoryCredentials: {
        region: '',
        subscriptionKey: ''
      },
      addressData: retorikProps.addressData,
      userData: retorikProps.userData,
      externalEventHandler: undefined,
      customVoice: {
        voice: json.voice || undefined,
        gender: undefined
      },
      width: undefined,
      height: undefined,
      colors: {
        theme: json.colorSettings.theme || undefined,
        primary: json.colorSettings.primary || undefined,
        secondary: json.colorSettings.secondary || undefined,
        black: json.colorSettings.black || undefined,
        whereToEatColor: json.colorSettings.whereToEatColor || undefined,
        whereToSleepColor: json.colorSettings.whereToSleepColor || undefined,
        tobeSeenColor: json.colorSettings.tobeSeenColor || undefined,
        tobeDoneColor: json.colorSettings.tobeDoneColor || undefined,
        localProductsColor: json.colorSettings.localProductsColor || undefined,
        servicesColor: json.colorSettings.servicesColor || undefined,
        companyName: json.colorSettings.companyName || undefined,
        loader: {
          animation: json.colorSettings.loader?.animation || undefined,
          background: json.colorSettings.loader?.background || undefined,
          text: json.colorSettings.loader?.text || undefined,
          toggle: {
            text: json.colorSettings.loader?.toggle?.text || undefined,
            background:
              json.colorSettings.loader?.toggle?.background || undefined
          },
          button: {
            background: {
              default:
                json.colorSettings.loader?.button?.background?.default ||
                undefined,
              hover:
                json.colorSettings.loader?.button?.background?.hover ||
                undefined
            },
            border: {
              default:
                json.colorSettings.loader?.button?.border?.default || undefined,
              hover:
                json.colorSettings.loader?.button?.border?.hover || undefined
            },
            text: {
              default:
                json.colorSettings.loader?.button?.text?.default || undefined,
              hover: json.colorSettings.loader?.button?.text?.hover || undefined
            }
          }
        },
        card: {
          frame: {
            background: json.colorSettings.card?.frame?.background || undefined,
            border: json.colorSettings.card?.frame?.border || undefined,
            text: json.colorSettings.card?.frame?.text || undefined
          },
          button: {
            background: {
              default:
                json.colorSettings.card?.button?.background?.default ||
                undefined,
              hover:
                json.colorSettings.card?.button?.background?.hover || undefined
            },
            border: {
              default:
                json.colorSettings.card?.button?.border?.default || undefined,
              hover: json.colorSettings.card?.button?.border?.hover || undefined
            },
            text: {
              default:
                json.colorSettings.card?.button?.text?.default || undefined,
              hover: json.colorSettings.card?.button?.text?.hover || undefined
            }
          },
          discoverButton: {
            background: {
              default:
                json.colorSettings.card?.discoverButton?.background?.default ||
                undefined,
              hover:
                json.colorSettings.card?.discoverButton?.background?.hover ||
                undefined
            },
            border: {
              default:
                json.colorSettings.card?.discoverButton?.border?.default ||
                undefined,
              hover:
                json.colorSettings.card?.discoverButton?.border?.hover ||
                undefined
            },
            text: {
              default:
                json.colorSettings.card?.discoverButton?.text?.default ||
                undefined,
              hover:
                json.colorSettings.card?.discoverButton?.text?.hover ||
                undefined
            }
          }
        },
        textMode: {
          panel: {
            background:
              json.colorSettings.textMode?.panel?.background || undefined,
            border: json.colorSettings.textMode?.panel?.border || undefined,
            conversationUser:
              json.colorSettings.textMode?.panel?.conversationUser || undefined,
            conversationBot:
              json.colorSettings.textMode?.panel?.conversationBot || undefined
          }
        },
        vocalMode: {
          subtitles: {
            text: json.colorSettings.vocalMode?.subtitles?.text || undefined,
            background:
              json.colorSettings.vocalMode?.subtitles?.background || undefined
          }
        },
        formInput: {
          text: {
            default:
              json.colorSettings.formInput?.inputText?.default || undefined,
            hover: json.colorSettings.formInput?.inputText?.hover || undefined
          },
          inputRadioCheckbox: {
            unchecked: {
              background:
                json.colorSettings.formInput?.inputRadioCheckbox?.unchecked
                  ?.background || undefined,
              border:
                json.colorSettings.formInput?.inputRadioCheckbox?.unchecked
                  ?.border || undefined
            },
            checked: {
              background:
                json.colorSettings.formInput?.inputRadioCheckbox?.checked
                  ?.background || undefined,
              border:
                json.colorSettings.formInput?.inputRadioCheckbox?.checked
                  ?.border || undefined,
              item:
                json.colorSettings.formInput?.inputRadioCheckbox?.checked
                  ?.item || undefined
            }
          },
          inputButton: {
            background: {
              default:
                json.colorSettings.formInput?.inputButton?.background
                  ?.default || undefined,
              hover:
                json.colorSettings.formInput?.inputButton?.background?.hover ||
                undefined
            },
            border: {
              default:
                json.colorSettings.formInput?.inputButton?.border?.default ||
                undefined,
              hover:
                json.colorSettings.formInput?.inputButton?.border?.hover ||
                undefined
            },
            text: {
              default:
                json.colorSettings.formInput?.inputButton?.text?.default ||
                undefined,
              hover:
                json.colorSettings.formInput?.inputButton?.text?.hover ||
                undefined
            }
          },
          likert: {
            default: json.colorSettings.formInput?.likert?.default || undefined,
            hover: json.colorSettings.formInput?.likert?.hover || undefined
          }
        }
      },
      defaultMode: json.defaultMode
    }
    return props
  }
  return null
}

const deepMerge = (target, source): RetorikMainComponentProps => {
  const isObject = (obj): boolean => obj && typeof obj === 'object'

  if (!isObject(target) || !isObject(source)) {
    return source
  }

  Object.keys(target).forEach((key) => {
    const targetValue = target[key]
    const sourceValue = source[key]

    if (targetValue) {
      if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
        source[key] = targetValue.concat(sourceValue)
      } else if (isObject(targetValue) && isObject(sourceValue)) {
        source[key] = deepMerge(targetValue, sourceValue)
      } else {
        source[key] = targetValue
      }
    }
  })

  return source
}

export default getRetorikConfigs
