import React from 'react'
import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter'
import { ArrowLeftIcon } from '../../Icons/ArrowIcons'

interface BackButtonProps {
  title: string
  className?: string
  handleBack: () => void
}

const BackButton = ({
  title,
  className,
  handleBack
}: BackButtonProps): JSX.Element => {
  return (
    <button
      className={`rf-flex rf-flex-row rf-items-center ${className}`}
      onClick={handleBack}
      aria-label={title}
    >
      <ArrowLeftIcon className='rf-w-8' />
      <div className='rf-ml-1'>{capitalizeFirstLetter(title)}</div>
    </button>
  )
}

export default BackButton
