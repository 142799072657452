import React from 'react'
import type { IconDefaultProps } from '../../../models/types'

const TutorialIcon = ({ className, color }: IconDefaultProps): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      className={className || 'rf-h-6 rf-w-6'}
      viewBox='0 0 21.039 21.039'
    >
      <defs>
        <filter id='Icon_ionic-ios-help-buoy'>
          <feOffset dy='3' in='SourceAlpha' />
          <feGaussianBlur stdDeviation='3' result='blur' />
          <feFlood floodOpacity='0.161' result='color' />
          <feComposite operator='out' in='SourceGraphic' in2='blur' />
          <feComposite operator='in' in='color' />
          <feComposite operator='in' in2='SourceGraphic' />
        </filter>
      </defs>
      <g data-type='innerShadowGroup'>
        <path
          id='Icon_ionic-ios-help-buoy-2'
          data-name='Icon ionic-ios-help-buoy'
          d='M13.894,3.375A10.519,10.519,0,1,0,24.414,13.894,10.518,10.518,0,0,0,13.894,3.375Zm2.761,1.841a9.126,9.126,0,0,1,5.917,5.917l-4.491.3a4.856,4.856,0,0,0-1.714-1.72Zm-9.2,2.24a9.038,9.038,0,0,1,3.677-2.24l.288,4.5a4.968,4.968,0,0,0-1.714,1.714l-4.5-.3A9.139,9.139,0,0,1,7.456,7.456Zm3.677,15.117a9.126,9.126,0,0,1-5.917-5.917l4.5-.288a4.9,4.9,0,0,0,1.709,1.709Zm2.761-5.239a3.439,3.439,0,1,1,3.439-3.439A3.443,3.443,0,0,1,13.894,17.333Zm6.438,3a9.072,9.072,0,0,1-3.677,2.24l-.3-4.5a4.872,4.872,0,0,0,1.725-1.73l4.491.3A9.056,9.056,0,0,1,20.332,20.332Z'
          transform='translate(-3.375 -3.375)'
          fill={color || '#a4a7af'}
        />
        <g
          transform='matrix(1, 0, 0, 1, 0, 0)'
          filter='url(#Icon_ionic-ios-help-buoy)'
        >
          <path
            d='M13.894,3.375A10.519,10.519,0,1,0,24.414,13.894,10.518,10.518,0,0,0,13.894,3.375Zm2.761,1.841a9.126,9.126,0,0,1,5.917,5.917l-4.491.3a4.856,4.856,0,0,0-1.714-1.72Zm-9.2,2.24a9.038,9.038,0,0,1,3.677-2.24l.288,4.5a4.968,4.968,0,0,0-1.714,1.714l-4.5-.3A9.139,9.139,0,0,1,7.456,7.456Zm3.677,15.117a9.126,9.126,0,0,1-5.917-5.917l4.5-.288a4.9,4.9,0,0,0,1.709,1.709Zm2.761-5.239a3.439,3.439,0,1,1,3.439-3.439A3.443,3.443,0,0,1,13.894,17.333Zm6.438,3a9.072,9.072,0,0,1-3.677,2.24l-.3-4.5a4.872,4.872,0,0,0,1.725-1.73l4.491.3A9.056,9.056,0,0,1,20.332,20.332Z'
            transform='translate(-3.38 -3.38)'
            fill='#fff'
          />
        </g>
      </g>
    </svg>
  )
}

export default TutorialIcon
