import React, { useEffect, useState } from 'react'
import { useLocaleStore } from '../../Contexts/localeStore'
import translation from '../../../translations/poi.json'
import QRCode from 'qrcode'

interface DownloadQRProps {
  url: string
}

const DownloadQR = ({ url }: DownloadQRProps): JSX.Element => {
  const locale = useLocaleStore((state) => state.locale)
  const [qrCodeData, setQrCodeData] = useState<string | undefined>(undefined)

  useEffect(() => {
    setQrCodeDataAsync()
  }, [])

  const setQrCodeDataAsync = async (): Promise<void> => {
    const qrCodeString = await QRCode.toDataURL(url)
    setQrCodeData(qrCodeString)
  }

  return (
    <div className='rf-w-full rf-flex rf-flex-col rf-items-start large-vertical:rf-items-center'>
      {`${translation[locale]?.download || translation['fr-FR'].download} ${
        translation[locale]?.scanQR || translation['fr-FR'].scanQR
      }`}
      {qrCodeData && (
        <div className='rf-mt-4'>
          <img src={qrCodeData} alt='QR Code' />
        </div>
      )}
    </div>
  )
}

export default DownloadQR
