import { create } from 'zustand'

interface WidgetStore {
  widgetDisplayType: string
  open: boolean
  loaded: boolean
}

export const useWidgetStore = create<WidgetStore>()(() => {
  return {
    widgetDisplayType: 'large',
    open: false,
    loaded: false
  }
})

export const openWidget = (): void => {
  useWidgetStore.setState({
    open: true,
    loaded: true
  })
}

export const closeWidget = (): void => {
  useWidgetStore.setState({
    open: false,
    loaded: true
  })
}

export const toggleWidget = (): void => {
  const open = useWidgetStore.getState().open
  useWidgetStore.setState({
    open: !open,
    loaded: true
  })
}

export const toggleDisplay = (): void => {
  const displayType = useWidgetStore.getState().widgetDisplayType
  useWidgetStore.setState({
    widgetDisplayType: displayType === 'large' ? 'thumbnail' : 'large'
  })
}
