/* eslint-disable react/self-closing-comp */
import React from 'react'
import type { IconDefaultProps } from '../../../models/types'

const WeatherIcon = ({ className, color }: IconDefaultProps): JSX.Element => {
  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      className={className || 'rf-h-6 rf-w-6'}
      viewBox='0 0 512 512'
      xmlSpace='preserve'
    >
      <g fill={color || '#1999B1'}>
        <g>
          <path
            d='M439.2,385.3c0.7-3.5,1-7,1-10.6c0-31.2-25.4-56.5-56.5-56.5c-6.5,0-13,1.1-19.1,3.3c-1.4-3.6-3-7.1-4.8-10.4
			C392,287.3,411,249.8,411,209.6c0-69.5-56.5-126-126-126c-69.5,0-126,56.5-126,126c0,5.7,0.4,11.3,1.1,16.9
			c-16.7,9.5-30,24.6-37.2,43c-5.5-1.9-11.4-2.9-17.3-2.9c-29.1,0-52.7,23.6-52.7,52.7c0,3.2,0.3,6.4,0.9,9.5
			c-20.8,10.3-34.5,31.8-34.5,55.3c0,34,27.6,61.6,61.6,61.6c0.7,0.2,1.4,0.3,2.1,0.3h26.1c2.9,15.6,10.3,29.9,21.6,41.4
			c14.2,14.4,33,22.8,53.2,23.7c0.8,0.3,1.6,0.4,2.5,0.4h221.5c0.8,0,1.5-0.1,2.2-0.3c0,0,0.1,0,0.1,0c36.5,0,66.2-29.7,66.2-66.2
			C476.6,419.5,461.8,396.3,439.2,385.3z M173.1,209.6c0-61.7,50.2-112,112-112s112,50.2,112,112c0,35.4-16.7,68.5-44.8,89.6
			c-17-22.6-44-36.7-73.4-36.7c-0.1,0-0.1,0-0.2,0c-14.3-28.8-43.8-47.2-76.2-47.2c-10,0-19.7,1.8-28.8,5.1
			C173.3,216.7,173.1,213.2,173.1,209.6z M85.4,432c-0.8-0.3-1.7-0.4-2.5-0.4c-0.6,0-1.2,0-1.8,0c-26.3,0-47.6-21.4-47.6-47.6
			c0-19.8,12.5-37.8,31.1-44.7c3.5-1.3,5.4-5.1,4.3-8.6c-1.1-3.7-1.7-7.6-1.7-11.5c0-21.3,17.4-38.7,38.7-38.7
			c6.3,0,12.6,1.6,18.2,4.5c1.9,1,4.1,1.1,6,0.3c1.9-0.8,3.4-2.5,4-4.5c8.6-30.4,36.8-51.6,68.4-51.6c25.2,0,48.2,13.3,61,34.5
			c-42.5,7.2-75.1,43.8-76.2,88c-43.7,0.3-79.2,35.9-79.2,79.7c0,0.2,0,0.4,0,0.6H85.4z M410.3,497.2c-0.7,0-1.3,0-2-0.1
			c-0.9,0-1.8,0.1-2.6,0.4H188.9c-0.7-0.2-1.4-0.4-2.2-0.4c-17.4-0.3-33.6-7.2-45.8-19.6c-12.2-12.4-18.9-28.8-18.9-46.1
			c0-36.2,29.5-65.7,65.7-65.7c1.9,0,3.9,0.1,6,0.3c2,0.2,4-0.5,5.5-1.9s2.2-3.4,2.1-5.4c-0.1-1.6-0.1-3.1-0.1-4.5
			c0-42.8,34.8-77.7,77.7-77.7c34.6,0,65.3,23.2,74.7,56.4c0.6,2,2,3.7,4,4.5c1.9,0.8,4.2,0.7,6-0.3c6.1-3.3,13-5,20-5
			c23.5,0,42.5,19.1,42.5,42.5c0,4.3-0.6,8.6-1.9,12.6c-1.1,3.6,0.8,7.3,4.3,8.6c20.4,7.6,34.1,27.2,34.1,49
			C462.6,473.8,439.2,497.2,410.3,497.2z'
          />
          <path d='M485.4,203.6H443c-3.9,0-7,3.1-7,7s3.1,7,7,7h42.4c3.9,0,7-3.1,7-7S489.3,203.6,485.4,203.6z' />
          <path d='M84.6,217.6h42.4c3.9,0,7-3.1,7-7s-3.1-7-7-7H84.6c-3.9,0-7,3.1-7,7S80.7,217.6,84.6,217.6z' />
          <path d='M284.7,58.9c3.9,0,7-3.1,7-7V9.5c0-3.9-3.1-7-7-7s-7,3.1-7,7v42.4C277.7,55.7,280.8,58.9,284.7,58.9z' />
          <path
            d='M360,79.4c1.1,0.6,2.3,0.9,3.5,0.9c2.4,0,4.8-1.3,6.1-3.5l21.2-36.7c1.9-3.3,0.8-7.6-2.6-9.6
			s-7.6-0.8-9.6,2.6l-21.2,36.7C355.5,73.2,356.7,77.4,360,79.4z'
          />
          <path
            d='M199.7,76.8c1.3,2.2,3.6,3.5,6.1,3.5c1.2,0,2.4-0.3,3.5-0.9c3.3-1.9,4.5-6.2,2.6-9.6l-21.2-36.7
			c-1.9-3.3-6.2-4.5-9.6-2.6s-4.5,6.2-2.6,9.6L199.7,76.8z'
          />
          <path
            d='M107.8,116.6l36.7,21.2c1.1,0.6,2.3,0.9,3.5,0.9c2.4,0,4.8-1.3,6.1-3.5c1.9-3.3,0.8-7.6-2.6-9.6l-36.7-21.2
			c-3.3-1.9-7.6-0.8-9.6,2.6S104.5,114.7,107.8,116.6z'
          />
          <path
            d='M422.3,138.7c1.2,0,2.4-0.3,3.5-0.9l36.7-21.2c3.3-1.9,4.5-6.2,2.6-9.6c-1.9-3.3-6.2-4.5-9.6-2.6l-36.7,21.2
			c-3.3,1.9-4.5,6.2-2.6,9.6C417.5,137.5,419.9,138.7,422.3,138.7z'
          />
          <path
            d='M416.5,286.6c-1.9,3.3-0.8,7.6,2.6,9.6l36.7,21.2c1.1,0.6,2.3,0.9,3.5,0.9c2.4,0,4.8-1.3,6.1-3.5
			c1.9-3.3,0.8-7.6-2.6-9.6l-36.7-21.2C422.8,282.1,418.5,283.3,416.5,286.6z'
          />
        </g>
      </g>
    </svg>
  )
}

export default WeatherIcon
