import React, { useEffect, useState } from 'react'
import { useUtilsStore } from '../../Contexts/utilsStore'
import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter'
import { checkForData } from '../../../utils/localStorageInputsUtils'
import { CurrentSubView } from '../../../models/enums'

interface MultiSelectProps {
  id: string
  choices: any
  smallImages: boolean
  showCaptions: boolean
  handleSelect: (value: Array<string>) => void
}

const MultiSelect = ({
  id,
  choices,
  smallImages,
  showCaptions,
  handleSelect
}: MultiSelectProps): JSX.Element => {
  const { currentSubView } = useUtilsStore()
  const [display, setDisplay] = useState<Array<any>>([])
  const [chosen, setChosen] = useState<Array<string>>(checkForData(id, []))
  const [overflow, setOverflow] = useState<boolean>(false)
  const [style, setStyle] = useState<string>('')

  /**
   * On choices props change :
   *  - fill display state with choices value
   *  - set overflow and style states depending on the smallImages boolean prop and the number of choices
   */
  useEffect(() => {
    if (choices) {
      setDisplay(choices)
      if (smallImages) {
        if (choices.length > 6) {
          setOverflow(true)
          setStyle(
            'rf-flex rf-flex-row rf-justify-start rf-align-center rf-overflow-x-scroll rf-pb-4'
          )
        } else {
          setOverflow(false)
          setStyle('rf-grid rf-grid-rows-2 rf-grid-cols-3')
        }
      } else {
        if (choices.length > 2) {
          setOverflow(true)
          setStyle(
            'rf-flex rf-flex-row rf-justify-start rf-align-center rf-overflow-x-scroll rf-pb-4'
          )
        } else {
          setOverflow(false)
          setStyle('rf-grid rf-grid-rows-1 rf-grid-cols-2')
        }
      }
    }
  }, [choices])

  /**
   * On chosen state change :
   *  - call parent's handleChangeCheckbox method
   */
  useEffect(() => {
    handleSelect(chosen)
  }, [chosen])

  /**
   * On call:
   *  - check if the component will be rendered in history, if so bypass every interaction
   *  - check for checked / unchecked values and fill chosen state with these
   *  - call parent's handleSelect method
   * @param value : string
   */
  const handleChange = (value: string): void => {
    if (currentSubView !== CurrentSubView.history) {
      let tempChosen = [...chosen]
      if (tempChosen.includes(value)) {
        tempChosen = tempChosen.filter((chosenValue) => chosenValue !== value)
      } else {
        tempChosen = [...tempChosen, value]
      }
      setChosen(tempChosen)
    }
  }

  return display && display.length > 0 ? (
    <div
      className={`rf-w-full rf-gap-4 rf-text-size-auto rf-text-white ${style} rf-scrollbar-x-custom`}
    >
      {display.map((choice, key) => {
        return (
          <div
            key={key}
            className={`rf-flex rf-flex-col rf-items-start ${
              overflow && 'rf-flex-[0_0_40%]'
            }`}
          >
            <label
              className={`rf-w-full rf-h-full rf-flex rf-justify-center rf-items-center rf-border-2 rf-rounded-lg ${
                chosen.includes(choice.value)
                  ? 'rf-border-primary'
                  : 'rf-border-formInputRadioCheckboxUncheckedBorder'
              } hover:rf-cursor-pointer rf-aspect-square rf-overflow-hidden`}
            >
              <img src={choice.url} className='rf-max-w-full rf-max-h-full' />
              <input
                type='checkbox'
                value={choice.value}
                checked={chosen.includes(choice.value)}
                className='rf-input-hidden'
                onChange={(): void => handleChange(choice.value)}
              />
            </label>
            {showCaptions && (
              <div className='rf-mt-2 rf-truncate'>
                {capitalizeFirstLetter(choice.title)}
              </div>
            )}
          </div>
        )
      })}
    </div>
  ) : (
    <React.Fragment />
  )
}

export default MultiSelect
