import { webchatTokenRetrievalAddress } from '../models/constants'

const processBaseURI = (baseURI: string): string => {
  if (baseURI && baseURI.length > 0) {
    if (baseURI.indexOf(`/${webchatTokenRetrievalAddress}`) > -1) {
      baseURI = baseURI.replace(`/${webchatTokenRetrievalAddress}`, '')
    }
  }
  return baseURI
}

export default processBaseURI
