{
  "_comment": "[CI-LOCKED] Innholdet i denne filen er låst. Bidrag er velkomne, men validering av endring kan bli forsinket.",
  "ACCESS_KEY_ALT": "Alt Skift $1",
  "ACCESS_KEY_FOR_MAC_ALT": "Kontroll Tilvalg $1",
  "ACTIVITY_BOT_SAID_ALT": "Roboten $1 sa:",
  "ACTIVITY_YOU_SAID_ALT": "Du sa:",
  "ACTIVITY_BOT_ATTACHED_ALT": "Roboten la ved:",
  "ACTIVITY_ERROR_BOX_TITLE": "Feilmelding",
  "ACTIVITY_INTERACTIVE_FOOTNOTE_ALT": "Klikk for å samhandle.",
  "ACTIVITY_INTERACTIVE_REASON_INTERACTIVE_CONTENT_ALT": "Meldingen er interaktiv.",
  "ACTIVITY_INTERACTIVE_REASON_LINK_ALT": "En eller flere koblinger i meldingen.",
  "ACTIVITY_INTERACTIVE_REASON_SEND_FAILED_ALT": "Sending mislyktes.",
  "ACTIVITY_YOU_ATTACHED_ALT": "Du la ved:",
  "ACTIVITY_NUM_ATTACHMENTS_ONE_ALT": "1 vedlegg.",
  "ACTIVITY_NUM_ATTACHMENTS_FEW_ALT": "$1 vedlegg.",
  "ACTIVITY_NUM_ATTACHMENTS_MANY_ALT": "$1 vedlegg.",
  "ACTIVITY_NUM_ATTACHMENTS_OTHER_ALT": "$1 vedlegg.",
  "ACTIVITY_NUM_ATTACHMENTS_TWO_ALT": "$1 vedlegg.",
  "ACTIVITY_STATUS_SEND_FAILED_RETRY": "Sending mislyktes. [Prøv på nytt][RETRY].",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENDING": "Sender",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENT_AT": "Sendt klokken $1",
  "ACTIVITY_STATUS_TIMESTAMP_JUST_NOW": "Akkurat nå",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_HOUR_AGO": "For en time siden",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_MINUTE_AGO": "For et minutt siden",
  "ACTIVITY_STATUS_TIMESTAMP_TODAY": "I dag",
  "ACTIVITY_STATUS_TIMESTAMP_YESTERDAY": "I går",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_PARSE": "Feil ved analyse av AdaptiveCard",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_RENDER": "Feil ved gjengivelse av AdaptiveCard",
  "ATTACHMENT_AUDIO": "Et lydklipp.",
  "ATTACHMENT_CARD": "Et kort: $1 $2 $3",
  "ATTACHMENT_FILE": "En fil: $1",
  "ATTACHMENT_IMAGE": "Et bilde",
  "ATTACHMENT_TEXT": "En tekst: $1",
  "ATTACHMENT_VIDEO": "Et videoklipp",
  "CAROUSEL_FLIPPER_LEFT_ALT": "Venstre",
  "CAROUSEL_FLIPPER_RIGHT_ALT": "Rettigheter",
  "CONNECTIVITY_STATUS_ALT_CONNECTED": "Tilkoblet",
  "CONNECTIVITY_STATUS_ALT_CONNECTING": "Kobler til ...",
  "CONNECTIVITY_STATUS_ALT_FATAL": "Kan ikke koble til.",
  "CONNECTIVITY_STATUS_ALT_RECONNECTING": "Det oppstod et nettverksbrudd. Kobler til på nytt ...",
  "CONNECTIVITY_STATUS_ALT_RENDER_ERROR": "Gjengivelsesfeil. Kontroller konsollen eller kontakt robotutvikleren.",
  "CONNECTIVITY_STATUS_ALT_SLOW_CONNECTION": "Tar lengre tid enn vanlig å koble til.",
  "CONNECTIVITY_STATUS_ALT": "Tilkoblingsstatus: $1",
  "FILE_CONTENT_ALT": "$1",
  "FILE_CONTENT_DOWNLOADABLE_ALT": "Last ned filen $1",
  "FILE_CONTENT_DOWNLOADABLE_WITH_SIZE_ALT": "Last ned filen $1 med størrelsen $2",
  "FILE_CONTENT_WITH_SIZE_ALT": "$1 med størrelsen $2",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_BODY": "Enter-tast",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_HEADER": "Få tilgang til elementer i meldingen",
  "KEYBOARD_HELP_CHAT_HISTORY_HEADER": "Chattelogg",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_BODY": "ESC-tast",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_HEADER": "Lukk melding",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_BODY": "Tabulatortast\nSkift + tabulatortast",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_HEADER": "Flytt mellom elementer i en melding",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_BODY": "Pil opp-tast\nPil ned-tast",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_HEADER": "Flytt mellom meldinger",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_BODY": "Enter-tast",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_HEADER": "Utfør handling",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_BODY": "Tabulatortast\nSkift + tabulatortast",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_HEADER": "Flytt mellom elementer i chatvinduet",
  "KEYBOARD_HELP_CHAT_WINDOW_HEADER": "Chatvindu",
  "KEYBOARD_HELP_CLOSE_BUTTON_ALT": "Lukk",
  "KEYBOARD_HELP_HEADER": "Tastaturkontroller",
  "MARKDOWN_EXTERNAL_LINK_ALT": "Åpnes i et nytt vindu, eksternt.",
  "RECEIPT_CARD_TAX": "Avgift",
  "RECEIPT_CARD_TOTAL": "Totalt",
  "RECEIPT_CARD_VAT": "Merverdiavgift",
  "SEND_BOX_IS_EMPTY_TOOLTIP_ALT": "Kan ikke sende tom melding.",
  "SPEECH_INPUT_LISTENING": "Lytter ...",
  "SPEECH_INPUT_MICROPHONE_BUTTON_CLOSE_ALT": "Mikrofon av",
  "SPEECH_INPUT_MICROPHONE_BUTTON_OPEN_ALT": "Mikrofon på",
  "SPEECH_INPUT_STARTING": "Starter ...",
  "SUGGESTED_ACTIONS_LABEL_ALT": "Foreslåtte handlinger",
  "TEXT_INPUT_ALT": "Meldingsinndataboks",
  "TEXT_INPUT_PLACEHOLDER": "Skriv inn meldingen",
  "TEXT_INPUT_SEND_BUTTON_ALT": "Send",
  "TEXT_INPUT_SPEAK_BUTTON_ALT": "Tal",
  "TEXT_INPUT_UPLOAD_BUTTON_ALT": "Last opp fil",
  "TOAST_ACCORDION_FEW": "$1 varslinger: Klikk her for å se detaljer",
  "TOAST_ACCORDION_MANY": "$1 varslinger: Klikk her for å se detaljer",
  "TOAST_ACCORDION_OTHER": "$1 varslinger: Klikk her for å se detaljer",
  "TOAST_ACCORDION_TWO": "$1 varslinger: Klikk her for å se detaljer",
  "TOAST_ALT_ERROR": "Feil",
  "TOAST_ALT_INFO": "Informasjon",
  "TOAST_ALT_SUCCESS": "Vellykket",
  "TOAST_ALT_WARN": "Advarsel!",
  "TOAST_DISMISS_BUTTON": "Forkast",
  "TOAST_TITLE_ALT": "Varsling",
  "TRANSCRIPT_ARIA_LABEL_ALT": "Chattelogg, trykk på piltastene for å navigere.",
  "TRANSCRIPT_ARIA_ROLE_ALT": "chattelogg",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_LABEL_ALT": "Meldingen er interaktiv. Trykk på skift tabulatortasten to til tre ganger for å bytte til chatteloggen. Deretter klikker du på meldingen for å samhandle.",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_WITH_LINKS_LABEL_ALT": "En eller flere koblinger i meldingen. Trykk på skift tabulatortasten to til tre ganger for å bytte til chatteloggen. Deretter klikker du på meldingen for å samhandle.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_LABEL_ALT": "Meldingen har foreslåtte handlinger. Trykk på skift tab to til tre ganger for å velge dem.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_WITH_ACCESS_KEY_LABEL_ALT": "Meldingen har foreslåtte handlinger. Trykk på $1 for å velge dem.",
  "TRANSCRIPT_LIVE_REGION_SEND_FAILED_ALT": "Kan ikke sende meldingen.",
  "TRANSCRIPT_MORE_MESSAGES": "Flere meldinger",
  "TRANSCRIPT_NEW_MESSAGES": "Nye meldinger",
  "TRANSCRIPT_TERMINATOR_TEXT": "Slutten på chatteloggen",
  "TYPING_INDICATOR_ALT": "Viser skriveindikatoren",
  "TYPING_INDICATOR_SINGLE_TEXT": "$1 skriver.",
  "TYPING_INDICATOR_MULTIPLE_TEXT": "$1 og andre skriver."
}