import React from 'react'
import { useView } from '../Contexts/ViewContext'
import type { TemplateDefaultProps } from '../../models/types'
import { FullScreen } from '.'

const FullScreenMobile = ({
  className,
  depth,
  background,
  children
}: TemplateDefaultProps): JSX.Element => {
  const { isMobile, displayControls } = useView()

  return (
    <FullScreen
      className={`${
        isMobile && displayControls && 'rf-mb-12'
      } rf-p-4 rf-flex rf-flex-col ${className}`}
      depth={depth}
      background={background || '#FFFFFF'}
    >
      {children}
    </FullScreen>
  )
}

export default FullScreenMobile
