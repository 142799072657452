/* eslint-disable react/jsx-boolean-value */
import React from 'react'
import { jsxToHtmlElement } from '../../../utils/jsxToHtmlElement'
import { updateData } from '../../../utils/localStorageInputsUtils'
import Radio from '../ChoiceSet/Radio'
import RadioAsButton from '../ChoiceSet/RadioAsButton'
import Checkbox from '../ChoiceSet/Checkbox'
import CheckboxAsButton from '../ChoiceSet/CheckboxAsButton'
import Select from '../ChoiceSet/Select'
import * as AC from 'adaptivecards'

interface Choice {
  title: string
  value: string
}

export class ChoiceSetClass extends AC.ChoiceSetInput {
  private _value

  static readonly JsonTypeName = 'ChoiceSetClass'

  getJsonTypeName(): string {
    return ChoiceSetClass.JsonTypeName
  }

  // Add custom preperties to schema
  static readonly splitProperty = new AC.BoolProperty(
    AC.Versions.v1_0,
    'split',
    false
  )

  static readonly displayAsButtonProperty = new AC.BoolProperty(
    AC.Versions.v1_0,
    'asButtons',
    false
  )

  // Split property accessors
  @AC.property(ChoiceSetClass.splitProperty)
  get split(): boolean {
    return !!this.getValue(ChoiceSetClass.splitProperty)
  }

  set split(value: boolean) {
    if (this.split !== value) {
      this.setValue(ChoiceSetClass.splitProperty, value)
      this.updateLayout()
    }
  }

  // AsButtons property accessors
  @AC.property(ChoiceSetClass.displayAsButtonProperty)
  get asButtons(): boolean {
    return !!this.getValue(ChoiceSetClass.displayAsButtonProperty)
  }

  set asButtons(value: boolean) {
    if (this.asButtons !== value) {
      this.setValue(ChoiceSetClass.displayAsButtonProperty, value)
      this.updateLayout()
    }
  }

  public get value(): any {
    return this._value
  }

  public isSet(): any {
    return this._value
  }

  private handleSelectOne = (value: string | undefined): void => {
    this._value = value
    this.id && updateData(this.id, value)
  }

  private handleSelect = (choice: Choice): void => {
    this._value = choice.value
    this.id && updateData(this.id, choice.value)
  }

  private handleChangeCheckbox = (value: Array<string>): void => {
    this._value = value
    this.id && updateData(this.id, value)
  }

  protected internalRender(): HTMLElement {
    const element = jsxToHtmlElement(this.renderReact())
    return element
  }

  protected renderReact = (): JSX.Element => {
    // Return empty fragment if no id provided
    if (!this.id) {
      return <React.Fragment />
    }

    // Check if we use checkboxes, radio buttons or select input
    let inputType = 'select'
    if (this.asButtons) {
      inputType = this.isMultiSelect ? 'checkboxbuttons' : 'radiobuttons'
    } else if (!this.isCompact) {
      inputType = this.isMultiSelect ? 'checkbox' : 'radio'
    }

    switch (inputType) {
      case 'select':
        break
      case 'checkbox':
        this._value = []
        return (
          <Checkbox
            id={this.id}
            choices={this.choices}
            split={this.split}
            handleChangeCheckbox={this.handleChangeCheckbox}
          />
        )
      case 'radio':
        return (
          <Radio
            id={this.id}
            choices={this.choices}
            split={this.split}
            handleSelect={this.handleSelectOne}
          />
        )
      case 'checkboxbuttons':
        return (
          <CheckboxAsButton
            id={this.id}
            choices={this.choices}
            handleChangeCheckbox={this.handleChangeCheckbox}
          />
        )
      case 'radiobuttons':
        return (
          <RadioAsButton
            id={this.id}
            choices={this.choices}
            handleSelect={this.handleSelectOne}
          />
        )
    }

    return (
      <Select
        id={this.id}
        choices={this.choices}
        placeholder={this.placeholder}
        handleSelect={this.handleSelect}
      />
    )
  }
}
