import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import type { RootState, AppDispatch } from './store' 

// Use throughout your app instead of plain `useDispatch` and `useSelector`
const useAppDispatch = () => useDispatch<AppDispatch>()
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

const useIsLicenceAllowed = () => useAppSelector((state: RootState) => state.licenceReducer.value.allowed)
const useLicence = () => useAppSelector((state: RootState) => state.licenceReducer.value.licence)
const useErrorCode = () => useAppSelector((state: RootState) => state.licenceReducer.value.errorCode)
const useData = () => useAppSelector((state: RootState) => state.licenceReducer.value)

export {
    useAppDispatch,
    useAppSelector,
    useIsLicenceAllowed,
    useLicence,
    useData,
    useErrorCode
}