import React, { useEffect, useState, useRef } from 'react'
import translation from '../../translations/news.json'
import { useLocaleStore } from '../Contexts/localeStore'

interface TimeProps {
  belowReturnButton?: boolean
}

const Time = ({ belowReturnButton }: TimeProps): JSX.Element => {
  const { locale } = useLocaleStore()
  const [time, setTime] = useState<string>('')
  const timerRef: React.MutableRefObject<any> = useRef(null)

  /**
   * Set current time (hours and minutes) depending on the locale (ex: adds AM/PM when needed)
   */
  const setCurrentTime = (): void => {
    const now = new Date()
    setTime(
      now.toLocaleTimeString(locale, {
        hour: '2-digit',
        minute: '2-digit'
      })
    )
  }

  /**
   * On component mount :
   *  - set time
   *  - set interval for time update
   * On component unmount :
   *  - clear interval
   */
  useEffect(() => {
    setCurrentTime()
    timerRef && (timerRef.current = setInterval(setCurrentTime, 1000))

    return (): void => timerRef && clearInterval(timerRef.current)
  }, [])

  return (
    <div
      className={`rf-relative ${
        belowReturnButton
          ? 'rf-ml-4'
          : 'rf-col-start-1 rf-col-span-2 rf-row-start-1 rf-row-span-2 rf-self-start rf-justify-self-start rf-m-4 large:rf-m-8'
      }`}
    >
      <div className='rf-w-fit rf-h-fit rf-flex rf-flex-col rf-justify-start rf-items-start rf-text-white'>
        {/* Text 'Live' + round */}
        <div className='rf-w-full rf-text-size-auto rf-px-3 rf-py-0 large:rf-px-4 large:rf-py-1 rf-text-center rf-font-semibold rf-bg-red-600 rf-whitespace-nowrap'>
          {`${(
            translation[locale]?.live || translation['fr-FR'].live
          ).toLocaleUpperCase(locale)} \u25cf`}
        </div>
        {/* Time */}
        <div className='rf-w-full rf-title-size-auto rf-px-3 rf-py-0 large:rf-px-4 large:rf-py-1 rf-text-center rf-font-bold rf-bg-blue-500'>
          {time}
        </div>
      </div>
    </div>
  )
}

export default Time
