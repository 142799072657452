import { hooks } from 'botframework-webchat'
import { useSpeech } from '../components/Contexts/SpeechContext'
import type { RetorikActivity } from '../models/activityTypes'

const { useActivities } = hooks

export default function useCurrentOrLastPlayedActivity():
  | RetorikActivity
  | undefined {
  const [activities] = useActivities()
  const { currentPlaying } = useSpeech()
  const { endedActivities } = useSpeech()

  if (!currentPlaying) {
    if (endedActivities.length > 0) {
      return (
        activities.find(
          (activity) =>
            activity.id === endedActivities[endedActivities.length - 1]
        ) || undefined
      )
    }
  } else {
    return currentPlaying
  }

  return undefined
}
