import React, { useEffect, useRef, useState } from 'react'
import { useList } from '../../Contexts/ListContext'
import { useView } from '../../Contexts/ViewContext'
import type { DirectLineAttachment } from 'botframework-webchat-core'
import {
  CONTENT_TYPE_EMPTY,
  CONTENT_TYPE_POI,
  CONTENT_TYPE_PAGINATION
} from '../../../models/attachmentTypes'
import { useRenderAttachment } from 'botframework-webchat-api/lib/hooks'
import { DeviceType } from '../../../models/enums'
import translation from '../../../translations/poi.json'
import GridTemplate from '../../Templates/Grid'
import { useLocaleStore } from '../../Contexts/localeStore'

interface ListContainerProps {
  hasFilterOrSuggestedAction: boolean
}

const ListContainer = ({
  hasFilterOrSuggestedAction
}: ListContainerProps): JSX.Element => {
  const locale = useLocaleStore((state) => state.locale)
  const { content } = useList()
  const { currentDeviceType, isMobile } = useView()
  const [contentToDisplay, setContentToDisplay] = useState<
    DirectLineAttachment[]
  >([])
  const [landscapeStyle, setLandscapeStyle] = useState<string>('large:rf-h-3/5')
  const [limitToFill, setLimitToFill] = useState<number>(0)
  const renderAttachment = useRenderAttachment()
  const scrollRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setLandscapeStyle(hasFilterOrSuggestedAction ? 'large:rf-h-[60vh]' : '')
  }, [hasFilterOrSuggestedAction])

  useEffect(() => {
    switch (currentDeviceType) {
      case DeviceType.landscape:
        setLimitToFill(10)
        break
      case DeviceType.borne:
        setLimitToFill(9)
        break
      case DeviceType.mobile:
      case DeviceType.widget:
        setLimitToFill(0)
        break
    }
  }, [currentDeviceType])

  useEffect(() => {
    if (content?.attachments && content.attachments.length > 0) {
      // Transform first and last attachments if they are 'previous' or 'next' cards
      if (content.attachments[0].contentType !== CONTENT_TYPE_POI) {
        content.attachments[0].contentType = CONTENT_TYPE_PAGINATION
      }
      if (
        content.attachments.length > 2 &&
        content.attachments[content.attachments.length - 1].contentType !==
          CONTENT_TYPE_POI
      ) {
        content.attachments[content.attachments.length - 1].contentType =
          CONTENT_TYPE_PAGINATION
      }
      // On a mobile device, if there is an odd number of attachments, we put a grey one at the end
      if (isMobile && content.attachments.length % 2 !== 0) {
        setContentToDisplay([
          ...content?.attachments,
          { contentType: CONTENT_TYPE_EMPTY }
        ])
      }
      // On a landscape device, we fill with grey cards if less than 10 attachments, same on borne device with less than 9
      else if (content.attachments.length < limitToFill) {
        const tempAttachments = [...content.attachments]
        let i = content.attachments.length
        while (i < limitToFill) {
          tempAttachments.push({ contentType: CONTENT_TYPE_EMPTY })
          i++
        }
        setContentToDisplay(tempAttachments)
      } else {
        setContentToDisplay(content.attachments)
      }
    } else {
      setContentToDisplay([])
    }
  }, [content, limitToFill])

  /**
   * On call :
   *  - scroll to the scrollRef HTML element at the top of the view
   */
  const scrollToRef = (): void => {
    if (scrollRef && scrollRef.current !== null) {
      scrollRef.current.scrollIntoView({
        behavior: 'smooth'
      })
    }
  }

  /**
   * On content change :
   *  - call scrollToRef method if we are not in mobile mobe
   */
  useEffect(() => {
    const timer =
      content && !isMobile
        ? setTimeout(() => {
            scrollToRef()
          }, 100)
        : null

    return (): void => {
      timer && clearTimeout(timer)
    }
  }, [content])

  return content ? (
    <div
      className={`rf-w-full rf-mb-8 ${landscapeStyle} large-vertical:rf-h-[50vh] rf-overflow-y-visible large:rf-overflow-y-scroll rf-scrollbar-hidden ${
        !isMobile && 'rf-opacity-gradient-b-thin'
      } rf-px-2 large:rf-px-0 rf-pb-8 large:rf-pb-16`}
    >
      <div ref={scrollRef} />
      <GridTemplate>
        {contentToDisplay.length === 0 ? (
          <div>
            {translation[locale]?.nodata || translation['fr-FR'].nodata}
          </div>
        ) : (
          contentToDisplay.map((attachment, key) => {
            return (
              <div key={key}>
                {renderAttachment({ activity: content, attachment })}
              </div>
            )
          })
        )}
      </GridTemplate>
    </div>
  ) : (
    <React.Fragment />
  )
}

export default ListContainer
