import React from 'react'
import { useView } from '../../Contexts/ViewContext'
import { useLocaleStore } from '../../Contexts/localeStore'
import translation from '../../../translations/menu.json'

interface ConversationHeaderProps {
  agentName?: string
  companyName?: string
  subCompanyMessage?: string
  imageUrl?: string
}

const ConversationHeader = ({
  agentName = 'Léa',
  companyName = 'Davi',
  subCompanyMessage,
  imageUrl = ''
}: ConversationHeaderProps): JSX.Element => {
  const { themeColors } = useView()
  const { locale } = useLocaleStore()

  return (
    <div
      id='retorik-conversation-header'
      className='rf-w-full rf-h-fit rf-grid rf-grid-cols-4 rf-grid-rows-headerContainer rf-bg-truewhite'
    >
      {/* Avatar */}
      <div className='rf-col-start-1 rf-col-end-2 rf-row-start-1 rf-row-end-4 rf-flex rf-items-center rf-px-2 rf-py-2'>
        <img
          src={imageUrl}
          alt='Agent'
          className='rf-w-full rf-aspect-square rf-rounded-half rf-border-secondary rf-border-2 rf-bg-truewhite'
        />
      </div>

      {/* Company */}
      <div
        className='rf-col-start-2 rf-col-span-full rf-row-start-1 rf-row-end-2 rf-flex rf-items-end rf-font-bold'
        style={{
          color: themeColors.companyName
        }}
      >
        {companyName}
      </div>

      {/* Agent Title */}
      <div className='rf-col-start-2 rf-col-span-full rf-row-start-3 rf-row-end-4 rf-flex rf-items-start rf-text-sm rf-italic'>
        {subCompanyMessage ||
          `${agentName} ${
            translation[locale]?.answer || translation['fr-FR'].answer
          }`}
      </div>

      <div className='rf-col-start-2 rf-col-span-full rf-row-start-2 rf-row-end-3 -rf-ml-2 rf-flex rf-items-center'>
        <hr className='rf-h-1 rf-bg-gray-200 rf-border-0 rf-w-full rf-shadow-[0_3px_6px_rgba(0,0,0,0.16)] rf-mb-1' />
      </div>
    </div>
  )
}

export { ConversationHeader }
