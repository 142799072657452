import React from 'react'
import type { IconDefaultProps } from '../../../models/types'

const SendMessageIcon = ({
  className,
  color
}: IconDefaultProps): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className || 'rf-w-6'}
      viewBox='0 0 23.207 23.207'
    >
      <g transform='translate(0.5 0.707)'>
        <path
          d='M28.6,3,16.5,15.1'
          transform='translate(-6.6 -3)'
          fill='none'
          stroke={color || '#1999b1'}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1'
        />
        <path
          d='M25,3,17.3,25l-4.4-9.9L3,10.7Z'
          transform='translate(-3 -3)'
          fill='none'
          stroke={color || '#1999b1'}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1'
        />
      </g>
    </svg>
  )
}

export default SendMessageIcon
