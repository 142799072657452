import React from 'react'
import { useView } from '../Contexts/ViewContext'
import RemoteContainer from './Remote/RemoteContainer'
import MobileContainer from './Mobile/MobileContainer'
import { LanguageButton } from './Common'

interface MenuDisplayerProps {
  hideMenu?: boolean
}

const MenuDisplayer = ({ hideMenu }: MenuDisplayerProps): JSX.Element => {
  const { isMobile, displayControls } = useView()

  return !displayControls ? (
    <React.Fragment />
  ) : isMobile ? (
    <MobileContainer hideMenu={!!hideMenu} />
  ) : (
    <React.Fragment>
      <RemoteContainer hideMenu={!!hideMenu} />
      <LanguageButton />
    </React.Fragment>
  )
}

export default MenuDisplayer
