import React, { useState, useEffect, useRef } from 'react'
import { useLocaleStore } from '../..'
import { useRetorik } from '../Contexts/RetorikContext'
import { useView } from '../Contexts/ViewContext'
import translation from '../../translations/loader.json'
import ToggleWithTexts from '../Utils/ToggleWithTexts'
import Animation from './Animation'
import useRefDimensions from '../../hooks/useRefDimensions'
import { Mode } from '../../models/enums'
import { RetorikLogoIcon } from '../Icons/Miscellaneous'

const LoaderCallToAction = ({ handleValidation }): JSX.Element => {
  const {
    mode,
    setMode,
    configuration: { loaderInformationTexts, hideRetorikLogo }
  } = useRetorik()
  const { locale } = useLocaleStore()
  const { themeColors, isMobile } = useView()
  const [textSize, setTextSize] = useState<string>('landscape')
  const container = useRef<HTMLDivElement>(null)
  const dimensions = useRefDimensions(container)
  const [hover, setHover] = useState<boolean>(false)
  const animationColor = {
    '--rf-color-loader-animation': themeColors.loader.animation
  } as React.CSSProperties

  /**
   * On container dimension change :
   *  - check which display mode is active (mobile, borne , landscape)
   *  - set text size depending on the display mode
   */
  useEffect(() => {
    let isPortrait = true
    if (
      !(
        dimensions.width &&
        dimensions.height &&
        dimensions.width < dimensions.height
      )
    ) {
      isPortrait = false
    }

    if (dimensions.width && dimensions.width > 600) {
      setTextSize(isPortrait ? 'rf-text-lgcustom' : 'rf-text-basecustom')
    } else {
      setTextSize('rf-text-sm')
    }
  }, [dimensions])

  const handleChange = (modeId: number): void => {
    const tempMode = modeId === 1 ? Mode.vocal : Mode.text
    setMode(tempMode)
  }

  return (
    <div
      className={`rf-absolute rf-z-modal rf-top-0 rf-left-0 rf-h-full rf-w-full rf-flex rf-flex-col rf-items-center ${textSize}`}
      ref={container}
      style={{
        background: themeColors.loader.background,
        ...animationColor
      }}
    >
      {/* Animation + Text */}
      <div className='rf-w-full rf-h-1/2 rf-pb-6 rf-flex rf-flex-col rf-items-center rf-justify-end'>
        {/* Animation */}
        <div className='rf-w-6 rf-h-6 rf-mb-16'>
          <Animation />
        </div>
        {/* Text */}
        <div
          className='rf-text-center'
          style={{
            color: themeColors.loader.text
          }}
        >
          {/* First line of informations */}
          {loaderInformationTexts?.vocal.top &&
          loaderInformationTexts.text.top ? (
            <p>
              {mode === Mode.vocal
                ? loaderInformationTexts?.vocal.top
                : loaderInformationTexts.text.top}
            </p>
          ) : (
            <p>
              {mode === Mode.vocal
                ? translation[locale]?.loader.optimal ||
                  translation['fr-FR'].loader.optimal
                : translation[locale]?.loader.classic ||
                  translation['fr-FR'].loader.classic}
            </p>
          )}

          {/* Second line of informations */}
          {loaderInformationTexts?.vocal.top &&
          loaderInformationTexts.text.top ? (
            loaderInformationTexts?.vocal.bottom &&
            loaderInformationTexts.text.bottom && (
              <p className='rf-font-bold'>
                {mode === Mode.vocal
                  ? loaderInformationTexts?.vocal.bottom
                  : loaderInformationTexts.text.bottom}
              </p>
            )
          ) : (
            <p className='rf-font-bold'>
              {mode === Mode.vocal
                ? translation[locale]?.loader.vocal ||
                  translation['fr-FR'].loader.vocal
                : translation[locale]?.loader.text ||
                  translation['fr-FR'].loader.text}
            </p>
          )}
        </div>
      </div>

      {/* Button + Logo */}
      <div
        className={`rf-w-full rf-h-1/2 rf-flex rf-flex-col ${
          hideRetorikLogo ? 'rf-justify-start' : 'rf-justify-between'
        } rf-items-center`}
      >
        <div className='rf-flex rf-flex-col rf-items-center'>
          {/* Toggle button with both modes */}
          <ToggleWithTexts
            texts={{
              left:
                translation[locale]?.modes.vocal ||
                translation['fr-FR'].modes.vocal,
              leftId: 1,
              right:
                translation[locale]?.modes.text ||
                translation['fr-FR'].modes.text,
              rightId: 2
            }}
            colors={themeColors.loader.toggle}
            defaultChecked={mode}
            handleChange={handleChange}
          />
          {/* Validation button or step text */}
          <button
            className='rf-font-bold rf-border-2 rf-rounded-lg rf-px-6 rf-py-3 rf-mt-8'
            style={{
              color: hover
                ? themeColors.loader.button.text.hover
                : themeColors.loader.button.text.default,
              borderColor: hover
                ? themeColors.loader.button.border.hover
                : themeColors.loader.button.border.default,
              background: hover
                ? themeColors.loader.button.background.hover
                : themeColors.loader.button.background.default
            }}
            onClick={handleValidation}
            onMouseEnter={(): void => setHover(true)}
            onMouseLeave={(): void => setHover(false)}
          >
            {translation[locale]?.loaderValidation ||
              translation['fr-FR'].loaderValidation}
          </button>
        </div>
        {/* Logo */}
        {!hideRetorikLogo &&
          (isMobile ? (
            <RetorikLogoIcon className='rf-mb-10 rf-h-14' />
          ) : (
            <div className='rf-w-full rf-flex rf-justify-start'>
              <RetorikLogoIcon className='rf-ml-4 rf-mb-4 rf-h-14' />
            </div>
          ))}
      </div>
    </div>
  )
}

export default LoaderCallToAction
