import React from 'react'
import type { IconDefaultProps } from '../../../models/types'

const HistoryIcon = ({ className, color }: IconDefaultProps): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      className={className || 'rf-w-6 rf-flex-1'}
      viewBox='0 0 14 14'
    >
      <defs>
        <filter id='Icon_awesome-history'>
          <feOffset dy='3' in='SourceAlpha' />
          <feGaussianBlur stdDeviation='3' result='blur' />
          <feFlood floodOpacity='0.161' result='color' />
          <feComposite operator='out' in='SourceGraphic' in2='blur' />
          <feComposite operator='in' in='color' />
          <feComposite operator='in' in2='SourceGraphic' />
        </filter>
      </defs>
      <g>
        <path
          d='M14.562,7.549A7,7,0,0,1,3.169,13.013a.677.677,0,0,1-.052-1.005l.318-.318a.679.679,0,0,1,.9-.056A5.194,5.194,0,1,0,4,3.778L5.437,5.211a.452.452,0,0,1-.319.771h-4.1A.452.452,0,0,1,.563,5.53v-4.1a.452.452,0,0,1,.771-.319L2.727,2.5A7,7,0,0,1,14.562,7.549ZM9.456,9.773l.277-.356a.677.677,0,0,0-.119-.951L8.466,7.572V4.627a.677.677,0,0,0-.677-.677H7.337a.677.677,0,0,0-.677.677V8.456L8.505,9.892A.677.677,0,0,0,9.456,9.773Z'
          transform='translate(-0.563 -0.563)'
          fill={color || '#a4a7af'}
        />
        <g
          transform='matrix(1, 0, 0, 1, 0, 0)'
          filter='url(#Icon_awesome-history)'
        >
          <path
            d='M14.562,7.549A7,7,0,0,1,3.169,13.013a.677.677,0,0,1-.052-1.005l.318-.318a.679.679,0,0,1,.9-.056A5.194,5.194,0,1,0,4,3.778L5.437,5.211a.452.452,0,0,1-.319.771h-4.1A.452.452,0,0,1,.563,5.53v-4.1a.452.452,0,0,1,.771-.319L2.727,2.5A7,7,0,0,1,14.562,7.549ZM9.456,9.773l.277-.356a.677.677,0,0,0-.119-.951L8.466,7.572V4.627a.677.677,0,0,0-.677-.677H7.337a.677.677,0,0,0-.677.677V8.456L8.505,9.892A.677.677,0,0,0,9.456,9.773Z'
            transform='translate(-0.56 -0.56)'
            fill='#fff'
          />
        </g>
      </g>
    </svg>
  )
}

export default HistoryIcon
