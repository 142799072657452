import { checkForData } from './localStorageInputsUtils'

const getDateAsArray = (
  id: string,
  dateAsString: string | undefined
): Array<number> => {
  // Test local storage first, then parameter dateAsString, finally get current date if nothing has been found
  const dateFromLocalStorage = checkForData(id, dateAsString)
  if (dateFromLocalStorage) {
    const resultFromLocal = processSplitDate(dateFromLocalStorage.split('-'))
    if (resultFromLocal) {
      return resultFromLocal
    }
  }

  const now = new Date()

  return [now.getFullYear(), now.getMonth() + 1, now.getDate()]
}

const processSplitDate = (
  splitDate: Array<string>
): Array<number> | undefined => {
  if (splitDate.length === 3) {
    const year = parseInt(splitDate[0])
    const month = parseInt(splitDate[1])
    const day = parseInt(splitDate[2])
    if (!isNaN(year) && !isNaN(month) && !isNaN(day)) {
      return [year, month, day]
    }
  }

  return undefined
}

const getMonthAsString = (month: number, locale: string): string => {
  const date = new Date(`2000-${month}-1`).toLocaleString(locale, {
    month: 'long'
  })

  return date
}

export { getDateAsArray, getMonthAsString }
