import React, { useEffect, useState } from 'react'
import type { WidgetConfiguration, WidgetFrame } from '../models/types'
import type { RetorikMainComponentProps } from '../models/retorikTypes'
import { CloseIcon } from './Icons/Miscellaneous'
import ShadowRoot from './Common/ShadowRoot'
import RetorikContainer from './Common/RetorikContainer'
import {
  getWidgetBorderStyle,
  getWidgetButtonStyle
} from '../utils/getWidgetStyles'
import translation from '../translations/widget.json'
import { widgetDefault } from '../models/colors'
import {
  useWidgetStore,
  openWidget,
  closeWidget,
  toggleWidget,
  toggleDisplay
} from './Contexts/widgetStore'
import { ContainerParent } from '../models/enums'

window.Retorik = {
  ...window.Retorik,
  openWidget,
  closeWidget,
  toggleWidget,
  toggleDisplay
}

type RetorikWidgetProps = {
  widgetConfig: WidgetConfiguration
}

const RetorikWidget = (
  props: RetorikMainComponentProps & RetorikWidgetProps
): JSX.Element => {
  // --------- /!\ -----------
  // Ne mettre ici que de la logique spécifique au widget
  // Ce qui est commun avec RetorikAgent ou autres doit aller dans RetorikComposer ou ContextProvider
  // -------------------------

  const { open, loaded, widgetDisplayType } = useWidgetStore()
  const [currentConfiguration, setCurrentConfiguration] = useState<
    WidgetFrame | undefined
  >(props.widgetConfig?.large)
  const [widgetButton, setWidgetButton] = useState<string>('')
  const [widgetBorder, setWidgetBorder] = useState<string>('')
  const [mobileWidth, setMobileWidth] = useState<boolean>(
    window.innerWidth < 600
  )
  const [frameStyle, setFrameStyle] = useState<Record<string, any>>({})

  const handleResize = (): void => {
    setMobileWidth(window.innerWidth < 600)
  }

  useEffect(() => {
    setWidgetButton(getWidgetButtonStyle(props.widgetConfig?.button))
    window.addEventListener('resize', handleResize)

    return (): void => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    mobileWidth
      ? setFrameStyle({
          width: open ? '100%' : '0px',
          height: '100%'
        })
      : setFrameStyle({
          top: currentConfiguration?.position?.top,
          bottom: currentConfiguration?.position?.bottom
            ? currentConfiguration?.position?.bottom
            : currentConfiguration?.position?.top ||
              currentConfiguration?.position?.left ||
              currentConfiguration?.position?.right
            ? undefined
            : '1rem',
          left: currentConfiguration?.position?.left,
          right: currentConfiguration?.position?.right
            ? currentConfiguration?.position?.right
            : currentConfiguration?.position?.top ||
              currentConfiguration?.position?.bottom ||
              currentConfiguration?.position?.left
            ? undefined
            : '1rem',
          borderColor: currentConfiguration?.border?.color || widgetDefault.rgb
        })
  }, [mobileWidth, currentConfiguration])

  useEffect(() => {
    switch (widgetDisplayType) {
      case 'large':
        setCurrentConfiguration(props.widgetConfig?.large)
        setWidgetBorder(getWidgetBorderStyle(props.widgetConfig?.large?.border))
        break
      case 'thumbnail':
        setCurrentConfiguration(
          props.widgetConfig?.thumbnail || props.widgetConfig?.large
        )
        setWidgetBorder(
          getWidgetBorderStyle(
            props.widgetConfig?.thumbnail?.border ||
              props.widgetConfig?.large?.border
          )
        )
        break
      default:
        break
    }
  }, [widgetDisplayType])

  return (
    <ShadowRoot>
      {props.widgetConfig?.button?.display !== false && (
        <button
          className={`rf-fixed rf-px-2 rf-flex rf-flex-row rf-items-center ${widgetButton}`}
          style={{
            color: props.widgetConfig?.button?.text?.color || widgetDefault.rgb,
            backgroundColor:
              props.widgetConfig?.button?.background?.color || 'transparent',
            borderWidth:
              props.widgetConfig?.button?.border?.thickness !== undefined
                ? `${props.widgetConfig?.button?.border?.thickness}px`
                : '1px',
            borderColor:
              props.widgetConfig?.button?.border?.color || widgetDefault.rgb,
            top: props.widgetConfig?.button?.position?.top,
            bottom: props.widgetConfig?.button?.position?.bottom
              ? props.widgetConfig?.button?.position?.bottom
              : props.widgetConfig?.button?.position?.top ||
                props.widgetConfig?.button?.position?.left ||
                props.widgetConfig?.button?.position?.right
              ? undefined
              : '1rem',
            left: props.widgetConfig?.button?.position?.left,
            right: props.widgetConfig?.button?.position?.right
              ? props.widgetConfig?.button?.position?.right
              : props.widgetConfig?.button?.position?.top ||
                props.widgetConfig?.button?.position?.bottom ||
                props.widgetConfig?.button?.position?.left
              ? undefined
              : '1rem'
          }}
          onClick={toggleWidget}
          id='retorik-widget-button'
        >
          {/* Image on the left if there is a URL and either position = 'left' or no position */}
          {props.widgetConfig?.button?.image?.url &&
            props.widgetConfig?.button?.image?.position !== 'right' && (
              <img
                className={`${
                  props.widgetConfig?.button?.image.marginY === false
                    ? ''
                    : 'rf-my-1'
                }`}
                src={`${props.widgetConfig.button.image.url}`}
                alt='Retorik widget'
                style={{
                  height: props.widgetConfig?.button?.image.height || '2rem'
                }}
              />
            )}
          {/* Text content */}
          <div className='rf-py-2 rf-ml-1'>
            {props.widgetConfig?.button?.text?.content ||
              (navigator.language && translation[navigator.language]?.button) ||
              translation['fr-FR'].button}
          </div>
          {/* Image on the right if there is a URL and position = 'right' */}
          {props.widgetConfig?.button?.image?.url &&
            props.widgetConfig?.button?.image?.position === 'right' && (
              <img
                className={`${
                  props.widgetConfig?.button?.image.marginY === false
                    ? ''
                    : 'rf-my-1'
                }`}
                src={`${props.widgetConfig.button.image.url}`}
                alt='Retorik widget'
                style={{
                  height: props.widgetConfig?.button?.image.height || '2rem'
                }}
              />
            )}
        </button>
      )}
      {loaded && (
        <div
          className={`rf-fixed rf-pt-8 rf-height-fill-available rf-transition-all rf-overflow-hidden ${
            mobileWidth ? 'rf-max-w-[100vw]' : 'rf-max-w-[600px]'
          }`}
          style={{
            ...frameStyle,
            width: open
              ? mobileWidth
                ? '100%'
                : currentConfiguration?.width || '400px'
              : '0px',
            height:
              currentConfiguration?.height || (mobileWidth ? '100%' : '630px')
          }}
          data-testid='widget-wrapper'
        >
          <button
            onClick={toggleWidget}
            aria-label='Close'
            className='rf-absolute rf-z-exitwidget rf-flex rf-right-0 rf-top-0 rf-p-1 rf-bg-truewhite rf-border rf-border-trueblack rf-rounded-lg'
          >
            <CloseIcon className='rf-h-4 rf-w-4' color='#000000' />
          </button>
          <div
            className={`rf-relative rf-h-full rf-w-full rf-overflow-hidden ${
              !mobileWidth && widgetBorder
            }`}
            style={{
              borderColor: frameStyle.borderColor
            }}
          >
            <RetorikContainer
              {...{
                parent: ContainerParent.widget,
                ...props,
                width: 'full',
                height: 'full',
                mobile: mobileWidth
              }}
            />
          </div>
        </div>
      )}
    </ShadowRoot>
  )
}

export default RetorikWidget
