/* eslint-disable react/jsx-boolean-value */
import React, { useEffect, useState } from 'react'
import RetorikComposer from './RetorikComposer'
import RetorikRouter from './RetorikRouter'
import Container from './Container'
import ContextProvider from '../Contexts/ContextProvider'
import getThemeColors from '../../utils/getThemeColors'
import type { RetorikMainComponentProps } from '../../models/retorikTypes'
import type { ThemeColors } from '../../models/colorTypes'
import NewsView from '../Views/NewsView'
import { ContainerParent, Mode } from '../../models/enums'
import SpeechManager from '../Speech/SpeechManager'
import MobileLandscapePreventer from '../Utils/MobileLandscapePreventer'
import getRetorikConfigs from '../../utils/getConfigs'
import { useRetorik } from '../Contexts/RetorikContext'
import { AgentData } from '../../models/types'

type RetorikContainerProps = RetorikMainComponentProps & {
  parent: number
}

const RetorikContainer = (props: RetorikContainerProps): JSX.Element => {
  const [themeColors, setThemeColors] = useState<ThemeColors>(
    getThemeColors(props.colors)
  )
  const [isConfigUpdated, setIsConfigUpdated] = useState<boolean>(false)
  const { setConfiguration, setAgentData } = useRetorik()
  const [skipLoader, setSkipLoader] = useState(props.skipLoader)
  const [defaultMode, setDefaultMode] = useState(props.defaultMode)
  const [parent] = useState(props.parent)
  const [config, setConfig] = useState(props.config)
  const [viewsConfig, setViewsConfig] = useState(props.viewsConfig)
  const [chatbotData, setChatbotData] = useState(props.chatbotData)
  const [agentData, setagentData] = useState(props.agentData)
  const [ponyfillFactoryCredentials, setPonyfillFactoryCredentials] = useState(
    props.ponyfillFactoryCredentials
  )
  const [addressData, setAddressData] = useState(props.addressData)
  const [userData, setUserData] = useState(props.userData)
  const [externalEventHandler, setExternalEventHandler] = useState<any>(
    props.externalEventHandler
  )
  const [width, setWidth] = useState(props.width)
  const [height, setHeight] = useState(props.height)
  const [customVoice, setCustomVoice] = useState(props.customVoice)
  const [externalComponents, setExternalComponents] = useState(
    props.externalComponents
  )
  useEffect(() => {
    const getUpdatedConfig = async (): Promise<void> => {
      const {
        skipLoader,
        defaultMode,
        config: _config,
        viewsConfig,
        chatbotData,
        agentData,
        ponyfillFactoryCredentials,
        addressData,
        userData,
        externalEventHandler,
        width,
        height,
        customVoice,
        colors,
        externalComponents
      } = await getRetorikConfigs(props)
      setSkipLoader(skipLoader)
      setDefaultMode(defaultMode)
      setConfig(_config)
      setViewsConfig(viewsConfig)
      setChatbotData(chatbotData)
      setagentData(agentData)
      setPonyfillFactoryCredentials(ponyfillFactoryCredentials)
      setAddressData(addressData)
      setUserData(userData)
      setExternalEventHandler(externalEventHandler)
      setWidth(width)
      setHeight(height)
      setCustomVoice(customVoice)
      setThemeColors(getThemeColors(colors))
      setExternalComponents(externalComponents)
    }
    getUpdatedConfig().then(() => {
      setIsConfigUpdated(true)
    })
  }, [])

  useEffect(() => {
    if (isConfigUpdated) {
      setConfiguration(config)
      if (agentData && typeof agentData === 'object')
        setAgentData(agentData as AgentData)
    }
  }, [isConfigUpdated])

  return (
    <ContextProvider
      skipLoader={skipLoader}
      mode={defaultMode || Mode.vocal}
      viewsConfiguration={viewsConfig}
      config={config}
      addressData={addressData}
      agentSource={agentData}
      ponyfillFactoryCredentials={ponyfillFactoryCredentials}
      themeColors={themeColors}
      isConfigUpdated={isConfigUpdated}
    >
      <MobileLandscapePreventer />
      <RetorikComposer
        addressData={addressData}
        userData={userData}
        externalEventHandler={externalEventHandler}
        customVoice={customVoice}
        colors={themeColors}
      >
        <SpeechManager />
        <Container
          fullSize={
            parent !== ContainerParent.widget && config.fullSize !== false
          }
          width={width}
          height={height}
          colors={themeColors}
          parent={parent}
        >
          {parent === ContainerParent.news ? (
            <NewsView
              chatbotData={chatbotData}
              hideMenu={true}
              isRetorikNews={true}
            />
          ) : (
            <RetorikRouter
              chatbotData={chatbotData}
              externalComponents={externalComponents}
            />
          )}
        </Container>
      </RetorikComposer>
    </ContextProvider>
  )
}

export default RetorikContainer
