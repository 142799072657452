import React, { useEffect, useRef, useState } from 'react'
import type { NewsContent } from '../../models/attachmentTypes'
import { useView } from '../Contexts/ViewContext'
import { useLocaleStore } from '../Contexts/localeStore'
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter'
import translation from '../../translations/news.json'

interface BannerProps {
  news: NewsContent
}

const Banner = ({ news }: BannerProps): JSX.Element => {
  const { locale } = useLocaleStore()
  const { currentWidth, isMobile } = useView()
  const timerRef: React.MutableRefObject<any> = useRef(null)
  const [scrollDuration, setScrollDuration] = useState<number>(0)
  const [imageSize, setImageSize] = useState<number>(0)
  const elementRef = useRef<HTMLDivElement>(null)

  /**
   * Set animation for text horizontal scrolling if necessary
   */
  const updateDuration = (): void => {
    // Reset animation
    setScrollDuration(0)
    // Set animation, after a 1s timeout
    timerRef &&
      (timerRef.current = setTimeout(() => {
        setScrollDuration(Math.floor(news.text.length / 11))
      }, 1000))
  }

  /**
   * On news props change :
   *  - call updateDuration function
   * On component unmount :
   *  - clear timeout
   */
  useEffect(() => {
    updateDuration()

    return (): void => timerRef && clearTimeout(timerRef.current)
  }, [news])

  /**
   * On ref change :
   *  - set the width of the image container equal to the category container's width
   */
  useEffect(() => {
    elementRef.current && setImageSize(elementRef.current.clientWidth)
  }, [elementRef])

  return (
    <div className='rf-h-fit rf-col-start-1 rf-col-span-full rf-row-start-9 large:rf-row-start-10 rf-row-span-3 large:rf-mb-6 rf-flex rf-flex-col rf-self-end rf-items-center rf-glass-background rf-text-white'>
      {/* Category + Title */}
      <div className='rf-w-full rf-flex rf-flex-row rf-justify-start rf-text-size-auto'>
        <div
          className='rf-px-4 rf-pb-2 rf-pt-2 large:rf-pt-3 rf-font-bold rf-bg-red-600 rf-whitespace-nowrap'
          ref={elementRef}
        >
          {(
            news.category ||
            translation[locale]?.continuous ||
            translation['fr-FR'].continuous
          ).toLocaleUpperCase(locale)}
        </div>
        <div className='rf-px-4 rf-mb-2 rf-mt-2 large:rf-mt-3 rf-font-bold rf-line-clamp-1'>
          {news.title && news.title.toLocaleUpperCase(locale)}
        </div>
      </div>

      {/* Subtitle + image in mobile / borne mode */}
      <div className='rf-w-full rf-h-fit rf-flex rf-flex-row rf-justify-start'>
        {isMobile && news.image?.url && (
          <div
            style={{
              minWidth: `${imageSize}px`,
              backgroundImage: `url(${news.image?.url})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center'
            }}
          />
        )}
        <div className='rf-px-4 rf-my-2 large:rf-my-3 rf-title-large-size-auto rf-line-clamp-2'>
          {news.subtitle ? capitalizeFirstLetter(news.subtitle) : '...'}
        </div>
      </div>

      {/* Text */}
      <div
        className='rf-w-full rf-pr-4 rf-py-4 rf-subtitle-size-auto rf-text-black rf-bg-white rf-overflow-clip'
        style={{
          paddingLeft: currentWidth
        }}
      >
        {scrollDuration !== 0 ? (
          <p
            className='rf-w-max rf-whitespace-nowrap rf-animate-hScroll'
            style={{
              animation: `rf-hScroll ${scrollDuration}s infinite linear`,
              paddingRight: currentWidth
            }}
          >
            {news.text ? capitalizeFirstLetter(news.text) : '...'}
          </p>
        ) : (
          <p className='rf-w-max rf-whitespace-nowrap'>
            {news.text ? capitalizeFirstLetter(news.text) : '...'}
          </p>
        )}
      </div>
    </div>
  )
}

export default Banner
