import React, { useState } from 'react'
import { useRetorik } from '../../Contexts/RetorikContext'
import { useView } from '../../Contexts/ViewContext'
import { hooks } from 'botframework-webchat'
import { Mode as ModeEnum } from '../../../models/enums'
import MicrophoneButton from '../Common/MicrophoneButton'
import { KeyboardIcon, MicrophoneIcon } from '../../Icons/MenuIcons'

const { useMicrophoneButtonClick, useSendBoxSpeechInterimsVisible } = hooks

const Mode = (): JSX.Element => {
  const { mode, setMode, setCanFocusSendBox } = useRetorik()
  const { themeColors } = useView()
  const [fromTextToVocal, setFromTextToVocal] = useState<boolean>(false)
  const microphoneClick = useMicrophoneButtonClick()
  const [interimsVisible] = useSendBoxSpeechInterimsVisible()

  const toggleMode = (newMode: number): void => {
    setFromTextToVocal(true)
    if (newMode === ModeEnum.text) {
      interimsVisible && microphoneClick()
      setCanFocusSendBox(true)
    }
    setMode(newMode)
  }

  return (
    <div className='rf-w-full rf-px-4 rf-grid rf-grid-cols-1 rf-grid-rows-2 rf-items-center'>
      {/* Vocal mode button */}
      <div className='rf-w-full rf-flex rf-justify-center'>
        {mode === ModeEnum.text ? (
          <button
            className='rf-w-full rf-aspect-square rf-flex rf-justify-center rf-items-center rf-border rf-border-menuBorder rf-rounded-half rf-shadow-[0_0_20px_1px_#A4A7AF80]'
            onClick={(): void => toggleMode(ModeEnum.vocal)}
            aria-label='vocal mode'
          >
            <MicrophoneIcon
              className='rf-h-5 rf-w-5 tactile:rf-h-6 tactile:rf-w-6'
              color='#A4A7AF'
            />
          </button>
        ) : (
          <MicrophoneButton openMicrophone={fromTextToVocal} />
        )}
      </div>

      {/* Text mode button */}
      <button
        className='rf-flex rf-justify-center rf-items-center'
        onClick={(): void => toggleMode(ModeEnum.text)}
        aria-label='text mode'
      >
        <KeyboardIcon
          className='rf-h-5 rf-w-5 large-vertical:rf-h-6 large-vertical:rf-w-6'
          color={mode === ModeEnum.text ? themeColors.secondary : '#A4A7AF'}
        />
      </button>
    </div>
  )
}

export default Mode
