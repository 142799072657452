import React from 'react'
import { useList } from '../../Contexts/ListContext'
import ChosenItem from './Filter/ChosenItem'
import FilterItem from './Filter/FilterItem'

type FilterLargeProps = {
  className?: string
  openedFilterKey?: number
  setOpenedFilterKey: (key: number | undefined) => void
}

const FilterLarge = ({
  className,
  openedFilterKey,
  setOpenedFilterKey
}: FilterLargeProps): JSX.Element => {
  const { filters, selectedFilters } = useList()

  const closeOpenedFilters = (key: number | undefined): void => {
    setOpenedFilterKey(key)
  }

  return (
    <div className={`rf-flex rf-flex-col rf-justify-start ${className}`}>
      <div className='rf-relative rf-flex rf-flex-row rf-flex-wrap'>
        {filters.map((filter, key) => {
          return (
            <FilterItem
              key={key}
              identifier={key + 1}
              opened={(openedFilterKey && key + 1 === openedFilterKey) === true}
              filter={filter}
              closeOpenedFilters={closeOpenedFilters}
            />
          )
        })}
      </div>
      <div className='rf-flex rf-flex-row rf-my-2 rf-flex-wrap'>
        {selectedFilters?.map((chosenFilter, key1) => {
          return chosenFilter.items.map((name, key2) => {
            return (
              <ChosenItem
                className='rf-px-4 rf-mr-2 rf-mb-1'
                key={`${key1}-${key2}`}
                name={name}
                parent={{ id: chosenFilter.id, title: chosenFilter.title }}
              />
            )
          })
        })}
      </div>
    </div>
  )
}

export default FilterLarge
