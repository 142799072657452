import type { ImageAttachment, ImageData } from '../models/imageAttachment'
import { CONTENT_TYPE_IMAGE } from '../models/attachmentTypes'
import { imageFiles } from './imageFiles'

const createImageAttachmentCard = async (
  imageData: ImageData
): Promise<ImageAttachment> => {
  const naturalSize = await getNaturalSize(imageData.url)
  let miniaturedDisplay = false
  // If an error occured, we retrieve width = 0 and height = 0
  if (naturalSize.width === 0 && naturalSize.height === 0) {
    imageData = {
      url: `${imageFiles.noimage}`,
      alt: 'Image no found'
    }
  } else {
    miniaturedDisplay = naturalSize.width < 400 && naturalSize.height < 300
  }

  return {
    contentType: CONTENT_TYPE_IMAGE,
    content: {
      ...imageData,
      isMiniature: miniaturedDisplay
    }
  }
}

const getNaturalSize = async (
  url: string
): Promise<{ width: number; height: number }> => {
  return new Promise((resolve) => {
    const img = new Image()
    img.onload = (): void =>
      resolve({ width: img.naturalWidth, height: img.naturalHeight })
    img.onerror = (): void => {
      resolve({ width: 0, height: 0 })
    }
    img.src = url
  })
}

export default createImageAttachmentCard
