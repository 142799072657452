import React, { ComponentType } from 'react'
import { createRoot } from 'react-dom/client'

export default function renderAgent(
  RetorikSpiritEngineSprite: ComponentType<any>,
  props: any,
  element: HTMLElement
): void {
  const root = createRoot(element)
  root.render(
    <React.StrictMode>
      <RetorikSpiritEngineSprite {...props} />
    </React.StrictMode>
  )
}
