{
  "fr-FR": {
    "samu": "samu",
    "police": "police",
    "firefighter": "pompiers",
    "general": "général"
  },
  "en-US": {
    "samu": "maeu",
    "police": "police",
    "firefighter": "firefighters",
    "general": "general"
  },
  "es-ES": {
    "samu": "samu",
    "police": "policía",
    "firefighter": "bomberos",
    "general": "general"
  },
  "de-DE": {
    "samu": "samu",
    "police": "polizei",
    "firefighter": "feuerwehrleute",
    "general": "allgemein"
  }
}
