import React from 'react'
import { useLocaleStore } from '../../Contexts/localeStore'
import translation from '../../../translations/menu.json'
import ParameterButton from './ParameterButton'
// import { useSendMessage } from 'botframework-webchat-api/lib/hooks'

interface TutorialButtonProps {
  handleBack?: () => void
}

const TutorialButton = ({ handleBack }: TutorialButtonProps): JSX.Element => {
  // const sendMessage = useSendMessage()
  const { locale } = useLocaleStore()

  const handleClick = (): void => {
    handleBack && handleBack()
    console.log('Launch tutorial')
    // sendMessage('conseil', 'speech')
  }

  return (
    <ParameterButton
      title={translation[locale]?.tutorial || translation['fr-FR'].tutorial}
      icon='tutorial'
      onClick={handleClick}
    />
  )
}

export default TutorialButton
