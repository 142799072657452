import { hooks } from 'botframework-webchat'
import type { RetorikActivity } from '../models/activityTypes'

const { useActivities } = hooks

export default function useLastBotActivity(): Array<
  RetorikActivity | undefined
> {
  const [activities] = useActivities()
  const lastBotActivity = activities
    .slice()
    .reverse()
    .find(({ from: { role }, type }) => role === 'bot' && type === 'message')

  return [lastBotActivity]
}
