import { create } from 'zustand'
import { CurrentSubView, RetorikEvent } from '../../models/enums'
import type { CustomMenu } from '../../models/types'
import { WebChatActivity } from 'botframework-webchat-core'

interface UtilsStore {
  fullScreenImage: string | undefined
  lastListActivity: string | undefined
  listClosed: boolean
  currentSubView: number
  currentCustomView: CustomMenu | undefined
  retorikEvent: RetorikEvent | undefined
  displayAgent: boolean
  activityToSend: WebChatActivity | undefined
}

export const useUtilsStore = create<UtilsStore>()(() => {
  return {
    fullScreenImage: undefined,
    lastListActivity: undefined,
    listClosed: false,
    currentSubView: CurrentSubView.none,
    currentCustomView: undefined,
    retorikEvent: undefined,
    displayAgent: true,
    activityToSend: undefined
  }
})

export const setFullScreenImage = (src: string | undefined): void => {
  useUtilsStore.setState({ fullScreenImage: src })
}

export const setLastListActivity = (id: string | undefined): void => {
  useUtilsStore.setState({ lastListActivity: id })
}

export const setListClosed = (value: boolean): void => {
  useUtilsStore.setState({ listClosed: value })
}

export const setCurrentSubView = (subview: number): void => {
  useUtilsStore.setState({ currentSubView: subview })
}

export const setCurrentCustomView = (customView: CustomMenu): void => {
  useUtilsStore.setState({ currentCustomView: customView })
}

export const setRetorikEvent = (event: RetorikEvent): void => {
  useUtilsStore.setState({ retorikEvent: event })
}

export const showAgent = (): void => {
  useUtilsStore.setState({ displayAgent: true })
}

export const hideAgent = (): void => {
  useUtilsStore.setState({ displayAgent: false })
}

export const sendActivity = (activity: WebChatActivity | undefined): void => {
  useUtilsStore.setState({ activityToSend: activity })
}
