import React from 'react'
import { useView } from '../Contexts/ViewContext'
import useFullscreenImage from '../../hooks/useFullscreenImage'
import ScrollButtonIcon from '../Icons/ScrollButton/ScrollButtonIcon'

interface ScrollButtonProps {
  direction: 'top' | 'bottom'
  display: boolean
  ScrollHandler: (to: 'bottom' | 'top', from: 'newMessage' | 'button') => void
  setPreventScroll: (value: boolean) => void
  className: string
}

const ScrollButton = ({
  direction,
  display,
  ScrollHandler,
  setPreventScroll,
  className
}: ScrollButtonProps): JSX.Element => {
  const { themeColors } = useView()
  const fullScreenImage = useFullscreenImage()

  const handleClick = (): void => {
    setPreventScroll(true)
    if (direction === 'top') {
      ScrollHandler('top', 'button')
      console.log('handleClick - top')
    } else {
      console.log('handleClick - bottom')
      ScrollHandler('bottom', 'button')
    }
  }
  return (
    <>
      {display && !fullScreenImage ? (
        <div id='ScrollButton' className={className} onClick={handleClick}>
          <ScrollButtonIcon
            className={`${
              direction === 'top' ? 'rf-rotate-0' : 'rf-rotate-180'
            }`}
            color={themeColors.primary}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export { ScrollButton }
