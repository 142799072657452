import React, { useState } from 'react'

interface ToggleProps {
  className?: string
  title: string
  handleChange: (title: string) => void
  checked: boolean
}

const Radio = ({
  className,
  title,
  handleChange,
  checked
}: ToggleProps): JSX.Element => {
  const [transition, setTransition] = useState<string>(
    checked ? 'rf-scale-1' : 'rf-scale-0'
  )

  const handleToggle = (): void => {
    setTransition(checked ? 'rf-scale-0' : 'rf-scale-1')
    handleChange(title)
  }

  return (
    <div
      className={`rf-flex rf-flex-row rf-items-center rf-justify-between hover:rf-cursor-pointer ${className}`}
    >
      {/* Title */}
      <div className='rf-capitalize'>{title}</div>

      {/* Toggle */}
      <button
        className={`rf-relative rf-w-7 large:rf-w-8 large-vertical:rf-w-14 rf-h-7 large:rf-h-8 large-vertical:rf-h-14 ${
          !checked && 'rf-border rf-border-[#9FA2AA]'
        } rf-rounded-half`}
        style={{
          background: checked
            ? 'linear-gradient(to right, #1999B1, #0a4652)'
            : '#FFFFFF',
          boxShadow: checked ? '-3px 3px 6px #00000029' : ''
        }}
        onClick={handleToggle}
        aria-label={title}
      >
        <div
          className={`rf-absolute rf-top-2 large-vertical:rf-top-4 rf-left-2 large-vertical:rf-left-4 rf-w-3 large:rf-w-4 large-vertical:rf-w-6 rf-h-3 large:rf-h-4 large-vertical:rf-h-6 rf-rounded-half rf-bg-[#F8F8F8] rf-transition-all ${transition}`}
        />
      </button>
    </div>
  )
}

export default Radio
