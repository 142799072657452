import React from 'react'
import type { Filter } from '../../../../models/filterTypes'
import { useLocaleStore } from '../../../Contexts/localeStore'
import { capitalizeFirstLetter } from '../../../../utils/capitalizeFirstLetter'
import MobileUpperBand from './MobileUpperBand'
import SubFilter from './SubFilter'
import SubFilterAll from './SubFilterAll'
import SubFilterCalendar from './SubFilterCalendar'

type MobileSubFilterProps = {
  filter: Filter
  close: () => void
  resetFilters: () => void
}

const MobileSubFilterPage = ({
  filter,
  close,
  resetFilters
}: MobileSubFilterProps): JSX.Element => {
  const { locale } = useLocaleStore((state) => ({
    locale: state.locale
  }))

  return (
    <React.Fragment>
      {/* Upper band with left arrow + text + reset on the right */}
      <MobileUpperBand
        title={capitalizeFirstLetter(filter.title, locale)}
        handleReset={resetFilters}
        handleReturn={close}
      />
      <div className='rf-w-full rf-px-2'>
        {filter.id === 'Event.DateRange' ? (
          <SubFilterCalendar parent={{ id: filter.id, title: filter.title }} />
        ) : (
          <React.Fragment>
            <SubFilterAll
              className='rf-text-xl rf-border-b rf-border-b-silver'
              fullParent={filter}
            />
            {filter.items.map((item, key) => {
              return (
                <SubFilter
                  key={key}
                  className='rf-text-xl'
                  name={item}
                  parent={{ id: filter.id, title: filter.title }}
                />
              )
            })}
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  )
}

export default MobileSubFilterPage
