import React from 'react'
import { jsxToHtmlElement } from '../../../utils/jsxToHtmlElement'
import Slider from '../CustomElements/Slider'
import * as AC from 'adaptivecards'
import { updateData } from '../../../utils/localStorageInputsUtils'

export class SliderClass extends AC.Input {
  private _value =
    this.minValue && this.maxValue
      ? Math.floor((this.minValue + this.maxValue) / 2)
      : 50

  public get value(): any {
    return this._value
  }

  public isSet(): any {
    return this._value
  }

  static readonly JsonTypeName = 'Retorik.Slider'

  getJsonTypeName(): string {
    return SliderClass.JsonTypeName
  }

  // Add custom properties to schema
  static readonly minValueProperty = new AC.NumProperty(
    AC.Versions.v1_0,
    'minValue'
  )

  static readonly maxValueProperty = new AC.NumProperty(
    AC.Versions.v1_0,
    'maxValue'
  )

  static readonly minLabelProperty = new AC.StringProperty(
    AC.Versions.v1_0,
    'minLabel',
    true
  )

  static readonly maxLabelProperty = new AC.StringProperty(
    AC.Versions.v1_0,
    'maxLabel',
    true
  )

  // minValue property accessors
  @AC.property(SliderClass.minValueProperty)
  get minValue(): number {
    return this.getValue(SliderClass.minValueProperty)
  }

  set minValue(value: number) {
    if (this.minValue !== value) {
      this.setValue(SliderClass.minValueProperty, value)
      this.updateLayout()
    }
  }

  // maxValue property accessors
  @AC.property(SliderClass.maxValueProperty)
  get maxValue(): number {
    return this.getValue(SliderClass.maxValueProperty)
  }

  set maxValue(value: number) {
    if (this.maxValue !== value) {
      this.setValue(SliderClass.maxValueProperty, value)
      this.updateLayout()
    }
  }

  // minLabel property accessors
  @AC.property(SliderClass.minLabelProperty)
  get minLabel(): string {
    return this.getValue(SliderClass.minLabelProperty)
  }

  set minLabel(value: string) {
    if (this.minLabel !== value) {
      this.setValue(SliderClass.minLabelProperty, value)
      this.updateLayout()
    }
  }

  // maxLabel property accessors
  @AC.property(SliderClass.maxLabelProperty)
  get maxLabel(): string {
    return this.getValue(SliderClass.maxLabelProperty)
  }

  set maxLabel(value: string) {
    if (this.maxLabel !== value) {
      this.setValue(SliderClass.maxLabelProperty, value)
      this.updateLayout()
    }
  }

  private handleChange = (value: number): void => {
    this._value = value
    this.id && updateData(this.id, value)
  }

  protected internalRender(): HTMLElement {
    const element = jsxToHtmlElement(this.renderReact())
    return element
  }

  protected renderReact = (): JSX.Element => {
    return this.id ? (
      <Slider
        id={this.id}
        minValue={this.minValue}
        maxValue={this.maxValue}
        minLabel={this.minLabel}
        maxLabel={this.maxLabel}
        handleChangeSlider={this.handleChange}
      />
    ) : (
      <React.Fragment />
    )
  }
}
