import React from 'react'
import type { IconDefaultProps } from '../../../models/types'

const PhoneIcon = ({ className }: IconDefaultProps): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 30 40'
      className={className || 'rf-h-6 rf-w-6'}
    >
      <g transform='translate(-183.86 -57.462) rotate(-21)'>
        <path d='M162.1,156.952a2.852,2.852,0,0,0,2.91-2.915V127.8a2.851,2.851,0,0,0-2.91-2.915H151.91A2.851,2.851,0,0,0,149,127.8v26.233a2.852,2.852,0,0,0,2.91,2.915ZM157,155.738a1.455,1.455,0,1,1,1.455-1.455A1.456,1.456,0,0,1,157,155.738Zm-2.183-29.072c0-.18.086-.322.194-.322h3.978c.106,0,.194.144.194.322v.086c0,.18-.087.322-.194.322h-3.978c-.106,0-.194-.144-.194-.322Zm-4.365,1.861h13.1V151.81h-13.1Zm0,0' />
      </g>
    </svg>
  )
}
export default PhoneIcon
