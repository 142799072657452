import MarkdownIt from 'markdown-it'
import {
  replaceSpeechMarkdownTags,
  getTextWithoutSpeechMarkdown
} from '../utils/speechMarkdownUtils'
import type { UrlData } from '../models/linkAttachment'
import type { ImageData } from '../models/imageAttachment'

type ImagesAndUrls = {
  images: ImageData[]
  urls: UrlData[]
}

type ReturnType = {
  images: ImageData[]
  urls: UrlData[]
  text: string
  htmlText: string
}

type Attribute = string[]

type Token = {
  type: string
  content: string
  attrs: [Attribute] | null
}

const regexLink = /<\/?a([^>])+>/g
const regexImg = /<\/?img([^>])+>/g

export const processMarkdownAndSpeechMarkdown = (text: string): ReturnType => {
  const md = new MarkdownIt({
    breaks: true,
    html: true,
    xhtmlOut: true,
    typographer: true,
    quotes: `""''`
  })
  let attachmentsToCreate: ImagesAndUrls = { images: [], urls: [] }
  // text = `[backgroundaudio:"https://daviretorikcdnstorage.blob.core.windows.net/retorik-framework/audiofiles/audiotest.mp3 0.5 2000 1500"][silence:"Leading 1s"] ((Bonjour, comment  allez-vous)[sentence] ? (Bien, merci, et vous-même ?)[sentence;voice:"Fr-fR-jerOMeNeuRal"])[paragraph] (Je suis de (tomato)[ipa:"təˈmeɪtoʊ"] (sapi)[sapi:"iy eh n y uw eh s"] (ups)[ups:"JH AU"] retour (après avoir été remplacée)[emphasis:"strong"] par Brigitte.[silence:"Sentenceboundary 3s"] Au revoir)[voice:"fr-FR-DeniseNeural";emotion:"calm SeniorMale"] ![image](images/bertranges.jpg)`
  // text = `((Bonjour, comment allez-vous ?)[sentence] [1s] (Bien, et toi Jérôme, ça va ?)[sentence])[paragraph] (Bien, merci, et vous-même ?)[voice:"Fr-fR-jerOMeNeuRal"]. Tout-va-pour-le-mieux. (Hallo Denise, wie geht es dir heute ?)[rate:"slow";vol:"x-loud";lang:"de-DE"] (Bonjour, je suis de retour (ASAP)[characters] après avoir été remplacée par Brigitte.Merci)[voice:"fr-FR-DeniseNeural";vol:"loud"] ![image](images/bertranges.jpg)`
  // text = `(Como estas ?)[lang:"es-ES"][1s] Parti - pour le test - (greenage)[lang:"en-US"]. Mettons une image ![image](images/bertranges.jpg).[500ms] En anglais, (parce-que en Français ce n'est pas ça...) greenage se dit (greenage)[lang:"en-US"]. Fin de message (aufwiedersehen)[lang:"de-DE"]`
  const speechMarkdownReplacedData = replaceSpeechMarkdownTags(text)

  const parsed = md.parseInline(speechMarkdownReplacedData.text)
  parsed.length > 0 &&
    (attachmentsToCreate = createNewAttachments(parsed[0].children))

  const textWithHtmlTags = md.render(text)
  // Strip html tags from rendered text
  const newText = stripHtml(textWithHtmlTags)
  const speechMarkdownTagsPosition: Array<[number, number]> = []
  speechMarkdownReplacedData.replacements.forEach((replacement) => {
    const index = text.indexOf(replacement.speechMarkdown)
    speechMarkdownTagsPosition.push([
      index,
      index + replacement.speechMarkdown.length
    ])
  })

  return {
    ...attachmentsToCreate,
    text: newText,
    htmlText: stripHtmlLinkTags(textWithHtmlTags, speechMarkdownTagsPosition)
  }
}

/**
 * Removes speechmarkdown, <a href ...></a> and <img ... /> tags from the text and keeps the others
 * @param text : string
 * @returns string
 */
const stripHtmlLinkTags = (
  text: string,
  speechMarkdownTagsPosition: Array<[number, number]>
): string => {
  // Replace all characters replaced by mardkdown-it renderer (quotes, <, >)
  let newText = text.replaceAll('&quot;', '"')
  newText = newText.replaceAll('&lt;', '<')
  newText = newText.replaceAll('&gt;', '>')
  // Remove speechMarkdown tags, and link/images html tags
  newText = getTextWithoutSpeechMarkdown(
    newText,
    speechMarkdownTagsPosition
  ).replaceAll(regexLink, '')
  newText = newText.replaceAll(regexImg, '')

  return newText
}

/**
 * Removes all html tags from the text
 * @param text : string
 * @returns string
 */
const stripHtml = (text: string): string => {
  const temp = document.createElement('div')
  temp.innerHTML = text

  return temp.textContent && temp.textContent !== '' ? temp.textContent : ''
}

const getDataFromToken = (
  attributes: [Attribute] | null,
  type: 'href' | 'title' | 'alt' | 'src'
): string | undefined => {
  // Check if there are attributes, then if one of these attributes contains data with the desired key, and finally if there is a value attached to this key
  const allData = attributes?.find(
    (data) =>
      data.length > 1 && data[0] === type && data.length > 0 && data[1] !== ''
  )
  if (allData && allData.length > 1) {
    return allData[1]
  }

  return type === 'href' ? undefined : ''
}

const createNewAttachments = (tokens: Token[]): ImagesAndUrls => {
  const images: ImageData[] = []
  const urls: UrlData[] = []
  if (tokens && tokens.length > 0) {
    for (let i = 0; i < tokens.length; i++) {
      const attributes = tokens[i].attrs
      switch (tokens[i].type) {
        case 'link_open': {
          // Try to retrieve an URl from the href token
          const url: string | undefined = getDataFromToken(attributes, 'href')
          // If there is an URL, try to retrieve the text contained in the next token 'content' field
          if (url) {
            const title =
              getDataFromToken(attributes, 'title') || tokens[i + 1]?.content
            urls.push({
              url: url,
              title: title
            })
          }
          break
        }
        case 'image': {
          const url = getDataFromToken(attributes, 'src')
          url &&
            images.push({
              url: url,
              title: getDataFromToken(attributes, 'title'),
              alt: getDataFromToken(attributes, 'alt')
            })
          break
        }
        default:
          break
      }
    }
  }

  return {
    images: images,
    urls: urls
  }
}
