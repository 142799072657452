/* eslint-disable react/self-closing-comp */
import React from 'react'
import type { IconDefaultProps } from '../../../models/types'

const NewsChannelIcon = ({
  className,
  color
}: IconDefaultProps): JSX.Element => {
  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 512 512'
      className={className || 'rf-h-6 rf-w-6'}
      xmlSpace='preserve'
    >
      <g fill={color || '#1999B1'}>
        <path
          d='M500.9,13.5H325.3c-3.9,0-7,3.1-7,7v142.4c0,3.9,3.1,7,7,7h39.6v41.4c0,2.7,1.6,5.2,4,6.3c0.9,0.4,2,0.7,3,0.7
		c1.6,0,3.2-0.6,4.5-1.6l55.5-46.8h68.9c3.9,0,7-3.1,7-7V20.5C507.9,16.6,504.8,13.5,500.9,13.5z M493.9,155.9h-64.5
		c-1.6,0-3.2,0.6-4.5,1.6l-46,38.7v-33.4c0-3.9-3.1-7-7-7h-39.6V27.5h161.6V155.9z'
        />
        <path d='M386.2,66h28.2c3.9,0,7-3.1,7-7s-3.1-7-7-7h-28.2c-3.9,0-7,3.1-7,7S382.3,66,386.2,66z' />
        <path d='M436.7,66h32.6c3.9,0,7-3.1,7-7s-3.1-7-7-7h-32.6c-3.9,0-7,3.1-7,7S432.8,66,436.7,66z' />
        <path d='M355.6,98.5h113.7c3.9,0,7-3.1,7-7s-3.1-7-7-7H355.6c-3.9,0-7,3.1-7,7S351.7,98.5,355.6,98.5z' />
        <path d='M355.6,130.8h113.7c3.9,0,7-3.1,7-7s-3.1-7-7-7H355.6c-3.9,0-7,3.1-7,7S351.7,130.8,355.6,130.8z' />
        <path
          d='M335.4,277.7c-17-4.2-47.9-12.2-60.2-15.4c0.7-1.7,0.7-3.6-0.1-5.4c-0.9-2-2.6-3.5-4.7-4l-40.8-10.2v-12.9
		c18.6-11.3,32.3-30.3,37-52.8h12.9c12.1,0,21.9-9.8,21.9-21.9s-9.8-21.9-21.9-21.9h-12c4.4-7.2,16.2-28.5,16.6-48.9
		c0.4-26.5-29.5-49.5-37.6-53.3c-1.1-0.5-2.8-1.6-4.9-2.8c-10-5.9-28.6-16.9-50.6-16.9c-32.8,0.1-55.2,25-56.1,26.1
		c-0.4,0.5-0.8,1-1,1.6C112,43.2,95.5,62.5,95.5,85.6c0,3.1,0.3,6.3,0.9,9.5c0.1,0.4,0.2,0.9,0.4,1.3l14.7,36.8h-9.8
		c-12.1,0-21.9,9.8-21.9,21.9s9.8,21.9,21.9,21.9h12.9c4.7,22.5,18.4,41.5,36.9,52.8v12.9l-40.8,10.2c-2.1,0.5-3.9,2-4.7,4
		c-0.8,1.8-0.8,3.8,0,5.5l-62.7,16.7c0,0-0.1,0-0.1,0c-1.1,0.3-26.8,8.1-26.8,42.2v140.6c0,0.3,0,0.6,0.1,0.8
		c1.8,14.9,12.5,43,40.2,43h299.3c3.9,0,7-3.1,7-7l0-177.9C362.9,319.3,362.1,284.3,335.4,277.7z M258.1,264.3l-29.1,52l-25.1-27.9
		c16.2-5.9,23.6-21.6,25.5-31.3L258.1,264.3z M287.4,155.1c0,4.4-3.5,7.9-7.9,7.9h-11.2c0-1,0.1-2.1,0.1-3.1c0-4.4-0.2-8.6-0.6-12.7
		h11.7C283.9,147.2,287.4,150.7,287.4,155.1z M109.5,85.6c0-18.5,15.1-33.6,33.6-33.6c12.2,0,23.5,6.7,29.4,17.4
		c1.9,3.4,6.1,4.6,9.5,2.7c3.4-1.9,4.6-6.1,2.7-9.5c-6.6-12-18-20.4-31.1-23.4c8-6.1,21.3-13.9,37.3-13.9c18.2,0,34,9.3,43.4,14.9
		c2.5,1.5,4.4,2.6,6.1,3.4c5.1,2.4,29.8,21.3,29.6,40.4c-0.2,11.4-5.1,23.7-9.4,32.4c-2.6-6.8-5.8-13.6-9.8-20.7
		c-1.1-2-3.1-3.3-5.4-3.5c-2.2-0.2-4.5,0.6-6,2.3c-0.2,0.2-20,22.1-49,22.2c-28.9-0.2-48.8-22-49-22.2c-1.5-1.7-3.7-2.6-6-2.3
		c-2.3,0.2-4.3,1.5-5.4,3.5c-4.2,7.4-7.5,14.4-10.1,21.5l-10.1-25.4C109.7,89.8,109.5,87.6,109.5,85.6z M101.7,163
		c-4.4,0-7.9-3.5-7.9-7.9s3.5-7.9,7.9-7.9h11.7c-0.4,4.1-0.6,8.3-0.6,12.7c0,1,0,2.1,0.1,3.1H101.7z M126.8,159.9
		c0-17.8,3.5-33,11.5-49.3c9.5,7.9,27.7,19.9,51.7,20.2c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0
		c24-0.3,42.3-12.3,51.7-20.2c8,16.3,11.5,31.5,11.5,49.3c0,36.8-28.6,66.8-63.8,67C155.4,226.7,126.8,196.7,126.8,159.9z
		 M189.5,240.9c0.2,0,0.5,0,0.7,0c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0,0.4,0c0.3,0,0.5,0,0.7,0c8.3-0.2,16.3-1.7,23.9-4.4v17.8
		c-0.8,3.5-5.8,22.1-25,22.3c-19.3-0.2-24.3-18.9-25.1-22.3v-17.9C173,239.2,181.1,240.7,189.5,240.9z M151.8,257.1
		c1.9,9.7,9.3,25.4,25.5,31.3l-25.1,27.9l-29.1-52L151.8,257.1z M209.4,445.8c2.1,0.8,8.2,4.2,8.2,16.5v29.4h-53.8v-45.9H209.4z
		 M208.3,369.8h-34.6v-15.2c0-9.5,7.8-17.3,17.3-17.3s17.3,7.8,17.3,17.3V369.8z M219.9,383.8V403H159v-19.3H219.9z M199.9,417v14.8
		h-18.8V417H199.9z M348.9,491.7h-56.1V381.6c0-3.9-3.1-7-7-7s-7,3.1-7,7v110.1h-47.2v-29.4c0-13-5.1-20.5-9.3-24.5
		c-3.2-3-6.4-4.5-8.4-5.2V417h13c3.9,0,7-3.1,7-7v-33.3c0-3.9-3.1-7-7-7h-4.6v-15.2c0-17.3-14-31.3-31.3-31.3s-31.3,14-31.3,31.3
		v15.2H152c-3.9,0-7,3.1-7,7V410c0,3.9,3.1,7,7,7h15.2v14.8h-65.8v-49.8c0-3.9-3.1-7-7-7s-7,3.1-7,7v56.8c0,3.9,3.1,7,7,7h55.5v45.9
		H56.6c-21.1,0-25.7-26.6-26.3-30.3V321.3c0-23.1,15.7-28.4,16.6-28.7l66-17.5l31.7,56.6c1.1,2,3.1,3.3,5.3,3.5c0.3,0,0.5,0,0.8,0
		c2,0,3.9-0.8,5.2-2.3l34.5-38.4l34.5,38.4c1.3,1.5,3.2,2.3,5.2,2.3c0.3,0,0.5,0,0.8,0c2.2-0.2,4.2-1.6,5.3-3.5l31.8-56.7
		c8.6,2.2,44.9,11.7,63.9,16.4c15.9,3.9,16.8,28.5,16.8,29.6V491.7z'
        />
      </g>
    </svg>
  )
}

export default NewsChannelIcon
