{
  "_comment": "[CI-LOCKED] Vsebina te datoteke je zaklenjena. Prispevki so dobrodošli, vendar je preverjanje sprememb lahko zakasnjeno.",
  "ACCESS_KEY_ALT": "Alt Shift $1",
  "ACCESS_KEY_FOR_MAC_ALT": "Control Option $1",
  "ACTIVITY_BOT_SAID_ALT": "Bot $1 je rekel:",
  "ACTIVITY_YOU_SAID_ALT": "Rekli ste:",
  "ACTIVITY_BOT_ATTACHED_ALT": "Bot je priložil:",
  "ACTIVITY_ERROR_BOX_TITLE": "Sporočilo o napaki",
  "ACTIVITY_INTERACTIVE_FOOTNOTE_ALT": "Kliknite za interakcijo.",
  "ACTIVITY_INTERACTIVE_REASON_INTERACTIVE_CONTENT_ALT": "Sporočilo je interaktivno.",
  "ACTIVITY_INTERACTIVE_REASON_LINK_ALT": "V sporočilu je ena ali več povezav.",
  "ACTIVITY_INTERACTIVE_REASON_SEND_FAILED_ALT": "Pošiljanje ni uspelo.",
  "ACTIVITY_YOU_ATTACHED_ALT": "Priložili ste:",
  "ACTIVITY_NUM_ATTACHMENTS_ONE_ALT": "1 priloga.",
  "ACTIVITY_NUM_ATTACHMENTS_FEW_ALT": "$1 priloge.",
  "ACTIVITY_NUM_ATTACHMENTS_MANY_ALT": "$1 prilog.",
  "ACTIVITY_NUM_ATTACHMENTS_OTHER_ALT": "$1 prilog.",
  "ACTIVITY_NUM_ATTACHMENTS_TWO_ALT": "$1 prilogi.",
  "ACTIVITY_STATUS_SEND_FAILED_RETRY": "Pošiljanje ni uspelo. [Vnovični poskus][RETRY].",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENDING": "Pošiljanje",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENT_AT": "Poslano: $1",
  "ACTIVITY_STATUS_TIMESTAMP_JUST_NOW": "Zdaj",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_HOUR_AGO": "Pred uro",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_MINUTE_AGO": "Pred minuto",
  "ACTIVITY_STATUS_TIMESTAMP_TODAY": "danes",
  "ACTIVITY_STATUS_TIMESTAMP_YESTERDAY": "včeraj",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_PARSE": "Napaka pri razčlenjevanju prilagodljive kartice",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_RENDER": "Napaka pri upodabljanju prilagodljive kartice",
  "ATTACHMENT_AUDIO": "Zvočni posnetek.",
  "ATTACHMENT_CARD": "Kartica: $1 $2 $3",
  "ATTACHMENT_FILE": "Datoteka: $1",
  "ATTACHMENT_IMAGE": "Slika",
  "ATTACHMENT_TEXT": "Besedilo: $1",
  "ATTACHMENT_VIDEO": "Videoposnetek",
  "CAROUSEL_FLIPPER_LEFT_ALT": "Levo",
  "CAROUSEL_FLIPPER_RIGHT_ALT": "Desno",
  "CONNECTIVITY_STATUS_ALT_CONNECTED": "Povezava je vzpostavljena.",
  "CONNECTIVITY_STATUS_ALT_CONNECTING": "Povezovanje …",
  "CONNECTIVITY_STATUS_ALT_FATAL": "Povezave ni mogoče vzpostaviti.",
  "CONNECTIVITY_STATUS_ALT_RECONNECTING": "Prišlo je do omrežne prekinitve. Vnovično povezovanje …",
  "CONNECTIVITY_STATUS_ALT_RENDER_ERROR": "Napaka pri upodabljanju. Preverite konzolo ali se obrnite na razvijalca bota.",
  "CONNECTIVITY_STATUS_ALT_SLOW_CONNECTION": "Povezovanje traja dlje kot običajno.",
  "CONNECTIVITY_STATUS_ALT": "Stanje povezave: $1",
  "FILE_CONTENT_ALT": "»$1«",
  "FILE_CONTENT_DOWNLOADABLE_ALT": "Prenesi datoteko »$1«",
  "FILE_CONTENT_DOWNLOADABLE_WITH_SIZE_ALT": "Prenos datoteke »$1« velikosti $2",
  "FILE_CONTENT_WITH_SIZE_ALT": "»$1« velikosti $2",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_BODY": "Tipka Enter",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_HEADER": "Dostop do elementov v sporočilu",
  "KEYBOARD_HELP_CHAT_HISTORY_HEADER": "Zgodovina klepeta",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_BODY": "Tipka Escape",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_HEADER": "Izhod iz sporočila",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_BODY": "Tabulatorka\nShift + tabulatorka",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_HEADER": "Premikanje med elementi v sporočilu",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_BODY": "Tipka s puščico gor\nTipka s puščico dol",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_HEADER": "Premikanje med sporočili",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_BODY": "Tipka Enter",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_HEADER": "Izvedba dejanja",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_BODY": "Tabulatorka\nShift + tabulatorka",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_HEADER": "Pomikanje med elementi v oknu za klepet",
  "KEYBOARD_HELP_CHAT_WINDOW_HEADER": "Okno za klepet",
  "KEYBOARD_HELP_CLOSE_BUTTON_ALT": "Zapri",
  "KEYBOARD_HELP_HEADER": "Kontrolniki na tipkovnici",
  "MARKDOWN_EXTERNAL_LINK_ALT": "Odpre se v novem oknu; zunanje.",
  "RECEIPT_CARD_TAX": "Davek",
  "RECEIPT_CARD_TOTAL": "Skupaj",
  "RECEIPT_CARD_VAT": "DDV",
  "SEND_BOX_IS_EMPTY_TOOLTIP_ALT": "Praznega sporočila ni mogoče poslati.",
  "SPEECH_INPUT_LISTENING": "Poslušanje …",
  "SPEECH_INPUT_MICROPHONE_BUTTON_CLOSE_ALT": "Mikrofon je izklopljen",
  "SPEECH_INPUT_MICROPHONE_BUTTON_OPEN_ALT": "Mikrofon je vklopljen",
  "SPEECH_INPUT_STARTING": "Začetek …",
  "SUGGESTED_ACTIONS_LABEL_ALT": "Predlagana dejanja",
  "TEXT_INPUT_ALT": "Polje za vnos sporočila",
  "TEXT_INPUT_PLACEHOLDER": "Vnesite sporočilo",
  "TEXT_INPUT_SEND_BUTTON_ALT": "Pošlji",
  "TEXT_INPUT_SPEAK_BUTTON_ALT": "Govor",
  "TEXT_INPUT_UPLOAD_BUTTON_ALT": "Naloži datoteko",
  "TOAST_ACCORDION_FEW": "Obvestila: $1 – za podrobnosti kliknite tukaj",
  "TOAST_ACCORDION_MANY": "Obvestila: $1 – za podrobnosti kliknite tukaj",
  "TOAST_ACCORDION_OTHER": "Obvestila: $1 – za podrobnosti kliknite tukaj",
  "TOAST_ACCORDION_TWO": "Obvestila: $1 – za podrobnosti kliknite tukaj",
  "TOAST_ALT_ERROR": "Napaka",
  "TOAST_ALT_INFO": "Informacije",
  "TOAST_ALT_SUCCESS": "Uspelo je",
  "TOAST_ALT_WARN": "Opozorilo",
  "TOAST_DISMISS_BUTTON": "Opusti",
  "TOAST_TITLE_ALT": "Obvestilo",
  "TRANSCRIPT_ARIA_LABEL_ALT": "Zgodovina klepeta – za pomikanje pritisnite tipke s puščico.",
  "TRANSCRIPT_ARIA_ROLE_ALT": "zgodovina klepeta",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_LABEL_ALT": "Sporočilo je interaktivno. Dvakrat ali trikrat pritisnite tipko shift in tabulatorko, da preklopite na zgodovino klepeta. Nato kliknite sporočilo za interakcijo z njim.",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_WITH_LINKS_LABEL_ALT": "V sporočilu je ena ali več povezav. Dvakrat ali trikrat pritisnite tipko shift in tabulatorko, da preklopite na zgodovino klepeta. Nato kliknite sporočilo za interakcijo z njim.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_LABEL_ALT": "Sporočilo ima predlagana dejanja. 2- do 3-krat pritisnite dvigalko in tabulatorko, da jih izberete.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_WITH_ACCESS_KEY_LABEL_ALT": "Sporočilo ima predlagana dejanja. Pritisnite $1, da jih izberete.",
  "TRANSCRIPT_LIVE_REGION_SEND_FAILED_ALT": "Sporočila ni bilo mogoče poslati.",
  "TRANSCRIPT_MORE_MESSAGES": "Več sporočil",
  "TRANSCRIPT_NEW_MESSAGES": "Nova sporočila",
  "TRANSCRIPT_TERMINATOR_TEXT": "Konec zgodovine klepeta",
  "TYPING_INDICATOR_ALT": "Prikaz indikatorja tipkanja",
  "TYPING_INDICATOR_SINGLE_TEXT": "$1 tipka.",
  "TYPING_INDICATOR_MULTIPLE_TEXT": "$1 in drugi tipkajo."
}