import { CDNCharactersAddress } from '../models/constants'

const characters = {
  alice: `${CDNCharactersAddress}/alice`,
  jeanmarc: `${CDNCharactersAddress}/jean-marc`,
  ikaa: `${CDNCharactersAddress}/ikaa`,
  karine: `${CDNCharactersAddress}/karine`,
  lea: `${CDNCharactersAddress}/lea`,
  prosper: `${CDNCharactersAddress}/prosper`,
  raphael: `${CDNCharactersAddress}/raphael`,
  zahra: `${CDNCharactersAddress}/zahra`
}

export { characters }
