import { hooks } from 'botframework-webchat'
import { useUtilsStore } from '../components/Contexts/utilsStore'

const { useActivities } = hooks

const useFullscreenImage = (): string | undefined => {
  const [activities] = useActivities()
  const { fullScreenImage } = useUtilsStore()

  if (fullScreenImage) {
    return fullScreenImage
  }

  if (activities && activities.length > 0) {
    const lastActivity = activities.slice(-1)[0] as any
    if (
      lastActivity.type === 'event' &&
      lastActivity.name === 'hospitality.ShowImage' &&
      lastActivity.value
    ) {
      return lastActivity.value
    }
  }

  return undefined
}

export default useFullscreenImage
