import React, { useEffect, useState, useRef } from 'react'
import { hooks } from 'botframework-webchat'
import { useView } from '../Contexts/ViewContext'

const { useDictateState, useDictateInterims } = hooks

interface VoiceInputProps {
  className?: string
  setListening?: (x: boolean) => void
}

const VoiceInput = ({
  className,
  setListening
}: VoiceInputProps): JSX.Element => {
  const { isMobile } = useView()
  const dictateState = useDictateState()[0]
  const [dictateInterims] = useDictateInterims()
  const [textToDisplay, setTextToDisplay] = useState<string>('\u2800')
  const [hideText, setHideText] = useState<boolean>(true)
  const timerRef: React.MutableRefObject<any> = useRef(null)

  useEffect(() => {
    if (dictateState === 2 || dictateState === 3) {
      if (dictateInterims.length > 0 && dictateInterims[0].length > 0) {
        setHideText(false)
      }
    } else {
      timerRef &&
        (timerRef.current = setTimeout(() => {
          setHideText(true)
          setTextToDisplay('\u2800')
          setListening && setListening(false)
        }, 1500))
    }

    return (): void => {
      timerRef && clearTimeout(timerRef.current)
    }
  }, [dictateState, dictateInterims])

  /**
   * On dictateInterims change :
   *  - update textToDisplay state
   */
  useEffect(() => {
    dictateInterims[0] && setTextToDisplay(dictateInterims[0])
  }, [dictateInterims])

  return !hideText ? (
    isMobile ? (
      <p className={className}>{textToDisplay}</p>
    ) : (
      <div className='rf-w-full rf-px-4 rf-py-3 rf-subtitle-size-auto rf-font-bold rf-text-truewhite rf-text-shadow-black'>
        {textToDisplay}
      </div>
    )
  ) : (
    <React.Fragment />
  )
}

export default VoiceInput
