import React from 'react'
import type { TemplateDefaultProps } from '../../models/types'

const FullScreenRemote = ({
  className,
  background,
  onClick,
  children
}: TemplateDefaultProps): JSX.Element => {
  return (
    <div
      className={`rf-relative rf-overflow-y-scroll rf-scrollbar-hidden rf-text-black rf-text-size-auto rf-bg-templateBackground large:rf-mr-30 rf-col-span-full rf-row-span-full ${className}`}
      style={{ background: background || '' }}
      onClick={onClick ? (): void => onClick() : (): void => {}}
    >
      {children}
    </div>
  )
}

export default FullScreenRemote
