import { useActivities } from 'botframework-webchat-api/lib/hooks'
import { CONTENT_TYPE_NEWS, NewsContent } from '../models/attachmentTypes'
import type { RetorikActivity } from '../models/activityTypes'

type NewsAttachment = {
  contentType: string
  content: NewsContent
}

const useNews = (): {
  newsAttachments: Array<NewsAttachment>
  activity?: RetorikActivity
} => {
  const [activities] = useActivities()
  if (!activities.length) {
    return { newsAttachments: [] }
  }
  const activity = activities[activities.length - 1] as any

  if (
    !activity ||
    !(activity.type === 'event') ||
    !activity.attachments?.length
  ) {
    return { newsAttachments: [], activity }
  }

  const newsAttachments: NewsAttachment[] = []
  activity.attachments.forEach((attachment) => {
    if (attachment.contentType === CONTENT_TYPE_NEWS) {
      newsAttachments.push({
        contentType: CONTENT_TYPE_NEWS,
        content: attachment.content || ''
      })
    }
  })

  return { newsAttachments, activity }
}

export default useNews
