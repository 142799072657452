import React from 'react'
import { jsxToHtmlElement } from '../../../utils/jsxToHtmlElement'
import { DateInput } from 'adaptivecards/lib/card-elements'
import InputDate from '../InputDate'
import InputCalendar from '../InputCalendar'
import { updateData } from '../../../utils/localStorageInputsUtils'
import * as AC from 'adaptivecards'

export class DateInputClass extends DateInput {
  private _value = this.defaultValue || undefined
  public get value(): any {
    return this._value
  }

  public isSet(): any {
    return this._value
  }

  // Add custom property to schema
  static readonly calendarProperty = new AC.BoolProperty(
    AC.Versions.v1_0,
    'calendar',
    false
  )

  // Calendar property accessors
  @AC.property(DateInputClass.calendarProperty)
  get calendar(): boolean {
    return !!this.getValue(DateInputClass.calendarProperty)
  }

  private handleChange = (value: string): void => {
    this._value = value
    this.id && updateData(this.id, value)
  }

  protected internalRender(): HTMLElement {
    const element = jsxToHtmlElement(this.renderReact())
    return element
  }

  protected renderReact = (): JSX.Element => {
    return this.id ? (
      this.calendar ? (
        <InputCalendar
          id={this.id}
          defaultValue={this.defaultValue}
          maxValue={this.max}
          minValue={this.min}
          handleChange={this.handleChange}
        />
      ) : (
        <InputDate
          id={this.id}
          defaultValue={this.defaultValue}
          maxValue={this.max}
          minValue={this.min}
          handleChange={this.handleChange}
        />
      )
    ) : (
      <React.Fragment />
    )
  }
}
