import React from 'react'
import type { Filter } from '../../../../models/filterTypes'
import { capitalizeFirstLetter } from '../../../../utils/capitalizeFirstLetter'
import { useLocaleStore } from '../../../Contexts/localeStore'

type SuggestionsItemProps = {
  className?: string
  title: string
  value: Filter
  results?: number | string
  handleClick: (value: Filter) => void
}

const SuggestionsItem = ({
  className,
  title,
  value,
  results,
  handleClick
}: SuggestionsItemProps): JSX.Element => {
  const { locale } = useLocaleStore()

  return (
    <button
      onClick={(): void => handleClick(value)}
      className={`rf-h-fit rf-px-4 rf-py-3 rf-mr-4 rf-mb-4 large-vertical:rf-py-4 rf-border rf-rounded-lg rf-bg-templateBackground rf-text-black rf-border-black hover:rf-cursor-pointer rf-flex rf-flex-row rf-items-center rf-justify-center ${className}`}
      aria-label={title}
    >
      {capitalizeFirstLetter(title, locale)}
      <div className='rf-ml-6 rf-text-gray'>{results || 0}</div>
    </button>
  )
}

export default SuggestionsItem
