{
  "aa": "Qafar",
  "aa-DJ": "Qafar (Yabuuti)",
  "aa-ER": "Qafar (Eretria)",
  "aa-ET": "Qafar (Otobbia)",
  "af": "Afrikaans",
  "af-NA": "Afrikaans (Namibië)",
  "af-ZA": "Afrikaans (Suid-Afrika)",
  "agq": "Aghem",
  "agq-CM": "Aghem (Kàmàlûŋ)",
  "ak": "Akan",
  "ak-GH": "Akan (Gaana)",
  "am": "አማርኛ",
  "am-ET": "አማርኛ (ኢትዮጵያ)",
  "ar": "العربية",
  "ar-001": "العربية (العالم)",
  "ar-AE": "العربية (الإمارات العربية المتحدة)",
  "ar-BH": "العربية (البحرين)",
  "ar-DJ": "العربية (جيبوتي)",
  "ar-DZ": "العربية (الجزائر)",
  "ar-EG": "العربية (مصر)",
  "ar-ER": "العربية (إريتريا)",
  "ar-IL": "العربية (إسرائيل)",
  "ar-IQ": "العربية (العراق)",
  "ar-JO": "العربية (الأردن)",
  "ar-KM": "العربية (جزر القمر)",
  "ar-KW": "العربية (الكويت)",
  "ar-LB": "العربية (لبنان)",
  "ar-LY": "العربية (ليبيا)",
  "ar-MA": "العربية (المملكة المغربية)",
  "ar-MR": "العربية (موريتانيا)",
  "arn": "Mapudungun",
  "arn-CL": "Mapudungun (Chile)",
  "ar-OM": "العربية (عمان)",
  "ar-PS": "العربية (السلطة الفلسطينية)",
  "ar-QA": "العربية (قطر)",
  "ar-SA": "العربية (المملكة العربية السعودية)",
  "ar-SD": "العربية (السودان)",
  "ar-SO": "العربية (الصومال)",
  "ar-SS": "العربية (جنوب السودان)",
  "ar-SY": "العربية (سوريا)",
  "ar-TD": "العربية (تشاد)",
  "ar-TN": "العربية (تونس)",
  "ar-YE": "العربية (اليمن)",
  "as": "অসমীয়া",
  "asa": "Kipare",
  "asa-TZ": "Kipare (Tadhania)",
  "as-IN": "অসমীয়া (ভাৰত)",
  "ast": "asturianu",
  "ast-ES": "asturianu (España)",
  "az": "azərbaycan",
  "az-Cyrl": "Азәрбајҹан дили",
  "az-Cyrl-AZ": "азәрбајҹан (Азәрбајҹан)",
  "az-Latn": "azərbaycan",
  "az-Latn-AZ": "azərbaycan (Azərbaycan)",
  "ba": "Башҡорт",
  "ba-RU": "Башҡорт (Рәсәй)",
  "bas": "Ɓàsàa",
  "bas-CM": "Ɓàsàa (Kàmɛ̀rûn)",
  "be": "Беларуская",
  "be-BY": "Беларуская (Беларусь)",
  "bem": "Ichibemba",
  "bem-ZM": "Ichibemba (Zambia)",
  "bez": "Hibena",
  "bez-TZ": "Hibena (Hutanzania)",
  "bg": "български",
  "bg-BG": "български (България)",
  "bin": "Ẹ̀dó",
  "bin-NG": "Ẹ̀dó (Nigeria)",
  "bm": "bamanakan",
  "bm-Latn": "bamanakan",
  "bm-Latn-ML": "bamanakan (Mali)",
  "bn": "বাংলা",
  "bn-BD": "বাংলা (বাংলাদেশ)",
  "bn-IN": "বাংলা (ভারত)",
  "bo": "བོད་ཡིག",
  "bo-CN": "བོད་ཡིག (ཀྲུང་ཧྭ་མི་དམངས་སྤྱི་མཐུན་རྒྱལ་ཁབ།)",
  "bo-IN": "བོད་སྐད་ (རྒྱ་གར་)",
  "br": "brezhoneg",
  "br-FR": "brezhoneg (Frañs)",
  "brx": "बड़ो",
  "brx-IN": "बड़ो (भारत)",
  "bs": "bosanski",
  "bs-Cyrl": "босански",
  "bs-Cyrl-BA": "босански (Босна и Херцеговина)",
  "bs-Latn": "bosanski",
  "bs-Latn-BA": "bosanski (Bosna i Hercegovina)",
  "byn": "ብሊን",
  "byn-ER": "ብሊን (ኤርትራ)",
  "ca": "català",
  "ca-AD": "català (Andorra)",
  "ca-ES": "català (català)",
  "ca-ES-valencia": "valencià (Espanya)",
  "ca-FR": "català (França)",
  "ca-IT": "català (Itàlia)",
  "ce": "нохчийн",
  "ce-RU": "нохчийн (Росси)",
  "cgg": "Rukiga",
  "cgg-UG": "Rukiga (Uganda)",
  "chr": "ᏣᎳᎩ",
  "chr-Cher": "ᏣᎳᎩ",
  "chr-Cher-US": "ᏣᎳᎩ (ᏌᏊ ᎢᏳᎾᎵᏍᏔᏅ ᏍᎦᏚᎩ)",
  "co": "Corsu",
  "co-FR": "Corsu (Francia)",
  "cs": "čeština",
  "cs-CZ": "čeština (Česko)",
  "cu": "церковнослове́нскїй",
  "cu-RU": "церковнослове́нскїй (рѡссі́а)",
  "cy": "Cymraeg",
  "cy-GB": "Cymraeg (Y Deyrnas Unedig)",
  "da": "dansk",
  "da-DK": "dansk (Danmark)",
  "da-GL": "dansk (Grønland)",
  "dav": "Kitaita",
  "dav-KE": "Kitaita (Kenya)",
  "de": "Deutsch",
  "de-AT": "Deutsch (Österreich)",
  "de-BE": "Deutsch (Belgien)",
  "de-CH": "Deutsch (Schweiz)",
  "de-DE": "Deutsch (Deutschland)",
  "de-IT": "Deutsch (Italien)",
  "de-LI": "Deutsch (Liechtenstein)",
  "de-LU": "Deutsch (Luxemburg)",
  "dje": "Zarmaciine",
  "dje-NE": "Zarmaciine (Nižer)",
  "dsb": "dolnoserbšćina",
  "dsb-DE": "dolnoserbšćina (Nimska)",
  "dua": "duálá",
  "dua-CM": "duálá (Cameroun)",
  "dv": "ދިވެހިބަސް",
  "dv-MV": "ދިވެހިބަސް (ދިވެހި ރާއްޖެ)",
  "dyo": "joola",
  "dyo-SN": "joola (Senegal)",
  "dz": "རྫོང་ཁ",
  "dz-BT": "རྫོང་ཁ (འབྲུག)",
  "ebu": "Kĩembu",
  "ebu-KE": "Kĩembu (Kenya)",
  "ee": "Eʋegbe",
  "ee-GH": "Eʋegbe (Ghana nutome)",
  "ee-TG": "Eʋegbe (Togo nutome)",
  "el": "Ελληνικά",
  "el-CY": "Ελληνικά (Κύπρος)",
  "el-GR": "Ελληνικά (Ελλάδα)",
  "en": "English",
  "en-001": "English (World)",
  "en-029": "English (Caribbean)",
  "en-150": "English (Europe)",
  "en-AG": "English (Antigua and Barbuda)",
  "en-AI": "English (Anguilla)",
  "en-AS": "English (American Samoa)",
  "en-AT": "English (Austria)",
  "en-AU": "English (Australia)",
  "en-BB": "English (Barbados)",
  "en-BE": "English (Belgium)",
  "en-BI": "English (Burundi)",
  "en-BM": "English (Bermuda)",
  "en-BS": "English (Bahamas)",
  "en-BW": "English (Botswana)",
  "en-BZ": "English (Belize)",
  "en-CA": "English (Canada)",
  "en-CC": "English (Cocos (Keeling) Islands)",
  "en-CH": "English (Switzerland)",
  "en-CK": "English (Cook Islands)",
  "en-CM": "English (Cameroon)",
  "en-CX": "English (Christmas Island)",
  "en-CY": "English (Cyprus)",
  "en-DE": "English (Germany)",
  "en-DK": "English (Denmark)",
  "en-DM": "English (Dominica)",
  "en-ER": "English (Eritrea)",
  "en-FI": "English (Finland)",
  "en-FJ": "English (Fiji)",
  "en-FK": "English (Falkland Islands)",
  "en-FM": "English (Micronesia)",
  "en-GB": "English (United Kingdom)",
  "en-GD": "English (Grenada)",
  "en-GG": "English (Guernsey)",
  "en-GH": "English (Ghana)",
  "en-GI": "English (Gibraltar)",
  "en-GM": "English (Gambia)",
  "en-GU": "English (Guam)",
  "en-GY": "English (Guyana)",
  "en-HK": "English (Hong Kong SAR)",
  "en-ID": "English (Indonesia)",
  "en-IE": "English (Ireland)",
  "en-IL": "English (Israel)",
  "en-IM": "English (Isle of Man)",
  "en-IN": "English (India)",
  "en-IO": "English (British Indian Ocean Territory)",
  "en-JE": "English (Jersey)",
  "en-JM": "English (Jamaica)",
  "en-KE": "English (Kenya)",
  "en-KI": "English (Kiribati)",
  "en-KN": "English (Saint Kitts and Nevis)",
  "en-KY": "English (Cayman Islands)",
  "en-LC": "English (Saint Lucia)",
  "en-LR": "English (Liberia)",
  "en-LS": "English (Lesotho)",
  "en-MG": "English (Madagascar)",
  "en-MH": "English (Marshall Islands)",
  "en-MO": "English (Macao SAR)",
  "en-MP": "English (Northern Mariana Islands)",
  "en-MS": "English (Montserrat)",
  "en-MT": "English (Malta)",
  "en-MU": "English (Mauritius)",
  "en-MW": "English (Malawi)",
  "en-MY": "English (Malaysia)",
  "en-NA": "English (Namibia)",
  "en-NF": "English (Norfolk Island)",
  "en-NG": "English (Nigeria)",
  "en-NL": "English (Netherlands)",
  "en-NR": "English (Nauru)",
  "en-NU": "English (Niue)",
  "en-NZ": "English (New Zealand)",
  "en-PG": "English (Papua New Guinea)",
  "en-PH": "English (Philippines)",
  "en-PK": "English (Pakistan)",
  "en-PN": "English (Pitcairn Islands)",
  "en-PR": "English (Puerto Rico)",
  "en-PW": "English (Palau)",
  "en-RW": "English (Rwanda)",
  "en-SB": "English (Solomon Islands)",
  "en-SC": "English (Seychelles)",
  "en-SD": "English (Sudan)",
  "en-SE": "English (Sweden)",
  "en-SG": "English (Singapore)",
  "en-SH": "English (St Helena, Ascension, Tristan da Cunha)",
  "en-SI": "English (Slovenia)",
  "en-SL": "English (Sierra Leone)",
  "en-SS": "English (South Sudan)",
  "en-SX": "English (Sint Maarten)",
  "en-SZ": "English (Swaziland)",
  "en-TC": "English (Turks and Caicos Islands)",
  "en-TK": "English (Tokelau)",
  "en-TO": "English (Tonga)",
  "en-TT": "English (Trinidad and Tobago)",
  "en-TV": "English (Tuvalu)",
  "en-TZ": "English (Tanzania)",
  "en-UG": "English (Uganda)",
  "en-UM": "English (U.S. Outlying Islands)",
  "en-US": "English (United States)",
  "en-VC": "English (Saint Vincent and the Grenadines)",
  "en-VG": "English (British Virgin Islands)",
  "en-VI": "English (U.S. Virgin Islands)",
  "en-VU": "English (Vanuatu)",
  "en-WS": "English (Samoa)",
  "en-ZA": "English (South Africa)",
  "en-ZM": "English (Zambia)",
  "en-ZW": "English (Zimbabwe)",
  "eo": "esperanto",
  "eo-001": "esperanto (World)",
  "es": "español",
  "es-419": "español (Latinoamérica)",
  "es-AR": "español (Argentina)",
  "es-BO": "español (Bolivia)",
  "es-BR": "español (Brasil)",
  "es-BZ": "español (Belice)",
  "es-CL": "español (Chile)",
  "es-CO": "español (Colombia)",
  "es-CR": "español (Costa Rica)",
  "es-CU": "español (Cuba)",
  "es-DO": "español (República Dominicana)",
  "es-EC": "español (Ecuador)",
  "es-ES": "español (España, alfabetización internacional)",
  "es-GQ": "español (Guinea Ecuatorial)",
  "es-GT": "español (Guatemala)",
  "es-HN": "español (Honduras)",
  "es-MX": "español (México)",
  "es-NI": "español (Nicaragua)",
  "es-PA": "español (Panamá)",
  "es-PE": "español (Perú)",
  "es-PH": "español (Filipinas)",
  "es-PR": "español (Puerto Rico)",
  "es-PY": "español (Paraguay)",
  "es-SV": "español (El Salvador)",
  "es-US": "español (Estados Unidos)",
  "es-UY": "español (Uruguay)",
  "es-VE": "español (Venezuela)",
  "et": "eesti",
  "et-EE": "eesti (Eesti)",
  "eu": "euskara",
  "eu-ES": "euskara (euskara)",
  "ewo": "ewondo",
  "ewo-CM": "ewondo (Kamərún)",
  "fa": "فارسی",
  "fa-IR": "فارسی (ایران)",
  "ff": "Fulah",
  "ff-CM": "Pulaar (Kameruun)",
  "ff-GN": "Pulaar (Gine)",
  "ff-Latn": "Fulah",
  "ff-Latn-SN": "Fulah (Sénégal)",
  "ff-MR": "Pulaar (Muritani)",
  "ff-NG": "Pulaar (Nigeria)",
  "fi": "suomi",
  "fi-FI": "suomi (Suomi)",
  "fil": "Filipino",
  "fil-PH": "Filipino (Pilipinas)",
  "fo": "føroyskt",
  "fo-DK": "føroyskt (Danmark)",
  "fo-FO": "føroyskt (Føroyar)",
  "fr": "français",
  "fr-029": "français (caraïbes)",
  "fr-BE": "français (Belgique)",
  "fr-BF": "français (Burkina Faso)",
  "fr-BI": "français (Burundi)",
  "fr-BJ": "français (Bénin)",
  "fr-BL": "français (Saint-Barthélemy)",
  "fr-CA": "français (Canada)",
  "fr-CD": "français (Congo, République démocratique du)",
  "fr-CF": "français (République centrafricaine)",
  "fr-CG": "français (Congo)",
  "fr-CH": "français (Suisse)",
  "fr-CI": "français (Côte d’Ivoire)",
  "fr-CM": "français (Cameroun)",
  "fr-DJ": "français (Djibouti)",
  "fr-DZ": "français (Algérie)",
  "fr-FR": "français (France)",
  "fr-GA": "français (Gabon)",
  "fr-GF": "français (Guyane française)",
  "fr-GN": "français (Guinée)",
  "fr-GP": "français (Guadeloupe)",
  "fr-GQ": "français (Guinée équatoriale)",
  "fr-HT": "français (Haïti)",
  "fr-KM": "français (Comores)",
  "fr-LU": "français (Luxembourg)",
  "fr-MA": "français (Maroc)",
  "fr-MC": "français (Monaco)",
  "fr-MF": "français (Saint-Martin)",
  "fr-MG": "français (Madagascar)",
  "fr-ML": "français (Mali)",
  "fr-MQ": "français (Martinique)",
  "fr-MR": "français (Mauritanie)",
  "fr-MU": "français (Maurice)",
  "fr-NC": "français (Nouvelle-Calédonie)",
  "fr-NE": "français (Niger)",
  "fr-PF": "français (Polynésie française)",
  "fr-PM": "français (Saint-Pierre-et-Miquelon)",
  "fr-RE": "français (La Réunion)",
  "fr-RW": "français (Rwanda)",
  "fr-SC": "français (Seychelles)",
  "fr-SN": "français (Sénégal)",
  "fr-SY": "français (Syrie)",
  "fr-TD": "français (Tchad)",
  "fr-TG": "français (Togo)",
  "fr-TN": "français (Tunisie)",
  "fr-VU": "français (Vanuatu)",
  "fr-WF": "français (Wallis-et-Futuna)",
  "fr-YT": "français (Mayotte)",
  "fur": "furlan",
  "fur-IT": "furlan (Italie)",
  "fy": "Frysk",
  "fy-NL": "Frysk (Nederlân)",
  "ga": "Gaeilge",
  "ga-IE": "Gaeilge (Éire)",
  "gd": "Gàidhlig",
  "gd-GB": "Gàidhlig (An Rìoghachd Aonaichte)",
  "gl": "galego",
  "gl-ES": "galego (galego)",
  "gn": "Avañe’ẽ",
  "gn-PY": "Avañe’ẽ (Paraguái)",
  "gsw": "Schwiizertüütsch",
  "gsw-CH": "Schwiizertüütsch (Schwiiz)",
  "gsw-FR": "Elsässisch (Frànkrisch)",
  "gsw-LI": "Schwiizertüütsch (Liächteschtäi)",
  "gu": "ગુજરાતી",
  "gu-IN": "ગુજરાતી (ભારત)",
  "guz": "Ekegusii",
  "guz-KE": "Ekegusii (Kenya)",
  "gv": "Gaelg",
  "gv-IM": "Gaelg (Ellan Vannin)",
  "ha": "Hausa",
  "ha-Latn": "Hausa",
  "ha-Latn-GH": "Hausa (Gana)",
  "ha-Latn-NE": "Hausa (Nijar)",
  "ha-Latn-NG": "Hausa (Najeriya)",
  "haw": "ʻŌlelo Hawaiʻi",
  "haw-US": "ʻŌlelo Hawaiʻi (ʻAmelika Hui Pū ʻIa)",
  "he": "עברית",
  "he-IL": "עברית (ישראל)",
  "hi": "हिन्दी",
  "hi-IN": "हिन्दी (भारत)",
  "hr": "hrvatski",
  "hr-BA": "hrvatski (Bosna i Hercegovina)",
  "hr-HR": "hrvatski (Hrvatska)",
  "hsb": "hornjoserbšćina",
  "hsb-DE": "hornjoserbšćina (Němska)",
  "hu": "magyar",
  "hu-HU": "magyar (Magyarország)",
  "hy": "Հայերեն",
  "hy-AM": "Հայերեն (Հայաստան)",
  "ia": "interlingua",
  "ia-001": "interlingua (World)",
  "ia-FR": "interlingua (Francia)",
  "ibb": "Ibibio-Efik",
  "ibb-NG": "Ibibio-Efik (Nigeria)",
  "id": "Indonesia",
  "id-ID": "Indonesia (Indonesia)",
  "ig": "Igbo",
  "ig-NG": "Igbo (Naịjịrịa)",
  "ii": "ꆈꌠꁱꂷ",
  "ii-CN": "ꆈꌠꁱꂷ (ꍏꉸꏓꂱꇭꉼꇩ)",
  "is": "íslenska",
  "is-IS": "íslenska (Ísland)",
  "it": "italiano",
  "it-CH": "italiano (Svizzera)",
  "it-IT": "italiano (Italia)",
  "it-SM": "italiano (San Marino)",
  "it-VA": "italiano (Città del Vaticano)",
  "iu": "Inuktitut",
  "iu-Cans": "ᐃᓄᒃᑎᑐᑦ",
  "iu-Cans-CA": "ᐃᓄᒃᑎᑐᑦ (ᑲᓇᑕᒥ)",
  "iu-Latn": "Inuktitut",
  "iu-Latn-CA": "Inuktitut (Kanatami)",
  "ja": "日本語",
  "ja-JP": "日本語 (日本)",
  "jgo": "Ndaꞌa",
  "jgo-CM": "Ndaꞌa (Kamɛlûn)",
  "jmc": "Kimachame",
  "jmc-TZ": "Kimachame (Tanzania)",
  "jv": "Basa Jawa",
  "jv-Java": "ꦧꦱꦗꦮ",
  "jv-Java-ID": "ꦧꦱꦗꦮ (Indonesia)",
  "jv-Latn": "Basa Jawa",
  "jv-Latn-ID": "Basa Jawa (Indonesia)",
  "ka": "ქართული",
  "kab": "Taqbaylit",
  "kab-DZ": "Taqbaylit (Lezzayer)",
  "ka-GE": "ქართული (საქართველო)",
  "kam": "Kikamba",
  "kam-KE": "Kikamba (Kenya)",
  "kde": "Chimakonde",
  "kde-TZ": "Chimakonde (Tanzania)",
  "kea": "kabuverdianu",
  "kea-CV": "kabuverdianu (Kabu Verdi)",
  "khq": "Koyra ciini",
  "khq-ML": "Koyra ciini (Maali)",
  "ki": "Gikuyu",
  "ki-KE": "Gikuyu (Kenya)",
  "kk": "қазақ тілі",
  "kkj": "kakɔ",
  "kkj-CM": "kakɔ (Kamɛrun)",
  "kk-KZ": "қазақ тілі (Қазақстан)",
  "kl": "kalaallisut",
  "kl-GL": "kalaallisut (Kalaallit Nunaat)",
  "kln": "Kalenjin",
  "kln-KE": "Kalenjin (Emetab Kenya)",
  "km": "ភាសាខ្មែរ",
  "km-KH": "ភាសាខ្មែរ (កម្ពុជា)",
  "kn": "ಕನ್ನಡ",
  "kn-IN": "ಕನ್ನಡ (ಭಾರತ)",
  "ko": "한국어",
  "kok": "कोंकणी",
  "kok-IN": "कोंकणी (भारत)",
  "ko-KP": "한국어 (조선민주주의인민공화국)",
  "ko-KR": "한국어(대한민국)",
  "kr": "Kanuri",
  "kr-NG": "Kanuri (Nigeria)",
  "ks": "کٲشُر",
  "ks-Arab": "کٲشُر",
  "ks-Arab-IN": "کٲشُر (اَربی)",
  "ksb": "Kishambaa",
  "ksb-TZ": "Kishambaa (Tanzania)",
  "ks-Deva": "कॉशुर",
  "ks-Deva-IN": "कॉशुर",
  "ksf": "rikpa",
  "ksf-CM": "rikpa (kamɛrún)",
  "ksh": "Kölsch",
  "ksh-DE": "Kölsch (Doütschland)",
  "ku": "کوردیی ناوەڕاست",
  "ku-Arab": "کوردیی ناوەڕاست",
  "ku-Arab-IQ": "کوردیی ناوەڕاست (عێراق)",
  "ku-Arab-IR": "کوردی (ئێران)",
  "kw": "kernewek",
  "kw-GB": "kernewek (Rywvaneth Unys)",
  "ky": "Кыргыз",
  "ky-KG": "Кыргыз (Кыргызстан)",
  "la": "lingua latīna",
  "la-001": "lingua latīna (World)",
  "lag": "Kɨlaangi",
  "lag-TZ": "Kɨlaangi (Taansanía)",
  "lb": "Lëtzebuergesch",
  "lb-LU": "Lëtzebuergesch (Lëtzebuerg)",
  "lg": "Luganda",
  "lg-UG": "Luganda (Yuganda)",
  "lkt": "Lakȟólʼiyapi",
  "lkt-US": "Lakȟólʼiyapi (Mílahaŋska Tȟamákȟočhe)",
  "ln": "lingála",
  "ln-AO": "lingála (Angóla)",
  "ln-CD": "lingála (Republíki ya Kongó Demokratíki)",
  "ln-CF": "lingála (Repibiki ya Afríka ya Káti)",
  "ln-CG": "lingála (Kongo)",
  "lo": "ລາວ",
  "lo-LA": "ລາວ (ລາວ)",
  "lrc": "لۊری شومالی",
  "lrc-IQ": "لۊری شومالی (Iraq)",
  "lrc-IR": "لۊری شومالی (Iran)",
  "lt": "lietuvių",
  "lt-LT": "lietuvių (Lietuva)",
  "lu": "Tshiluba",
  "lu-CD": "Tshiluba (Ditunga wa Kongu)",
  "luo": "Dholuo",
  "luo-KE": "Dholuo (Kenya)",
  "luy": "Luluhia",
  "luy-KE": "Luluhia (Kenya)",
  "lv": "latviešu",
  "lv-LV": "latviešu (Latvija)",
  "mas": "Maa",
  "mas-KE": "Maa (Kenya)",
  "mas-TZ": "Maa (Tansania)",
  "mer": "Kĩmĩrũ",
  "mer-KE": "Kĩmĩrũ (Kenya)",
  "mfe": "kreol morisien",
  "mfe-MU": "kreol morisien (Moris)",
  "mg": "Malagasy",
  "mgh": "Makua",
  "mgh-MZ": "Makua (Umozambiki)",
  "mg-MG": "Malagasy (Madagasikara)",
  "mgo": "metaʼ",
  "mgo-CM": "metaʼ (Kamalun)",
  "mi": "te reo Māori",
  "mi-NZ": "te reo Māori (Aotearoa)",
  "mk": "македонски",
  "mk-MK": "македонски (Република Македонија)",
  "ml": "മലയാളം",
  "ml-IN": "മലയാളം (ഇന്ത്യ)",
  "mn": "монгол",
  "mn-Cyrl": "монгол",
  "mni": "মৈতৈলোন্",
  "mni-IN": "মৈতৈলোন্ (India)",
  "mn-MN": "монгол (Монгол)",
  "mn-Mong": "ᠮᠣᠩᠭᠣᠤᠯ ᠬᠡᠯᠡ",
  "mn-Mong-CN": "ᠮᠣᠩᠭᠣᠤᠯ ᠬᠡᠯᠡ (ᠪᠦᠭᠦᠳᠡ ᠨᠠᠢᠷᠠᠮᠳᠠᠬᠤ ᠳᠤᠮᠳᠠᠳᠤ ᠠᠷᠠᠳ ᠣᠯᠣᠰ)",
  "mn-Mong-MN": "ᠮᠣᠩᠭᠣᠯ ᠬᠡᠯᠡ (ᠮᠣᠩᠭᠣᠯ ᠣᠯᠣᠰ)",
  "moh": "Kanien’kéha",
  "moh-CA": "Kanien'kéha",
  "mr": "मराठी",
  "mr-IN": "मराठी (भारत)",
  "ms": "Melayu",
  "ms-BN": "Melayu (Brunei)",
  "ms-MY": "Melayu (Malaysia)",
  "ms-SG": "Melayu (Singapura)",
  "mt": "Malti",
  "mt-MT": "Malti (Malta)",
  "mua": "MUNDAŊ",
  "mua-CM": "MUNDAŊ (kameruŋ)",
  "my": "ဗမာ",
  "my-MM": "မြန်မာ (မြန်မာ)",
  "mzn": "مازرونی",
  "mzn-IR": "مازرونی (ایران)",
  "naq": "Khoekhoegowab",
  "naq-NA": "Khoekhoegowab (Namibiab)",
  "nb": "norsk bokmål",
  "nb-NO": "norsk bokmål (Norge)",
  "nb-SJ": "norsk bokmål (Svalbard og Jan Mayen)",
  "nd": "isiNdebele",
  "nds": "Neddersass’sch",
  "nds-DE": "Neddersass’sch (Düütschland)",
  "nds-NL": "Neddersass’sch (Nedderlannen)",
  "nd-ZW": "isiNdebele (Zimbabwe)",
  "ne": "नेपाली",
  "ne-IN": "नेपाली (भारत)",
  "ne-NP": "नेपाली (नेपाल)",
  "nl": "Nederlands",
  "nl-AW": "Nederlands (Aruba)",
  "nl-BE": "Nederlands (België)",
  "nl-BQ": "Nederlands (Bonaire, Sint Eustatius en Saba)",
  "nl-CW": "Nederlands (Curaçao)",
  "nl-NL": "Nederlands (Nederland)",
  "nl-SR": "Nederlands (Suriname)",
  "nl-SX": "Nederlands (Sint-Maarten)",
  "nmg": "Kwasio",
  "nmg-CM": "Kwasio (Kamerun)",
  "nn": "nynorsk",
  "nnh": "Shwóŋò ngiembɔɔn",
  "nnh-CM": "Shwóŋò ngiembɔɔn (Kàmalûm)",
  "nn-NO": "nynorsk (Noreg)",
  "no": "norsk",
  "nqo": "ߒߞߏ",
  "nqo-GN": "ߒߞߏ (ߖߌ߬ߣߍ߬ ߞߊ߲ߓߍ߲)",
  "nr": "isiNdebele",
  "nr-ZA": "isiNdebele (South Africa)",
  "nso": "Sesotho sa Leboa",
  "nso-ZA": "Sesotho sa Leboa (Afrika Borwa)",
  "nus": "Thok Nath",
  "nus-SS": "Thok Nath (South Sudan)",
  "nyn": "Runyankore",
  "nyn-UG": "Runyankore (Uganda)",
  "oc": "Occitan",
  "oc-FR": "Occitan (França)",
  "om": "Oromoo",
  "om-ET": "Oromoo (Itoophiyaa)",
  "om-KE": "Oromoo (Keeniyaa)",
  "or": "ଓଡ଼ିଆ",
  "or-IN": "ଓଡ଼ିଆ (ଭାରତ)",
  "os": "ирон",
  "os-GE": "ирон (Гуырдзыстон)",
  "os-RU": "ирон (Уӕрӕсе)",
  "pa": "ਪੰਜਾਬੀ",
  "pa-Arab": "پنجابی",
  "pa-Arab-PK": "پنجابی (پاکستان)",
  "pa-Guru": "ਪੰਜਾਬੀ",
  "pa-IN": "ਪੰਜਾਬੀ (ਭਾਰਤ)",
  "pap": "Papiamentu",
  "pap-029": "Papiamentu (Caribbean)",
  "pl": "polski",
  "pl-PL": "polski (Polska)",
  "prg": "prūsiskan",
  "prg-001": "prūsiskan (swītai)",
  "prs": "درى",
  "prs-AF": "درى (افغانستان)",
  "ps": "پښتو",
  "ps-AF": "پښتو (افغانستان)",
  "pt": "português",
  "pt-AO": "português (Angola)",
  "pt-BR": "português (Brasil)",
  "pt-CH": "português (Suíça)",
  "pt-CV": "português (Cabo Verde)",
  "pt-GQ": "português (Guiné Equatorial)",
  "pt-GW": "português (Guiné-Bissau)",
  "pt-LU": "português (Luxemburgo)",
  "pt-MO": "português (RAE de Macau)",
  "pt-MZ": "português (Moçambique)",
  "pt-PT": "português (Portugal)",
  "pt-ST": "português (São Tomé e Príncipe)",
  "pt-TL": "português (Timor-Leste)",
  "quc": "K'iche'",
  "quc-Latn": "K'iche'",
  "quc-Latn-GT": "K'iche' (Guatemala)",
  "quz": "Runasimi",
  "quz-BO": "Runasimi (Bolivia)",
  "quz-EC": "Runasimi (Ecuador)",
  "quz-PE": "Runasimi (Perú)",
  "rm": "rumantsch",
  "rm-CH": "rumantsch (Svizra)",
  "rn": "Ikirundi",
  "rn-BI": "Ikirundi (Uburundi)",
  "ro": "română",
  "rof": "Kihorombo",
  "rof-TZ": "Kihorombo (Tanzania)",
  "ro-MD": "română (Republica Moldova)",
  "ro-RO": "română (România)",
  "ru": "русский",
  "ru-BY": "русский (Беларусь)",
  "ru-KG": "русский (Киргизия)",
  "ru-KZ": "русский (Казахстан)",
  "ru-MD": "русский (Молдова)",
  "ru-RU": "русский (Россия)",
  "ru-UA": "русский (Украина)",
  "rw": "Kinyarwanda",
  "rwk": "Kiruwa",
  "rwk-TZ": "Kiruwa (Tanzania)",
  "rw-RW": "Kinyarwanda (U Rwanda)",
  "sa": "संस्कृत",
  "sah": "Саха",
  "sah-RU": "Саха (Россия)",
  "sa-IN": "संस्कृत (भारतम्)",
  "saq": "Kisampur",
  "saq-KE": "Kisampur (Kenya)",
  "sbp": "Ishisangu",
  "sbp-TZ": "Ishisangu (Tansaniya)",
  "sd": "سنڌي",
  "sd-Arab": "سنڌي",
  "sd-Arab-PK": "سنڌي (پاکستان)",
  "sd-Deva": "सिन्धी",
  "sd-Deva-IN": "सिन्धी (India)",
  "se": "davvisámegiella",
  "se-FI": "davvisámegiella (Suopma)",
  "seh": "sena",
  "seh-MZ": "sena (Moçambique)",
  "se-NO": "davvisámegiella (Norga)",
  "ses": "Koyraboro senni",
  "se-SE": "davvisámegiella (Ruoŧŧa)",
  "ses-ML": "Koyraboro senni (Maali)",
  "sg": "Sängö",
  "sg-CF": "Sängö (Ködörösêse tî Bêafrîka)",
  "shi": "ⵜⴰⵛⵍⵃⵉⵜ",
  "shi-Latn": "Tashelḥiyt",
  "shi-Latn-MA": "Tashelḥiyt (lmɣrib)",
  "shi-Tfng": "ⵜⴰⵛⵍⵃⵉⵜ",
  "shi-Tfng-MA": "ⵜⴰⵛⵍⵃⵉⵜ (ⵍⵎⵖⵔⵉⴱ)",
  "si": "සිංහල",
  "si-LK": "සිංහල (ශ්‍රී ලංකාව)",
  "sk": "slovenčina",
  "sk-SK": "slovenčina (Slovensko)",
  "sl": "slovenščina",
  "sl-SI": "slovenščina (Slovenija)",
  "sma": "åarjelsaemiengïele",
  "sma-NO": "åarjelsaemiengïele (Nöörje)",
  "sma-SE": "åarjelsaemiengïele (Sveerje)",
  "smj": "julevusámegiella",
  "smj-NO": "julevusámegiella (Vuodna)",
  "smj-SE": "julevusámegiella (Svierik)",
  "smn": "anarâškielâ",
  "smn-FI": "anarâškielâ (Suomâ)",
  "sms": "sää´mǩiõll",
  "sms-FI": "sää´mǩiõll (Lää´ddjânnam)",
  "sn": "chiShona",
  "sn-Latn": "chiShona (Latin)",
  "sn-Latn-ZW": "chiShona (Zimbabwe)",
  "so": "Soomaali",
  "so-DJ": "Soomaali (Jabuuti)",
  "so-ET": "Soomaali (Itoobiya)",
  "so-KE": "Soomaali (Kiiniya)",
  "so-SO": "Soomaali (Soomaaliya)",
  "sq": "shqip",
  "sq-AL": "shqip (Shqipëri)",
  "sq-MK": "shqip (Republika e Maqedonisë)",
  "sq-XK": "shqip (Kosovë)",
  "sr": "srpski",
  "sr-Cyrl": "српски",
  "sr-Cyrl-BA": "српски (Босна и Херцеговина)",
  "sr-Cyrl-ME": "српски (Црна Гора)",
  "sr-Cyrl-RS": "српски (Србија)",
  "sr-Cyrl-XK": "српски (Косово)",
  "sr-Latn": "srpski",
  "sr-Latn-BA": "srpski (Bosna i Hercegovina)",
  "sr-Latn-ME": "srpski (Crna Gora)",
  "sr-Latn-RS": "srpski (Srbija)",
  "sr-Latn-XK": "srpski (Kosovo)",
  "ss": "Siswati",
  "ss-SZ": "siSwati (Swaziland)",
  "ssy": "Saho",
  "ssy-ER": "Saho (Eretria)",
  "ss-ZA": "siSwati (South Africa)",
  "st": "Sesotho",
  "st-LS": "Sesotho (Lesotho)",
  "st-ZA": "Sesotho (South Africa)",
  "sv": "svenska",
  "sv-AX": "svenska (Åland)",
  "sv-FI": "svenska (Finland)",
  "sv-SE": "svenska (Sverige)",
  "sw": "Kiswahili",
  "sw-CD": "Kiswahili (Jamhuri ya Kidemokrasia ya Kongo)",
  "sw-KE": "Kiswahili (Kenya)",
  "sw-TZ": "Kiswahili (Tanzania)",
  "sw-UG": "Kiswahili (Uganda)",
  "syr": "ܣܘܪܝܝܐ",
  "syr-SY": "ܣܘܪܝܝܐ (ܣܘܪܝܐ)",
  "ta": "தமிழ்",
  "ta-IN": "தமிழ் (இந்தியா)",
  "ta-LK": "தமிழ் (இலங்கை)",
  "ta-MY": "தமிழ் (மலேசியா)",
  "ta-SG": "தமிழ் (சிங்கப்பூர்)",
  "te": "తెలుగు",
  "te-IN": "తెలుగు (భారత దేశం)",
  "teo": "Kiteso",
  "teo-KE": "Kiteso (Kenia)",
  "teo-UG": "Kiteso (Uganda)",
  "tg": "Тоҷикӣ",
  "tg-Cyrl": "тоҷикӣ",
  "tg-Cyrl-TJ": "тоҷикӣ (Тоҷикистон)",
  "th": "ไทย",
  "th-TH": "ไทย (ไทย)",
  "ti": "ትግርኛ",
  "ti-ER": "ትግርኛ (ኤርትራ)",
  "ti-ET": "ትግርኛ (ኢትዮጵያ)",
  "tig": "ትግረ",
  "tig-ER": "ትግረ (ኤርትራ)",
  "tk": "Türkmen dili",
  "tk-TM": "Türkmen dili (Türkmenistan)",
  "tn": "Setswana",
  "tn-BW": "Setswana (Botswana)",
  "tn-ZA": "Setswana (Aforika Borwa)",
  "to": "lea fakatonga",
  "to-TO": "lea fakatonga (Tonga)",
  "tr": "Türkçe",
  "tr-CY": "Türkçe (Kıbrıs)",
  "tr-TR": "Türkçe (Türkiye)",
  "ts": "Xitsonga",
  "ts-ZA": "Xitsonga (South Africa)",
  "tt": "Татар",
  "tt-RU": "Татар (Россия)",
  "twq": "Tasawaq senni",
  "twq-NE": "Tasawaq senni (Nižer)",
  "tzm": "Tamaziɣt n laṭlaṣ",
  "tzm-Arab": "أطلس المركزية التامازيتية",
  "tzm-Arab-MA": "أطلس المركزية التامازيتية (Morocco)",
  "tzm-Latn": "Tamaziɣt n laṭlaṣ",
  "tzm-Latn-DZ": "Tamaziɣt n laṭlaṣ (Djazaïr)",
  "tzm-Latn-MA": "Tamaziɣt n laṭlaṣ (Meṛṛuk)",
  "tzm-Tfng": "ⵜⴰⵎⴰⵣⵉⵖⵜ",
  "tzm-Tfng-MA": "ⵜⴰⵎⴰⵣⵉⵖⵜ (ⵍⵎⵖⵔⵉⴱ)",
  "ug": "ئۇيغۇرچە",
  "ug-CN": "ئۇيغۇرچە (جۇڭخۇا خەلق جۇمھۇرىيىتى)",
  "uk": "українська",
  "uk-UA": "українська (Україна)",
  "ur": "اُردو",
  "ur-IN": "اردو (بھارت)",
  "ur-PK": "اُردو (پاکستان)",
  "uz": "o‘zbek",
  "uz-Arab": "اوزبیک",
  "uz-Arab-AF": "اوزبیک (افغانستان)",
  "uz-Cyrl": "ўзбекча",
  "uz-Cyrl-UZ": "ўзбекча (Ўзбекистон)",
  "uz-Latn": "o‘zbek",
  "uz-Latn-UZ": "o‘zbek (Oʻzbekiston)",
  "vai": "ꕙꔤ",
  "vai-Latn": "Vai",
  "vai-Latn-LR": "Vai (Laibhiya)",
  "vai-Vaii": "ꕙꔤ",
  "vai-Vaii-LR": "ꕙꔤ (ꕞꔤꔫꕩ)",
  "ve": "Tshivenḓa",
  "ve-ZA": "Tshivenḓa (South Africa)",
  "vi": "Tiếng Việt",
  "vi-VN": "Tiếng Việt (Việt Nam)",
  "vo": "Volapük",
  "vo-001": "Volapük (World)",
  "vun": "Kyivunjo",
  "vun-TZ": "Kyivunjo (Tanzania)",
  "wae": "Walser",
  "wae-CH": "Walser (Schwiz)",
  "wal": "ወላይታቱ",
  "wal-ET": "ወላይታቱ (ኢትዮጵያ)",
  "wo": "Wolof",
  "wo-SN": "Wolof (Senegaal)",
  "xh": "isiXhosa",
  "xh-ZA": "isiXhosa (eMzantsi Afrika)",
  "xog": "Olusoga",
  "xog-UG": "Olusoga (Yuganda)",
  "yav": "nuasue",
  "yav-CM": "nuasue (Kemelún)",
  "yi": "ייִדיש",
  "yi-001": "ייִדיש (וועלט)",
  "yo": "Èdè Yorùbá",
  "yo-BJ": "Èdè Yorùbá (Orílɛ́ède Bɛ̀nɛ̀)",
  "yo-NG": "Èdè Yorùbá (Orílẹ́ède Nàìjíríà)",
  "zgh": "ⵜⴰⵎⴰⵣⵉⵖⵜ",
  "zgh-Tfng": "ⵜⴰⵎⴰⵣⵉⵖⵜ",
  "zgh-Tfng-MA": "ⵜⴰⵎⴰⵣⵉⵖⵜ (ⵍⵎⵖⵔⵉⴱ)",
  "zh": "中文",
  "zh-CHS": "中文(简体) 旧版",
  "zh-CHT": "中文(繁體) 舊版",
  "zh-CN": "中文(中国)",
  "zh-Hans": "中文(简体)",
  "zh-Hans-HK": "中文 (香港特别行政区)",
  "zh-Hans-MO": "中文 (澳门特别行政区)",
  "zh-Hant": "中文(繁體)",
  "zh-HK": "中文(香港特別行政區)",
  "zh-MO": "中文(澳門特別行政區)",
  "zh-SG": "中文(新加坡)",
  "zh-TW": "中文(台灣)",
  "zu": "isiZulu",
  "zu-ZA": "isiZulu (iNingizimu Afrika)"
}
