// Get style for widget's border (except colors)
const getWidgetBorderStyle = (borderData): string => {
  if (
    !borderData ||
    (!borderData.color &&
      borderData.thickness === undefined &&
      borderData.rounded === undefined)
  ) {
    return 'rf-border rf-rounded rf-border-gray'
  }

  const borderThickness =
    borderData.thickness !== undefined && borderData.thickness !== 1
      ? `rf-border-${borderData.thickness}`
      : 'rf-border'
  const borderRounded = borderData.rounded === false ? '' : 'rf-rounded-lg'

  return `${borderThickness} ${borderRounded}`
}

// Get style for widget's button (except colors)
const getWidgetButtonStyle = (buttonData): string => {
  if (
    !buttonData ||
    (!buttonData.text &&
      !buttonData.image &&
      !buttonData.border &&
      !buttonData.background &&
      !buttonData.position)
  ) {
    return 'rf-rounded-lg rf-font-bold'
  }

  // Button border
  const borderStyle =
    buttonData.border?.rounded === false ? '' : 'rf-rounded-lg'

  // Button text
  const textStyle =
    buttonData.text?.bold && buttonData.text.bold === false
      ? ''
      : 'rf-font-bold'

  return `${borderStyle} ${textStyle}`
}

export { getWidgetButtonStyle, getWidgetBorderStyle }
