import React from 'react'
import type { RetorikActivity } from '../../models/activityTypes'
import ListContextProvider from '../Contexts/ListContextProvider'
import ListContent from '../Display/List'

interface ListProps {
  activity?: RetorikActivity
  onClose?: () => void
}

const List = ({ activity, onClose }: ListProps): JSX.Element => {
  return (
    <ListContextProvider>
      <ListContent activity={activity} onClose={onClose} />
    </ListContextProvider>
  )
}

export default List
