{
  "fr-FR": {
    "download": "Télécharger",
    "goto": "Accéder à l'adresse",
    "click": "Cliquez-ici",
    "scanQR": "en scannant le QrCode"
  },
  "en-US": {
    "download": "Download",
    "goto": "Go to address",
    "click": "Click here",
    "scanQR": "by scanning the QrCode"
  },
  "es-ES": {
    "download": "Download",
    "goto": "Go to address",
    "click": "Click here",
    "scanQR": "by scanning the QrCode"
  },
  "de-DE": {
    "download": "Download",
    "goto": "Go to address",
    "click": "Click here",
    "scanQR": "by scanning the QrCode"
  }
}
