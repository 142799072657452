import {
  hospitalityApiAddress,
  webchatTokenRetrievalAddress
} from '../models/constants'
import { EmergencyTypes } from '../models/emergencyTypes'
import type { AddressData } from '../models/types'
import processPrefix from './studioRetorikAddressFromPrefix'

async function fetchEmergencyData(data: AddressData): Promise<EmergencyTypes> {
  // baseURI is used for retrocompatibility
  let fullAddress = ''
  if (data.baseURI) {
    fullAddress = data.baseURI
  } else {
    fullAddress = `${processPrefix(data.prefix)}/${data.tenant}`
  }
  if (fullAddress.indexOf(`/${webchatTokenRetrievalAddress}`) > -1) {
    fullAddress = fullAddress.replace(`/${webchatTokenRetrievalAddress}`, '')
  }
  fullAddress += `/${hospitalityApiAddress}`
  const res = await fetch(fullAddress)
  const token = await res.json()

  return token
}

export { fetchEmergencyData }
