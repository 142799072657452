import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { GUID } from '../../../utils/data'

type LicenceItem = {
  productid: string
  expireAt: string
}

type Licence = {
  Customer: {
    Name: string
  }
  Vendor: {
    Name: string
  }
  Licences: Array<LicenceItem>
}

export interface LicenceState {
  value: {
    licence: string | undefined
    allowed: boolean
    errorCode: number
  }
}

/* Error codes
0: no error
1: no licence
2: incorrect licence
3: no data for this product
4: outdated licence
*/

// Define the initial state using that type
const initialState: LicenceState = {
  value: {
    licence: undefined,
    allowed: false,
    errorCode: 1
  }
}

export const licenceSlice = createSlice({
  name: 'licenceSlice',
  initialState,
  reducers: {
    setLicence: (state, action: PayloadAction<any>) => {
      state.value = {
        licence: action.payload,
        allowed: false,
        errorCode: 1
      }
    },
    checkLicence: (state) => {
      // TODO Decrypt licence

      const decodedLicence = {
        Customer: {
          Name: 'DAVI SAS'
        },
        Vendor: {
          Name: 'DAVI SAS'
        },
        Licences: [
          {
            productid: '093A49A9-4EF5-4D1D-9BC4-102361C87844',
            expireAt: '2026-02-18'
          }
        ]
      } as Licence

      // Check licence data validity
      const licence = state.value.licence

      var errorCode = 0
      // Check if the fields are present
      !(decodedLicence.Customer && decodedLicence.Customer.Name) &&
        (errorCode = 2)
      !(decodedLicence.Vendor && decodedLicence.Vendor.Name) && (errorCode = 2)
      !decodedLicence.Licences && (errorCode = 2)

      var goodItem = null
      if (errorCode === 0) {
        decodedLicence.Licences.length === 0 && (errorCode = 3)
        // Check if the product id is the same as the GUID for the SpiritEngineSprite
        goodItem = decodedLicence.Licences.find(
          (item) => item.productid === GUID
        )
        !goodItem && (errorCode = 3)
      }

      if (errorCode === 0) {
        !goodItem?.expireAt && (errorCode = 2)
        // @ts-ignore
        const date = new Date(goodItem.expireAt).getTime()
        const now = Date.now()
        // Check if the timestamp retrieved is a number
        if (isNaN(date)) {
          errorCode = 2
        } else {
          date < now && (errorCode = 4)
        }
      }

      state.value = {
        licence: licence,
        allowed: errorCode === 0 ? true : false,
        errorCode: errorCode
      }
    }
  }
})

export const { setLicence, checkLicence } = licenceSlice.actions

export default licenceSlice.reducer
