import React from 'react'
import type { IconDefaultProps } from '../../../models/types'

const MicrophoneIcon = ({
  className,
  color
}: IconDefaultProps): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      className={className || 'rf-h-6'}
      viewBox='0 0 14.426 20.983'
    >
      <defs>
        <filter id='Icon_awesome-microphone'>
          <feOffset dy='3' in='SourceAlpha' />
          <feGaussianBlur stdDeviation='3' result='blur' />
          <feFlood floodOpacity='0.161' result='color' />
          <feComposite operator='out' in='SourceGraphic' in2='blur' />
          <feComposite operator='in' in='color' />
          <feComposite operator='in' in2='SourceGraphic' />
        </filter>
      </defs>
      <g>
        <path
          d='M7.213,14.426a3.934,3.934,0,0,0,3.934-3.934V3.934a3.934,3.934,0,1,0-7.869,0v6.557A3.934,3.934,0,0,0,7.213,14.426ZM13.77,7.869h-.656a.656.656,0,0,0-.656.656v1.967a5.252,5.252,0,0,1-5.77,5.22,5.405,5.405,0,0,1-4.722-5.454V8.524a.656.656,0,0,0-.656-.656H.656A.656.656,0,0,0,0,8.524V10.17a7.439,7.439,0,0,0,6.229,7.446v1.4h-2.3a.656.656,0,0,0-.656.656v.656a.656.656,0,0,0,.656.656h6.557a.656.656,0,0,0,.656-.656v-.656a.656.656,0,0,0-.656-.656H8.2V17.632a7.22,7.22,0,0,0,6.229-7.14V8.524A.656.656,0,0,0,13.77,7.869Z'
          fill={color || '#a4a7af'}
        />
        <g
          transform='matrix(1, 0, 0, 1, 0, 0)'
          filter='url(#Icon_awesome-microphone)'
        >
          <path
            d='M7.213,14.426a3.934,3.934,0,0,0,3.934-3.934V3.934a3.934,3.934,0,1,0-7.869,0v6.557A3.934,3.934,0,0,0,7.213,14.426ZM13.77,7.869h-.656a.656.656,0,0,0-.656.656v1.967a5.252,5.252,0,0,1-5.77,5.22,5.405,5.405,0,0,1-4.722-5.454V8.524a.656.656,0,0,0-.656-.656H.656A.656.656,0,0,0,0,8.524V10.17a7.439,7.439,0,0,0,6.229,7.446v1.4h-2.3a.656.656,0,0,0-.656.656v.656a.656.656,0,0,0,.656.656h6.557a.656.656,0,0,0,.656-.656v-.656a.656.656,0,0,0-.656-.656H8.2V17.632a7.22,7.22,0,0,0,6.229-7.14V8.524A.656.656,0,0,0,13.77,7.869Z'
            fill='#fff'
          />
        </g>
      </g>
    </svg>
  )
}

export default MicrophoneIcon
