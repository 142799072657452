import React from 'react'
import { RetorikLogoIcon } from '../Icons/Miscellaneous'

const RetorikLogo = (): JSX.Element => {
  return (
    <a
      href='https://davi.ai/retorik'
      title='Retorik by Davi'
      target='_blank'
      rel='noreferrer'
      className='rf-hidden large:rf-block rf-relative rf-col-start-1 rf-col-span-full rf-row-start-12 rf-row-span-1 rf-self-end rf-justify-self-start'
    >
      <RetorikLogoIcon className='rf-ml-4 rf-mb-4 rf-h-14' />
    </a>
  )
}

export default RetorikLogo
