import useLastBotActivity from './useLastBotActivity'
import type { RetorikActivity } from '../models/activityTypes'

export default function useSpeechEnded(): RetorikActivity | undefined {
  const [lastBotActivity] = useLastBotActivity()

  if (lastBotActivity?.channelData?.speak === false) {
    return lastBotActivity
  }
  return undefined
}
