enum AvailableViews {
  home = 1,
  news,
  weather,
  emergency
}

enum AvailableSubViews {
  history = 1000,
  tutorial
}

enum CategoryId {
  eat = 'where to eat',
  sleep = 'where to sleep',
  see = 'what to see',
  do = 'what to do',
  localproducts = 'local products',
  services = 'services',
  agenda = 'events'
}

enum ContainerParent {
  agent = 1,
  widget,
  news
}

enum CurrentSubView {
  none = 1,
  history,
  languages,
  weather,
  emergency,
  custom
}

enum Depth {
  background = 1,
  util,
  chatbot,
  ui,
  dropdown,
  overlay,
  menu,
  modal,
  exitwidget
}

enum DeviceType {
  mobile = 1,
  borne,
  landscape,
  widget
}

enum LoaderSteps {
  loader = 1,
  locale,
  supported,
  store,
  directline,
  ponyfill,
  agentdata
}

enum Mode {
  vocal = 1,
  text,
  dashboard
}

enum Position {
  top = 1,
  right,
  bottom,
  left
}

enum RetorikEvent {
  DetailViewOpen = 1,
  DetailViewClose = 2,
  SpeechStarted = 3,
  SpeechEnded = 4
}

export {
  AvailableViews,
  AvailableSubViews,
  CategoryId,
  ContainerParent,
  CurrentSubView,
  Depth,
  DeviceType,
  LoaderSteps,
  Mode,
  Position,
  RetorikEvent
}
