const getDistanceAsString = (distance: number | undefined): string => {
  if (typeof distance !== 'number') {
    return 'NC'
  }

  if (distance <= 0) return '0 m'
  if (distance < 1) return `${distance * 100} cm`
  if (distance < 1000) return `${distance} m`
  if (distance >= 1000) return `${(Math.ceil(distance / 1000) * 10) / 10} km`

  return 'NC'
}

export default getDistanceAsString
