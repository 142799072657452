import React, { useEffect, useState } from 'react'
import RoutesItem from './RoutesItem'
import { openRoute, openRouteAPIKey } from '../../../models/constants'
import type { FeatureCollection } from 'geojson'

type RoutesProps = {
  coordinates: number[][]
  setMapLayerData: (data: FeatureCollection | undefined) => void
  color?: string
  colorWithTransparency?: string
}

const Routes = ({
  coordinates,
  setMapLayerData,
  color,
  colorWithTransparency
}: RoutesProps): JSX.Element => {
  const [drivingCar, setDrivingCar] = useState<FeatureCollection | undefined>(
    undefined
  )
  const [cyclingRegular, setCyclingRegular] = useState<
    FeatureCollection | undefined
  >(undefined)
  const [footWalking, setFootWalking] = useState<FeatureCollection | undefined>(
    undefined
  )
  const [currentChosen, setCurrentChosen] = useState<string | undefined>(
    undefined
  )

  /**
   * On component mount :
   *  - call methods to retrieve data from openroute API
   */
  useEffect(() => {
    calculateForProfile('driving-car')
    calculateForProfile('cycling-regular')
    calculateForProfile('foot-walking')
  }, [])

  /**
   * On currentChosen state change :
   *  - switch on currentChosen value to display the associated data on the map
   */
  useEffect(() => {
    switch (currentChosen) {
      case 'drivingCar':
        drivingCar && drivingCar.features && setMapLayerData(drivingCar)
        break
      case 'cyclingRegular':
        cyclingRegular &&
          cyclingRegular.features &&
          setMapLayerData(cyclingRegular)
        break
      case 'footWalking':
        footWalking && footWalking.features && setMapLayerData(footWalking)
        break
      default:
        break
    }
  }, [currentChosen])

  /**
   * On drivingCar state change :
   *  - if driving car data were retrieved and no course has been chosen, set curretnChosen state to 'driving-car'
   */
  useEffect(() => {
    drivingCar && !currentChosen && setCurrentChosen('drivingCar')
  }, [drivingCar])

  /**
   * On call :
   *  - call the openroute API to retrieve data for the current course
   * @param profile : string
   */
  const calculateForProfile = async (profile: string): Promise<void> => {
    const url = `${openRoute}/${profile}/geojson`
    const data = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: openRouteAPIKey,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ coordinates: coordinates })
    })
      .then((result) => {
        return result.json()
      })
      .catch(() => {
        return null
      })

    if (data && !data.error) {
      switch (profile) {
        case 'driving-car':
          setDrivingCar(data)
          break
        case 'cycling-regular':
          setCyclingRegular(data)
          break
        case 'foot-walking':
          setFootWalking(data)
          break
        default:
          break
      }
    }
  }

  return drivingCar || cyclingRegular || footWalking ? (
    <div className='rf-absolute rf-bottom-0 rf-left-1/2 -rf-translate-x-1/2 rf-grid rf-grid-cols-routes rf-border rf-border-x-truewhite rf-border-t-0 rf-bg-truewhite rf-text-xs large:rf-text-sm rf-shadow-[0_-5px_10px_silver,-5px_0_10px_silver,5px_0_10px_silver]'>
      <RoutesItem
        summary={{
          type: 'drivingCar',
          distance:
            drivingCar?.features && drivingCar.features.length > 0
              ? drivingCar.features[0].properties?.summary?.distance
              : undefined,
          duration:
            drivingCar?.features && drivingCar.features.length > 0
              ? drivingCar.features[0].properties?.summary?.duration
              : undefined
        }}
        chosen={currentChosen === 'drivingCar'}
        color={color}
        colorWithTransparency={colorWithTransparency}
        onClick={setCurrentChosen}
      />
      <RoutesItem
        summary={{
          type: 'cyclingRegular',
          distance:
            cyclingRegular?.features && cyclingRegular.features.length > 0
              ? cyclingRegular.features[0].properties?.summary?.distance
              : undefined,
          duration:
            cyclingRegular?.features && cyclingRegular.features.length > 0
              ? cyclingRegular.features[0].properties?.summary?.duration
              : undefined
        }}
        // eslint-disable-next-line react/jsx-boolean-value
        middle={true}
        chosen={currentChosen === 'cyclingRegular'}
        color={color}
        colorWithTransparency={colorWithTransparency}
        onClick={setCurrentChosen}
      />
      <RoutesItem
        summary={{
          type: 'footWalking',
          distance:
            footWalking?.features && footWalking.features.length > 0
              ? footWalking.features[0].properties?.summary?.distance
              : undefined,
          duration:
            footWalking?.features && footWalking.features.length > 0
              ? footWalking.features[0].properties?.summary?.duration
              : undefined
        }}
        chosen={currentChosen === 'footWalking'}
        color={color}
        colorWithTransparency={colorWithTransparency}
        onClick={setCurrentChosen}
      />
    </div>
  ) : (
    <React.Fragment />
  )
}

export default Routes
