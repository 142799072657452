import React from 'react'
import type { IconDefaultProps } from '../../../models/types'

const MicrophoneIconOn = ({
  className,
  color
}: IconDefaultProps): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className || 'rf-h-6 rf-w-8'}
      viewBox='0 0 21.055 18.479'
    >
      <g transform='translate(-44.181 -45.388)'>
        <rect
          width='8.229'
          height='18.479'
          rx='2'
          transform='translate(44.181 45.388)'
          fill={color || '#ff6462'}
        />
        <rect
          width='8.229'
          height='18.479'
          rx='2'
          transform='translate(57.007 45.388)'
          fill={color || '#ff6462'}
        />
      </g>
    </svg>
  )
}

export default MicrophoneIconOn
