{
  "_comment": "[CI-LOCKED] محتوى هذا الملف مؤمن. نرحب بالمساهمات، ولكن التحقق من صحة التغيير قد يتأخر.",
  "ACCESS_KEY_ALT": "Alt Shift $1",
  "ACCESS_KEY_FOR_MAC_ALT": "Control Option $1",
  "ACTIVITY_BOT_SAID_ALT": "قابل الروبوت $1:",
  "ACTIVITY_YOU_SAID_ALT": "لقد قلت:",
  "ACTIVITY_BOT_ATTACHED_ALT": "قام الروبوت بإرفاق:",
  "ACTIVITY_ERROR_BOX_TITLE": "رسالة الخطأ",
  "ACTIVITY_INTERACTIVE_FOOTNOTE_ALT": "انقر للتفاعل.",
  "ACTIVITY_INTERACTIVE_REASON_INTERACTIVE_CONTENT_ALT": "الرسالة تفاعلية.",
  "ACTIVITY_INTERACTIVE_REASON_LINK_ALT": "ارتباط واحد أو أكثر في الرسالة.",
  "ACTIVITY_INTERACTIVE_REASON_SEND_FAILED_ALT": "فشل الإرسال.",
  "ACTIVITY_YOU_ATTACHED_ALT": "لقد قمت بإرفاق:",
  "ACTIVITY_NUM_ATTACHMENTS_ONE_ALT": "‏‏مرفق واحد.",
  "ACTIVITY_NUM_ATTACHMENTS_FEW_ALT": "$1 مرفق (مرفقات).",
  "ACTIVITY_NUM_ATTACHMENTS_MANY_ALT": "$1 مرفق (مرفقات).",
  "ACTIVITY_NUM_ATTACHMENTS_OTHER_ALT": "$1 مرفق (مرفقات).",
  "ACTIVITY_NUM_ATTACHMENTS_TWO_ALT": "$1 مرفق (مرفقات).",
  "ACTIVITY_STATUS_SEND_FAILED_RETRY": "فشل الإرسال. [إعادة المحاولة][RETRY].",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENDING": "جارٍ الإرسال",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENT_AT": "تم إرسالها عند الساعة $1",
  "ACTIVITY_STATUS_TIMESTAMP_JUST_NOW": "الآن",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_HOUR_AGO": "قبل ساعة",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_MINUTE_AGO": "قبل دقيقة",
  "ACTIVITY_STATUS_TIMESTAMP_TODAY": "اليوم",
  "ACTIVITY_STATUS_TIMESTAMP_YESTERDAY": "الأمس",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_PARSE": "خطأ في تحليل البطاقة الموائمة",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_RENDER": "خطأ في عرض البطاقة الموائمة",
  "ATTACHMENT_AUDIO": "مقطع صوتي.",
  "ATTACHMENT_CARD": "بطاقة: $1 $2 $3",
  "ATTACHMENT_FILE": "ملف: $1",
  "ATTACHMENT_IMAGE": "صورة",
  "ATTACHMENT_TEXT": "نص: $1",
  "ATTACHMENT_VIDEO": "مقطع فيديو",
  "CAROUSEL_FLIPPER_LEFT_ALT": "يسار",
  "CAROUSEL_FLIPPER_RIGHT_ALT": "يمين",
  "CONNECTIVITY_STATUS_ALT_CONNECTED": "متصل",
  "CONNECTIVITY_STATUS_ALT_CONNECTING": "جارٍ الاتصال…",
  "CONNECTIVITY_STATUS_ALT_FATAL": "يتعذر الاتصال.",
  "CONNECTIVITY_STATUS_ALT_RECONNECTING": "حدث انقطاع في الشبكة. جارٍ إعادة الاتصال…",
  "CONNECTIVITY_STATUS_ALT_RENDER_ERROR": "خطأ في العرض. الرجاء فحص وحدة التحكم أو الاتصال بمطور الروبوت.",
  "CONNECTIVITY_STATUS_ALT_SLOW_CONNECTION": "يستغرق وقتًا أطول من المعتاد للاتصال.",
  "CONNECTIVITY_STATUS_ALT": "حالة الاتصال: $1",
  "FILE_CONTENT_ALT": "'$1'",
  "FILE_CONTENT_DOWNLOADABLE_ALT": "تنزيل ملف '$1'",
  "FILE_CONTENT_DOWNLOADABLE_WITH_SIZE_ALT": "تنزيل الملف '$1' بحجم $2",
  "FILE_CONTENT_WITH_SIZE_ALT": "'$1' بحجم $2",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_BODY": "مفتاح Enter",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_HEADER": "الوصول إلى العناصر في الرسالة",
  "KEYBOARD_HELP_CHAT_HISTORY_HEADER": "محفوظات الدردشة",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_BODY": "مفتاح Escape",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_HEADER": "الخروج من الرسالة",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_BODY": "مفتاح Tab\nمفتاح Shift + tab",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_HEADER": "التنقل بين العناصر في رسالة",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_BODY": "مفتاح السهم لأعلى\nمفتاح السهم لأسفل",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_HEADER": "التنقل بين الرسائل",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_BODY": "مفتاح Enter",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_HEADER": "القيام بالإجراء",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_BODY": "مفتاح Tab\nمفتاح Shift + tab",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_HEADER": "التنقل بين العناصر في نافذة الدردشة",
  "KEYBOARD_HELP_CHAT_WINDOW_HEADER": "نافذة الدردشة",
  "KEYBOARD_HELP_CLOSE_BUTTON_ALT": "إغلاق",
  "KEYBOARD_HELP_HEADER": "عناصر تحكم لوحة المفاتيح",
  "MARKDOWN_EXTERNAL_LINK_ALT": "فتح في نافذة جديدة؛ خارجية.",
  "RECEIPT_CARD_TAX": "الضريبة",
  "RECEIPT_CARD_TOTAL": "الإجمالي‬",
  "RECEIPT_CARD_VAT": "ضريبة القيمة المضافة",
  "SEND_BOX_IS_EMPTY_TOOLTIP_ALT": "لا يمكن إرسال رسالة فارغة.",
  "SPEECH_INPUT_LISTENING": "جارٍ الاستماع…",
  "SPEECH_INPUT_MICROPHONE_BUTTON_CLOSE_ALT": "الميكروفون متوقف عن التشغيل",
  "SPEECH_INPUT_MICROPHONE_BUTTON_OPEN_ALT": "الميكروفون قيد التشغيل",
  "SPEECH_INPUT_STARTING": "البدء…",
  "SUGGESTED_ACTIONS_LABEL_ALT": "الإجراءات المقترحة",
  "TEXT_INPUT_ALT": "مربع إدخال الرسالة",
  "TEXT_INPUT_PLACEHOLDER": "اكتب رسالتك",
  "TEXT_INPUT_SEND_BUTTON_ALT": "‏‏إرسال",
  "TEXT_INPUT_SPEAK_BUTTON_ALT": "تحدّث",
  "TEXT_INPUT_UPLOAD_BUTTON_ALT": "تحميل الملف",
  "TOAST_ACCORDION_FEW": "$1 إعلامات: انقر هنا للاطلاع على التفاصيل",
  "TOAST_ACCORDION_MANY": "$1 إعلامات: انقر هنا للاطلاع على التفاصيل",
  "TOAST_ACCORDION_OTHER": "$1 إعلامات: انقر هنا للاطلاع على التفاصيل",
  "TOAST_ACCORDION_TWO": "$1 إعلامات: انقر هنا للاطلاع على التفاصيل",
  "TOAST_ALT_ERROR": "‏‏الخطأ",
  "TOAST_ALT_INFO": "المعلومات",
  "TOAST_ALT_SUCCESS": "نجاح",
  "TOAST_ALT_WARN": "تحذير",
  "TOAST_DISMISS_BUTTON": "رفض",
  "TOAST_TITLE_ALT": "الإعلام",
  "TRANSCRIPT_ARIA_LABEL_ALT": "محفوظات الدردشة، اضغط على مفاتيح الأسهم للتنقل.",
  "TRANSCRIPT_ARIA_ROLE_ALT": "محفوظات الدردشة",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_LABEL_ALT": "الرسالة تفاعلية. اضغط على مفتاح shift tab من مرتين إلى 3 مرات للتبديل إلى محفوظات الدردشة. ثم انقر فوق الرسالة للتفاعل.",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_WITH_LINKS_LABEL_ALT": "هناك ارتباط أو أكثر في الرسالة. اضغط على مفتاح shift tab من مرتين إلى 3 مرات للتبديل إلى محفوظات الدردشة. ثم انقر فوق الرسالة للتفاعل.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_LABEL_ALT": "تتضمن الرسالة إجراءات مقترحة. اضغط على shift tab من مرتين إلى 3 مرات لتحديدها.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_WITH_ACCESS_KEY_LABEL_ALT": "تتضمن الرسالة إجراءات مقترحة. اضغط على $1 لتحديدها.",
  "TRANSCRIPT_LIVE_REGION_SEND_FAILED_ALT": "فشل إرسال الرسالة.",
  "TRANSCRIPT_MORE_MESSAGES": "المزيد من الرسائل",
  "TRANSCRIPT_NEW_MESSAGES": "رسائل جديدة",
  "TRANSCRIPT_TERMINATOR_TEXT": "نهاية محفوظات الدردشة",
  "TYPING_INDICATOR_ALT": "إظهار مؤشر الكتابة",
  "TYPING_INDICATOR_SINGLE_TEXT": "$1 يكتب الآن.",
  "TYPING_INDICATOR_MULTIPLE_TEXT": "$1 وغيره يكتبون الآن."
}