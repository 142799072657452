/* eslint-disable react/jsx-boolean-value */
import React from 'react'
import { useLocaleStore } from '../Contexts/localeStore'
import { useView } from '../Contexts/ViewContext'
import type { RetorikActivity } from '../../models/activityTypes'
import { getTextWithoutSpeechMarkdown } from '../../utils/speechMarkdownUtils'
import Attachments from '../Attachments/Attachments'

interface HistoryQAProps {
  activity: RetorikActivity
  isQuestion: boolean
}

const HistoryQA = ({ activity, isQuestion }: HistoryQAProps): JSX.Element => {
  const { locale } = useLocaleStore()
  const { isMobile } = useView()
  const time = activity.localTimestamp
    ? new Date(activity.localTimestamp)
    : activity.timestamp
    ? new Date(activity.timestamp)
    : new Date()

  return activity.text || !!activity.attachments?.length ? (
    <div
      className={`${
        isMobile ? 'rf-w-full' : 'rf-max-w-full'
      } rf-flex rf-flex-col rf-gap-1`}
    >
      {/* Time */}
      <div className='rf-text-[#9A9A9A]'>
        {time.toLocaleString(locale, {
          hour: 'numeric',
          minute: 'numeric'
        })}
      </div>

      {/* Question or Answer */}
      <div
        className={`${
          isQuestion
            ? 'rf-text-textModePanelConversationUser'
            : 'rf-text-textModePanelConversationBot'
        }`}
      >
        {activity.htmlText ? (
          <div
            dangerouslySetInnerHTML={{
              __html: activity.htmlText
            }}
          />
        ) : activity.text ? (
          getTextWithoutSpeechMarkdown(activity.text)
        ) : (
          ''
        )}
        {/* Display attachments if there are some and the display mode wasn't a list */}
        {!!activity.attachments?.length &&
          !(
            activity.attachmentLayout &&
            activity.attachmentLayout.toLowerCase() === 'davilist'
          ) && (
            <div
              className={`rf-w-full rf-min-h-16 rf-overflow-x-hidden rf-overflow-y-auto ${
                activity.text && 'rf-pt-4'
              }`}
            >
              <Attachments activity={activity} history={true} />
            </div>
          )}
      </div>
    </div>
  ) : (
    <React.Fragment />
  )
}

export default HistoryQA
