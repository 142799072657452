/* eslint-disable react/jsx-boolean-value */
import React from 'react'
import { useView } from '../Contexts/ViewContext'
import { DeviceType } from '../../models/enums'
import type { TemplateDefaultProps } from '../../models/types'
import { FullScreenRemote } from '.'
import CloseButton from '../Common/CloseButton'
import AbsoluteLargeClosingButton from '../Utils/AbsoluteLargeClosingButton'
import { BackButton } from '../Menu/Common'

type FullScreenRemoteClosingProps = TemplateDefaultProps & {
  closeButtonClassName?: string
  remoteCloseButtonZIndex?: string
  mobileReturnTitle?: string
  handleClose: () => void
  onClick?: () => void
}

const FullScreenRemoteClosing = ({
  className,
  background,
  closeButtonClassName,
  remoteCloseButtonZIndex,
  mobileReturnTitle,
  handleClose,
  onClick,
  children
}: FullScreenRemoteClosingProps): JSX.Element => {
  const { currentDeviceType, isMobile } = useView()

  return (
    <React.Fragment>
      <FullScreenRemote
        className={className}
        background={background}
        onClick={onClick}
      >
        {/* Close button for landscape and borne modes */}
        {currentDeviceType === DeviceType.landscape && (
          <CloseButton
            onClick={handleClose}
            className={closeButtonClassName}
            showLabel={true}
          />
        )}

        {/* Close button for mobile / widget modes if it is especilly demanded */}
        {isMobile && mobileReturnTitle && (
          <BackButton
            title={mobileReturnTitle}
            handleBack={handleClose}
            className='rf-p-4 rf-text-size-auto'
          />
        )}

        {children}
      </FullScreenRemote>

      {currentDeviceType === DeviceType.borne && (
        <AbsoluteLargeClosingButton
          dashboardVisible={true}
          zIndexClassname={remoteCloseButtonZIndex}
          onClick={handleClose}
        />
      )}
    </React.Fragment>
  )
}

export default FullScreenRemoteClosing
