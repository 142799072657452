{
  "_comment": "[CI-LOCKED] O conteúdo deste ficheiro está bloqueado. São aceites contribuições, mas a validação das alterações pode registar atrasos.",
  "ACCESS_KEY_ALT": "Alt Shift $1",
  "ACCESS_KEY_FOR_MAC_ALT": "Controlo Opção $1",
  "ACTIVITY_BOT_SAID_ALT": "O bot $1 disse:",
  "ACTIVITY_YOU_SAID_ALT": "Disse:",
  "ACTIVITY_BOT_ATTACHED_ALT": "Bot anexado:",
  "ACTIVITY_ERROR_BOX_TITLE": "Mensagem de erro",
  "ACTIVITY_INTERACTIVE_FOOTNOTE_ALT": "Clique para interagir.",
  "ACTIVITY_INTERACTIVE_REASON_INTERACTIVE_CONTENT_ALT": "A mensagem é interativa.",
  "ACTIVITY_INTERACTIVE_REASON_LINK_ALT": "A mensagem contém uma ou mais ligações.",
  "ACTIVITY_INTERACTIVE_REASON_SEND_FAILED_ALT": "Falha ao enviar.",
  "ACTIVITY_YOU_ATTACHED_ALT": "Anexou:",
  "ACTIVITY_NUM_ATTACHMENTS_ONE_ALT": "1 anexo.",
  "ACTIVITY_NUM_ATTACHMENTS_FEW_ALT": "$1 anexos.",
  "ACTIVITY_NUM_ATTACHMENTS_MANY_ALT": "$1 anexos.",
  "ACTIVITY_NUM_ATTACHMENTS_OTHER_ALT": "$1 anexos.",
  "ACTIVITY_NUM_ATTACHMENTS_TWO_ALT": "$1 anexos.",
  "ACTIVITY_STATUS_SEND_FAILED_RETRY": "Falha ao enviar. [Repetir][RETRY].",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENDING": "A enviar",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENT_AT": "Enviado às $1",
  "ACTIVITY_STATUS_TIMESTAMP_JUST_NOW": "Agora mesmo",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_HOUR_AGO": "Há uma hora",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_MINUTE_AGO": "Há um minuto",
  "ACTIVITY_STATUS_TIMESTAMP_TODAY": "Hoje",
  "ACTIVITY_STATUS_TIMESTAMP_YESTERDAY": "Ontem",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_PARSE": "Erro de análise de Cartão Adaptável",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_RENDER": "Erro de composição de Cartão Adaptável",
  "ATTACHMENT_AUDIO": "Um clip de áudio.",
  "ATTACHMENT_CARD": "Um cartão: $1 $2 $3",
  "ATTACHMENT_FILE": "Um ficheiro: $1",
  "ATTACHMENT_IMAGE": "Uma imagem",
  "ATTACHMENT_TEXT": "Um texto: $1",
  "ATTACHMENT_VIDEO": "Um clip de vídeo",
  "CAROUSEL_FLIPPER_LEFT_ALT": "Esquerda",
  "CAROUSEL_FLIPPER_RIGHT_ALT": "Direita",
  "CONNECTIVITY_STATUS_ALT_CONNECTED": "Ligado",
  "CONNECTIVITY_STATUS_ALT_CONNECTING": "A ligar…",
  "CONNECTIVITY_STATUS_ALT_FATAL": "Não é possível ligar.",
  "CONNECTIVITY_STATUS_ALT_RECONNECTING": "Ocorreu uma interrupção de rede. A restabelecer ligação…",
  "CONNECTIVITY_STATUS_ALT_RENDER_ERROR": "Erro de composição. Verifique a consola ou contacte o programador do bot.",
  "CONNECTIVITY_STATUS_ALT_SLOW_CONNECTION": "O estabelecimento da ligação está a demorar mais tempo do que o esperado.",
  "CONNECTIVITY_STATUS_ALT": "Estado de Conectividade: $1",
  "FILE_CONTENT_ALT": "\"$1\"",
  "FILE_CONTENT_DOWNLOADABLE_ALT": "Transferir o ficheiro \"$1\"",
  "FILE_CONTENT_DOWNLOADABLE_WITH_SIZE_ALT": "Transferir o ficheiro \"$1\" com um tamanho de $2",
  "FILE_CONTENT_WITH_SIZE_ALT": "\"$1\" com um tamanho de $2",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_BODY": "Tecla Enter",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_HEADER": "Aceder a itens na mensagem",
  "KEYBOARD_HELP_CHAT_HISTORY_HEADER": "Histórico de chat",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_BODY": "Tecla Esc",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_HEADER": "Sair da mensagem",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_BODY": "Tecla de tabulação\nShift + tecla de tabulação",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_HEADER": "Mover-se entre itens numa mensagem",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_BODY": "Tecla de seta para cima\nTecla de seta para baixo",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_HEADER": "Mover-se entre mensagens",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_BODY": "Tecla Enter",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_HEADER": "Efetuar ação",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_BODY": "Tecla de tabulação\nShift + tecla de tabulação",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_HEADER": "Mover-se entre itens na janela de chat",
  "KEYBOARD_HELP_CHAT_WINDOW_HEADER": "Janela de chat",
  "KEYBOARD_HELP_CLOSE_BUTTON_ALT": "Fechar",
  "KEYBOARD_HELP_HEADER": "Controlos do teclado",
  "MARKDOWN_EXTERNAL_LINK_ALT": "Abre numa nova janela externa.",
  "RECEIPT_CARD_TAX": "Imposto",
  "RECEIPT_CARD_TOTAL": "Total",
  "RECEIPT_CARD_VAT": "IVA",
  "SEND_BOX_IS_EMPTY_TOOLTIP_ALT": "Não é possível enviar uma mensagem vazia.",
  "SPEECH_INPUT_LISTENING": "A escutar…",
  "SPEECH_INPUT_MICROPHONE_BUTTON_CLOSE_ALT": "Microfone desativado",
  "SPEECH_INPUT_MICROPHONE_BUTTON_OPEN_ALT": "Microfone ativado",
  "SPEECH_INPUT_STARTING": "A iniciar…",
  "SUGGESTED_ACTIONS_LABEL_ALT": "Ações sugeridas",
  "TEXT_INPUT_ALT": "Caixa de entrada de mensagens",
  "TEXT_INPUT_PLACEHOLDER": "Escreva a sua mensagem",
  "TEXT_INPUT_SEND_BUTTON_ALT": "Enviar",
  "TEXT_INPUT_SPEAK_BUTTON_ALT": "Falar",
  "TEXT_INPUT_UPLOAD_BUTTON_ALT": "Carregar ficheiro",
  "TOAST_ACCORDION_FEW": "$1 Notificações: clique aqui para ver os detalhes",
  "TOAST_ACCORDION_MANY": "$1 Notificações: clique aqui para ver os detalhes",
  "TOAST_ACCORDION_OTHER": "$1 Notificações: clique aqui para ver os detalhes",
  "TOAST_ACCORDION_TWO": "$1 Notificações: clique aqui para ver os detalhes",
  "TOAST_ALT_ERROR": "Erro",
  "TOAST_ALT_INFO": "Informações",
  "TOAST_ALT_SUCCESS": "Êxito",
  "TOAST_ALT_WARN": "Aviso",
  "TOAST_DISMISS_BUTTON": "Dispensar",
  "TOAST_TITLE_ALT": "Notificação",
  "TRANSCRIPT_ARIA_LABEL_ALT": "Histórico de chat, prima as teclas de seta para navegar.",
  "TRANSCRIPT_ARIA_ROLE_ALT": "histórico de chat",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_LABEL_ALT": "A mensagem é interativa. Prima Shift + tecla de tabulação 2 a 3 vezes para mudar para o histórico de chat. Em seguida, clique na mensagem para interagir.",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_WITH_LINKS_LABEL_ALT": "A mensagem contém uma ou mais ligações. Prima Shift + tecla de tabulação 2 a 3 vezes para mudar para o histórico de chat. Em seguida, clique na mensagem para interagir.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_LABEL_ALT": "A mensagem tem ações sugeridas. Prima Shift Tabulação 2 a 3 vezes para as selecionar.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_WITH_ACCESS_KEY_LABEL_ALT": "A mensagem tem ações sugeridas. Prima $1 para as selecionar.",
  "TRANSCRIPT_LIVE_REGION_SEND_FAILED_ALT": "Falha ao enviar a mensagem.",
  "TRANSCRIPT_MORE_MESSAGES": "Mais mensagens",
  "TRANSCRIPT_NEW_MESSAGES": "Novas mensagens",
  "TRANSCRIPT_TERMINATOR_TEXT": "Fim do histórico de chat",
  "TYPING_INDICATOR_ALT": "A mostrar o indicador de escrita",
  "TYPING_INDICATOR_SINGLE_TEXT": "$1 está a escrever.",
  "TYPING_INDICATOR_MULTIPLE_TEXT": "$1 e outros utilizadores estão a escrever."
}