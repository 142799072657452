import React from 'react'
import type { IconDefaultProps } from '../../../models/types'

const PhotoCameraIcon = ({
  className,
  color
}: IconDefaultProps): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 67.258 60.532'
      className={className || 'rf-w-6 rf-h-6'}
    >
      <g transform='translate(15.629 13.766)'>
        <path
          d='M34.722,23.961A10.761,10.761,0,1,1,23.961,13.2,10.761,10.761,0,0,1,34.722,23.961Z'
          transform='translate(-5.961 -4.098)'
          fill={color || '#f1f1f1'}
        />
        <path
          d='M26.54,3,20.386,9.726H9.726A6.746,6.746,0,0,0,3,16.452V56.806a6.746,6.746,0,0,0,6.726,6.726H63.532a6.746,6.746,0,0,0,6.726-6.726V16.452a6.746,6.746,0,0,0-6.726-6.726H52.872L46.718,3ZM36.629,53.443A16.814,16.814,0,1,1,53.443,36.629,16.821,16.821,0,0,1,36.629,53.443Z'
          transform='translate(-18.629 -16.766)'
          fill={color || '#f1f1f1'}
        />
      </g>
    </svg>
  )
}

export default PhotoCameraIcon
