/* eslint-disable react/jsx-boolean-value */
import React from 'react'
import { useRetorik } from '../Contexts/RetorikContext'
import type { UrlData } from '../../models/linkAttachment'
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter'
import { checkFileNameInUrl } from '../../utils/checkFileNameInUrl'
import BorderlessButton from './Utils/BorderlessButton'

interface MultiLinkAttachmentProps {
  urlData: Array<UrlData>
  title?: string
  description?: string
  height?: number
}

const MultiLinkAttachment = ({
  urlData,
  title,
  description,
  height
}: MultiLinkAttachmentProps): JSX.Element => {
  const { configuration } = useRetorik()

  return (
    <div
      className={`rf-w-full rf-p-[25px] rf-flex rf-flex-col rf-items-center rf-gap-2 ${
        title || description ? 'rf-justify-between' : 'rf-justify-around'
      } rf-min-h-inherit rf-rounded rf-bg-cardFrameBackground rf-text-cardFrameText rf-overflow-y-scroll rf-scrollbar-thin rf-touch-none`}
      style={{
        height: height
      }}
    >
      {/* Title if present */}
      {title && (
        // Title on the left if there is a description, middle otherwise
        <div
          className={`rf-w-full rf-mb-4 ${
            description ? 'rf-text-left' : 'rf-text-center'
          } rf-title-size-auto ${
            configuration.isUsedOnBorne ? 'rf-truncate' : 'rf-line-clamp-2'
          }`}
        >
          {capitalizeFirstLetter(title)}
        </div>
      )}

      {/* Description if present */}
      {description && <div className='rf-mb-4'>{description}</div>}

      {/* Links */}
      {urlData.map((data, key) => {
        return (
          <BorderlessButton
            key={key}
            url={data.url}
            text={data.title || checkFileNameInUrl(data.url) || data.url}
            download={!!data.download}
          />
        )
      })}
    </div>
  )
}

export default MultiLinkAttachment
