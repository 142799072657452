/* eslint-disable camelcase *//* eslint-disable prettier/prettier */
const authenticate = async (licence: string) => {
  const sessionId = await fetch(
    'https://paas.retorik.fr/rest?method=authentification',
    {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({
        aLicence: licence
      })
    }
  )
    .then((response) => {
      return response.json()
    })
    .then((data) => {
      if (data?.authentification?.status === 'success') {
        return data.authentification
      }
      return false
    })
    .catch((error) => {
      console.log(error)
      return false
    })

  return sessionId
}

const sendMessage = async (licence: string, text: string) => {
  const auth = await authenticate(licence)

  if (auth.status === 'success') {
    const requestData = {
      idSession: auth.response,
      aFormat: 'mp3',
      aText: text
    }
    await fetch('https://paas.retorik.fr/rest?method=tts_send', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify(requestData)
    })
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        if (data?.tts_send?.status === 'success') {
          play(data?.tts_send.response)
        }
        return data
      })
      .catch((error) => console.log(error))
  } else {
    console.log('Authentication error')
  }
}

const play = (mp3: string) => {
  const audioElement = document.getElementById(
    'spirit-engine-sprite-audio'
  ) as HTMLAudioElement
  if (audioElement) {
    audioElement.src = mp3
    audioElement.play()
  }
}

export default sendMessage
