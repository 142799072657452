import React from 'react'
import type { IconDefaultProps } from '../../../models/types'

const ParametersIcon = ({
  className,
  color
}: IconDefaultProps): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      className={className || 'rf-h-6 rf-w-6'}
      viewBox='0 0 27.005 27.012'
    >
      <defs>
        <filter id='Icon_ionic-ios-settings'>
          <feOffset dy='3' in='SourceAlpha' />
          <feGaussianBlur stdDeviation='3' result='blur' />
          <feFlood floodOpacity='0.161' result='color' />
          <feComposite operator='out' in='SourceGraphic' in2='blur' />
          <feComposite operator='in' in='color' />
          <feComposite operator='in' in2='SourceGraphic' />
        </filter>
      </defs>
      <g>
        <path
          d='M29.276,18a3.475,3.475,0,0,1,2.229-3.242,13.774,13.774,0,0,0-1.667-4.016,3.522,3.522,0,0,1-1.414.3,3.467,3.467,0,0,1-3.172-4.881A13.733,13.733,0,0,0,21.245,4.5a3.472,3.472,0,0,1-6.484,0,13.774,13.774,0,0,0-4.016,1.667,3.467,3.467,0,0,1-3.172,4.881,3.407,3.407,0,0,1-1.414-.3A14.079,14.079,0,0,0,4.5,14.768a3.474,3.474,0,0,1,.007,6.484,13.774,13.774,0,0,0,1.667,4.016,3.469,3.469,0,0,1,4.578,4.578,13.855,13.855,0,0,0,4.016,1.667,3.466,3.466,0,0,1,6.47,0,13.774,13.774,0,0,0,4.016-1.667,3.472,3.472,0,0,1,4.578-4.578A13.855,13.855,0,0,0,31.5,21.252,3.492,3.492,0,0,1,29.276,18Zm-11.21,5.619A5.626,5.626,0,1,1,23.692,18,5.625,5.625,0,0,1,18.066,23.622Z'
          transform='translate(-4.5 -4.5)'
          fill={color || '#a4a7af'}
        />
        <g
          transform='matrix(1, 0, 0, 1, 0, 0)'
          filter='url(#Icon_ionic-ios-settings)'
        >
          <path
            d='M29.276,18a3.475,3.475,0,0,1,2.229-3.242,13.774,13.774,0,0,0-1.667-4.016,3.522,3.522,0,0,1-1.414.3,3.467,3.467,0,0,1-3.172-4.881A13.733,13.733,0,0,0,21.245,4.5a3.472,3.472,0,0,1-6.484,0,13.774,13.774,0,0,0-4.016,1.667,3.467,3.467,0,0,1-3.172,4.881,3.407,3.407,0,0,1-1.414-.3A14.079,14.079,0,0,0,4.5,14.768a3.474,3.474,0,0,1,.007,6.484,13.774,13.774,0,0,0,1.667,4.016,3.469,3.469,0,0,1,4.578,4.578,13.855,13.855,0,0,0,4.016,1.667,3.466,3.466,0,0,1,6.47,0,13.774,13.774,0,0,0,4.016-1.667,3.472,3.472,0,0,1,4.578-4.578A13.855,13.855,0,0,0,31.5,21.252,3.492,3.492,0,0,1,29.276,18Zm-11.21,5.619A5.626,5.626,0,1,1,23.692,18,5.625,5.625,0,0,1,18.066,23.622Z'
            transform='translate(-4.5 -4.5)'
            fill='#fff'
          />
        </g>
      </g>
    </svg>
  )
}

export default ParametersIcon
