import React, { useState, useEffect, useRef } from 'react'
import { useList } from '../../Contexts/ListContext'
import { CommentsPennantIcon } from '../../Icons/DetailedPOIIcons'

type ComponentsPennantType = {
  className?: string
  customerReviews: number
}

const CommentsPennant = ({
  className,
  customerReviews
}: ComponentsPennantType): JSX.Element => {
  const { color } = useList()
  const [widthInPx, setWidthInPx] = useState<number>(0)
  const pennantRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (pennantRef.current) {
      setWidthInPx(Math.ceil(pennantRef.current.offsetWidth / 2))
    }
  }, [pennantRef])

  return (
    <div
      className={`${className} rf-flex rf-flex-col rf-justify-center rf-text-white`}
      ref={pennantRef}
    >
      <div
        className='rf-flex rf-flex-col rf-items-center rf-p-1'
        style={{ backgroundColor: color }}
      >
        <CommentsPennantIcon className='rf-w-6 large:rf-w-8 rf-py-2 vertical:rf-py-1' />
        <div className='rf-font-bold rf-small-size-auto rf-text-center'>
          {customerReviews}/10
        </div>
      </div>
      <div
        className='rf-w-0 rf-h-0 rf-border-t-0 rf-border-x-primary rf-border-b-transparent'
        style={{
          borderLeftColor: color,
          borderRightColor: color,
          borderBottomWidth: (widthInPx * 2) / 3,
          borderLeftWidth: widthInPx,
          borderRightWidth: widthInPx
        }}
      />
    </div>
  )
}

export default CommentsPennant
