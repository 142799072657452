import { buildCard } from './adaptiveCardBuilder'
import { CONTENT_TYPE_HERO_CARD } from '../models/attachmentTypes'

const processAttachments = (
  attachments?: Array<any>
): Array<any> | undefined => {
  if (attachments && attachments.length > 0) {
    let tempAttachments: Array<any> = []

    attachments.forEach((attachment) => {
      if (attachment.contentType === CONTENT_TYPE_HERO_CARD) {
        const heroToAdaptive = buildCard({
          ...attachment.content
        })
        tempAttachments = [...tempAttachments, { ...heroToAdaptive }]
      } else {
        tempAttachments = [...tempAttachments, { ...attachment }]
      }
    })

    return tempAttachments
  }

  return undefined
}

export { processAttachments }
