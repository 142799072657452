{
  "fr-FR": {
    "1": "accueil",
    "2": "chaîne info live",
    "3": "météo",
    "4": "numéros d'urgence",
    "1000": "historique",
    "1001": "tutoriel"
  },
  "en-US": {
    "1": "home",
    "2": "live info channel",
    "3": "weather",
    "4": "emergency numbers",
    "1000": "history",
    "1001": "tutorial"
  },
  "es-ES": {
    "1": "home",
    "2": "live info channel",
    "3": "weather",
    "4": "emergency numbers",
    "1000": "history",
    "1001": "tutorial"
  },
  "de-DE": {
    "1": "home",
    "2": "live info channel",
    "3": "weather",
    "4": "emergency numbers",
    "1000": "history",
    "1001": "tutorial"
  }
}
