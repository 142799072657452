{
  "fr-FR": {
    "warningtext": "Vous allez accéder à la chaîne info, le son de votre appareil va être activé",
    "warningcontinue": "Continuer",
    "warningback": "Annuler",
    "live": "direct",
    "continuous": "en continu"
  },
  "en-US": {
    "warningtext": "You are about to access the information channel, the sound of your device will be activated",
    "warningcontinue": "Continue",
    "warningback": "Abort",
    "live": "live",
    "continuous": "continuous"
  },
  "es-ES": {
    "warningtext": "You are about to access the information channel, the sound of your device will be activated",
    "warningcontinue": "Continue",
    "warningback": "Abort",
    "live": "live",
    "continuous": "continuous"
  },
  "de-DE": {
    "warningtext": "You are about to access the information channel, the sound of your device will be activated",
    "warningcontinue": "Continue",
    "warningback": "Abort",
    "live": "live",
    "continuous": "continuous"
  }
}
