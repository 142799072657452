import React, { useEffect, useState } from 'react'
import { RetorikProvider } from './RetorikContext'
import { SpeechProvider, SpeechProviderProps } from './SpeechContext'
import { ViewProvider } from './ViewContext'
import type { ViewsProviderProps } from './ViewContext'
import fetchAgentData from '../../utils/fetchAgentData'
import type { AddressData, AgentData, Configuration } from '../../models/types'
import { checkLocale } from '../../utils/checkLocale'
import LocaleChangeEventListener from '../Utils/LocaleChangeEventListener'
import {
  useLocaleStore,
  setLocale,
  fetchSupportedLanguages
} from './localeStore'

type ContextProviderProps = {
  skipLoader?: boolean
  mode: number
  config: Configuration
  addressData: AddressData
  agentSource:
    | string
    | {
        url: string
        name?: string
      }
  isConfigUpdated: boolean
} & SpeechProviderProps &
  ViewsProviderProps

const ContextProvider = ({
  skipLoader,
  mode,
  viewsConfiguration,
  config,
  addressData,
  agentSource,
  ponyfillFactoryCredentials,
  themeColors,
  children,
  isConfigUpdated
}: ContextProviderProps): JSX.Element | null => {
  const [agentManifest, setAgentManifest] = useState<AgentData>(null)
  const { locale, supported, defaultLocale } = useLocaleStore()

  /**
   * On component mount :
   *  - fetch supported languages on studio retorik
   */
  useEffect(() => {
    fetchSupportedLanguages(addressData)
  }, [])

  /**
   * On supported state change :
   *  - set locale state depending on data retrieved after fetching available languages and data from config prop
   */
  useEffect(() => {
    if (supported.length) {
      setLocale(
        checkLocale({ all: supported, default: defaultLocale }, config.locales)
      )
    }
  }, [supported, config, isConfigUpdated])
  /**
   * On agentSource prop change :
   *  - fetch agent data from the url present in agentSource prop
   *  - set the agent's name if present in agentSource prop
   *  - set agentManifest state
   */
  useEffect(() => {
    const getData = async (): Promise<void> => {
      const url =
        typeof agentSource === 'string' ? agentSource : agentSource.url
      const data = await fetchAgentData(url)
      data &&
        typeof agentSource !== 'string' &&
        agentSource.name &&
        (data.name = agentSource.name)

      setAgentManifest(data)
    }
    getData()
  }, [agentSource])

  return locale && supported && agentManifest ? (
    <ViewProvider
      viewsConfiguration={viewsConfiguration}
      themeColors={themeColors}
    >
      <RetorikProvider
        skipLoader={skipLoader}
        chosenMode={mode}
        config={config}
        currentLocale={locale}
        allLocales={supported}
        agentManifest={agentManifest}
        addressData={addressData}
      >
        <SpeechProvider ponyfillFactoryCredentials={ponyfillFactoryCredentials}>
          {children}
          <LocaleChangeEventListener />
        </SpeechProvider>
      </RetorikProvider>
    </ViewProvider>
  ) : (
    <React.Fragment />
  )
}

export default ContextProvider
