import React, { useEffect, useState } from 'react'

type Dimensions = {
  width: number
  height: number
}

const useRefDimensions = (ref: React.RefObject<HTMLElement>): Dimensions => {
  const [dimensions, setDimensions] = useState<Dimensions>({
    width: 1,
    height: 1
  })
  const [windowSize, setWindowSize] = useState<{
    width: number
    height: number
  }>({ width: window.innerWidth, height: window.innerHeight })
  const handleResize = (): void => {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight })
  }
  useEffect(() => {
    window.addEventListener('resize', handleResize)
    if (ref.current) {
      const { width, height } = ref.current.getBoundingClientRect()
      setDimensions({ width, height })
    }
    return (): void => window.removeEventListener('resize', handleResize)
  }, [ref, windowSize])
  return dimensions
}

export default useRefDimensions
